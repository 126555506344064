import { Editor, Element, Range, Transforms, Location } from 'slate';
import { ReactEditor } from 'slate-react';
import { QuoteWithPhotoFormData } from '@area/redactor/components/Wysiwyg/components/Toolbar/components/QuoteWithPhoto/QuoteWithPhotoForm';
import { CustomEditor, QuoteWithPhotoElement } from '../types';

export const isQuoteWithPhotoActive = (editor: CustomEditor) => {
  const [quote] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'quote-with-photo',
  });
  return !!quote;
};

export const addQuoteWithPhoto = (editor: CustomEditor, formData: QuoteWithPhotoFormData, selection?: Location) => {
  if (isQuoteWithPhotoActive(editor)) {
    removeQuoteWithPhoto(editor);
  }

  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const div: QuoteWithPhotoElement = {
    type: 'quote-with-photo',
    quote: formData.quote,
    imageUrl: formData.imageUrl,
    imageFileUrl: formData.imageFileUrl,
    imageFileId: formData.imageFileId,
    authorName: formData.authorName,
    authorInfo: formData.authorInfo || '',
    children: [{ text: formData.quote }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [div, { type: 'paragraph', children: [{ text: '' }] }], { at: selection });
  } else {
    Transforms.wrapNodes(editor, div, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const updateQuoteWithPhoto = (
  editor: CustomEditor,
  formData: QuoteWithPhotoFormData,
  element: QuoteWithPhotoElement,
) => {
  const path = ReactEditor.findPath(editor, element);

  const div: QuoteWithPhotoElement = {
    type: 'quote-with-photo',
    quote: formData.quote,
    imageUrl: formData.imageUrl,
    imageFileUrl: formData.imageFileUrl,
    imageFileId: formData.imageFileId,
    authorName: formData.authorName,
    authorInfo: formData.authorInfo,
    children: [{ text: formData.quote }],
  };

  Transforms.delete(editor, { at: path });
  Transforms.insertNodes(editor, div, { at: path });
};

export const deleteQuoteWithPhoto = (editor: CustomEditor, element: QuoteWithPhotoElement): void => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.delete(editor, { at: path });
};

export const removeQuoteWithPhoto = (editor: CustomEditor) => {
  Transforms.removeNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'quote-with-photo',
  });
};

export const insertQuoteWithPhoto = (editor: CustomEditor, formData: QuoteWithPhotoFormData, selection?: Location) => {
  if (editor.selection) {
    addQuoteWithPhoto(editor, formData, selection);
  }
};
