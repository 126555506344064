import { FC } from 'react';
import { useParams } from 'react-router';
import { Modal } from '@components/Modal';
import { Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormField } from '@components/FormField';
import indents from '@styles/indents.module.scss';
import { useApplicationControllerRejectApplicationMutation } from '@api/admin/adminGeneratedApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { useGetApplicationById } from '@area/applications/hooks/useGetApplicationById';
import styles from './style.module.scss';
import { TValues, validationSchema } from './validationSchema';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CancelParticipationModal: FC<Props> = ({ open, onClose }) => {
  const { id } = useParams();
  const { refetch } = useGetApplicationById(id!);
  const resolver = useYupValidationResolver<TValues>(validationSchema);
  const form = useForm({
    resolver,
  });
  const [reject, { isLoading }] = useApplicationControllerRejectApplicationMutation();
  const handleSubmit = form.handleSubmit(async ({ reason }) => {
    await reject({ id: id!, applicationRejectRequest: { reason } });
    await refetch();
    onClose();
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Отклонить участие в программе"
      fullWidth={false}
      className={styles.root}
      loading={isLoading}
      actions={
        <Grid container flexDirection="row" gap={indents.s}>
          <Grid item>
            <Button variant="contained" onClick={handleSubmit}>
              Да, отклонить
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined">
              Отмена
            </Button>
          </Grid>
        </Grid>
      }>
      <Typography variant="body2">Вы действительно хотите отклонить участие в программе?</Typography>
      <FormProvider {...form}>
        <FormField
          className={styles.reasonField}
          name="reason"
          type="text"
          title="Причина *"
          titleVariant="subtitle2"
          fullWidth
        />
      </FormProvider>
    </Modal>
  );
};
