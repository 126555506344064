import { useState, useMemo } from 'react';
import { Column } from '@components/Table/types';
import { adminApi } from '@api/admin/adminApi';
import {
  useEducationalOrganizationControllerSetEduOrgGrantMutation,
  EducationalOrganizationResponse,
} from '@api/admin/adminGeneratedApi';
import { Input } from '../components/Input';

export const useScholarships = () => {
  const [fetchOrgsGrants, { data, isFetching, isSuccess, isError }] =
    adminApi.endpoints.educationalOrganizationControllerGetEduOrgGrants.useLazyQuery();
  const [setGrant, { isSuccess: isSuccessSaving, isError: isErrorSaving }] =
    useEducationalOrganizationControllerSetEduOrgGrantMutation();
  const [loadingRowId, setLoadingRowId] = useState<string>();

  const columns = useMemo<Column<EducationalOrganizationResponse>[]>(() => {
    return [
      {
        title: 'ВУЗ',
        dataIndex: 'nameShort',
      },
      {
        title: 'Значение',
        dataIndex: 'value',
        width: 300,
        render: (_, record) => {
          const defaultValue = record.grant?.price ? String(record.grant?.price) : '';

          return (
            <Input
              eduId={record.id}
              setGrant={setGrant}
              defaultValue={defaultValue}
              setLoadingRowId={setLoadingRowId}
            />
          );
        },
      },
    ];
  }, [data]);

  return {
    columns,
    fetchOrgsGrants,
    data,
    isLoadingOrgs: isFetching,
    isErrorOrgs: isError,
    isFetchedOrgs: isSuccess,
    loadingRowId,
    isSuccessSaving,
    isErrorSaving,
  };
};
