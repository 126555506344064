import { createTheme, Theme } from '@mui/material';

import colors from '@styles/colors.module.scss';
import indents from '@styles/indents.module.scss';
import shadows from '@styles/shadows.module.scss';
import typography from '@styles/typography.module.scss';
import fontWeights from '@styles/font-weights.module.scss';
import borders from '@styles/borders.module.scss';

const MENU_ITEM_HEIGHT = 36;

export const innerTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: shadows.card,
            marginTop: 2,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            maxHeight: MENU_ITEM_HEIGHT * 5 + 12, // 12 - удвоенный размер паддинга (6px)
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: indents.xxxs,
            paddingBottom: indents.xxxs,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            height: MENU_ITEM_HEIGHT,
            padding: `${indents.xxxs} ${indents.xs}`,
            transition: 'background-color 0.124s ease-in',
            '&:hover': {
              backgroundColor: colors.background,
            },
            '&.Mui-selected': {
              backgroundColor: colors.lightRed,
              '&:hover': {
                backgroundColor: colors.lightRed,
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: 'Inter, sans-serif',
            fontSize: typography.bodyFontSize,
            lineHeight: typography.bodyLineHeight,
            fontWeight: fontWeights.regular,
            color: colors.white,
            backgroundColor: colors.error,
            borderRadius: borders.radiusM,
            padding: `4px ${indents.xs}`,
          },
          popper: {
            '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
              marginTop: `${indents.xxs} !important`,
            },
          },
          arrow: {
            transform: 'translateX(20px) !important',
            '&::before': {
              backgroundColor: colors.error,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  });
