import { UserCreateForm, UserUpdateForm } from '@area/users/type';
import { UserCreatorDto, UserResponse, UserUpdaterDto } from '@api/admin/adminGeneratedApi';
import { Option } from '@components/Select/types';
import { userRoleDictionary } from '@area/users/roles';

export class UserMapper {
  public static userResponseToForm(user: UserResponse): UserUpdateForm {
    return {
      firstName: user.firstName ?? '',
      middleName: user.middleName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
    };
  }

  public static userRoleToSelectOptions(role: UserResponse['role']): Option[] {
    return role.map((r) => ({ value: r as any, label: userRoleDictionary[r] }));
  }

  public static selectOptionsToUserRole(options: Option[]): UserResponse['role'] {
    return options.map(({ value }) => Number(value) as any);
  }

  public static userCreateFormToUserCreatorDto(data: UserCreateForm): UserCreatorDto {
    return {
      ...data,
      role: data.role.map(({ value }) => Number(value) as any),
    };
  }

  public static userUpdateFormToUserUpdaterDto(data: Partial<UserCreateForm>): UserUpdaterDto {
    return {
      ...data,
    } as any;
  }
}
