import { forwardRef, Fragment, Ref, SyntheticEvent, ReactNode } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  CircularProgress,
  SxProps,
} from '@mui/material';
import { debounce } from 'utils/debounce';

export interface AutocompleteProps<T>
  extends Omit<MuiAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>, 'onChange'> {
  name: string;
  value?: string;
  onChange?: (value?: T) => void;
  fetcher?: (value: string) => void;
  options: readonly T[];
  sx?: SxProps;
  loading?: boolean;
  placeholder?: string;
  startAdornmentInputIcon?: ReactNode;
}

const DEBOUNCED_DELAY = 150;

export const Autocomplete = forwardRef(<T extends unknown>(props: AutocompleteProps<T>, ref: Ref<HTMLDivElement>) => {
  const { name, value, onChange, options, fetcher, loading, placeholder, freeSolo, startAdornmentInputIcon, ...rest } =
    props;

  const onInputChange = async (_: SyntheticEvent, value: string) => {
    if (value) {
      if (freeSolo) {
        onChange?.({ [name]: value } as T);
      }
      fetcher?.(value);
    } else {
      if (freeSolo) {
        onChange?.();
      }
    }
  };

  const onLocalChange = (event: React.SyntheticEvent, value: any) => {
    onChange?.(value);
  };

  return (
    <MuiAutocomplete
      value={value || null}
      freeSolo={freeSolo}
      onInputChange={debounce(onInputChange, DEBOUNCED_DELAY)}
      onChange={debounce(onLocalChange, DEBOUNCED_DELAY)}
      noOptionsText="Нет доступных вариантов"
      loadingText="Загрузка..."
      forcePopupIcon={false}
      {...rest}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: startAdornmentInputIcon,
              endAdornment: (
                <Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
              sx: {
                '& .MuiOutlinedInput-input.MuiAutocomplete-input': {
                  padding: '0 !important',
                },
              },
            }}
          />
        );
      }}
    />
  );
});
