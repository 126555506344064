import { useParams } from 'react-router';
import { Button, CircularProgress, Typography } from '@mui/material';
import { PaymentEoOrgDocFormat } from '@area/payments/types';
import { useDownloadFile } from '@hooks/useDownloadFile';
import styles from './styles.module.scss';

export interface DownloadDocButtonProps {
  educationalOrganizationId: string;
  format: PaymentEoOrgDocFormat;
}

export const DownloadDocButton = ({ educationalOrganizationId, format }: DownloadDocButtonProps) => {
  const { id } = useParams();
  const url = `/api/payments/${id}/edu-org/${educationalOrganizationId}/download/${format}`;
  const { isDownloading, download } = useDownloadFile(url, `payment_result.${format}`);

  return (
    <Button disabled={isDownloading} className={styles.downloadButton} onClick={download}>
      {isDownloading && <CircularProgress size={18} thickness={5} className={styles.downloadButtonLoader} />}
      <Typography variant="body1" textTransform="uppercase">
        {format}
      </Typography>
    </Button>
  );
};
