import { Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { DefinitionInfoFormData } from '../components/Toolbar/components/DefinitionInfo/definitionInfoForm';
import { CustomEditor, DefinitionInfoElement } from '../types';

export const isDefinitionInfoActive = (editor: CustomEditor) => {
  const [quote] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'definition-info',
  });
  return !!quote;
};

export const addDefinitionInfo = (editor: CustomEditor, formData: DefinitionInfoFormData) => {
  if (isDefinitionInfoActive(editor)) {
    removeDefinitionInfo(editor);
  }

  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const div: DefinitionInfoElement = {
    type: 'definition-info',
    title: formData.title,
    definition: formData.definition,
    children: [{ text: formData.title }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [div, { type: 'paragraph', children: [{ text: '' }] }]);
  } else {
    Transforms.wrapNodes(editor, div, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const updateDefinitionInfo = (
  editor: CustomEditor,
  formData: DefinitionInfoFormData,
  element: DefinitionInfoElement,
) => {
  const path = ReactEditor.findPath(editor, element);

  const div: DefinitionInfoElement = {
    type: 'definition-info',
    title: formData.title,
    definition: formData.definition,
    children: [{ text: formData.title }],
  };

  Transforms.delete(editor, { at: path });
  Transforms.insertNodes(editor, div, { at: path });
};

export const deleteDefinitionInfo = (editor: CustomEditor, element: DefinitionInfoElement): void => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.delete(editor, { at: path });
};

export const removeDefinitionInfo = (editor: CustomEditor) => {
  Transforms.removeNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'definition-info',
  });
};

export const insertDefinitionInfo = (editor: CustomEditor, formData: DefinitionInfoFormData) => {
  if (editor.selection) {
    addDefinitionInfo(editor, formData);
  }
};
