import { ReactNode } from 'react';
import { Mask } from 'react-text-mask';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';

export const InputMask: Record<string, Mask> = {
  Phone: ['8', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  Otp: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
  Time: [/\d/, /\d/, ':', /\d/, /\d/],
};

export interface InputProps
  extends Omit<OutlinedTextFieldProps, 'error' | 'variant' | 'value' | 'type' | 'helperText'> {
  value?: string;
  type?: 'password' | 'text' | 'number';
  readOnly?: boolean;
  error?: string;
  helperText?: string;
  transformValue?: (value?: string) => string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  mask?: Mask | ((value: string) => Mask);
  countText?: boolean;
  maxTextLength?: number;
  showEyeIcon?: boolean; // work only with password
}
