import { FormField } from '@components/FormField';
import { Modal } from '@components/Modal';
import { Button, Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { validationSchema } from './validation';

export interface DefinitionInfoFormData {
  title: string;
  definition: string;
}

export interface DefinitionInfoFormProps {
  initialFormData?: DefinitionInfoFormData;
  onSubmit: SubmitHandler<DefinitionInfoFormData>;
  onClose: () => void;
}

export const DefinitionInfoForm: FC<DefinitionInfoFormProps> = (props) => {
  const { onClose, onSubmit, initialFormData } = props;
  const resolver = useYupValidationResolver<any>(validationSchema);

  const form = useForm({
    defaultValues: initialFormData,
    resolver,
  });

  const { handleSubmit } = form;

  const submit = handleSubmit(onSubmit);

  return (
    <Modal
      open
      maxWidth="xs"
      onClose={onClose}
      title={initialFormData ? 'Редактирование определения' : 'Добавление определения'}
      actions={
        <>
          <Button variant="contained" onClick={submit}>
            Принять
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.m}>
          <Grid>
            <FormField type="text" name="title" title="Заголовок" fullWidth />
          </Grid>
          <Grid>
            <FormField type="text" name="definition" title="Текст" fullWidth />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
