import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import styles from './styles.module.scss';

export const LoadingSpinner: FC<{ sx?: SxProps }> = ({ sx }) => {
  return (
    <Box className={styles.spinnerWrapper} sx={sx}>
      <CircularProgress className={styles.spinner} />
    </Box>
  );
};
