import { useMemo, useState, useEffect } from 'react';
import { Box, Button, Paper, Grid, Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { Column } from '@components/Table/types';
import { useNavigate, useOutlet } from 'react-router';
import { Table } from '@components/Table/Table';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { DateService } from '@services/DateService';
import { Tag } from '@components/Tag';
import { Routes } from '@navigation/routes';
import { TicketsFilter } from '@area/ticketsPage/TicketsFilter';
import { useTickets } from '@area/tickets/hooks/useTickets';
import { TicketShortResponse } from '@api/admin/adminGeneratedApi';
import { DEFAULT_TICKETS_PAGE_SIZE } from '@area/tickets/defaultValues';
import { useQueryParams } from '@hooks/useQueryParams';
import colors from '@styles/colors.module.scss';
import { TicketsFilterType } from '@area/tickets/types';
import dayjs from 'dayjs';
import { getHumanName } from '@area/tickets/utils/getHumanName';
import styles from './styles.module.scss';

export const TicketsPage = () => {
  usePageTitle('Обращения');
  const [data, setData] = useState<TicketShortResponse[]>([]);

  const outlet = useOutlet();
  const navigate = useNavigate();

  const { params } = useQueryParams();

  const { getTickets, ticketsList, isSuccess, paginate, originalArgs, getValuesFromParams, isLoading } = useTickets();

  const fetchData = (values: Partial<TicketsFilterType>, skip: number, size = DEFAULT_TICKETS_PAGE_SIZE) => {
    getTickets({
      skip,
      size,
      ticketFilterRequest: {
        search: values.search,
        status: typeof values.status === 'object' ? Number(values.status?.value) : Number(values.status),
        createdAt: values.createdAt,
        userId: values.userId,
      },
    });
  };

  const onChange = (values: any) => {
    setData([]);

    const val = {
      ...values,
      createdAt: values.createdAt ? dayjs(values.createdAt).format('YYYY-MM-DD') : undefined,
      userId: values.userId ? values.userId.id : undefined,
    };

    fetchData(val, 0);
  };

  const loadMore = () => {
    fetchData(originalArgs?.ticketFilterRequest! as TicketsFilterType, data.length);
  };

  const columns: Column<TicketShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        dataIndex: 'resolved',
        key: 'resolved',
        width: '72px',
        align: 'center',
        render: (value: boolean) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tag
                label={value ? 'Решено' : 'Новое'}
                sx={{
                  background: value ? colors.success : colors.lightRed,
                  color: value ? colors.white : colors.black,
                }}
              />
            </Box>
          );
        },
      },
      {
        title: 'Дата создание',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '72px',
        render: (value: Date) => (
          <Typography className={styles.date} variant="body2">
            {DateService.getLocalDate(value)}
          </Typography>
        ),
      },
      {
        title: 'Тема',
        dataIndex: 'subject',
        key: 'subject',
        width: '170px',
      },
      {
        title: 'Пользователь',
        dataIndex: 'humanName',
        key: 'humanName',
        width: '72px',
        render: (_: string, record: TicketShortResponse) => getHumanName(record),
      },
    ],
    [],
  );

  const navigateToTicket = (row: TicketShortResponse) => {
    navigate(`/${Routes.tickets}/${row.id}`);
  };

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(ticketsList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Grid container direction="column" gap="24px">
        <Grid item>
          <Header
            title="Обращения"
            actions={
              <Button variant="contained" onClick={() => navigate('/tickets/new')}>
                Новое обращение
              </Button>
            }
          />
        </Grid>
        <Grid item>
          <TicketsFilter onChange={onChange} />
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <>
            <span className={styles.searchResult}>Всего обращений: </span>
            <span className={styles.totalCount}>{paginate?.totalCount}</span>
          </>
          <>
            <span className={styles.searchResult}> Новых обращений: </span>
            <span className={styles.newTicketsCount}>{data.filter((t) => !t.resolved).length}</span>
          </>
          <Paper className={styles.paper}>
            <Table<TicketShortResponse>
              columns={columns}
              data={data}
              onRowClick={(row) => navigateToTicket(row)}
              loading={isLoading}
            />
            {!!paginate?.totalCount && data.length < paginate?.totalCount && (
              <Button
                variant="outlined"
                className={styles.loadMoreBtn}
                startIcon={<Icon name="more" />}
                onClick={loadMore}
                disabled={isLoading}>
                Загрузить еще
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
