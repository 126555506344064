import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { EditModalHandlers } from '@area/translations/components/EditModal/types';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useResourcesControllerGetTranslationsQuery, useResourcesControllerLanguagesQuery } from '@api/admin/adminApi';
import { Column } from '@components/Table/types';
import { TranslationResponse } from '@api/admin/adminGeneratedApi';
import { IconButton } from '@mui/material';
import { Icon } from '@components/Icon';
import styles from '../styles.module.scss';

export const useTranslations = () => {
  const [searchParams] = useSearchParams({ search: '' });

  const modalRef = useRef<EditModalHandlers>(null);
  const { handlePushItem: push } = useNotifications();
  const languages = useResourcesControllerLanguagesQuery({});
  const size = searchParams.get('size');
  const page = searchParams.get('page');
  const search = searchParams.get('search');

  const translations = useResourcesControllerGetTranslationsQuery(
    {
      size: Number(size),
      skip: (Number(page!) - 1) * Number(size!),
      mnemonic: search || undefined,
    },
    {
      skip: !languages.isSuccess,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: {
          ...(data ?? {}),
          data:
            data?.data?.map((item) => ({
              ...item,
              language: languages.data?.data?.find(({ key }) => key === item.languageKey)?.title ?? '',
            })) ?? [],
        },
      }),
    },
  );

  const columns = useMemo<Column<TranslationResponse>[]>(() => {
    return [
      {
        title: 'Мнемоник',
        dataIndex: 'mnemonic',
        width: 300,
      },
      {
        title: 'Перевод',
        dataIndex: 'translation',
      },
      {
        title: 'Язык',
        dataIndex: 'language',
        width: 150,
      },
      {
        title: '',
        dataIndex: 'action',
        align: 'center',
        width: 80,
        render: (_: unknown, record) => (
          <IconButton className={styles.iconButton} onClick={() => modalRef.current?.open(record)}>
            <Icon name="edit" />
          </IconButton>
        ),
      },
    ];
  }, []);

  const processTranslations = useMemo(() => {
    if (!translations.data?.data || !size || !page) return [];

    return translations.data?.data;
  }, [translations.data, search, size, page]);

  useEffect(() => {
    if (languages.isError) {
      push({ severity: 'error', text: 'Не удалось получить список доступных языков.' });
    }
    if (translations.isError) {
      push({ severity: 'error', text: 'Не удалось получить список переводов.' });
    }
  }, [languages.isError, push, translations.isError]);

  return {
    modalRef,
    languages,
    translations: processTranslations,
    totalCount: translations.data?.paginate?.totalCount,
    columns,
    isLoading: languages.isLoading || translations.isFetching,
    isFetched: translations.isError || translations.isSuccess,
    refetch: translations.refetch,
  };
};
