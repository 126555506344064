import { useUsersControllerResetPasswordMutation } from '@api/admin/adminApi';

export const useResetPasswordUser = (id: string) => {
  const [reset, result] = useUsersControllerResetPasswordMutation();

  const handleResetPassword = async () => {
    return await reset({ id }).unwrap();
  };

  return {
    handleResetPassword,
    result,
  };
};
