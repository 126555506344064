import { OrderType } from '../types';

export const getApplicationType = (type: OrderType) => {
  switch (type) {
    case OrderType.esk_card:
      return 'Выпуск карты ЕСК';
    case OrderType.extra_grant:
      return 'Выплата дополнительной стипендии';
    case OrderType.credit_grant:
      return 'Компенсация образовательного кредита';
    default:
      return 'Неизвестный тип';
  }
};
