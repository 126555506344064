import * as yup from 'yup';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { Option } from '@components/Select/types';

export type EmailFormData = {
  title: string;
  text: CustomDescendant[];
  from: Option | null;
  targets: string;
};

export const validationSchema = yup.object<EmailFormData>({
  title: yup.string().required('Обязательное поле.').min(5, 'Минимальное количество символов 5'),
  link: yup.string(),
  from: yup.object().nullable().required('Обязательное поле'),
});
