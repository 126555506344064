import { Grid } from '@mui/material';
import { usePageTitle } from '@hooks/usePageTitle';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { Settings } from '@area/settings';

export const SettingsPage = () => {
  usePageTitle('Настройки');

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header title="Настройки" />
      </Grid>
      <Settings />
    </Grid>
  );
};
