import {
  HumansFilterRequest,
  HumanShortResponse,
  useParticipantsHumansControllerCreateFilterHumansNotificationMutation,
} from '@api/admin/adminGeneratedApi';
import { useHumans } from '@area/studentsPage/hooks/useHumans';
import { StudentsFilter } from '@area/studentsPage/StudentsFilter';
import { Icon } from '@components/Icon';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParams } from '@hooks/useQueryParams';
import { Header } from '@layouts/Base/components/Header';
import { Box, Button, Grid, Paper } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutlet } from 'react-router';
import { FilterFormValues, DEFAULT_STUDENTS_SIZE, studentsFilterOptions } from '@area/studentsPage/defaultValues';
import { getFilterParamsFromStatusSelectValue } from '@area/studentsPage/utils/getFilterParamsFromStatusSelectValue';
import { DateService } from '@services/DateService';
import { Select } from '@components/Select';
import { Option } from '@components/Select/types';
import { StudentsPaymentModal } from '@area/studentsPage/StudentsPaymentModal';
import { useDownloadFile } from '@hooks/useDownloadFile';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import styles from './styles.module.scss';

export const StudentsPage = () => {
  usePageTitle('Участники программ');
  const outlet = useOutlet();
  const navigate = useNavigate();

  const goToStudent = (row: HumanShortResponse) => navigate(row.id);

  const { getHumans, humansList, isSuccess, paginate, isLoading, originalArgs, getValuesFromParams } = useHumans();

  const { handlePushItem } = useNotifications();

  const [data, setData] = useState<HumanShortResponse[]>([]);
  const { params } = useQueryParams();

  const [filter, setFilter] = useState<string[]>(Object.keys(params));
  const [selectedFilter, setSelectedFilter] = useState<Option | Option[]>(
    studentsFilterOptions.filter((el) => filter.includes(el.value)),
  );

  const [openModal, setOpenModal] = useState(false);

  const actionsOptions: Option[] = [
    { label: 'Отправить рассылку', value: 'send', icon: <Icon name="send" size={20} /> },
    { label: 'Скачать список', value: 'save', icon: <Icon name="download" size={20} /> },
    { label: 'Сделать выплату', value: 'payment', icon: <Icon name="coin" size={20} /> },
  ];

  const fetchData = (values: Partial<HumansFilterRequest>, skip: number, size = DEFAULT_STUDENTS_SIZE) => {
    getHumans({
      skip,
      size,
      asc: false,
      humansFilterRequest: {
        search: values.search,
        isActive: values.isActive,
        isStudent: values.isStudent,
        registrationDateFrom: values.registrationDateFrom ? values.registrationDateFrom : undefined,
        registrationDateTo: values.registrationDateTo ? values.registrationDateTo : undefined,
        unconfirmedByEduOrgs: values.unconfirmedByEduOrgs,
        eduOrgs: values.eduOrgs,
        educationalPrograms: values.educationalPrograms,
        courseNumber: values.courseNumber,
        applicationType: values.applicationType,
      },
    });
  };

  const onChange = (values: FilterFormValues) => {
    setData([]);

    const valuesFromStatus = getFilterParamsFromStatusSelectValue(values.status?.value);
    const val = {
      registrationDateFrom: values.registrationDateFrom
        ? DateService.getUTCDate(values.registrationDateFrom)
        : undefined,
      registrationDateTo: values.registrationDateTo ? DateService.getUTCDate(values.registrationDateTo) : undefined,
      search: values.search,
      unconfirmedByEduOrgs: values.unconfirmedByEduOrgs?.map(({ value }) => value),
      eduOrgs: values.eduOrgs?.map(({ value }) => value),
      educationalPrograms: values.educationalPrograms?.map(({ value }) => value),
      courseNumber: values.courseNumber?.value,
      applicationType: values.applicationType?.value,
      ...valuesFromStatus,
    };

    fetchData(val, 0);
  };

  const onChangeSelectFilter = (values: Option | Option[]) => {
    setSelectedFilter(values);
    const filterKeys = Array.isArray(values) ? values.map((el) => el.value) : [];
    setFilter(filterKeys);
  };

  const { download } = useDownloadFile(
    '/api/participants/humans/filter/download-report',
    `participants_result.xlsx`,
    getValuesFromParams(params),
  );

  const [createNotification] = useParticipantsHumansControllerCreateFilterHumansNotificationMutation();

  const onCreateNotification = async () => {
    try {
      const createdNotification = await createNotification({
        humansFilterRequest: originalArgs?.humansFilterRequest!,
      }).unwrap();
      navigate(
        buildRoutePath([Routes.notifications, 'email', createdNotification?.id.toString() as any], { absolute: true }),
      );
    } catch (error) {
      handlePushItem({ severity: 'error', text: 'Не удалось создать рассылку' });
    }
  };

  const onActionChange = (option: Option | Option[]) => {
    const value = Array.isArray(option) ? option[0].value : option.value;
    if (value === 'save') {
      download();
    } else if (value === 'payment') {
      setOpenModal(true);
    } else if (value === 'send') {
      onCreateNotification();
    }
  };

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(humansList || [])]);
    }
  }, [isSuccess]);

  const loadMore = () => {
    fetchData(originalArgs?.humansFilterRequest!, data.length);
  };

  const columns: Column<HumanShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '72px',
        render: (value: boolean) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
            </Box>
          );
        },
      },
      {
        title: 'Студент',
        align: 'center',
        dataIndex: 'isStudent',
        key: 'isStudent',
        width: '72px',
        render: (value: boolean, record: HumanShortResponse) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Icon
              name={record?.isStudent ? 'check' : 'close'}
              htmlColor={record?.isStudent ? colors.success : colors.error}
            />
          </Box>
        ),
      },
      {
        title: 'ВУЗ',
        dataIndex: 'educationalOrganizationShortName',
        key: 'educationalOrganizationShortName',
      },
      //   {
      //     title: 'Преподаватель',
      //     align: 'center',
      //     dataIndex: 'isTeacher',
      //     key: 'isTeacher',
      //     width: '72px',
      //     render: (value: boolean) => (
      //       <Box display="flex" alignItems="center" justifyContent="center">
      //         <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
      //       </Box>
      //     ),
      //   },
      {
        title: 'ФИО',
        dataIndex: 'firstName',
        key: 'firstName',
        width: '300px',
        render: (value: boolean, record: HumanShortResponse) => {
          return [record.lastName, record.firstName, record.middleName].filter((w) => !!w).join(' ');
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '72px',
      },
      {
        title: 'СНИЛС',
        dataIndex: 'snils',
        key: 'snils',
      },
      {
        title: 'Дата регистрации',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value: string) => DateService.getLocalDate(value, DateService.dateFormats.date),
      },
    ],
    [],
  );

  const hiddenFilterButtons = (
    <>
      <Select
        placeholder="Добавить/Снять фильтр"
        icon={<Icon name="filterAdd" />}
        options={studentsFilterOptions}
        onChange={onChangeSelectFilter}
        value={selectedFilter}
        multiple
        isMenuSelect
      />
      <Select
        placeholder="Действия"
        onChange={onActionChange}
        icon={<Icon name="more" />}
        options={actionsOptions}
        disabled={!Object.keys(params).length ? true : !data.length}
        isMenuSelect
      />
    </>
  );

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Grid container direction="column" gap="24px">
        <Grid item>
          <Header title="Участники программ" />
        </Grid>
        <Grid item>
          <StudentsFilter onChange={onChange} filter={filter} hiddenFilterButtons={hiddenFilterButtons} />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <>
            <span className={styles.searchResult}>Найдено участников: </span>
            <span className={styles.totalCount}>{paginate?.totalCount ?? 0}</span>
          </>
          <Paper className={styles.paper}>
            <Table<HumanShortResponse> columns={columns} data={data} onRowClick={goToStudent} loading={isLoading} />
            {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
              <Button
                variant="outlined"
                className={styles.loadMoreBtn}
                onClick={loadMore}
                startIcon={<Icon name="more" />}
                disabled={isLoading}>
                Загрузить еще
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
      <StudentsPaymentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        humansFilterRequest={getValuesFromParams(params)}
      />
    </>
  );
};
