import {
  NotificationsControllerFilterApiArg,
  NotificationsFiltersRequest,
  useNotificationsControllerFilterMutation,
} from '@api/admin/adminGeneratedApi';
import { DEFAULT_PUBLICATIONS_SIZE } from '../../publications/defaultValues';

export const useNotificationsFilter = () => {
  const query: NotificationsControllerFilterApiArg = {
    size: DEFAULT_PUBLICATIONS_SIZE,
    notificationsFiltersRequest: {},
  };
  const [getNotificationsMailing, res] = useNotificationsControllerFilterMutation();

  const handleRequest = (
    args?: Omit<NotificationsControllerFilterApiArg, 'notificationsFiltersRequest'> & {
      notificationsFiltersRequest: Partial<NotificationsFiltersRequest>;
    },
  ) => {
    const request: NotificationsControllerFilterApiArg = {
      ...query,
      ...args,
      notificationsFiltersRequest: {
        ...query.notificationsFiltersRequest,
        ...args?.notificationsFiltersRequest,
      },
    };

    getNotificationsMailing(request);
  };

  const getValuesFromParams = (params: { [key: string]: string }) => {
    return { ...params };
  };

  return {
    getNotifications: handleRequest,
    notificationsList: res.data?.data,
    paginate: res.data?.paginate,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
    getValuesFromParams,
  };
};
