import { useEventsControllerUpdateEventMutation } from '@api/admin/adminGeneratedApi';
import { FormKampusEventValues } from '../defaultValues';
import { KampusEventMapper } from '../mappers';

export const useKampusUpdateEvent = (id: string) => {
  const [updateKampusEvent, resultOfUpdate] = useEventsControllerUpdateEventMutation();

  const handleUpdate = async (values: FormKampusEventValues) => {
    const event = await updateKampusEvent({
      id,
      eventRequest: KampusEventMapper.formModelToApiModel(values),
    }).unwrap();
    return event;
  };

  return {
    updateKampusEvent: handleUpdate,
    resultOfUpdate,
  };
};
