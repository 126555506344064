import { Column } from '@components/Table/types';
import { SyncDataResponseDto } from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';

export const columns: Column<SyncDataResponseDto>[] = [
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    width: 160,
    render: (value) => (value ? DateService.getLocalDate(value) : ''),
  },
  {
    title: 'Тип операции',
    dataIndex: 'operationType',
    width: 200,
  },
  {
    title: 'Дата обработки',
    dataIndex: 'processDate',
    width: 160,
    render: (value) => (value ? DateService.getLocalDate(value) : ''),
  },
  {
    title: 'Результат',
    dataIndex: 'statusText',
    width: 200,
    render: (value) => value ?? '',
    ellipsis: true,
  },
];
