import { Stack } from '@mui/material';
import { PaymentEoOrgDocFormat } from '@area/payments/types';
import { DownloadDocButton } from './DownloadDocButton';
import styles from './styles.module.scss';

const buttons = [
  PaymentEoOrgDocFormat.csv,
  PaymentEoOrgDocFormat.xlsx,
  // PaymentEoOrgDocFormat.pdf,
];

export const DownloadDocButtons = ({ educationalOrganizationId }: { educationalOrganizationId: string }) => (
  <Stack direction="row" justifyContent="flex-end" className={styles.downloadButtons}>
    {buttons.map((format) => (
      <DownloadDocButton key={format} format={format} educationalOrganizationId={educationalOrganizationId} />
    ))}
  </Stack>
);
