import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type Params = { [key: string]: string };

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = useCallback(
    () => [...searchParams.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as Params),
    [searchParams],
  );

  const setParams = useCallback(
    (params: Params) => {
      const currentParams = getParams();

      setSearchParams({ ...setSearchParams, ...currentParams, ...params });
    },
    [setSearchParams, getParams],
  );

  const resetParams = useCallback(
    (params: string[]) => {
      Object.keys(params).forEach((name) => {
        searchParams.delete(name);
      });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    params: getParams(),
    setParams,
    resetParams,
  };
};
