import { useInfoPagesControllerCreateInfoPageMutation } from '@api/admin/adminGeneratedApi';
import { FormInfoPageValues } from '../defaultValues';
import { InfoPageMapper } from '../mappers';

export const useCreateInfoPage = () => {
  const [createKampusEvent, resultOfCreate] = useInfoPagesControllerCreateInfoPageMutation();

  const handleCreate = async (values: FormInfoPageValues) => {
    const infoPage = await createKampusEvent({
      infoPageRequest: InfoPageMapper.formModelToApiModel(values),
    }).unwrap();
    return infoPage;
  };

  return {
    createInfoPage: handleCreate,
    resultOfCreate,
  };
};
