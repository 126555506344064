import { ChangeEvent, forwardRef, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, InputAdornment, TextField, ThemeProvider, Tooltip, Typography } from '@mui/material';
import MaskedInput from 'react-text-mask';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import cn from 'classnames';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { innerTheme } from '../TooltipInnerTheme/theme';
import { InputProps } from './types';
import styles from './style.module.scss';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value = '',
      error,
      leftIcon,
      rightIcon,
      mask,
      helperText,
      onChange,
      transformValue,
      readOnly,
      countText,
      showEyeIcon = true,
      maxTextLength = Infinity,
      ...props
    }: InputProps,
    rawRef,
  ) => {
    const [type, setType] = useState(props.type);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const prevErrorRef = useRef(error);

    const ref = useCallback(
      (elm: HTMLInputElement) => {
        if (typeof rawRef === 'function') {
          rawRef?.(elm);
        }

        inputRef.current = elm;

        return elm;
      },
      [rawRef],
    );

    const inputComponent = useMemo<StandardInputProps['inputComponent']>(() => {
      if (!mask) return;

      return forwardRef(({ inputRef, ...props }, _) => {
        return <MaskedInput ref={inputRef} {...props} mask={mask} />;
      });
    }, [mask]);
    const passwordEyeIcon = useMemo<ReactElement | undefined>(() => {
      if (props.type !== 'password' || !showEyeIcon) return undefined;

      const onClick = () => setType(type === 'password' ? 'text' : 'password');

      return (
        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
          <Icon name={type === 'password' ? 'eye' : 'eyeOff'} color={colors.black} onClick={onClick} />
        </InputAdornment>
      );
    }, [props.type, showEyeIcon, type]);
    const inputProps = useMemo<Partial<StandardInputProps>>(() => {
      return {
        readOnly,
        startAdornment: leftIcon && <InputAdornment position="start">{leftIcon}</InputAdornment>,
        endAdornment: passwordEyeIcon ?? (rightIcon && <InputAdornment position="end">{rightIcon}</InputAdornment>),
        inputComponent,
      };
    }, [passwordEyeIcon, readOnly, leftIcon, rightIcon, inputComponent]);

    if (countText) {
      const length = value?.length ?? 0;

      inputProps.endAdornment = (
        <Box className={cn(styles.textCount, length > maxTextLength && styles.error)}>
          <Typography>{length}</Typography>
        </Box>
      );
    }

    const onLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
      const transformedValue = transformValue?.(event.currentTarget.value) ?? event.currentTarget.value;
      event.currentTarget.value = event.target.value = transformedValue;
      onChange?.(event);
    };

    useEffect(() => {
      if (error && prevErrorRef.current !== error) {
        prevErrorRef.current = error;
      }
    }, [error]);

    useEffect(() => {
      setType(props.type);
    }, [props.type]);

    return (
      <ThemeProvider theme={innerTheme}>
        <Tooltip
          open={Boolean(error)}
          title={error ?? prevErrorRef.current}
          arrow
          placement="bottom-start"
          PopperProps={{ sx: { maxWidth: inputRef.current?.clientWidth ?? 0 } }}>
          <TextField
            {...props}
            type={type}
            value={value}
            onChange={onLocalChange}
            error={Boolean(error)}
            ref={ref}
            variant="outlined"
            InputProps={inputProps}
          />
        </Tooltip>
      </ThemeProvider>
    );
  },
);
