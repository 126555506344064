import { PaymentStatusTag } from '@area/payments/components/PaymentStatusTag';
import { PaymentShortResponse } from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';
import { NumberService } from '@services/NumberService';
import { Column } from '@components/Table/types';
import { ActionButton } from './ActionButton';

export const getColumns = (refetchPayments: () => void): Column<PaymentShortResponse>[] => [
  {
    width: 140,
    title: 'Статус',
    dataIndex: 'status',
    align: 'left',
    render: (value: PaymentShortResponse['status']) => <PaymentStatusTag status={value} />,
  },
  {
    width: 200,
    title: 'Дата создания',
    dataIndex: 'createdAt',
    render: (value) => (value ? DateService.getLocalDate(value) : ''),
  },
  {
    width: 200,
    title: 'Дата подтверждения',
    dataIndex: 'approvedAt',
    render: (value) => (value ? DateService.getLocalDate(value) : ''),
  },
  {
    width: 200,
    title: 'Инициатор',
    dataIndex: 'startedBy',
    render: (value) => value.nickname,
  },
  {
    width: 160,
    title: 'Кол-во студентов',
    dataIndex: 'studentsCount',
  },
  // {
  //   title: 'Сумма, руб.',
  //   dataIndex: 'sum',
  //   render: (value) => NumberService.formatMoney(value),
  // },
  {
    title: '',
    dataIndex: 'status',
    key: 'action',
    align: 'right',
    render: (_, payment: PaymentShortResponse) => <ActionButton {...payment} onSuccess={refetchPayments} />,
  },
];
