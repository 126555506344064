export class TypeService {
  public static isNil(value: unknown): value is null | undefined {
    return value === null || value === undefined;
  }

  public static isNumber(value: unknown): value is number {
    return typeof value === 'number' && !isNaN(Number(value));
  }

  public static isString(value: unknown): value is string {
    return typeof value === 'string';
  }
}
