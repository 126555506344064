import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetEduOrgs } from '@area/payments/hooks/useGetEduOrgs';
import colors from '@styles/colors.module.scss';
import { PaymentType } from '@area/payments/types';
import { useDownloadFile } from '@hooks/useDownloadFile';
import queryString from 'query-string';
import { FormData } from './ReportModalForm';
import { ReportModal as ReportModalComponent } from './ReportModal';
import { LoadingModal } from './LoadingModal';

export const ReportModal = () => {
  const [showReportModal, setShowReportModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [url, setUrl] = useState<string>();
  const { data: eduOrgs } = useGetEduOrgs();
  const { paymentType } = useParams<{ paymentType: PaymentType }>();
  const onOpenModal = () => setShowReportModal(true);
  const { isDownloading, isSuccess, isError, download } = useDownloadFile(url!);
  const handleSubmit = (data: FormData) => {
    const fromDate = data.fromDate ? data.fromDate.utc(true).toJSON() : undefined;
    const toDate = data.toDate ? data.toDate.utc(true).toJSON() : undefined;

    const eduOrgsIds =
      data.eduOrgsIds?.length === 0 || data.eduOrgsIds?.length === eduOrgs?.length
        ? undefined
        : data.eduOrgsIds?.map(({ value }) => value);
    const qs = queryString.stringify({ fromDate, toDate, eduOrgsIds });

    setUrl(`/api/payments/${paymentType}/download-report?${qs}`);
  };

  useEffect(() => {
    if (url) {
      download();
      setShowLoadingModal(true);
      setUrl(undefined);
    }
  }, [url]);

  useEffect(() => {
    if (isError) {
      setShowLoadingModal(false);
    }
  }, [isError]);

  return (
    <>
      <Button onClick={onOpenModal} variant="outlined" sx={{ backgroundColor: colors.white }}>
        Сформировать отчет
      </Button>
      <ReportModalComponent onSubmit={handleSubmit} open={showReportModal} setShowModal={setShowReportModal} />
      <LoadingModal
        open={showLoadingModal}
        setShowModal={setShowLoadingModal}
        isDownloading={isDownloading}
        isSuccess={isSuccess}
      />
    </>
  );
};
