import { useAuthorsOptions } from '@area/authors/hooks/useAuthors';
import { useRubricsOptions } from '@area/rubrics/hooks/useRubrics';
import { Filter } from '@components/Filter';
import { FC } from 'react';
import colors from '@styles/colors.module.scss';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Icon } from '@components/Icon';
import { mockPublicationTypes } from '@area/publications/defaultValues';
import { PublicationsFilterValues } from './types';

interface Props {
  onChange: (values: any) => void; // TODO: нужно ли прокидывать сюда, или через Хук?
}

export const PublicationsFilter: FC<Props> = ({ onChange }) => {
  const { options: authorOptions, isLoading: isLoadingAuthors } = useAuthorsOptions();
  const { options: rubricOptions, isLoading: isLoadingRubrics } = useRubricsOptions();

  if (isLoadingAuthors || isLoadingRubrics) {
    // TODO loading indicator
    return <LoadingSpinner />;
  }

  return (
    <Filter<PublicationsFilterValues>
      onChange={onChange}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '200px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'authorIds',
          props: {
            placeholder: 'Автор',
            icon: <Icon name="edit" />,
            options: authorOptions,
            sx: { width: '200px', background: colors.white },
            multiple: true,
          },
        },
        {
          type: 'dateRangePicker',
          name: 'date',
          props: {
            placeholder: 'Дата',
            textFieldProps: { placeholder: 'Интервал дат', sx: { width: '240px', background: colors.white } },
          },
        },
        {
          type: 'select',
          name: 'type',
          props: {
            placeholder: 'Тип',
            sx: { width: '160px', background: colors.white },
            icon: <Icon name="type" />,
            options: mockPublicationTypes,
          },
        },
        {
          type: 'select',
          name: 'tagIds',
          props: {
            placeholder: 'Рубрика',
            sx: { width: '200px', background: colors.white },
            icon: <Icon name="rubric" />,
            options: rubricOptions?.map((o) => ({ ...o, remove: undefined })),
            multiple: true,
          },
        },
      ]}
    />
  );
};
