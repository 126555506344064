import { InfoPageRequest } from '@api/admin/adminGeneratedApi';
import { serialize } from '@area/redactor/components/Wysiwyg/utils/serialize';
import { TypeService } from '@services/TypeService';
import { FormInfoPageValues } from '../defaultValues';

export class InfoPageMapper {
  public static formModelToApiModel(values: FormInfoPageValues): InfoPageRequest {
    return {
      published: values.published,
      title: values.title,
      url: values.url,
      editorContent: TypeService.isString(values.editorContent)
        ? values.editorContent
        : serialize(values.editorContent),
      metaTags: values.metaTags,
      embedded: values.embedded,
      isMenu: values.isMenu,
    };
  }
}
