import { UserResponse } from '@api/admin/adminGeneratedApi';
import { getUserRoleOptions } from '@area/users/roles';
import { Select } from '@components/Select';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';
import { useState } from 'react';
import { UserMapper } from '@area/users/mappers';
import { Option } from '@components/Select/types';
import { useUpdateUserRole } from '@area/users/hooks/useUpdateUserRole';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useCurrentUserRoles } from '@area/user/hooks/useCurrentUserRoles';
import styles from './style.module.scss';

export interface RoleSelectProps {
  user: UserResponse;
}

export const RoleSelect = ({ user }: RoleSelectProps) => {
  const { currentUser } = useCurrentUser();
  const [options, setOptions] = useState(UserMapper.userRoleToSelectOptions(user.role));
  const { handlePushItem } = useNotifications();
  const { handleUpdate, result } = useUpdateUserRole(user.id);
  const { roles } = useCurrentUserRoles();

  const onChange = (newOptions: Option | Option[]) => {
    setOptions(newOptions as Option[]);
  };
  const onClose = async () => {
    await handleUpdate(UserMapper.selectOptionsToUserRole(options));
    handlePushItem({ severity: 'success', text: 'Роли пользователя успешно изменены' });
  };

  return (
    <Select
      multiple
      value={options}
      onChange={onChange}
      onClose={onClose}
      placeholder="Выберите роль"
      title="Роль в системе"
      options={getUserRoleOptions(roles)}
      disabled={result.isLoading || !currentUser || currentUser.id === user.id}
      className={styles.fieldWidth}
    />
  );
};
