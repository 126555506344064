import { Column } from '@components/Table/types';
import { Typography } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { DateService } from '@services/DateService';
import { OrderStatus } from '../types';

const getStatusText = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.success:
      return 'Успешно';
    case OrderStatus.new:
      return 'Новый';
    case OrderStatus.processing:
      return 'В обработке';
    case OrderStatus.paused:
      return 'Пауза';
    case OrderStatus.error:
      return 'Неуспешно';
    default:
      return 'Неизвестный тип';
  }
};

const getStatusColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.success:
      return colors.success;
    case OrderStatus.new:
    case OrderStatus.processing:
      return colors.dark;
    case OrderStatus.paused:
      return colors.dark;
    case OrderStatus.error:
      return colors.error;
    default:
      return colors.dark;
  }
};

export const columns: Column[] = [
  {
    title: 'Дата',
    dataIndex: 'date',
    render: (value) => <Typography variant="body2">{DateService.getLocalDate(value)}</Typography>,
  },
  {
    title: 'Результат',
    dataIndex: 'status',
    render: (value: OrderStatus) => {
      return <Typography color={getStatusColor(value)}>{getStatusText(value)}</Typography>;
    },
  },
  {
    title: 'Текст',
    dataIndex: 'statusText',
    render: (text: string) => (
      <Typography
        sx={{ whiteSpace: 'break-spaces' }}
        lineHeight={2}
        variant={'body2'}
        color={colors.grey}
        fontSize={12}
        maxWidth={500}>
        {text}
      </Typography>
    ),
  },
];
