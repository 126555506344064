import { FC, ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import colors from '@styles/colors.module.scss';
import { UserResponse } from '@api/admin/adminGeneratedApi';
import { Routes } from '@navigation/routes';
import { UserPhoto } from './UserPhoto';
import { UserShortCardSkeleton } from './UserShortCardSkeleton';

import styles from './styles.module.scss';

interface Props {
  textColor?: string;
  isOnlyPhoto?: boolean;
  afterComponent: ReactNode;
  user: UserResponse | null;
  openSidebar: boolean;
}

export const UserShortCard: FC<Props> = ({
  textColor = colors.white,
  isOnlyPhoto,
  afterComponent,
  user,
  openSidebar,
}) => {
  if (!user) {
    return <UserShortCardSkeleton openSidebar={openSidebar} />;
  }

  // user doesn't have photo property yet
  //   if (isOnlyPhoto) {
  //     return <UserPhoto name={user.name} photo={user.photo.short} />;
  //   }

  return (
    <Grid container alignItems="center" columnSpacing={1} wrap="nowrap" color={textColor}>
      <Grid item className={styles.linkWrapper}>
        <Link to={Routes.profile} className={styles.link}>
          <Grid container alignItems="center" columnSpacing={1} wrap="nowrap">
            <Grid item>
              <UserPhoto name={user.email} photo={user.avatar?.url} />
            </Grid>

            <Grid item minWidth={0} className={cn({ [styles.short]: !openSidebar })}>
              <Typography variant="body1" noWrap>
                {user.email}
              </Typography>
            </Grid>
          </Grid>
        </Link>
      </Grid>

      <Grid item marginLeft="auto">
        {afterComponent}
      </Grid>
    </Grid>
  );
};
