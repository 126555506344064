import { Box, Button, Grid } from '@mui/material';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { LoginResponseDto } from '@api/admin/adminGeneratedApi';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';

export interface ConfirmSuccessProps {
  loginResponse: LoginResponseDto;
}

export const ConfirmSuccess = ({ loginResponse }: ConfirmSuccessProps) => {
  const { login } = useAuthHelpers();
  const onClick = () => login(loginResponse);

  return (
    <>
      <Grid item alignSelf="center">
        <Box
          width={80}
          height={80}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ border: `4px solid ${colors.success}`, borderRadius: 40 }}>
          <Icon name="check" width={40} height={40} color={colors.success} />
        </Box>
      </Grid>
      <Grid item alignSelf="center">
        <Button onClick={onClick} variant="contained">
          Перейти в систему
        </Button>
      </Grid>
    </>
  );
};
