import { Icon } from '@components/Icon';
import { Button, Grid } from '@mui/material';
import { FC, useState } from 'react';
import indents from '@styles/indents.module.scss';
import { OrderStatus, OrderType } from '../types';
import { CancelParticipationModal } from './CancelParticipationModal';
import { ConfirmParticipationModal } from './ConfirmParticipationModal';
import styles from './style.module.scss';

type StatusProps = {
  status: OrderStatus;
  type: OrderType;
  isCreditDataValid?: boolean;
};

export const Statuses: FC<StatusProps> = ({ status, type, isCreditDataValid }) => {
  const canConfirmApplication = status === OrderStatus.new && type === OrderType.credit_grant;

  const canRejectApplication =
    status !== OrderStatus.processing &&
    status !== OrderStatus.success &&
    status !== OrderStatus.error &&
    type === OrderType.credit_grant;
  const canRefreshRejectingApplication = status === OrderStatus.error;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  return (
    <>
      <Grid container gap={indents.s} justifyContent="flex-end" sx={{ width: 'fit-content' }}>
        {canConfirmApplication && isCreditDataValid && (
          <Grid item>
            <Button
              type="button"
              variant="contained"
              startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
              onClick={() => setOpenConfirmModal(true)}>
              Подтвердить участие
            </Button>
          </Grid>
        )}

        {canRejectApplication && (
          <Grid item>
            <Button variant="outlined" startIcon={<Icon name="close" />} onClick={() => setOpenCancelModal(true)}>
              Отклонить
            </Button>
          </Grid>
        )}

        {canRefreshRejectingApplication && (
          <Grid item>
            {/* <Button variant="outlined" startIcon={<Icon name="refresh" />}>
              Перезапустить
            </Button> */}
          </Grid>
        )}
      </Grid>

      <ConfirmParticipationModal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)} />

      <CancelParticipationModal open={openCancelModal} onClose={() => setOpenCancelModal(false)} />
    </>
  );
};
