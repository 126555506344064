import { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { Form } from '@area/publications/components/PublicationForm/Form';
import { FormPublicationValues, defaultPublicationValues } from '@area/publications/defaultValues';
import { useCreatePublication } from '@area/publications/hooks/useCreatePublication';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { TypeService } from '@services/TypeService';
import { deserialize } from '@area/redactor/components/Wysiwyg';
import { getMetaTagsFromValues } from '@area/redactor/components/MetaTags/getMetaTagsFromValues';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';
import styles from './style.module.scss';

export const NewPublicationPage = () => {
  usePageTitle('Новая публикация');

  const navigate = useNavigate();
  const { handlePushItem } = useNotifications();
  const {
    createPublication,
    resultOfCreate: { isSuccess, isLoading, error: createError },
  } = useCreatePublication();

  const { uploadMediaFiles, uploadError, deleteError } = useUploadMediaFiles();

  const onSubmit = async (values: FormPublicationValues) => {
    const news = await createPublication(values);
    uploadMediaFiles({
      subjectId: news.id,
      mainImage: values.mainImage,
      snippetPhoto: values.snippetPhoto,
      photoGallery: values.photoGallery,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Новая публикация создана' });
      navigate(buildRoutePath(Routes.publications, { absolute: true }));
    }
  }, [isSuccess, handlePushItem]);

  useEffect(() => {
    if (createError && 'message' in createError) {
      handlePushItem({ severity: 'error', text: createError.message });
    }

    if (uploadError && 'message' in uploadError) {
      handlePushItem({ severity: 'error', text: uploadError.message });
    }

    if (deleteError && 'message' in deleteError) {
      handlePushItem({ severity: 'error', text: deleteError.message });
    }
  }, [uploadError, deleteError, createError, handlePushItem]);

  const defaultValues = useMemo(() => {
    return {
      ...defaultPublicationValues,
      editorContent: TypeService.isString(defaultPublicationValues.editorContent)
        ? deserialize(defaultPublicationValues.editorContent)
        : defaultPublicationValues.editorContent,
      metaTags: getMetaTagsFromValues(),
    };
  }, []);

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Новая публикация"
          backBtnTitle="Назад к списку публикаций"
          backBtnRoute={buildRoutePath(Routes.publications, { absolute: true })}
        />
      </Grid>
      <Form<FormPublicationValues> defaultValues={defaultValues} onSubmit={onSubmit} isLoading={isLoading} />
    </Grid>
  );
};
