import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { Box, Paper } from '@mui/material';
import { useAuthToken } from '@area/user/hooks/useAuthToken';
import { Icon } from '@components/Icon';
import { Routes } from '@navigation/routes';
import styles from './style.module.scss';

export const LoginLayout = () => {
  const navigate = useNavigate();
  const token = useAuthToken();

  useEffect(() => {
    if (token) {
      navigate(Routes.main);
    }
  }, [token, navigate]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" className={styles.root}>
      <div className={styles.logo}>
        <Icon name="logoLong" width={132} height={60} viewBox="0 0 131 60" />
      </div>
      <Paper variant="outlined" className={styles.content}>
        <Outlet />
      </Paper>
    </Box>
  );
};
