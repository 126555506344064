import {
  InfoPagesControllerFilterInfoPagesApiArg,
  InfoPageFiltersRequest,
  useInfoPagesControllerFilterInfoPagesMutation,
} from '@api/admin/adminGeneratedApi';
import { DEFAULT_INFO_PAGE_SIZE } from '../defaultValues';

export const useInfoPages = () => {
  const query: InfoPagesControllerFilterInfoPagesApiArg = {
    size: DEFAULT_INFO_PAGE_SIZE,
    infoPageFiltersRequest: {
      excludeDrafts: false,
    },
  };
  const [getInfoPages, res] = useInfoPagesControllerFilterInfoPagesMutation();

  const handleRequest = (
    args?: Omit<InfoPagesControllerFilterInfoPagesApiArg, 'infoPageFiltersRequest'> & {
      infoPageFiltersRequest: Partial<InfoPageFiltersRequest>;
    },
  ) => {
    const request: InfoPagesControllerFilterInfoPagesApiArg = {
      ...query,
      ...args,
      infoPageFiltersRequest: {
        ...query.infoPageFiltersRequest,
        ...args?.infoPageFiltersRequest,
      },
    };

    getInfoPages(request);
  };

  return {
    getInfoPages: handleRequest,
    infoPagesList: res.data?.data,
    paginate: res.data?.paginate,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
  };
};
