import { ButtonGroup, Grid } from '@mui/material';
import intends from '@styles/indents.module.scss';
import { ImageButton } from '@area/redactor/components/Wysiwyg/components/Toolbar/components/Image';
import { useSlate } from 'slate-react';
import { useTable } from '@area/redactor/components/Wysiwyg/hooks/useTabel';
import { DefinitionInfo } from './components/DefinitionInfo';
import { Quote } from './components/Quote';
// import { KeyQuote } from './components/KeyQuote';
import { QuoteWithPhoto } from './components/QuoteWithPhoto';
import { TextFormatting } from './components/TextFormatting';
// import { SubSupText } from './components/SubSupText';
// import { TextAlignment } from './components/TextAlignment';
// import { ListIndent } from './components/ListIndent';
import { Lists } from './components/Lists';
import { FontSettings } from './components/FontSettings';
import { Links } from './components/Links';
// import { CodeButton } from './components/CodeButton';
import { Table } from './components/Table';
import { History } from './components/History';
import { ShowHTML } from './components/ShowHTML';
import styles from './styles.module.scss';
import { ArticleLink } from './components/ArticleLink';

export const Toolbar = () => {
  const editor = useSlate();
  const isTable = useTable(editor);

  return (
    <Grid container gap={intends.xxs} className={styles.root} alignItems="flex-start">
      <TextFormatting />
      {/* <SubSupText /> */}
      {/* <TextAlignment /> */}
      {/* <ListIndent /> */}
      <FontSettings />
      <Lists />
      <ButtonGroup>
        <Links />
        <ImageButton isTable={isTable} />
        {/* <CodeButton /> */}
      </ButtonGroup>
      <Table />
      <ButtonGroup>
        <QuoteWithPhoto isTable={isTable} />
        <ArticleLink />
        <DefinitionInfo />
        <Quote isTable={isTable} />
      </ButtonGroup>
      <History />
      <ShowHTML />
    </Grid>
  );
};
