import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';

import { pushNotification, removeNotification } from '@area/notifications/store';
import { NotificationItem } from '../types';

const itemFactory = (data: NotificationItem['data']): NotificationItem => ({
  data,
  id: data.id !== undefined ? data.id : nanoid(6),
});

export const useNotifications = () => {
  const dispatch = useDispatch();

  const handleRemoveItem = useCallback(
    (id?: string) => {
      if (!id) {
        throw new Error('[Notifications error] All notifications must have an id');
      }
      dispatch(removeNotification(id!));
    },
    [dispatch],
  );

  const handlePushItem = useCallback(
    (data: NotificationItem['data']) => {
      if (data.id !== undefined) {
        handleRemoveItem(data.id);
      }
      const newItem = itemFactory(data);

      dispatch(pushNotification(newItem));

      return newItem;
    },
    [handleRemoveItem, dispatch],
  );

  return {
    handlePushItem,
    handleRemoveItem,
  };
};
