import { useState } from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { useFieldArray, FieldValues } from 'react-hook-form';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useFilesControllerDeleteFileMutation, MediaFileResponse } from '@api/admin/adminGeneratedApi';

import indents from '@styles/indents.module.scss';

import { MediaFileParams } from '@area/publications/defaultValues';
import styles from './styles.module.scss';

export const Photo = ({ name, photoGallery }: { name: string; photoGallery?: MediaFileParams[] }) => {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const { append, remove } = useFieldArray<FieldValues, string, keyof MediaFileResponse>({ name });
  const [deleteFile, { isLoading }] = useFilesControllerDeleteFileMutation();

  if (!photoGallery) {
    return null;
  }

  const handleDeletePhoto = async (field: MediaFileParams, index: number) => {
    if (field.id) {
      setDeletingId(field.id);
      await deleteFile({ fileId: field.id });
      setDeletingId(null);
    }

    remove(index);
  };

  const addPhoto = () => append({ file: null, alt: null, metadata: null });

  return (
    <Grid container className={styles.root} gap={indents.m}>
      {photoGallery.map((field, index) => (
        <Grid item width="100%" key={field.id}>
          <Grid container gap="20px">
            <Grid item sx={{ width: 304 }}>
              <FormField
                imageUrl={photoGallery?.[index]?.url}
                name={`${name}[${index}].file`}
                type="image"
                loadingText="Удаление фото"
                loading={isLoading && deletingId === field.id}
                onDelete={() => handleDeletePhoto(field, index)}
              />
            </Grid>
            <Grid item className={styles.imageInputsWrapper}>
              <Stack gap="20px">
                <FormField name={`${name}[${index}].alt`} placeholder="Текст подписи" fullWidth />
                <FormField name={`${name}[${index}].metadata`} placeholder="Имя Фамилия" fullWidth />
                <Button
                  variant="outlined"
                  startIcon={<Icon name="delete" />}
                  className={styles.btn}
                  onClick={() => handleDeletePhoto(field, index)}>
                  Удалить
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item>
        <Button variant="contained" onClick={addPhoto}>
          Добавить фото
        </Button>
      </Grid>
    </Grid>
  );
};
