import { Box, Button, Tooltip } from '@mui/material';
import { MouseEvent } from 'react';
import cn from 'classnames';
import { Icon } from '@components/Icon';
import { PreviewButtonType } from '../types';
import styles from './styles.module.scss';

export interface PreviewButtonsProps {
  className?: string;
  disabled?: boolean;
  onClick?: (type: PreviewButtonType, event: MouseEvent) => void;
}

export const PreviewButtons = ({ className, onClick, disabled }: PreviewButtonsProps) => {
  return (
    <Box className={cn(styles.buttons, className)}>
      <Tooltip title="Предпросмотр Desktop" arrow placement="bottom-start">
        <Button disabled={disabled} variant="text" className={styles.button} onClick={(e) => onClick?.('desktop', e)}>
          <Icon name="desktop" className={styles.buttonIcon} />
        </Button>
      </Tooltip>
      <Tooltip title="Предпросмотр Mobile" arrow placement="bottom-start">
        <Button disabled={disabled} variant="text" className={styles.button} onClick={(e) => onClick?.('mobile', e)}>
          <Icon name="mobile" className={styles.buttonIcon} />
        </Button>
      </Tooltip>
    </Box>
  );
};
