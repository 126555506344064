import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { sidebarSelector } from '@area/sidebar/store';
import layout from '@styles/layout.module.scss';
import { Icon } from '../Icon';
import styles from './styles.module.scss';

export interface ModalProps extends Pick<DialogProps, 'onClose' | 'maxWidth' | 'sx' | 'className'> {
  open: boolean;
  title: ReactNode;
  actions?: ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  title,
  actions,
  maxWidth = 'md',
  sx,
  className,
  fullWidth = true,
  children,
  loading,
}) => {
  const { open: openSidebar } = useSelector(sidebarSelector);
  const paddingLeft = openSidebar ? layout.drawerWidthOpen : layout.drawerWidth;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="paper"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={{ paddingLeft, ...sx }}
      className={className}>
      <DialogTitle variant="h1">
        {title}
        {onClose && (
          <IconButton disableRipple onClick={(event) => onClose(event, 'backdropClick')} className={styles.closeButton}>
            <Icon name="close" className={styles.closeIcon} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
      {loading && <LinearProgress />}
    </Dialog>
  );
};
