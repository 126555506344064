import { memo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ReportModalForm, FormData } from './ReportModalForm';

export interface ReportModalProps {
  open: boolean;
  setShowModal: (value: boolean) => void;
  onSubmit: SubmitHandler<FormData>;
}

export const ReportModal = memo(({ open, setShowModal, onSubmit }: ReportModalProps) => {
  const onClose = () => {
    setShowModal(false);
  };

  if (!open) {
    return null;
  }

  return (
    <ReportModalForm
      open={open}
      onClose={onClose}
      onSubmit={async (formData) => {
        await onSubmit(formData);
        onClose();
      }}
    />
  );
});
