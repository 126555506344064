import { ReactNode } from 'react';
import {
  Grid,
  Button,
  Box,
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Link,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { Stack } from '@mui/system';
import { rbdMockDate } from '@area/partnersPage/mock';
import { DateService } from '@services/DateService';
import colors from '@styles/colors.module.scss';
import { PartnerResponse, PartnerProgramResponse } from '@api/admin/adminGeneratedApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { BonusCard } from '../card/BonusCard';
import { BaseFieldValues, FormProps, UpdateFormDataValues } from '../types';
import styles from './style.module.scss';
import { validationSchema } from './validationSchema';
import { Container as Map } from './Map';

const fieldOptions: {
  field: Partial<keyof PartnerResponse | string>;
  title: string;
  formatter?: (value: any, record: PartnerResponse) => ReactNode;
}[] = [
  {
    field: 'nsiName',
    title: 'Название',
  },
  {
    field: 'nsiPhone',
    title: 'Телефон',
  },
  {
    field: 'nsiEmail',
    title: 'Email',
  },
  {
    field: 'nsiWebsite',
    title: 'Web-сайт',
    formatter: (value) => (
      <Link href={value} variant="body2" color={colors.blue} target="_blank">
        {value}
      </Link>
    ),
  },
  {
    field: 'nsiDescription',
    title: 'Описание',
  },
  {
    field: 'logoUrl',
    title: 'Логотип',
    formatter: (value) => (value ? <img className={styles.logo} src={value} alt="logo" /> : null),
  },
  {
    field: 'departments',
    title: 'Точки отделений на карте',
    formatter: (value) => <Map departments={value} />,
  },
];

const programmsFieldOptions: {
  field: Partial<keyof PartnerProgramResponse | string>;
  title: string;
  formatter?: (value: any, record: PartnerProgramResponse) => ReactNode;
}[] = [
  {
    field: 'name',
    title: 'Название',
  },
  {
    field: 'category',
    title: 'Категория',
  },
  {
    field: 'banner',
    title: 'Баннер',
    formatter: (value) =>
      value ? (
        <Box mt={1} sx={{ cursor: 'pointer' }} display={'flex'} alignItems={'center'} color={colors.red}>
          <Link href={value} variant="body2" color={colors.red} target="_blank">
            {value}
          </Link>
          <Icon name={'chevronRight'} />
        </Box>
      ) : null,
  },
  {
    field: 'startDate',
    title: 'Дата начала',
    formatter: (value) => DateService.getLocalDate(new Date(value), 'DD.MM.YYYY'),
  },
  {
    field: 'endDate',
    title: 'Дата окончания',
    formatter: (value) => DateService.getLocalDate(new Date(value), 'DD.MM.YYYY'),
  },
  {
    field: 'descriptionFull',
    title: 'Полное описание',
    formatter: (value) => (
      <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
        {value}
      </Typography>
    ),
  },
  {
    field: 'descriptionShort',
    title: 'Краткое описание',
  },
  {
    field: 'site',
    title: 'Web-сайт',
    formatter: (value) => (
      <Link href={value} variant="body2" color={colors.blue} target="_blank">
        {value}
      </Link>
    ),
  },
  {
    field: 'isActive',
    title: 'Активная',
    formatter: (value) => (
      <Grid container alignItems="center" gap={indents.xs}>
        <Icon name={value ? 'check' : 'close'} color={value ? colors.success : colors.red} />
        <Typography variant="body2">{value ? 'Да' : 'Нет'}</Typography>
      </Grid>
    ),
  },
];

export const EditForm = <TValues extends BaseFieldValues>({
  isLoading,
  categories,
  onSubmit,
  data,
}: FormProps<TValues>) => {
  const defaultValues = {
    ...data,
    isDraft: !data.isDraft,
    categories: data.categories.map(({ id, name }: { id: string; name: string }) => ({
      value: id,
      label: name,
    })),
  } as unknown as UpdateFormDataValues;
  const resolver = useYupValidationResolver(validationSchema);
  const form = useForm<any>({
    defaultValues,
    resolver,
  });

  const formData = form.watch();

  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
  });
  const handleDeleteFile = () => {};

  const bonusCardLogoUrl =
    formData.logo instanceof File ? URL.createObjectURL(formData.logo) : formData.logo?.url || formData.logoUrl;

  const needPercentSymbol = formData.discount[data.discount.length - 1] !== '%';

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <FormProvider {...form}>
          <Grid item>
            <FormField name="isDraft" type="switch" label={formData.isDraft ? 'Вкл' : 'Выкл'} title="Показывать" />
          </Grid>
          <Grid item>
            <FormField name="isAnchor" type="switch" label={formData.isAnchor ? 'Вкл' : 'Выкл'} title="Якорный" />
          </Grid>
          <Accordion className={styles.accordionStyles} defaultExpanded>
            <AccordionSummary expandIcon={<Icon name={'chevronDown'} />}>
              <Typography variant="h2">Карточка партнера</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item flexDirection="column" gap={indents.l} className={styles.formWrapper}>
                <Grid item lg={6}>
                  <FormField titleVariant="body1" name="name" title="Название" fullWidth />
                </Grid>
                <Grid item lg={6} className={styles.typeSelect}>
                  <FormField
                    name="categories"
                    titleVariant="body1"
                    type="select"
                    placeholder="Выберите категорию"
                    title="Категория"
                    options={categories}
                    multiple
                  />
                </Grid>
                <Grid item lg={6}>
                  <FormField titleVariant="body1" name="description" title="Описание" fullWidth />
                </Grid>
                <Grid item lg={6}>
                  <FormField titleVariant="body1" name="discount" title="Скидка" fullWidth />
                </Grid>
                <Grid item>
                  <Grid container flexDirection="column" gap={indents.xs}>
                    <Grid item>
                      <Typography variant="body1">Логотип</Typography>
                    </Grid>
                    <Grid item>
                      <FormField
                        onDelete={handleDeleteFile}
                        imageUrl={formData.logo?.url}
                        name="logo"
                        type="imagePartner"
                        accept="image/*"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid item>
                    <Typography variant="body1">Предпросмотр карточки</Typography>
                  </Grid>
                  <Grid container flexDirection="row" gap={indents.s} mt={1.5}>
                    <Grid container flexDirection="column" width="292px">
                      <Typography mb={1.5} variant="caption" color={colors.grey}>
                        ЯКОРНЫЙ
                      </Typography>
                      <BonusCard
                        name={formData.name}
                        description={formData.description}
                        categories={data.categories}
                        logoUrl={bonusCardLogoUrl}
                      />
                    </Grid>

                    <Grid container flexDirection="column" width="292px">
                      <Typography mb={1.5} variant="caption" color={colors.grey}>
                        НЕ ЯКОРНЫЙ
                      </Typography>
                      <Grid container className={styles.notAnchor}>
                        <Grid
                          container
                          flexDirection="row"
                          alignItems="baseline"
                          justifyContent="space-between"
                          flexWrap="nowrap"
                          gap="12px">
                          <Typography variant="h6" className={styles.description}>
                            «{formData.name}»
                          </Typography>
                          {formData.discount && (
                            <Typography className={styles.discount} variant="body2" flexShrink="0">
                              -{formData.discount}
                              {needPercentSymbol && '%'}
                            </Typography>
                          )}
                        </Grid>
                        <Typography variant="body2" className={styles.description}>
                          {formData.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordionStyles} defaultExpanded>
            <AccordionSummary expandIcon={<Icon name={'chevronDown'} />}>
              <Typography variant="h2">Данные партнера из РБД</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mb={2.5}>
                <Typography mr={1} className={styles.textBold} variant="body2">
                  Получены:
                </Typography>
                <Typography mr={1} color={colors.grey} variant="body2">
                  {/* TODO: add actual date */}
                  {rbdMockDate}
                </Typography>
                {/* <Icon name="refresh" color={colors.error} /> */}
              </Grid>
              <Stack flex={1} divider={<Divider className={styles.divider} />}>
                {fieldOptions.map(({ field, title, formatter }) => {
                  if (!(field in data)) return null;
                  const dataValue = data[field as keyof PartnerResponse];
                  return (
                    <Box key={field} className={styles.row}>
                      <Typography className={styles.rowTitle}>{title}:</Typography>
                      <Typography variant="body2" color={dataValue ? colors.black : colors.grey} sx={{ width: '100%' }}>
                        {dataValue !== undefined
                          ? formatter
                            ? formatter(dataValue, data as any)
                            : (dataValue as any)
                          : 'Не указан'}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordionStyles} defaultExpanded>
            <AccordionSummary expandIcon={<Icon name={'chevronDown'} />}>
              <Typography variant="h2">Программы партнера из РБД</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mb={2.5}>
                <Typography mr={1} className={styles.textBold} variant="body2">
                  Получены:
                </Typography>
                <Typography mr={1} color={colors.grey} variant="body2">
                  {rbdMockDate}
                </Typography>
                {/* <Icon name="refresh" color={colors.error} /> */}
              </Grid>
              <Grid container flexDirection="column" gap={indents.m}>
                {data.programs.map((programm) => (
                  <Stack
                    flex={1}
                    className={styles.stackStyles}
                    divider={<Divider className={styles.programmDivider} />}>
                    {programmsFieldOptions.map(({ field, title, formatter }) => {
                      if (!(field in programm)) return null;
                      const dataValue = programm[field as keyof PartnerProgramResponse];
                      return (
                        <Box key={field} className={styles.row}>
                          <Typography className={styles.rowTitle}>{title}:</Typography>
                          <Typography variant="body2" color={dataValue ? colors.black : colors.grey}>
                            {dataValue !== undefined
                              ? formatter
                                ? formatter(dataValue, programm)
                                : (dataValue as any)
                              : 'Не указан'}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Stack>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {isLoading && <LinearProgress className={styles.loadingIndicator} />}
        </FormProvider>
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          type="button"
          variant="contained"
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleSubmit}>
          Сохранить
        </Button>
      </Box>
    </>
  );
};
