import { Icon } from '@components/Icon';
import { FC, useRef } from 'react';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import {
  useFilesControllerUploadFileMutation,
  useFilesControllerDeleteFileMutation,
} from '@api/admin/adminGeneratedApi';
import { FileType } from '@hooks/useUploadMediaFiles';
import { QuoteWithPhotoElement } from '../../types';
import { deleteQuoteWithPhoto, updateQuoteWithPhoto } from '../../utils/quote-with-photo';
import { useConfirmDialog } from '../ConfirmDialog/useConfirmDialog';
import {
  AddQuoteWithPhotoModal,
  AddQuoteWithPhotoRef,
} from '../Toolbar/components/QuoteWithPhoto/AddQuoteWithPhotoModal';
import { QuoteWithPhotoFormData } from '../Toolbar/components/QuoteWithPhoto/QuoteWithPhotoForm';
import styles from './styles.module.scss';

export const QuoteWithPhotoCard: FC<RenderElementProps> = (props) => {
  const { attributes, children } = props;
  const editor = useSlate();
  const element = props.element as QuoteWithPhotoElement;
  const modalRef = useRef<AddQuoteWithPhotoRef>(null);
  const [uploadFile] = useFilesControllerUploadFileMutation();
  const [deleteFile] = useFilesControllerDeleteFileMutation();

  const { onClickConfirm, dialog } = useConfirmDialog({
    title: 'Подтвердите действие',
    text: 'Удалить авторскую цитату?',
    onConfirm: async () => {
      ReactEditor.focus(editor);
      if (element.imageFileId) {
        await deleteFile({ fileId: element.imageFileId });
      }
      deleteQuoteWithPhoto(editor, element);
    },
  });

  const onLinkClickEdit = () => {
    ReactEditor.focus(editor);
    modalRef.current?.open();
  };

  const handleSubmit = async (formData: QuoteWithPhotoFormData) => {
    if (formData.image) {
      if (element.imageFileId) {
        await deleteFile({ fileId: element.imageFileId });
      }

      const fd = new FormData();
      fd.set('file', formData.image);
      fd.set('type', FileType.editorPhoto.toString());
      const res = await uploadFile({ subjectId: '-1', mediaFileUpdaterDto: fd as any }).unwrap();

      updateQuoteWithPhoto(editor, { ...formData, imageFileUrl: res.url, imageFileId: res.id }, element);
      return;
    }
    updateQuoteWithPhoto(editor, formData, element);
  };

  return (
    <div className="quote-with-photo" {...attributes} contentEditable={false}>
      <div className="quote-with-photo__quote">{children}</div>
      <div className="quote-with-photo__author-wrapper">
        {(element.imageUrl || element.imageFileUrl) && (
          <img src={element.imageUrl || element.imageFileUrl} alt="" className="quote-with-photo__avatar" />
        )}
        {element.authorName && (
          <div className="quote-with-photo__author-info-wrapper">
            <div className="quote-with-photo__author-name">{element.authorName}</div>
            {element.authorInfo && <div className="quote-with-photo__author-info">{element.authorInfo}</div>}
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <button onClick={onLinkClickEdit}>
          <Icon name="edit" width={16} height={16} />
        </button>
        <button onClick={onClickConfirm}>
          <Icon name="delete" width={16} height={16} />
        </button>
      </div>
      <AddQuoteWithPhotoModal modalRef={modalRef} initialFormData={element} onSubmit={handleSubmit} />
      {dialog}
    </div>
  );
};
