import { forwardRef, ChangeEvent, useId, useState, useCallback } from 'react';
import cn from 'classnames';
import { Box, Typography, LinearProgress, Stack, Button, IconButton } from '@mui/material';
import { Icon } from '@components/Icon';
import { Input } from '@components/Input';

import indents from '@styles/indents.module.scss';

import styles from './styles.module.scss';

import { ImagePickerProps } from './types';

export const ImagePicker = forwardRef(
  (
    {
      value,
      onChange,
      accept,
      multiple,
      canUseLink,
      imageUrl,
      onDelete,
      error,
      loading,
      loadingText,
    }: ImagePickerProps,
    _,
  ) => {
    // TODO: remove loading when BE logic will be implemented
    const id = useId();
    const [url, setUrl] = useState('');

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value);

    const onBtnClick = useCallback(() => {
      // TODO: implement changing imgage logic
      // onChange?.(url);
    }, []);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        try {
          onChange?.(multiple ? [...(value as File[]), e.target.files[0]] : e.target.files[0]);
        } catch (e) {
          console.error(e);
        } finally {
        }
      }
    };

    const fileInput = useCallback(
      () => (
        <input
          id={id}
          type="file"
          onChange={onFileChange}
          accept={accept}
          className={styles.input}
          multiple={multiple}
        />
      ),
      [],
    );

    const inputWrapper = useCallback(
      () => (
        <Stack direction="row" gap={indents.xs}>
          <Input
            className={styles.textField}
            placeholder="Ссылка на изображение"
            value={url}
            onChange={onInputChange}
            fullWidth
          />

          <IconButton onClick={onBtnClick}>
            <Icon name="check" />
          </IconButton>
        </Stack>
      ),
      [url, onBtnClick],
    );

    const handleDelete = () => {
      if (value) {
        // TODO: remove if won't need
        //   onChange?.(multiple ? (value as string[]).filter((val: string) => val !== value[0]) : undefined)
        onChange?.(undefined);
        return;
      }
      if (imageUrl) onDelete?.();
    };

    if (loading) {
      return (
        <Stack gap="20px">
          <Box className={cn(styles.imgWrapper, { loading })}>
            <Typography variant="body2">{loadingText}</Typography>
            <LinearProgress className={styles.indicator} />
          </Box>

          {canUseLink && inputWrapper()}
        </Stack>
      );
    }

    if (!value && !imageUrl) {
      return (
        <Stack gap="20px">
          <label htmlFor={id} className={cn(styles.imgWrapper, { error })}>
            <Icon name="photo" viewBox="0 0 52 42" />

            <Typography variant="body2">Загрузить фотографию</Typography>

            {fileInput()}
          </label>

          {canUseLink && inputWrapper()}
          {error && <Box className={styles.error}>{error}</Box>}
        </Stack>
      );
    }

    const imagePreview = (() => {
      if (!value) return;
      return multiple && Array.isArray(value)
        ? URL.createObjectURL(value?.[0] as File)
        : URL.createObjectURL(value as File);
    })();

    return (
      <Stack gap="20px">
        <Box className={cn(styles.imgWrapper, { image: !!value || !!imageUrl, error })}>
          <img src={imagePreview ?? imageUrl} alt="" className={styles.img} />

          <Stack className={styles.buttons} direction="row" gap="16px">
            <Button variant="outlined" className={styles.button}>
              Выбрать фото
              <label htmlFor={id} className={styles.imageInput}>
                {fileInput()}
              </label>
            </Button>

            {!multiple && (
              <IconButton className={styles.button} onClick={handleDelete}>
                <Icon name="delete" />
              </IconButton>
            )}
          </Stack>
        </Box>
        {canUseLink && inputWrapper()}
        {error && <Box className={styles.error}>{error}</Box>}
      </Stack>
    );
  },
);
