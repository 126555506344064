import { usePageTitle } from '@hooks/usePageTitle';
import { useOutlet } from 'react-router';
import { Grid, Paper } from '@mui/material';
import { Header } from '@layouts/Base/components/Header';
import { NsiFilters } from 'area/nsi/components/NsiFilters';
import { NsiTable } from 'area/nsi/components/NsiTable';

export const NsiLogs = () => {
  usePageTitle('НСИ Логи');

  const outlet = useOutlet();
  if (outlet) return outlet;

  return (
    <Grid container direction="column" gap="24px">
      <Grid item>
        <Header title="НСИ Логи" />
      </Grid>
      <Grid item>
        <NsiFilters />
      </Grid>
      <Grid item width="100%">
        <Paper sx={{ padding: '20px' }}>
          <NsiTable />
        </Paper>
      </Grid>
    </Grid>
  );
};
