import { Filter } from '@components/Filter';
import { FC } from 'react';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { statusOptions } from '@area/tickets/defaultValues';
import { useHumans } from '@area/studentsPage/hooks/useHumans';
import { DateService } from '@services/DateService';
import { TicketsFilterValues } from './types';

interface Props {
  onChange: (values: any) => void;
}

const HUMANS_COUNT = 100;

export const TicketsFilter: FC<Props> = ({ onChange }) => {
  const { getHumans, humansList, isLoading } = useHumans();

  const fetcher = (search: string) => {
    getHumans({
      skip: 0,
      size: HUMANS_COUNT,
      asc: true,
      humansFilterRequest: {
        search,
      },
    });
  };

  return (
    <Filter<TicketsFilterValues>
      onChange={onChange}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '340px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'status',
          props: {
            placeholder: 'Статус',
            icon: <Icon name="filter" />,
            options: statusOptions,
            sx: { width: '240px', background: colors.white },
          },
        },
        {
          type: 'datepicker',
          name: 'createdAt',
          props: {
            dateFormat: DateService.dateFormats.date,
            placeholder: 'Дата создания',
            textFieldProps: {
              placeholder: 'Дата создания',
              sx: { width: '240px', background: colors.white },
            },
          },
        },
        {
          type: 'autocomplete',
          name: 'userId',
          props: {
            placeholder: 'Автор',
            sx: { width: '240px', background: colors.white },
            startAdornmentInputIcon: <Icon name="edit" style={{ marginRight: '8px' }} />,
            options: humansList || [],
            disableClearable: true,
            getOptionLabel: (option: any) => option.email,
            loading: isLoading,
            fetcher,
            optionLabelKey: 'email',
            filterValueKey: 'id',
            noOptionsText: humansList ? 'Нет доступных вариантов' : 'Начните вводить',
          },
        },
      ]}
    />
  );
};
