import { FC } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import styles from './styles.module.scss';

export const OpenPickerButton: FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      className={styles.openPickerButton}
      {...props}
      sx={{
        '&.MuiIconButton-root': {
          padding: '3px',
          right: '12px',
        },
      }}>
      <Icon name="calendar" htmlColor={colors.black} />
    </IconButton>
  );
};
