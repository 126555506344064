export enum OrderStatus {
  new = 0,
  processing = 1,
  paused = 2,
  success = 3,
  error = 4,
}

export enum OrderType {
  esk_card = 'esk_card',
  extra_grant = 'extra_grant',
  credit_grant = 'credit_grant',
}
