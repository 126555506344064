import { useParticipantsHumansControllerGetHumanQuery } from '@api/admin/adminGeneratedApi';
import { Grid, Skeleton } from '@mui/material';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { StudentTabs } from '@area/student/components/StudentTabs';
import { StudentNameEcp } from '@area/student/components/StudentNameEcp';
import indents from '@styles/indents.module.scss';
import { useParams } from 'react-router';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { useMemo } from 'react';

export const StudentPage = () => {
  usePageTitle('Данные участника'); // todo: заменит на имя участника?
  const { id } = useParams();
  const { data: humanResponse, isLoading } = useParticipantsHumansControllerGetHumanQuery({
    id: id as string,
  });
  const name = useMemo(() => {
    return humanResponse
      ? [humanResponse.lastName, humanResponse.firstName, humanResponse.middleName].filter((w) => !!w).join(' ')
      : null;
  }, [humanResponse]);

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item container flexDirection="column" gap={indents.xxs}>
        <Header
          title="Данные участника"
          backBtnTitle="Назад к списку участников"
          backBtnRoute={buildRoutePath(Routes.students, { absolute: true })}
        />
        {isLoading ? <Skeleton variant="text" width={200} /> : <StudentNameEcp name={name ?? '—'} />}
      </Grid>
      {isLoading ? (
        <LoadingSpinner sx={{ margin: '48px 0' }} />
      ) : (
        humanResponse && (
          <Grid sx={{ width: '100%' }} item>
            <StudentTabs human={humanResponse} />
          </Grid>
        )
      )}
    </Grid>
  );
};
