import { usePreviewUrl } from '@hooks/usePreviewUrl';
import { useMemo, useState } from 'react';
import { Grid, Button, Box, LinearProgress, Typography, Stack } from '@mui/material';
import { FormProvider, Path, useForm } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { Preview } from '@area/redactor/components/Preview/components';
import { Redactor } from '@area/redactor/components/Redactor';
import { MetaTags } from '@area/redactor/components/MetaTags';
import { ModalCheckMistakes } from '@area/redactor/components/ModalCheckMistakes';
import { useCheckMistakes } from '@area/redactor/hooks/useCheckMistakes';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { sharedValidation } from '@area/publications/validation/sharedValidation';
import { publicationValidation } from '@area/publications/validation/validation';
import { SlugField } from '@area/redactor/components/SlugField';
import { MediaFileParams, mockPublicationTypes, sharedPublicationFieldNames } from '@area/publications/defaultValues';
import { useAuthorsSelectOptions } from '@area/authors/hooks/useAuthorsSelectOptions';
import { useRubricsSelectOptions } from '@area/rubrics/hooks/useRubricsSelectOptions';
import { BaseFieldValues, FormProps } from '@area/publications/types';
import { Photo } from '@area/redactor/components/Photo';
import { Video } from '@area/redactor/components/Video';
import { Snippet } from '@area/redactor/components/Snippet';
import { Embedded } from '@area/redactor/components/Embedded';
import { TypographButton } from '@area/redactor/components/TypographButton';
import { useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import styles from './style.module.scss';

export const Form = <TValues extends BaseFieldValues>({ defaultValues, onSubmit, isLoading }: FormProps<TValues>) => {
  const resolver = useYupValidationResolver<TValues>(publicationValidation);
  const form = useForm({
    defaultValues,
    resolver,
  });

  const formData = form.watch();

  const [imgUrl, setImgUrl] = useState(formData?.mainImage?.url);
  const [key, setKey] = useState(0);
  const [deleteFile, { isLoading: imageDeletingLoading }] = useFilesControllerDeleteFileMutation();
  const handleSubmitInfo = (values: any) => {
    const result = sharedPublicationFieldNames.reduce((acc, name) => {
      acc[name] = values[name];
      return acc;
    }, {} as typeof values);

    onSubmit({
      ...form.getValues(),
      ...result,
    });
    setInitialValues(null);
  };

  const {
    fieldsToCheck,
    modalShow,
    form: modalForm,
    setInitialValues,
  } = useCheckMistakes(sharedPublicationFieldNames, 'publication', sharedValidation, formData, imgUrl);

  const handleCheckAndSubmit = form.handleSubmit((data) => {
    if (data.mainImage.file) {
      const url = URL.createObjectURL(data.mainImage.file);
      setImgUrl(url);
    }

    if (!formData.published) {
      onSubmit(data as TValues);
      return;
    }

    setInitialValues({
      photo: '',
      title: data.title,
      fullTitle: data.fullTitle,
    });
  });

  const rubricsSelectProps = useRubricsSelectOptions();
  const authorsSelectProps = useAuthorsSelectOptions();

  const bottomComponent = useMemo(() => {
    const valuesToCheck = {
      title: formData.title,
      fullTitle: formData.fullTitle,
      editorContent: formData.editorContent,
    };
    const callBackAfterCheck = (values: Record<string, any>) => {
      form.reset({
        ...formData,
        ...values,
      });

      setKey(key + 1);
    };

    return <TypographButton valuesToCheck={valuesToCheck} callBackAfterCheck={callBackAfterCheck} />;
  }, [form, formData]);

  const tabs = useMemo(
    () => [
      {
        label: 'Текст публикации',
        content: <FormField name="editorContent" type="wysiwyg" bottomComponent={bottomComponent} key={key} />,
      },
      {
        label: 'Embedded',
        content: <Embedded fieldName="embedded" />,
      },
      {
        label: 'Фото репортаж',
        content: <Photo name="photoGallery" photoGallery={formData.photoGallery} />,
      },
      {
        label: 'Видео',
        content: <Video />,
      },
      {
        label: 'Сниппет',
        content: <Snippet<TValues> form={form} snippetPhoto={formData.snippetPhoto as MediaFileParams} />,
      },
      {
        label: 'Метатеги',
        content: <MetaTags tags={defaultValues.metaTags} />,
      },
    ],
    [bottomComponent, defaultValues.metaTags, formData.photoGallery, formData.snippetPhoto],
  );

  const handleDeleteFile = async () => {
    const id = formData?.mainImage?.id;
    if (id) {
      await deleteFile({ fileId: id });
    }
    form.setValue('mainImage' as Path<TValues>, undefined as any);
  };

  const url = usePreviewUrl('news', formData.url);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <Preview url={url} disabled={!formData.id} />
        <FormProvider {...form}>
          <Grid item>
            <FormField name="published" type="switch" label={formData.published ? 'Вкл' : 'Выкл'} title="Показать" />
          </Grid>
          <Grid item>
            <FormField name="startDate" type="dateTimePicker" title="Дата публикации" sx={{ width: 200 }} />
          </Grid>
          <Grid item container gap={indents.l}>
            <Grid item flex="0 0 200px">
              <FormField
                name="type"
                type="select"
                placeholder="Выберите тип"
                icon={<Icon name={formData.type ? (formData.type?.value === 'news' ? 'article' : 'book') : 'type'} />}
                title="Тип"
                options={mockPublicationTypes}
              />
            </Grid>
            <Grid item flex={1}>
              <FormField
                name="newsTagIds"
                type="select"
                placeholder="Выберите рубрики"
                icon={<Icon name="rubric" />}
                title="Рубрики"
                multiple
                {...rubricsSelectProps}
              />
            </Grid>
          </Grid>
          <Grid item>
            <FormField name="fullTitle" title="Большой заголовок" fullWidth />
          </Grid>
          <Grid item>
            <FormField name="title" title="Основной заголовок" fullWidth countText />
          </Grid>
          <Grid item>
            <SlugField fieldNameToSlugify="title" disabled={!!defaultValues.id} />
          </Grid>
          <Grid item>
            <Grid container flexDirection="column" gap={indents.xs}>
              <Grid item>
                <Typography variant="h2">Главное фото</Typography>
              </Grid>
              <Grid item>
                <Grid container gap="20px">
                  <Grid item sx={{ width: 304 }}>
                    <FormField
                      onDelete={handleDeleteFile}
                      imageUrl={imgUrl}
                      name="mainImage.file"
                      type="image"
                      loadingText="Удаление фото"
                      loading={imageDeletingLoading}
                    />
                  </Grid>
                  <Grid item className={styles.imageInputsWrapper}>
                    <Stack gap="20px">
                      <FormField name="mainImage.alt" placeholder="Подпись" fullWidth />
                      <FormField name="mainImage.metadata" placeholder="Фотограф" fullWidth />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Redactor tabs={tabs} />
          </Grid>
          <Grid item>
            <FormField
              name="authorIds"
              type="select"
              placeholder="Укажите автора"
              icon={<Icon name="edit" />}
              multiple
              title="Автор"
              {...authorsSelectProps}
            />
          </Grid>
        </FormProvider>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          type="button"
          variant="contained"
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleCheckAndSubmit}>
          Сохранить
        </Button>
      </Box>
      <ModalCheckMistakes
        fieldsToCheck={fieldsToCheck}
        form={modalForm}
        open={modalShow}
        onClose={() => setInitialValues(null)}
        onSubmit={handleSubmitInfo}
      />
    </>
  );
};
