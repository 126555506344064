import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState } from 'react';
import { useUsersControllerUpdateUserActiveStatusMutation } from '@api/admin/adminApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';

export interface ActiveStatusSwitchProps {
  userId: string;
  isActive: boolean;
  disabled: boolean;
}

export const ActiveStatusSwitch = ({ userId, isActive, disabled }: ActiveStatusSwitchProps) => {
  const { handlePushItem } = useNotifications();
  const [checked, setChecked] = useState(isActive);
  const [update, { isLoading }] = useUsersControllerUpdateUserActiveStatusMutation();

  const onChange = async () => {
    const res = await update({
      id: userId,
      userActiveStatusUpdaterDto: { isActive: !checked },
    }).unwrap();

    setChecked(res.isActive);

    handlePushItem({
      severity: 'success',
      text: 'Статус пользователя успешно изменен',
      autoHideDuration: 3000,
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={isLoading || disabled}
          checked={checked}
          title={checked ? 'Активный' : 'Заблокирован'}
          onChange={onChange}
        />
      }
      label={isActive ? 'Активный' : 'Заблокирован'}
    />
  );
};
