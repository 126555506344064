import { Range, Transforms } from 'slate';
import { memo, Ref, useImperativeHandle, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useSlateStatic } from 'slate-react';
import { Input } from '@components/Input';
import { Modal } from '@components/Modal';
import { insertLink } from '@area/redactor/components/Wysiwyg/utils/links';
import indents from '@styles/indents.module.scss';

export interface AddLinkModalRef {
  open: () => void;
  close: () => void;
}

export interface AddLinkModalProps {
  modalRef: Ref<AddLinkModalRef>;
}

export const AddLinkModal = memo(({ modalRef }: AddLinkModalProps) => {
  const editor = useSlateStatic();

  const [showModal, setShowModal] = useState(false);
  const [isNeedName, setIsNeedName] = useState(true);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [selection, setSelection] = useState<Range | null>(null);

  const onClose = () => {
    setName('');
    setUrl('');
    setShowModal(false);

    if (selection) {
      const distance = name.length;
      const { anchor, focus } = selection;

      setTimeout(() => {
        Transforms.select(editor, focus.offset < anchor.offset ? focus : anchor);
        Transforms.move(editor, { distance, unit: 'character' });
      }, 50);
    }
  };
  const onAccept = () => {
    if (url) insertLink(editor, url, name);
    onClose();
  };

  useImperativeHandle(modalRef, () => ({
    open: () => {
      if (!editor.selection) return;

      setSelection(editor.selection);
      setIsNeedName(Range.isCollapsed(editor.selection));
      setShowModal(true);
    },
    close: onClose,
  }));

  return (
    <Modal
      maxWidth="xs"
      open={showModal}
      onClose={onClose}
      title="Добавление ссылки"
      actions={
        <>
          <Button onClick={onClose}>Отмена</Button>
          <Button variant="contained" onClick={onAccept}>
            Принять
          </Button>
        </>
      }>
      <Grid container flexDirection="column" gap={indents.m}>
        {isNeedName && (
          <Input value={name} onChange={({ target }) => setName(target.value)} fullWidth placeholder="Название" />
        )}
        <Input value={url} onChange={({ target }) => setUrl(target.value)} fullWidth placeholder="URL" />
      </Grid>
    </Modal>
  );
});
