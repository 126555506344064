import { useCallback } from 'react';
import { ValidationError, Schema } from 'yup';
import { Resolver } from 'react-hook-form';

export const useYupValidationResolver = <T extends Record<string, unknown>>(validationSchema: Schema): Resolver<T> => {
  return useCallback(
    async (data: T) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (error) {
        let errors = {};

        if (error instanceof ValidationError) {
          errors = error.inner.reduce<Partial<Record<keyof T, {}>>>(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path as string]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          );
        }

        return {
          values: {},
          errors,
        };
      }
    },
    [validationSchema],
  );
};
