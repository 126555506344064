import { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { Table } from '@components/Table/Table';
import { Filter } from '@components/Filter';
import { Field } from '@components/Filter/types';
import { EducationalOrganizationResponse } from '@api/admin/adminGeneratedApi';
import { useQueryParams } from '@hooks/useQueryParams';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useScholarships } from './hooks/useScholarships';

const searchField: Field = {
  type: 'text',
  name: 'search',
  props: { placeholder: 'Поиск', sx: { minWidth: '200px' } },
  stretchable: true,
};

export const Scholarships = () => {
  const {
    columns,
    fetchOrgsGrants,
    data,
    isLoadingOrgs,
    isErrorOrgs,
    isFetchedOrgs,
    loadingRowId,
    isSuccessSaving,
    isErrorSaving,
  } = useScholarships();
  const {
    params: { search },
  } = useQueryParams();
  const { handlePushItem } = useNotifications();

  const onChange = async (values: { search?: string }) => {
    fetchOrgsGrants({ search: values.search || '' });
  };

  useEffect(() => {
    onChange({ search });
  }, []);

  useEffect(() => {
    if (isErrorOrgs) {
      handlePushItem({ severity: 'error', text: 'Ошибка загрузки организаций' });
    }

    if (isErrorSaving) {
      handlePushItem({ severity: 'error', text: 'Ошибка сохранения суммы стипендии' });
    }
  }, [isErrorOrgs, isErrorSaving]);

  useEffect(() => {
    if (isSuccessSaving) {
      handlePushItem({ severity: 'success', text: 'Сумма стипендии успешно сохранена' });
    }
  }, [isSuccessSaving]);

  return (
    <>
      <Grid item>
        <Filter<{ search: string }> onChange={onChange} searchField={searchField} />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Paper sx={{ padding: '20px' }}>
          <Table<EducationalOrganizationResponse>
            columns={columns}
            data={data || []}
            loading={isLoadingOrgs}
            fetched={isFetchedOrgs}
            loadingRowId={loadingRowId}
          />
        </Paper>
      </Grid>
    </>
  );
};
