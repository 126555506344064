import { Icon } from '@components/Icon';
import { FC, useRef } from 'react';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import { KeyQuoteElement } from '../../types';
import { deleteKeyQuote, updateKeyQuote } from '../../utils/key-quote';
import { useConfirmDialog } from '../ConfirmDialog/useConfirmDialog';
import { AddKeyQuoteModal, AddKeyQuoteRef } from '../Toolbar/components/KeyQuote/AddKeyQuoteModal';
import './styles.scss';

export const KeyQuoteCard: FC<RenderElementProps> = (props) => {
  const { attributes, children } = props;
  const editor = useSlate();
  const element = props.element as KeyQuoteElement;
  const modalRef = useRef<AddKeyQuoteRef>(null);

  const { onClickConfirm, dialog } = useConfirmDialog({
    title: 'Подтвердите действие',
    text: 'Удалить цитату?',
    onConfirm: () => {
      ReactEditor.focus(editor);
      deleteKeyQuote(editor, element);
    },
  });

  const onLinkClickEdit = () => {
    ReactEditor.focus(editor);
    modalRef.current?.open();
  };

  return (
    <div className="key-quote-card" {...attributes} contentEditable={false}>
      <div className="key-quote-card__info">
        <div>Цитата:</div>
        <div>{children}</div>
      </div>
      <div className="key-quote-card__actions">
        <button onClick={onLinkClickEdit}>
          <Icon name="edit" width={16} height={16} />
        </button>
        <button onClick={onClickConfirm}>
          <Icon name="delete" width={16} height={16} />
        </button>
      </div>
      <AddKeyQuoteModal
        modalRef={modalRef}
        initialFormData={element}
        onSubmit={(formData) => {
          updateKeyQuote(editor, formData, element);
        }}
      />
      {dialog}
    </div>
  );
};
