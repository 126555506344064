import { useParams } from 'react-router';
import { Grid } from '@mui/material';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { PaymentData } from '@area/payments/components/PaymentData';
import indents from '@styles/indents.module.scss';

export const PaymentPage = () => {
  usePageTitle('Данные о выплате');

  const { paymentType } = useParams();

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item container flexDirection="column" gap={indents.xxs}>
        <Header
          title="Данные о выплате"
          backBtnTitle="Назад к списку выплат"
          backBtnRoute={buildRoutePath([Routes.payments, Routes.paymentsType], {
            params: { paymentType },
            absolute: true,
          })}
        />
      </Grid>
      <Grid item>
        <PaymentData />
      </Grid>
    </Grid>
  );
};
