import { Transforms, Editor, Range, Element, Path, NodeEntry } from 'slate';
import {
  CustomEditor,
  CustomText,
  TableCellElement,
  TableCellType,
  TableElement,
  TableHeaderElement,
  TableRowElement,
} from '../types';

export class TableUtil {
  constructor(private editor: CustomEditor) {}

  public insertTable(selection: Range, rows: number, columns: number) {
    Transforms.select(this.editor, selection);

    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table',
      mode: 'highest',
    });

    if (tableNode) return;
    if (!rows || !columns) {
      return;
    }
    const cellText = Array.from({ length: rows }, () => {
      return Array.from({ length: columns }, () => '');
    });

    const newTable = this.createTableNode(cellText);

    Transforms.insertNodes(this.editor, newTable, { mode: 'highest' });
    Transforms.insertNodes(this.editor, { type: 'paragraph', children: [{ text: '' }] }, { mode: 'highest' });
  }

  public insertColumn() {
    const { selection } = this.editor;
    if (!selection) return;

    const tableNode = this.getTableNode();
    if (!tableNode) return;

    const [oldTable, path] = tableNode;
    const currentColumnIndex = selection.focus.path[3];

    this.removeTable();
    this.insertCells(oldTable, path, 'columns', currentColumnIndex);
    Transforms.select(this.editor, selection);
  }

  public insertRow() {
    const { selection } = this.editor;
    if (!selection) return;

    const tableNode = this.getTableNode();
    if (!tableNode) return;

    const [oldTable, path] = tableNode;
    const currentRowIndex = selection.focus.path[2];
    this.removeTable();
    this.insertCells(oldTable, path, 'row', currentRowIndex);
    Transforms.select(this.editor, selection);
  }

  public removeColumn() {
    const { selection } = this.editor;
    if (!selection) return;

    const tableNode = this.getTableNode();
    if (!tableNode) return;

    const [oldTable, path] = tableNode;
    const currentColumnIndex = selection.focus.path[3];

    this.removeTable();
    this.removeCells(oldTable, path, 'columns', currentColumnIndex);
  }

  public removeRow() {
    const { selection } = this.editor;
    if (!selection) return;

    const tableNode = this.getTableNode();
    if (!tableNode) return;

    const [oldTable, path] = tableNode;
    const currentRowIndex = selection.focus.path[2];
    this.removeTable();
    this.removeCells(oldTable, path, 'row', currentRowIndex);
  }

  public removeTable() {
    Transforms.removeNodes(this.editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table',
      mode: 'highest',
    });
  }

  private getTableNode() {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table',
      });

      return tableNode as NodeEntry<TableElement> | undefined;
    }
  }

  private createNewTable(existingText: string[][], path: Path) {
    const newTable = this.createTableNode(existingText);
    Transforms.insertNodes(this.editor, newTable, {
      at: path,
    });
  }

  private insertCells(tableNode: TableElement, path: Path, action: 'columns' | 'row', currentCellIndex: number) {
    let existingText = tableNode.children[0].children.map((rows) =>
      rows.children.map((arr) => (arr.children[0] as CustomText).text),
    );
    const columns = existingText[0].length;
    if (action === 'row') {
      existingText.splice(currentCellIndex + 1, 0, Array(columns).fill(''));
    } else {
      existingText = existingText.map((item) => {
        item.splice(currentCellIndex + 1, 0, '');
        return item;
      });
    }

    this.createNewTable(existingText, path);
  }

  private removeCells(tableNode: TableElement, path: Path, action: 'columns' | 'row', currentCellIndex: number) {
    let existingText = Array.from(tableNode.children[0].children, (rows) =>
      Array.from(rows.children, (arr) => (arr.children[0] as CustomText).text),
    );
    if (action === 'row') {
      existingText.splice(currentCellIndex, 1);
    } else {
      existingText = Array.from(existingText, (item) => {
        item.splice(currentCellIndex, 1);
        return item;
      });
    }

    this.createNewTable(existingText, path);
  }

  private createRow(cellText: string[], cellType: TableCellType) {
    const newRow = cellText.map((value) => this.createTableCell(value, cellType));
    return {
      type: 'table-row',
      children: newRow,
    } as TableRowElement;
  }

  private createTableCell(text: string, type: TableCellType) {
    return {
      type: type,
      children: [{ text }],
      align: 'left',
    } as TableHeaderElement | TableCellElement;
  }

  private createTableNode(cellText: string[][]) {
    const tableChildren: TableRowElement[] = cellText.map((value, i) => {
      return this.createRow(value, i === 0 ? 'table-header' : 'table-cell');
    });

    return { type: 'table', children: [{ type: 'table-body', children: tableChildren }] } as TableElement;
  }
}
