import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Column } from '@components/Table/types';
import { Icon } from '@components/Icon';
import { HumanLogResponse, useParticipantsHumansControllerGetHumanLogsQuery } from '@api/admin/adminGeneratedApi';
import colors from '@styles/colors.module.scss';
import { Table } from '@components/Table/Table';
import { DateService } from '@services/DateService';
import styles from './styles.module.scss';

export const HumanLogs = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useParticipantsHumansControllerGetHumanLogsQuery({ id: id! });

  const columns: Column<HumanLogResponse>[] = useMemo(
    () => [
      {
        title: '',
        align: 'left',
        dataIndex: 'isError',
        key: 'isError',
        width: '72px',
        render: (value: boolean) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Icon name={value ? 'close' : 'check'} htmlColor={value ? colors.error : colors.success} />
          </Box>
        ),
      },
      {
        title: 'Дата',
        align: 'left',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '300px',
        render: (value: string) => <Typography variant="body2">{DateService.getLocalDate(value)}</Typography>,
      },
      {
        title: 'Тип',
        align: 'left',
        dataIndex: 'type',
        key: 'type',
        width: '300px',
        render: (value: string) => (
          <Typography variant="body2">{value === 'Auth' ? 'Авторизация' : 'Поиск студента '}</Typography>
        ),
      },
      {
        title: 'Текст',
        align: 'left',
        dataIndex: 'text',
        key: 'text',
        width: '500px',
        render: (value: string) => (
          <Typography className={styles.text} variant="body2">
            {value}
          </Typography>
        ),
      },
      {
        title: 'ВУЗ',
        align: 'left',
        dataIndex: 'organizationShortName',
        key: 'organizationShortName',
      },
    ],
    [],
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Typography>Ошибка загрузки данных</Typography>
      </Box>
    );
  }

  if (!data?.length) {
    return (
      <Box>
        <Typography>Нет данных</Typography>
      </Box>
    );
  }

  return <Table<HumanLogResponse> columns={columns} data={data} />;
};
