import { MetaRequest, MetaResponse } from '@api/admin/adminGeneratedApi';
import { defaultMetaTags } from './defaultValues';

export const getMetaTagsFromValues = (loadedMetaTags: (MetaRequest | MetaResponse)[] = []): MetaRequest[] => {
  // аккумулирование загруженных данных на базе defaultMetaTags
  const loadedValues = defaultMetaTags.reduce((acc, { name, content }) => {
    const { content: loadedContent } = loadedMetaTags?.find(({ name: loadedName }) => loadedName === name) || {};

    acc[name] = loadedContent || content;
    return acc;
  }, {} as any);

  // формирование массива с заполенными данными
  return defaultMetaTags.map((tag, index) => {
    return {
      ...tag,
      content: loadedValues[tag.name] || tag.content,
    };
  });
};
