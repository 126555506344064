import { usePartnerControllerGetPartnerQuery } from '@api/admin/adminGeneratedApi';

export const useGetPartnerById = (id: string) => {
  const { data, isLoading, isSuccess } = usePartnerControllerGetPartnerQuery({
    id,
  });

  return {
    data,
    isLoading,
    isSuccess,
  };
};
