import { FormField } from '@components/FormField';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import slugify from 'slugify';

interface Props {
  fieldNameToSlugify: string;
  fieldName?: string;
  disabled?: boolean;
}

function slugifyWithConfig(str: string = '') {
  return slugify(str, {
    replacement: '-',
    remove: /[`^_={}[\]*+~.()'"!?:@,\\/ь«»]/g,
    lower: true,
    trim: true,
  });
}

export const SlugField: FC<Props> = ({ fieldNameToSlugify, fieldName = 'url', disabled }) => {
  const { watch, setValue, getFieldState, resetField } = useFormContext<{ [k: typeof fieldNameToSlugify]: string }>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (disabled) return;

      if (name === fieldNameToSlugify) {
        setValue(fieldName, slugifyWithConfig(value[fieldNameToSlugify]));
        if (getFieldState(fieldName).invalid) {
          resetField(fieldName);
        }
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, disabled]);

  return (
    <FormField
      name={fieldName}
      title="Url адрес страницы"
      transformValue={slugifyWithConfig}
      disabled={disabled}
      fullWidth
    />
  );
};
