import { IconButton, ButtonGroup } from '@mui/material';
import { useSlate } from 'slate-react';
import { MouseEvent } from 'react';
import { Icon } from '@components/Icon';

export const History = () => {
  const { history, undo, redo } = useSlate();

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <ButtonGroup>
      <IconButton
        centerRipple={false}
        disabled={!history.undos.length}
        onClick={undo}
        onMouseDown={onMouseDown}
        title="Отменить">
        <Icon name="wysiwygUndo" viewBox="0 0 16 16" />
      </IconButton>
      <IconButton
        centerRipple={false}
        disabled={!history.redos.length}
        onClick={redo}
        onMouseDown={onMouseDown}
        title="Повторить">
        <Icon name="wysiwygRedo" viewBox="0 0 16 16" />
      </IconButton>
    </ButtonGroup>
  );
};
