import { FC } from 'react';
import { List, ListItem } from '@mui/material';
import { Link, useLocation, matchPath } from 'react-router-dom';
import cn from 'classnames';
import { useCurrentUserRoles } from '@area/user/hooks/useCurrentUserRoles';
import styles from './styles.module.scss';

import { Link as ChapterLink } from './types';

type ChaptersListProps = {
  links: ChapterLink[];
  isTooltip?: boolean;
};

export const ChaptersList: FC<ChaptersListProps> = ({ links, isTooltip }) => {
  const { pathname } = useLocation();
  const { hasRole } = useCurrentUserRoles();

  return (
    <List className={cn({ [styles.list]: isTooltip })} disablePadding>
      {links &&
        links.map(({ title, href, roles }) => {
          const active = matchPath({ path: href, end: false }, pathname);
          if (roles && !hasRole(roles)) {
            return null;
          }

          return (
            <ListItem key={href} className={cn(styles.listItem, { active })}>
              <Link className={cn(styles.link, { active })} to={href}>
                {title}
              </Link>
            </ListItem>
          );
        })}
    </List>
  );
};
