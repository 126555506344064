import { SyncDataFilterRequestDto } from '@api/admin/adminGeneratedApi';
import styles from '@area/payments/components/PaymentsFilters/styles.module.scss';
import colors from '@styles/colors.module.scss';
import { Filter } from '@components/Filter';
import { Icon } from '@components/Icon';
import { nsiOperationTypeOptions } from '@area/nsi/types';

export const NsiFilters = () => {
  return (
    <Filter<SyncDataFilterRequestDto>
      onChange={() => null}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '200px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'datepicker',
          name: 'dateFrom',
          props: {
            customLabel: <span className={styles.customLabel}>Дата создания:</span>,
            placeholder: 'Начало',
            textFieldProps: {
              placeholder: 'Начало',
              sx: { width: '160px', background: colors.white },
            },
          },
        },
        {
          type: 'datepicker',
          name: 'dateTo',
          props: {
            placeholder: 'Конец',
            textFieldProps: { placeholder: 'Конец', sx: { width: '160px', background: colors.white } },
          },
        },
        {
          type: 'select',
          name: 'operationType',
          props: {
            placeholder: 'Тип операции',
            sx: { width: '274px', background: colors.white },
            icon: <Icon name="type" />,
            options: nsiOperationTypeOptions,
            multiple: true,
          },
        },
      ]}
    />
  );
};
