import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { MouseEvent } from 'react';
import { useSlate } from 'slate-react';
import { Icon } from '@components/Icon';
import { listButtons } from '@area/redactor/components/Wysiwyg/constants';
import { List } from '@area/redactor/components/Wysiwyg/types';
import { toggleList, getActiveListType } from '@area/redactor/components/Wysiwyg/utils/lists';

export const Lists = () => {
  const editor = useSlate();
  const activeType = getActiveListType(editor);

  const onChange = (_: MouseEvent<HTMLElement>, value: List | null) => {
    if (activeType && !value) {
      toggleList(editor, activeType);
    }

    if (value) {
      toggleList(editor, value);
    }
  };
  const onMouseDown = (event: MouseEvent) => {
    event.preventDefault();
  };

  return (
    <ToggleButtonGroup exclusive value={activeType} onChange={onChange}>
      {listButtons.map(({ value, icon, title }) => (
        <ToggleButton value={value} key={value} onMouseDown={onMouseDown} title={title}>
          <Icon name={icon} viewBox="0 0 16 16" />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
