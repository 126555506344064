import { useEffect, Fragment, KeyboardEvent } from 'react';
import dayjs, { isDayjs } from 'dayjs';
import { IconButton, Grid, Paper } from '@mui/material';
import { FieldValues, FormProvider, Path, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useQueryParams } from '@hooks/useQueryParams';
import cn from 'classnames';
import { Icon } from '@components/Icon';
import { FormField } from '@components/FormField';
import { Option } from '@components/Select/types';
import colors from '@styles/colors.module.scss';
import { SearchParamsService } from '@services/SearchParamsService';
import { DateService } from '@services/DateService';
import { Field, FilterProps } from './types';
import styles from './styles.module.scss';

const dateFormat = DateService.dateFormats.dateField;

export function Filter<TValues extends FieldValues>({
  onChange,
  searchField,
  trackedFields,
  defaultValues,
  clearable = true,
  hiddenFilterButton,
  filter,
}: FilterProps<TValues>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const form = useForm<TValues>({ defaultValues });
  const { resetParams } = useQueryParams();

  const onSearchClick = () => {
    if (!searchField) return;
    const values = form.getValues();
    const searchValue = values[searchField.name];

    if (!searchValue) {
      searchParams.delete(searchField.name);
      setSearchParams(searchParams);
    } else {
      const params = SearchParamsService.getParams(searchParams);

      setSearchParams({
        ...searchParams,
        ...params,
        [searchField.name]: searchValue,
      });
    }

    onChange(values);
  };

  const setQueryParams = (values: FieldValues) => {
    const formValues = Object.keys(values).reduce((acc, name) => {
      const formValue = values[name];
      const field = trackedFields?.find((f) => f.name === name);

      if (formValue) {
        if (isDayjs(formValue)) {
          acc[name] = dayjs(formValue).format(dateFormat);
        } else if (Array.isArray(formValue)) {
          if (formValue.length === 0) {
            resetParams([name]);
          } else {
            acc[name] = encodeURIComponent(formValue.map((option) => option.value).join(','));
          }
        } else if (typeof formValue === 'object') {
          if ('startDate' in formValue && 'endDate' in formValue) {
            const startDate = formValue.startDate ? dayjs(formValue.startDate).format(dateFormat) : undefined;
            const endDate = formValue.endDate ? dayjs(formValue.endDate).format(dateFormat) : undefined;

            acc[name] = encodeURIComponent([startDate, endDate].join(','));
          } else if (field?.type === 'autocomplete') {
            const value = {
              [name]: formValue[field.props.filterValueKey],
              [field.props.optionLabelKey]: formValue[field.props.optionLabelKey],
            };

            acc[name] = encodeURIComponent(JSON.stringify(value));
          } else {
            acc[name] = formValue.value;
          }
        } else {
          acc[name] = formValue;
        }
      }

      return acc;
    }, {} as { [key: string]: string });

    setSearchParams({
      ...searchParams,
      ...formValues,
    });
  };

  const resetFields = () => {
    const values = form.getValues();

    Object.keys(values).forEach((name) => {
      searchParams.delete(name);
    });

    setSearchParams({ ...searchParams });
    form.reset();
    onChange({} as TValues);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && searchField) {
      onSearchClick();
    }
  };

  useEffect(() => {
    const params = SearchParamsService.getParams(searchParams);

    Object.keys(params).forEach((param) => {
      const field = trackedFields?.find((f) => f.name === param);
      const paramValue = params[param];
      let value;

      switch (field?.type) {
        case 'select': {
          if (field.props.multiple) {
            const selected = decodeURIComponent(paramValue)
              .split(',')
              .map((value) => (isNaN(Number(value)) ? value : Number(value)));
            value = field.props.options.filter((option: Option) => selected.includes(option.value));
          } else {
            value = field.props.options.find((option: Option) => option.value === paramValue);
          }
          break;
        }

        case 'datepicker': {
          const [d, m, y] = params[param].split('-');
          value = dayjs(`${m}-${d}-${y}`);
          break;
        }

        case 'dateRangePicker': {
          const [startDateFromParams, endDateFromParams] = decodeURIComponent(paramValue).split(',');
          let startDate;
          let endDate;

          if (Boolean(startDateFromParams)) {
            const [ds, ms, ys] = startDateFromParams.split('-');

            startDate = dayjs(`${ms}-${ds}-${ys}`).format();
          }

          if (Boolean(endDateFromParams)) {
            const [de, me, ye] = endDateFromParams.split('-');

            endDate = dayjs(`${me}-${de}-${ye}`).format();
          }

          value = { startDate, endDate };
          break;
        }

        case 'autocomplete': {
          value = JSON.parse(decodeURIComponent(params[param]));
          break;
        }

        default:
          value = paramValue;
      }

      form.setValue(param as Path<TValues>, value as any);
    });

    // Следим за изменениями остальных полей, исключая searchField.name
    const subscription = form.watch((value, { name }) => {
      if (name && name !== searchField?.name) {
        setQueryParams(value);
        onChange(value as TValues);
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(filter)) {
      const formValues = form.getValues();
      const deletedFilter = Object.keys(formValues).filter(
        (key) => key !== 'search' && !filter.includes(key) && formValues[key],
      );
      deletedFilter.forEach((item) => {
        form.resetField(item as Path<TValues>);
      });
    }
  }, [filter]);

  return (
    <FormProvider {...form}>
      <Grid container gap="16px">
        {searchField && (
          <>
            <Grid item className={cn({ [styles.stretchable]: searchField?.stretchable })}>
              <FormField
                type={searchField.type}
                name={searchField.name}
                {...searchField.props}
                fullWidth={searchField.stretchable}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={onSearchClick} sx={{ background: colors.white }}>
                <Icon name="search" />
              </IconButton>
            </Grid>
            {hiddenFilterButton && (
              <Grid item marginLeft="auto">
                <div className={styles.actionButtons}>{hiddenFilterButton}</div>
              </Grid>
            )}
          </>
        )}
        {!hiddenFilterButton &&
          trackedFields?.map((field: Field, index: number) => (
            <Fragment key={index}>
              {field.props.customLabel && field.props.customLabel}
              <Grid item className={cn({ [styles.stretchable]: field?.stretchable })}>
                <FormField type={field.type} name={field.name} {...field.props} fullWidth={field?.stretchable} />
              </Grid>
            </Fragment>
          ))}
        {clearable && (
          <Grid item>
            <IconButton onClick={resetFields} sx={{ background: colors.white }}>
              <Icon name="clearFilter" />
            </IconButton>
          </Grid>
        )}
        {hiddenFilterButton && filter && (
          <Paper className={cn(styles.paperFilter, !filter.length && styles.emptyFilter)}>
            <Grid item>
              <span className={styles.filterResult}>Выбрано фильтров: </span>
              <span className={styles.filterTotalCount}>{filter.length}</span>
            </Grid>
            <div className={styles.filtes}>
              {trackedFields?.map((field: Field, index: number) => (
                <Fragment key={`${index}_${field.name}`}>
                  {field.props.customLabel && field.props.customLabel}
                  <Grid item className={cn({ [styles.stretchable]: field?.stretchable })}>
                    <FormField type={field.type} name={field.name} {...field.props} fullWidth={field?.stretchable} />
                  </Grid>
                </Fragment>
              ))}
            </div>
          </Paper>
        )}
      </Grid>
    </FormProvider>
  );
}
