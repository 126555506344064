import colors from '@styles/colors.module.scss';
import { PaymentCandidatesResponse } from '@api/admin/adminGeneratedApi';
import { Column } from '@components/Table/types';
import { StudentFIO } from './StudentFIO';
import { Status } from './Status';

export const getColumns = (): Column<PaymentCandidatesResponse>[] => [
  {
    title: 'Фамилия И.О.',
    dataIndex: 'human',
    sx: { color: colors.red },
    width: 200,
    render: (human: PaymentCandidatesResponse['human']) => <StudentFIO {...human} />,
  },
  {
    title: 'ВУЗ',
    dataIndex: 'human',
    key: 'university',
    sx: { whiteSpace: 'wrap' },
    render: (human: PaymentCandidatesResponse['human']) => human.student?.educationalOrganization.nameShort ?? '',
  },
  {
    title: 'Результат обработки',
    dataIndex: 'id',
    render: (_: PaymentCandidatesResponse['id'], candidate: PaymentCandidatesResponse) => <Status {...candidate} />,
  },
  // {
  //   title: '',
  //   dataIndex: 'result',
  //   key: 'actions',
  //   align: 'right',
  //   render: (result: PaymentMembersResultStatuses) => (
  //     <Box>
  //       {result === 'handledWithError' && (
  //         <Tooltip title={'Обновить данные'}>
  //           <IconButton className={styles.iconButton}>
  //             <Icon className={styles.icon} name={'refresh'} />
  //           </IconButton>
  //         </Tooltip>
  //       )}
  //       {(result === 'handledWithError' || result === 'deleted') && (
  //         <Tooltip title={'Скачать лог'}>
  //           <IconButton className={styles.iconButton}>
  //             <Icon className={styles.icon} name={'download'} />
  //           </IconButton>
  //         </Tooltip>
  //       )}
  //       {result === 'handledWithError' && (
  //         <Tooltip title={'Удалить'}>
  //           <IconButton className={styles.iconButton}>
  //             <Icon className={styles.icon} name={'delete'} />
  //           </IconButton>
  //         </Tooltip>
  //       )}
  //     </Box>
  //   ),
  // },
];
