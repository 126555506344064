import { FC } from 'react';
import { Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { MetaRequest } from '@api/admin/adminGeneratedApi';
import styles from './styles.module.scss';
import { metaTagFields } from './defaultValues';
import { getMetaTagsFromValues } from './getMetaTagsFromValues';
import { MetaTagTitles } from './types';

interface Props {
  tags?: MetaRequest[];
}

export const MetaTags: FC<Props> = ({ tags = [] }) => {
  const metaTags = tags.length === 0 ? getMetaTagsFromValues(tags) : tags;

  return (
    <Grid container flexDirection="column" gap={indents.m} p="20px" className={styles.root}>
      {metaTags.map((tag, index) => {
        return (
          <Grid item key={tag.name}>
            <FormField
              // Формат metaTags[${index}] нужен для формирования данных форм в виде MetaRequest[]
              name={`metaTags[${index}].content`}
              {...metaTagFields[tag.name as MetaTagTitles]}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
