import { Grid } from '@mui/material';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { TicketData } from '@area/tickets/components/TicketData';
import indents from '@styles/indents.module.scss';

export const TicketPage = () => {
  usePageTitle('Данные обращения');

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item container flexDirection="column" gap={indents.xxs}>
        <Header
          title="Данные обращения"
          backBtnTitle="Назад к списку обращений"
          backBtnRoute={buildRoutePath([Routes.tickets], {
            absolute: true,
          })}
        />
      </Grid>
      <Grid item>
        <TicketData />
      </Grid>
    </Grid>
  );
};
