import { usePreviewUrl } from '@hooks/usePreviewUrl';
import { useMemo, useState } from 'react';
import { Grid, Button, Box, Typography, Stack, LinearProgress } from '@mui/material';
import { FormProvider, Path, useForm } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { Redactor } from '@area/redactor/components/Redactor';
import { Preview } from '@area/redactor/components/Preview/components';
import { Photo } from '@area/redactor/components/Photo';
import { Video } from '@area/redactor/components/Video';
import { Snippet } from '@area/redactor/components/Snippet';
import { MetaTags } from '@area/redactor/components/MetaTags';
import { ModalCheckMistakes } from '@area/redactor/components/ModalCheckMistakes';
import { useCheckMistakes } from '@area/redactor/hooks/useCheckMistakes';
import { SlugField } from '@area/redactor/components/SlugField';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Embedded } from '@area/redactor/components/Embedded';
import { TypographButton } from '@area/redactor/components/TypographButton';
import { useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import { MediaFileParams } from '@area/publications/defaultValues';
import { sharedKampusEventValidation } from '../validation/sharedValidation';
import { kampusEventValidation } from '../validation/validation';
import { kampusEventTypes, sharedKampusEventFieldNames } from '../defaultValues';
import styles from './style.module.scss';
import { BaseFieldValues, FormProps } from './types';

export const EditForm = <TValues extends BaseFieldValues>({
  defaultValues,
  onSubmit,
  isLoading,
}: FormProps<TValues>) => {
  const resolver = useYupValidationResolver<TValues>(kampusEventValidation);
  const form = useForm<TValues>({
    defaultValues,
    resolver,
  });

  const formData = form.watch();

  const [deleteFile, { isLoading: imageDeletingLoading }] = useFilesControllerDeleteFileMutation();

  const [imgUrl, setImgUrl] = useState(form.getValues()?.mainImage?.url);
  const [key, setKey] = useState(0);

  const {
    fieldsToCheck,
    modalShow,
    form: modalForm,
    setInitialValues,
  } = useCheckMistakes(sharedKampusEventFieldNames, 'kampusEvent', sharedKampusEventValidation, formData, imgUrl);

  const handleSubmitInfo = (values: any) => {
    const result = sharedKampusEventFieldNames.reduce((acc, name) => {
      acc[name] = values[name];
      return acc;
    }, {} as typeof values);

    onSubmit({
      ...form.getValues(),
      ...result,
    });
    setInitialValues(null);
  };

  const handleCheckAndSubmit = form.handleSubmit((data) => {
    if (data.mainImage.file) {
      const url = URL.createObjectURL(data.mainImage.file);
      setImgUrl(url);
    }

    setInitialValues({
      title: data.title,
      photo: '',
    });
  });

  const bottomComponent = useMemo(() => {
    const valuesToCheck = {
      title: formData.title,
      locationName: formData.locationName,
      locationCoordinates: formData.locationCoordinates,
      editorContent: formData.editorContent,
    };
    const callBackAfterCheck = (values: Record<string, any>) => {
      form.reset({
        ...formData,
        ...values,
      });

      setKey(key + 1);
    };

    return <TypographButton valuesToCheck={valuesToCheck} callBackAfterCheck={callBackAfterCheck} />;
  }, [form, formData]);

  const tabs = useMemo(
    () => [
      {
        label: 'Текст публикации',
        content: <FormField name="editorContent" type="wysiwyg" bottomComponent={bottomComponent} key={key} />,
      },
      {
        label: 'Embedded',
        content: <Embedded fieldName="embedded" />,
      },
      {
        label: 'Фото репортаж',
        content: <Photo name="photoGallery" photoGallery={formData.photoGallery} />,
      },
      {
        label: 'Видео',
        content: <Video />,
      },
      {
        label: 'Сниппет',
        content: <Snippet<TValues> form={form} snippetPhoto={formData.snippetPhoto as MediaFileParams} />,
      },
      {
        label: 'Метатеги',
        content: <MetaTags tags={defaultValues.metaTags} />,
      },
    ],
    [bottomComponent, defaultValues.metaTags, formData.photoGallery, formData.snippetPhoto],
  );

  const handleDeleteFile = async () => {
    const id = formData?.mainImage?.id;
    if (id) {
      await deleteFile({ fileId: id });
    }
    form.setValue('mainImage' as Path<TValues>, undefined as any);
  };

  const url = usePreviewUrl('events', formData.url);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <Preview url={url} disabled={!formData.id} />
        <FormProvider {...form}>
          <Grid item>
            <FormField name="published" type="switch" label={formData.published ? 'Вкл' : 'Выкл'} title="Показать" />
          </Grid>
          <Grid item>
            <FormField name="important" type="switch" label={formData.important ? 'Вкл' : 'Выкл'} title="Важное" />
          </Grid>
          <Grid item>
            <Grid container flexDirection="column" gap={indents.xs}>
              <Grid item>
                <Typography variant="h2">Даты проведения</Typography>
              </Grid>
              <Grid item>
                <Grid container gap={indents.xs} alignItems="center">
                  <Grid item className={styles.datePicker}>
                    <FormField
                      name="dateStart"
                      type="dateTimePicker"
                      fullWidth
                      textFieldProps={{ placeholder: 'Начало' }}
                      maxDate={formData.dateEnd}
                    />
                  </Grid>
                  <Grid item>
                    <Box className={styles.datesDelimiter}></Box>
                  </Grid>
                  <Grid item className={styles.datePicker}>
                    <FormField
                      name="dateEnd"
                      type="dateTimePicker"
                      fullWidth
                      textFieldProps={{ placeholder: 'Конец' }}
                      minDate={formData.dateStart}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: indents.l }}>
            <Grid item xs={12} lg={9}>
              <FormField name="locationName" title="Место проведения" fullWidth />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormField name="locationCoordinates" title="Геоданные места" fullWidth />
            </Grid>
          </Grid>
          <Grid item>
            <FormField name="title" title="Название" fullWidth />
          </Grid>
          <Grid item>
            <SlugField fieldNameToSlugify="title" disabled={!!defaultValues.id} />
          </Grid>
          <Grid item className={styles.typeSelect}>
            <FormField
              name="type"
              type="select"
              placeholder="Выберите тип"
              icon={<Icon name="type" />}
              title="Тип"
              options={kampusEventTypes}
            />
          </Grid>
          <Grid item>
            <Grid container flexDirection="column" gap={indents.xs}>
              <Grid item>
                <Typography variant="h2">Иллюстрация</Typography>
              </Grid>
              <Grid item>
                <Grid container gap="20px">
                  <Grid item sx={{ width: 304 }}>
                    <FormField
                      onDelete={handleDeleteFile}
                      imageUrl={imgUrl}
                      name="mainImage.file"
                      type="image"
                      loadingText="Удаление фото"
                      loading={imageDeletingLoading}
                    />
                  </Grid>
                  <Grid item className={styles.imageInputsWrapper}>
                    <Stack gap="20px">
                      <FormField name="mainImage.alt" placeholder="Подпись" fullWidth />
                      <FormField name="mainImage.metadata" placeholder="Фотограф" fullWidth />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Redactor tabs={tabs} />
          </Grid>
          {isLoading && <LinearProgress className={styles.loadingIndicator} />}
        </FormProvider>
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          type="button"
          variant="contained"
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleCheckAndSubmit}>
          Сохранить
        </Button>
      </Box>
      <ModalCheckMistakes
        fieldsToCheck={fieldsToCheck}
        form={modalForm}
        open={modalShow}
        onClose={() => setInitialValues(null)}
        onSubmit={handleSubmitInfo}
      />
    </>
  );
};
