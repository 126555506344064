import { Icon } from '@components/Icon';
import { IconButton } from '@mui/material';
import { FC, MouseEvent, useRef } from 'react';
import { useSlate } from 'slate-react';
import { useFilesControllerUploadFileMutation } from '@api/admin/adminGeneratedApi';
import { FileType } from '@hooks/useUploadMediaFiles';
import { Location } from 'slate';
import { insertQuoteWithPhoto } from '../../../../utils/quote-with-photo';
import { AddQuoteWithPhotoModal, AddQuoteWithPhotoRef } from './AddQuoteWithPhotoModal';
import { QuoteWithPhotoFormData } from './QuoteWithPhotoForm';
interface QuoteWithPhotoProps {
  isTable?: boolean;
}

export const QuoteWithPhoto: FC<QuoteWithPhotoProps> = ({ isTable = false }) => {
  const modalRef = useRef<AddQuoteWithPhotoRef>(null);
  const editor = useSlate();
  const [uploadFile] = useFilesControllerUploadFileMutation();

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onClickAdd = () => {
    modalRef.current?.open();
  };

  const handleSubmit = async (formData: QuoteWithPhotoFormData) => {
    if (formData.image) {
      const fd = new FormData();
      const { selection } = editor;
      fd.set('file', formData.image);
      fd.set('type', FileType.editorPhoto.toString());
      const res = await uploadFile({ subjectId: '-1', mediaFileUpdaterDto: fd as any }).unwrap();

      insertQuoteWithPhoto(editor, { ...formData, imageFileUrl: res.url, imageFileId: res.id }, selection as Location);
      return;
    }

    insertQuoteWithPhoto(editor, formData);
  };

  return (
    <>
      <IconButton
        centerRipple={false}
        onMouseDown={onMouseDown}
        onClick={onClickAdd}
        title="Вставить авторскую цитату"
        disabled={isTable}>
        <Icon name="wysiwygQuoteWithPhoto" viewBox="0 0 16 16" />
      </IconButton>
      <AddQuoteWithPhotoModal modalRef={modalRef} onSubmit={handleSubmit} />
    </>
  );
};
