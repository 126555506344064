import { Option } from '@components/Select/types';

export enum UserRole {
  None = 0,
  SuperUser = 1 << 0,
  Admin = 1 << 1,
  ContentMaker = 1 << 2,
  Operator = 1 << 3,
}

export const userRoleDictionary: Record<UserRole, string> = {
  [UserRole.None]: 'Пользователь',
  [UserRole.SuperUser]: 'Суперпользователь',
  [UserRole.Admin]: 'Администратор',
  [UserRole.ContentMaker]: 'Редактор',
  [UserRole.Operator]: 'Оператор системы',
};

export const getUserRoleOptions = (userRoles: UserRole[]): Option[] => {
  return Object.entries(userRoleDictionary)
    .filter(([value]) => {
      if (userRoles.includes(UserRole.SuperUser)) {
        return Number(value) !== UserRole.SuperUser;
      }

      return Number(value) !== UserRole.SuperUser && Number(value) !== UserRole.Admin;
    })
    .map(([value, label]) => ({
      value: Number(value) as any,
      label,
    }));
};
