import colors from '@styles/colors.module.scss';
import { PaymentStatusColors } from '@area/payments/defaultValues';
import { Tag } from '@components/Tag';
import { PaymentShortResponse } from '@api/admin/adminGeneratedApi';
import { PaymentStatus, PaymentStatusName } from '@area/payments/types';

export interface PaymentStatusTagProps {
  status: PaymentShortResponse['status'];
}

export const PaymentStatusTag = ({ status }: PaymentStatusTagProps) => (
  <Tag
    label={PaymentStatusName[status]}
    sx={{
      background: PaymentStatusColors[status],
      color: status === PaymentStatus.processing || status === PaymentStatus.paused ? colors.black : colors.white,
      p: '2px',
      width: '124px',
    }}
  />
);
