import { useTicketControllerAnswerTicketMutation, TicketAnswerRequest } from '@api/admin/adminGeneratedApi';

export const useAnswerTicket = () => {
  const [answerTicket, { isLoading, isSuccess }] = useTicketControllerAnswerTicketMutation();

  const handleAnswer = async ({
    id,
    ticketAnswerRequest,
  }: {
    id: string;
    ticketAnswerRequest: TicketAnswerRequest;
  }) => {
    await answerTicket({ id, ticketAnswerRequest });
  };

  return {
    answerTicket: handleAnswer,
    isLoading,
    isSuccess,
  };
};
