import { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePaymentControllerFilterCandidatesMutation } from '@api/admin/adminApi';
import { useQueryParams } from '@hooks/useQueryParams';
import { PaymentCandidatesResponse, PaymentsCandidatesFiltersRequest } from '@api/admin/adminGeneratedApi';
import { PaymentCandidateProcessStatus } from '@area/payments/types';
import { DEFAULT_PAYMENT_CANDIDATES_SIZE } from '../defaultValues';

const getValuesFromParams = (params: { [key: string]: string }): PaymentsCandidatesFiltersRequest => {
  const educationalOrganizations = params.educationalOrganizations
    ? decodeURIComponent(params.educationalOrganizations).split(',')
    : [];
  const processResults: PaymentCandidateProcessStatus[] = params.processResults
    ? decodeURIComponent(params.processResults).split(',').map(Number)
    : [];

  return {
    search: params.search,
    educationalOrganizations,
    processResults,
  };
};

export const useCandidates = (id: string) => {
  const isRefetch = useRef<boolean>(true);
  const localCandidates = useRef<PaymentCandidatesResponse[]>([]);
  const { params } = useQueryParams();
  const [fetch, { data, isLoading, isSuccess, isError, originalArgs }] = usePaymentControllerFilterCandidatesMutation();

  const candidates = useMemo(() => {
    if (!data?.data) {
      return localCandidates.current;
    }

    if (isRefetch.current) {
      isRefetch.current = false;
      localCandidates.current = [];
    }

    localCandidates.current = [...localCandidates.current, ...data?.data];

    return localCandidates.current;
  }, [data?.data]);
  const fetchCandidates = useCallback(
    (size = DEFAULT_PAYMENT_CANDIDATES_SIZE) => {
      fetch({
        id,
        skip: isRefetch.current ? 0 : localCandidates.current.length,
        size,
        paymentsCandidatesFiltersRequest: getValuesFromParams(params),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, id, params.search, params.educationalOrganizations, params.processResults],
  );
  const refetchPayments = useCallback(() => {
    isRefetch.current = true;
    fetchCandidates(originalArgs?.skip! + originalArgs?.size!);
  }, [fetchCandidates, originalArgs]);

  useEffect(() => {
    isRefetch.current = true;
    fetchCandidates();
  }, [fetchCandidates]);
  useEffect(() => {
    // const id = setInterval(() => refetchPayments(), 10000);
    // return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    candidates,
    isLoading: !candidates.length && isLoading,
    isFetched: !isRefetch.current && (isError || isSuccess),
    hasMore: (data?.paginate?.totalCount ?? 0) > localCandidates.current.length,
    fetchCandidates,
    refetchPayments,
  };
};
