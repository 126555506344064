import { useMemo, ComponentType } from 'react';
import { Dayjs } from 'dayjs';
import { styled } from '@mui/material/styles';
import { PickersDay, PickersDayProps as MuiPickersDayProps } from '@mui/x-date-pickers/PickersDay';
import colors from '@styles/colors.module.scss';

interface PickerDayProps extends MuiPickersDayProps<Dayjs> {
  dayIsBetween: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween',
})<PickerDayProps>(({ dayIsBetween }) => ({
  ...(dayIsBetween && {
    backgroundColor: colors.red,
    color: colors.white,
    transition: '.2s',

    '&.MuiPickersDay-today, &:hover, &:focus': {
      color: colors.black,
    },
  }),
})) as ComponentType<PickerDayProps>;

export function Day(props: MuiPickersDayProps<Dayjs> & { startDate?: Dayjs; endDate?: Dayjs }) {
  const { day, startDate, endDate, ...rest } = props;

  const start = startDate;
  const end = endDate;
  const dayIsBetween = useMemo(() => {
    if (!start || !end) {
      return false;
    }

    return day.isBetween(start, end, null, '[]');
  }, [start, end, day]);

  return <CustomPickersDay {...rest} day={day} disableMargin dayIsBetween={dayIsBetween} />;
}
