import {
  TicketControllerFilterApiArg,
  TicketFilterRequest,
  useTicketControllerFilterMutation,
} from '@api/admin/adminGeneratedApi';
import dayjs from 'dayjs';
import { DEFAULT_TICKETS_PAGE_SIZE } from '../defaultValues';

export const useTickets = () => {
  const query: TicketControllerFilterApiArg = {
    skip: 0,
    size: DEFAULT_TICKETS_PAGE_SIZE,
    ticketFilterRequest: {},
  };
  const [getTickets, res] = useTicketControllerFilterMutation();

  const handleRequest = (
    args?: Omit<TicketControllerFilterApiArg, 'ticketFilterRequest'> & {
      ticketFilterRequest: Partial<TicketFilterRequest>;
    },
  ) => {
    const request: TicketControllerFilterApiArg = {
      ...query,
      ...args,
      ticketFilterRequest: {
        ...query.ticketFilterRequest,
        ...args?.ticketFilterRequest,
      },
    };

    getTickets(request);
  };

  const getValuesFromParams = (params: { [key: string]: string }) => {
    const [d, m, y] = (params.createdAt || '').split('-');
    const createdAt = params.createdAt ? dayjs(`${y}-${m}-${d}`).format('YYYY-MM-DD') : undefined;
    const userId = params.userId ? JSON.parse(decodeURIComponent(params.userId)).userId : undefined;

    return { ...params, createdAt, userId };
  };

  return {
    getTickets: handleRequest,
    ticketsList: res.data?.data,
    paginate: res.data?.paginate,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
    getValuesFromParams,
  };
};
