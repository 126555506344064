import { CSSProperties } from 'react';

export const convertCSSPropertiesToString = (style: CSSProperties): string => {
  const camelToKebabCase = (str: string): string => {
    return str.replace(/[A-Z]/g, (v) => `-${v.toLowerCase()}`);
  };

  return Object.entries(style).reduce<string>((acc, [key, value]) => {
    const cssKey = camelToKebabCase(key);
    const cssVal = typeof value === 'number' && !isNaN(value) ? `${value}px` : value;

    if (!value) return acc;

    return `${acc}${cssKey}:${cssVal.replace("'", '')};`;
  }, '');
};
