import { CSSProperties } from 'react';
import { Option } from '@components/Select/types';
import {
  CustomText,
  ElementType,
  Header,
  List,
  ListButton,
  SubSupButton,
  TableCellType,
  TextAlign,
  TextAligningButton,
  TextFormattingButton,
} from './types';

export const TAB_UNICODE = '\u0009'; // 4 пробела. '\u2003' - для двух пробелов

export const INDENT_MAX_LEVEL = Infinity;

export const INDENT_LEVEL_SIZE = 40;

export const textAlignTypes: Readonly<TextAlign[]> = ['left', 'center', 'justify', 'right'] as const;

export const listTypes: Readonly<List[]> = ['ordered-list', 'unordered-list'] as const;

export const headerTypes: Readonly<Header[]> = ['heading-1', 'heading-2', 'heading-3'] as const;

export const hotkeys: Record<string, keyof Pick<CustomText, 'bold' | 'italic' | 'underline' | 'code'>> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
} as const;

export const hotkeysList: ReadonlyArray<keyof typeof hotkeys> = Object.keys(hotkeys);

export const imageExtensions = new Set([
  '3dv',
  'ai',
  'amf',
  'art',
  'ase',
  'awg',
  'blp',
  'bmp',
  'bw',
  'cd5',
  'cdr',
  'cgm',
  'cit',
  'cmx',
  'cpt',
  'cr2',
  'cur',
  'cut',
  'dds',
  'dib',
  'djvu',
  'dxf',
  'e2d',
  'ecw',
  'egt',
  'emf',
  'eps',
  'exif',
  'fs',
  'gbr',
  'gif',
  'gpl',
  'grf',
  'hdp',
  'heic',
  'heif',
  'icns',
  'ico',
  'iff',
  'int',
  'inta',
  'jfif',
  'jng',
  'jp2',
  'jpeg',
  'jpg',
  'jps',
  'jxr',
  'lbm',
  'liff',
  'max',
  'miff',
  'mng',
  'msp',
  'nef',
  'nitf',
  'nrrd',
  'odg',
  'ota',
  'pam',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pct',
  'pcx',
  'pdd',
  'pdn',
  'pgf',
  'pgm',
  'PI1',
  'PI2',
  'PI3',
  'pict',
  'png',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'ras',
  'raw',
  'rgb',
  'rgba',
  'rle',
  'sct',
  'sgi',
  'sid',
  'stl',
  'sun',
  'svg',
  'sxd',
  'tga',
  'tif',
  'tiff',
  'v2d',
  'vnd',
  'vrml',
  'vtf',
  'wdp',
  'webp',
  'wmf',
  'x3d',
  'xar',
  'xbm',
  'xcf',
  'xpm',
]);

export const textFormattingButtons: TextFormattingButton[] = [
  {
    value: 'bold',
    icon: 'wysiwygBold',
    title: 'Жирный',
  },
  {
    value: 'italic',
    icon: 'wysiwygItalic',
    title: 'Курсив',
  },
  //   {
  //     value: 'underline',
  //     icon: 'wysiwygUnderline',
  //     title: 'Подчеркнутый',
  //   },
  //   {
  //     value: 'strikethrough',
  //     icon: 'wysiwygStrikethrough',
  //     title: 'Зачеркнутый',
  //   },
];

export const subSupButtons: SubSupButton[] = [
  {
    value: 'sub',
    icon: 'wysiwygSubScript',
    title: 'Нижний индекс',
  },
  {
    value: 'sup',
    icon: 'wysiwygSupScript',
    title: 'Верхний индекс',
  },
];

export const textAligningButtons: TextAligningButton[] = [
  {
    value: 'left',
    icon: 'wysiwygAlignLeft',
    title: 'Выравнивание по левому краю',
  },
  {
    value: 'center',
    icon: 'wysiwygAlignCenter',
    title: 'Выравнивание по центру',
  },
  {
    value: 'justify',
    icon: 'wysiwygAlignJustify',
    title: 'Выравнивание по ширине',
  },
  {
    value: 'right',
    icon: 'wysiwygAlignRight',
    title: 'Выравнивание по правому краю',
  },
];

export const listButtons: ListButton[] = [
  {
    value: 'ordered-list',
    icon: 'wysiwygListOrdered',
    title: 'Нумерованный список',
  },
  {
    value: 'unordered-list',
    icon: 'wysiwygListUnordered',
    title: 'Маркированный список',
  },
];

export const fontSizes = ['12px', '14px', '16px', '18px', '24px'];

export const fontNames = ['Inter', 'Arial', 'Calibri', 'Times New Roman', 'Consolas'];

export const typography: Option<
  Exclude<ElementType, List | TableCellType | 'link' | 'table' | 'table-row' | 'list-item'>
>[] = [
  { value: 'heading-1', label: 'Заголовок 1' },
  { value: 'heading-2', label: 'Заголовок 2' },
  { value: 'heading-3', label: 'Заголовок 3' },
  { value: 'paragraph', label: 'Основной' },
  { value: 'caption', label: 'Подпись' },
];

export const defaultElementStyles: Partial<Record<ElementType, CSSProperties>> = {
  'heading-1': {
    fontWeight: 'normal',
    fontSize: 72,
    lineHeight: '76px',
  },
  'heading-2': {
    fontWeight: 'normal',
    fontSize: 64,
    lineHeight: '64px',
  },
  'heading-3': {
    fontWeight: 'normal',
    fontSize: 54,
    lineHeight: '54px',
  },
  paragraph: {
    fontSize: 20,
    lineHeight: '150%',
  },
  caption: {
    fontSize: 16,
    lineHeight: '131%',
  },
  'list-item': {
    marginBottom: '30px',
  },
  'ordered-list': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '148%',
    letterSpacing: '0.2px',
    paddingLeft: '30px',
  },
  'unordered-list': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '148%',
    letterSpacing: '0.2px',
    paddingLeft: '30px',
    listStyle:
      'url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjY4OTYyMyAxMi44NDU2QzAuMjU4NjA4IDEyLjQwOTQgMCAxMS44ODU5IDAgMTEuMjc1MkMwIDEwLjY2NDQgMC4yNTg2MDggMTAuMTQwOSAwLjY4OTYyMyA5LjcwNDdMMy4zNjE5MSA3TDAuNjg5NjIzIDQuMjk1M0MwLjI1ODYwOCAzLjg1OTA2IDAgMy4zMzU1NyAwIDIuNzI0ODNDMCAyLjExNDA5IDAuMjU4NjA4IDEuNTkwNiAwLjY4OTYyMyAxLjE1NDM2QzEuNTUxNjUgMC4yODE4NzkgMy4wMTcxIDAuMjgxODc5IDMuODc5MTMgMS4xNTQzNkw0LjU0ODU3IDEuODMxOTJDNC42NjYxNyAxLjU4NjU1IDQuODMxNDYgMS4zNjA3IDUuMDM1MzMgMS4xNTQzNkM1Ljg5NzM1IDAuMjgxODc5IDcuMzYyOCAwLjI4MTg3OSA4LjIyNDgzIDEuMTU0MzZMMTQuMDAwNCA3TDguMjI0ODMgMTIuODQ1NkM3LjM2MjggMTMuNzE4MSA1Ljg5NzM1IDEzLjcxODEgNS4wMzUzMyAxMi44NDU2QzQuODMxNDYgMTIuNjM5MyA0LjY2NjE3IDEyLjQxMzUgNC41NDg1NyAxMi4xNjgxTDMuODc5MTMgMTIuODQ1NkMzLjAxNzEgMTMuNzE4MSAxLjU1MTY1IDEzLjcxODEgMC42ODk2MjMgMTIuODQ1NloiIGZpbGw9IiM4MjY5RUYiLz48L3N2Zz4K)',
  },
};
