import { Box, Drawer as MuiDrawer, IconButton, List, Typography, Button, Stack } from '@mui/material';
import cn from 'classnames';
import { FC, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { clearStore } from '@store/actions';
import { LocalStorageKeys } from '@area/localstorage/constants';
import { Routes } from '@navigation/routes';
import { UserShortCard } from '@area/user/components/UserShortCard';
import { Icon } from '@components/Icon';
import { Logo } from '@components/Logo';
import { Modal } from '@components/Modal';
import colors from '@styles/colors.module.scss';
import indents from '@styles/indents.module.scss';
import { removeLocalStorageObject } from '@area/localstorage/localStorage';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';
import { Chapter } from './Chapter';

import styles from './styles.module.scss';
import { Chapter as ChapterProps } from './types';

interface SideBarProps {
  chapters: ChapterProps[];
  open: boolean;
  toggleDrawer: () => void;
}

export const Sidebar: FC<SideBarProps> = ({ chapters, open, toggleDrawer }) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const toggleModal = () => setOpenModal(!openModal);

  const onLogout = () => {
    navigate(Routes.login);
    removeLocalStorageObject(LocalStorageKeys.AuthToken);
    clearStore();
  };

  return (
    <>
      <MuiDrawer variant="permanent" open={open} className={cn(styles.drawer, { open })}>
        <Box className={styles.drawerHeader}>
          <Link to={Routes.main} className={cn(styles.logoWrapper, { open })}>
            <Logo htmlColor={colors.white} />
          </Link>
        </Box>

        <List className={styles.chapters} component="nav" disablePadding>
          {chapters.map((chapter) => (
            <Chapter key={chapter.title} {...chapter} openSidebar={open} toggleDrawer={toggleDrawer} />
          ))}
        </List>

        <Box className={cn(styles.user, { open })}>
          <UserShortCard
            user={currentUser}
            afterComponent={
              <IconButton onClick={toggleModal} className={styles.logoutBtn}>
                <Icon name="signout" htmlColor={colors.almostWhite} />
              </IconButton>
            }
            openSidebar={open}
          />
        </Box>

        <IconButton className={styles.toggleBtn} onClick={toggleDrawer}>
          <Icon
            name={open ? 'chevronLeft' : 'chevronRight'}
            htmlColor={colors.white}
            className={styles.iconToggleBtn}
          />
        </IconButton>
      </MuiDrawer>

      <Modal
        open={openModal}
        title="Выход"
        onClose={toggleModal}
        fullWidth={false}
        sx={{ paddingLeft: 0 }}
        actions={
          <Stack direction="row" gap={indents.s}>
            <Button variant="contained" onClick={onLogout}>
              Да, выйти
            </Button>
            <Button variant="outlined" onClick={toggleModal}>
              Нет, остаться
            </Button>
          </Stack>
        }>
        <Typography variant="body2">Вы действительно хотите выйти из системы?</Typography>
      </Modal>
    </>
  );
};
