import { useEffect } from 'react';
import { useOutlet } from 'react-router';

export const usePageTitle = (title = '') => {
  const outlet = useOutlet();

  useEffect(() => {
    if (!outlet) {
      document.title = `Кампус - панель управления | ${title}`;
    }
  }, [title, outlet]);
};
