import { useNavigate } from 'react-router';
import { Button, Grid, Paper } from '@mui/material';
import { UsersFilter } from '@area/users/components/UsersFilter';
import { Table } from '@components/Table/Table';
import { UserResponse } from '@api/admin/adminGeneratedApi';
import { Icon } from '@components/Icon';
import { useFilterUsers } from '@area/users/hooks/useFilterUsers';
import { useMemo } from 'react';
import { getColumns } from './columns';
import styles from './styles.module.scss';

export const UsersTable = () => {
  const navigate = useNavigate();
  const { users, isLoading, isFetched, hasMore, fetchUsers, refetchUsers } = useFilterUsers();
  const columns = useMemo(() => getColumns(refetchUsers), [refetchUsers]);
  const onRowClick = ({ id }: UserResponse) => navigate(id);

  return (
    <>
      <Grid item>
        <UsersFilter />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Paper className={styles.paper}>
          <Table<UserResponse>
            columns={columns}
            data={users}
            fetched={isFetched}
            loading={isLoading}
            onRowClick={onRowClick}
          />
          {hasMore && (
            <Button
              variant="outlined"
              className={styles.loadMoreBtn}
              disabled={isLoading}
              startIcon={<Icon name="more" />}
              onClick={() => fetchUsers()}>
              Загрузить еще
            </Button>
          )}
        </Paper>
      </Grid>
    </>
  );
};
