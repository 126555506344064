import {
  adminGeneratedApi,
  PaymentControllerFilterPaymentsApiArg,
  PaymentControllerFilterPaymentsApiResponse
} from './adminGeneratedApi';
import { ApiTags } from './tags';

export const overridedGeneratedApi = adminGeneratedApi.injectEndpoints({
  endpoints: build => ({
    getAllPaymentsByType: build.query<
      PaymentControllerFilterPaymentsApiResponse,
      PaymentControllerFilterPaymentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.paymentType}/filter`,
        method: "POST",
        body: queryArg.paymentsFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
      keepUnusedDataFor: 60000
    }),
  }),
  overrideExisting: true
})

export const adminApi = overridedGeneratedApi.enhanceEndpoints({
  endpoints: {
    usersControllerGetUser: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    usersControllerUpdateUser: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    usersControllerUpdateUserRole: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    usersControllerUpdateUserActiveStatus: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    participantsHumansControllerGetHuman: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetParticipant, id: arg.id }],
    },
    participantsHumansControllerUpdateHuman: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetParticipant, id: arg.id }],
    },
    participantsHumansControllerUndeleteHuman: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetParticipant, id: arg.id }],
    },
    usersControllerGetCurrentUser: {
      providesTags: [ApiTags.GetUserProfile],
    },
    usersControllerChangeCurrentUserPassword: {
      invalidatesTags: [ApiTags.GetUserProfile]
    },
    authorsControllerGetAuthors: {
      providesTags: [ApiTags.GetAuthors],
    },
    newsTagsControllerGetNewsTags: {
      providesTags: [ApiTags.GetPublicationTags],
    },
    eventsControllerGetEvent: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetEvent, id: arg.id }]
    },
    infoPagesControllerGetInfoPage: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetInfoPage, id: arg.id }]
    },
    newsControllerGetNews: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetNews, id: arg.id }]
    },
    authControllerRefresh: {
      invalidatesTags: [ApiTags.GetUserProfile],
    },
    authControllerLogin: {
      invalidatesTags: [ApiTags.GetUserProfile],
    },
    authorsControllerCreateAuthor: {
      invalidatesTags: [ApiTags.GetAuthors],
    },
    newsTagsControllerCreateNewsTag: {
      invalidatesTags: [ApiTags.GetPublicationTags],
    },
    newsTagsControllerDeleteNewsTag: {
      invalidatesTags: [ApiTags.GetPublicationTags],
    },
    newsControllerUpdateNews: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetNews, id: arg.id }];
      },
    },
    newsControllerDeleteNews: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetNews, id: arg.id }];
      },
    },
    eventsControllerUpdateEvent: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetEvent, id: arg.id }];
      },
    },
    eventsControllerDeleteEvent: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetEvent, id: arg.id }];
      },
    },
    infoPagesControllerUpdateInfoPage: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetInfoPage, id: arg.id }];
      },
    },
    infoPagesControllerDeleteInfoPage: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetInfoPage, id: arg.id }];
      },
    },
    filesControllerUploadFile: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: ApiTags.GetNews, id: arg.subjectId },
          { type: ApiTags.GetInfoPage, id: arg.subjectId },
          { type: ApiTags.GetEvent, id: arg.subjectId },
        ]
      }
    },
    getAllPaymentsByType: {
      providesTags: [ApiTags.GetPayments]
    },
    partnerControllerGetPartner: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetPartner, id: arg.id }],
    },
    partnerControllerUpdatePartner: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetPartner, id: arg.id }];
      },
    },
    notificationsControllerFilter: {
      providesTags: [ApiTags.GetNotifications] as any,
    },
    notificationsControllerUpdate: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    notificationsControllerDelete: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    notificationsControllerSendEmail: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    notificationsControllerCreate: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    participantsHumansControllerGetHumanTicketsAndMailing: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetHumanTicketsAndMailing, id: arg.id }]
    },
    footerLinksControllerGet: {
      providesTags: [ApiTags.GetFooterLinks],
    },
    footerLinksControllerCreate: {
      invalidatesTags: [ApiTags.GetFooterLinks],
    },
    footerLinksControllerUpdate: {
      invalidatesTags: [ApiTags.GetFooterLinks],
    },
    footerLinksControllerDelete: {
      invalidatesTags: [ApiTags.GetFooterLinks],
    },
  },
});

export const {
  useAuthControllerLoginMutation,
  useAuthControllerLogin2FaMutation,
  useAuthControllerRefreshMutation,
  useMetricsControllerIndexQuery,
  useUsersControllerFilterUsersMutation,
  useUsersControllerGetUserQuery,
  useUsersControllerCreateUserMutation,
  useUsersControllerUpdateUserMutation,
  useUsersControllerUpdateUserRoleMutation,
  useUsersControllerUpdateUserActiveStatusMutation,
  useUsersControllerDeleteUserMutation,
  useUsersControllerGetCurrentUserQuery,
  useUsersControllerUpdateCurrentUserMutation,
  useUsersControllerChangeCurrentUserPasswordMutation,
  useUsersControllerResetPasswordMutation,
  useResourcesControllerLanguagesQuery,
  useResourcesControllerCreateOrUpdateLanguageMutation,
  useResourcesControllerGetTranslationsQuery,
  useResourcesControllerCreateOrUpdateTranslationMutation,
  useResourcesControllerUserRolesQuery,
  useSettingsControllerGetQuery,
  useSettingsControllerUpdateMutation,
  useNewsTagsControllerGetNewsTagsQuery,
  useNewsTagsControllerCreateNewsTagMutation,
  useNewsTagsControllerUpdateNewsTagMutation,
  useNewsTagsControllerDeleteNewsTagMutation,
  useAuthorsControllerGetAuthorsQuery,
  useAuthorsControllerCreateAuthorMutation,
  useAuthorsControllerUpdateAuthorMutation,
  useAuthorsControllerDeleteAuthorMutation,
  useNewsControllerFilterNewsMutation,
  useNewsControllerCreateNewsMutation,
  useNewsControllerGetNewsQuery,
  useNewsControllerUpdateNewsMutation,
  useNewsControllerDeleteNewsMutation,
  useNewsControllerGetNewsByUrlQuery,
  useEventsControllerFilterEventsMutation,
  useEventsControllerCreateEventMutation,
  useEventsControllerGetEventQuery,
  useEventsControllerUpdateEventMutation,
  useEventsControllerDeleteEventMutation,
  useEventsControllerGetEventBuUrlQuery,
  useInfoPagesControllerFilterInfoPagesMutation,
  useInfoPagesControllerCreateInfoPageMutation,
  useInfoPagesControllerGetInfoPageQuery,
  useInfoPagesControllerUpdateInfoPageMutation,
  useInfoPagesControllerDeleteInfoPageMutation,
  useInfoPagesControllerGetInfoPageBuUrlQuery,
  useFilesControllerUploadFileMutation,
  usePaymentControllerCreatePaymentMutation,
  useLazyGetAllPaymentsByTypeQuery,
  usePaymentControllerGetPaymentQuery,
  usePaymentControllerChangeStatusMutation,
  usePaymentControllerDeletePaymentMutation,
  usePaymentControllerFilterCandidatesMutation,
  usePaymentControllerGetEducationalOrganizationsQuery,
  usePartnerControllerGetPartnerQuery,
  usePartnerControllerUpdatePartnerMutation,
  useNsiSyncControllerFilterSyncDataLogsMutation,
  useNsiSyncControllerGetSyncDataLogQuery,
  useNotificationsControllerFilterMutation,
  useNotificationsControllerUpdateMutation,
  useNotificationsControllerDeleteMutation,
  useNotificationsControllerCreateMutation,
  useNotificationsControllerSendEmailMutation,
  useParticipantsHumansControllerUndeleteHumanMutation,
  useParticipantsHumansControllerUpdateHumanMutation,
  useParticipantsHumansControllerGetHumanTicketsAndMailingQuery,
  useFooterLinksControllerGetQuery,
  useFooterLinksControllerCreateMutation,
  useFooterLinksControllerUpdateMutation,
  useFooterLinksControllerDeleteMutation,
} = adminApi;
