import { HumanResponse } from '@api/admin/adminGeneratedApi';
import { Link } from 'react-router-dom';
import { Routes } from '@navigation/routes';
import { Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { HumanService } from '@services/HumanService';
import styles from './styles.module.scss';

export const StudentFIO = (human: HumanResponse) => {
  return (
    <Link to={`/${Routes.students}/${human.id}`} target="_blank" className={styles.studentLink}>
      <Typography>{HumanService.getShortHumanName(human)}</Typography>
      <Icon name="chevronRight" />
    </Link>
  );
};
