import { useInfoPagesControllerUpdateInfoPageMutation } from '@api/admin/adminGeneratedApi';
import { FormInfoPageValues } from '../defaultValues';
import { InfoPageMapper } from '../mappers';

export const useUpdateInfoPage = (id: string) => {
  const [updateInfoPage, resultOfUpdate] = useInfoPagesControllerUpdateInfoPageMutation();

  const handleUpdate = async (values: FormInfoPageValues) => {
    const infoPage = await updateInfoPage({
      id,
      infoPageRequest: InfoPageMapper.formModelToApiModel(values),
    }).unwrap();
    return infoPage;
  };

  return {
    updateInfoPage: handleUpdate,
    resultOfUpdate,
  };
};
