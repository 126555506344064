import { createTheme, Theme } from '@mui/material';
import colors from '@styles/colors.module.scss';
import indents from '@styles/indents.module.scss';
import typography from '@styles/typography.module.scss';
import borders from '@styles/borders.module.scss';
import fontWeights from '@styles/font-weights.module.scss';

export const innerTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: 'Inter, sans-serif',
            fontSize: typography.bodyFontSize,
            lineHeight: typography.bodyLineHeight,
            fontWeight: fontWeights.regular,
            color: colors.white,
            backgroundColor: colors.error,
            borderRadius: borders.radiusM,
            padding: `4px ${indents.xs}`,
          },
          popper: {
            '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
              marginTop: `${indents.xxs} !important`,
            },
          },
          arrow: {
            transform: 'translateX(20px) !important',
            '&::before': {
              backgroundColor: colors.error,
            },
          },
        },
      },
    },
  });
