import { Controller, ControllerProps, useFormContext, RefCallBack } from 'react-hook-form';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useMemo } from 'react';
import cn from 'classnames';
import { DatePicker } from '@components/DatePicker/DatePicker';
import { DateTimePicker } from '@components/DatePicker/DateTimePicker';
import { DateRangePicker } from '@components/DatePicker/DateRangePicker';
import { Wysiwyg } from '@area/redactor/components/Wysiwyg';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { ImagePickerPartner } from '@components/ImagePickerPartner';
import { Checkbox } from '@components/Checkbox';
import { Autocomplete } from '@components/Autocomplete';
import { Input } from '../Input';
import { Select } from '../Select';
import { ImagePicker } from '../ImagePicker';
import { VideoPicker } from '../VideoPicker';
import { FormFieldProps } from './types';
import styles from './style.module.scss';

export const FormField = <T extends Record<string, unknown>>({
  type = 'text',
  name,
  rules,
  error,
  title,
  titleVariant = 'h2',
  titleAlign = 'left',
  ...props
}: FormFieldProps<T>) => {
  const { control, formState } = useFormContext<T>();

  const fieldError = error ?? formState.errors[name as string];
  const fieldErrorMessage = (fieldError?.message || fieldError?.type) as string;
  const textFieldProps = 'textFieldProps' in props ? props.textFieldProps : {};

  const controllerRender: ControllerProps<T>['render'] | null = useMemo(() => {
    switch (type) {
      case 'text':
      case 'password':
      case 'number':
        return ({ field }) => (
          <Input {...(field as any)} {...props} ref={field.ref as RefCallBack} type={type} error={fieldErrorMessage} />
        );
      case 'select':
        return ({ field }) => <Select {...(field as any)} {...props} error={fieldErrorMessage} />;
      case 'switch':
        return ({ field }) => {
          return (
            <FormControlLabel
              control={<Switch {...(field as any)} {...props} checked={Boolean(field.value)} />}
              label={props.label}
            />
          );
        };
      case 'checkbox':
        return ({ field }) => {
          return (
            <FormControlLabel
              control={<Checkbox {...(field as any)} {...props} checked={Boolean(field.value)} />}
              label={props.label}
            />
          );
        };
      case 'datepicker':
        return ({ field }) => (
          <DatePicker
            {...(field as any)}
            {...props}
            error={fieldErrorMessage}
            textFieldProps={{
              ...textFieldProps,
            }}
          />
        );
      case 'dateTimePicker':
        return ({ field }) => (
          <DateTimePicker
            {...(field as any)}
            {...props}
            error={fieldErrorMessage}
            textFieldProps={{
              ...textFieldProps,
            }}
          />
        );
      case 'dateRangePicker':
        return ({ field }) => <DateRangePicker {...(field as any)} {...props} />;
      case 'wysiwyg':
        return ({ field }) => (
          <Wysiwyg {...props} initialValue={field.value as CustomDescendant[]} onChange={field.onChange} />
        );
      case 'image':
        return ({ field }) => <ImagePicker {...(field as any)} {...props} error={fieldErrorMessage} />;
      case 'imagePartner':
        return ({ field }) => <ImagePickerPartner {...(field as any)} {...props} />;
      case 'video':
        return ({ field }) => <VideoPicker {...(field as any)} {...props} />;
      case 'autocomplete':
        return ({ field }) => <Autocomplete {...(field as any)} {...props} />;
      default:
        return null;
    }
  }, [fieldErrorMessage, props, type]);

  if (!controllerRender) {
    return null;
  }

  return (
    <Box className={cn(styles.root)}>
      {title && (
        <Typography className={styles.fieldTitle} variant={titleVariant} align={titleAlign}>
          {title}
        </Typography>
      )}
      <Controller<T> name={name} control={control} rules={rules} render={controllerRender} />
    </Box>
  );
};
