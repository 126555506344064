const DEFAULT_DELAY = 500;

export const debounce = (func: Function, delay?: number) => {
  let timer: ReturnType<typeof setTimeout> | null;
  return function (this: any, ...args: any[]) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay || DEFAULT_DELAY);
  };
};
