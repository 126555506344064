import { OrderStatus } from '../types';

export const getStatusText = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.new:
      return 'Новый';
    case OrderStatus.processing:
      return 'В обработке';
    case OrderStatus.paused:
      return 'Приостановлено';
    case OrderStatus.success:
      return 'Успешно';
    case OrderStatus.error:
      return 'Неуспешно';
    default:
      return 'Неизвестный статус';
  }
};
