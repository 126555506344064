import { Modal } from '@components/Modal';
import { Button, TextField } from '@mui/material';
import { FC } from 'react';
import { useSlateStatic } from 'slate-react';
import pretty from 'pretty';
import { serializeDescendant } from '@area/redactor/components/Wysiwyg/utils/serialize';

export interface ShowHTMLModalContentProps {
  onClose: () => void;
}

export const ShowHTMLModalContent: FC<ShowHTMLModalContentProps> = ({ onClose }) => {
  const editor = useSlateStatic();
  const value = editor.children.map((node) => pretty(serializeDescendant(node))).join('\n');

  return (
    <Modal
      open
      maxWidth="md"
      onClose={onClose}
      title="HTML разметка"
      actions={
        <Button variant="contained" onClick={onClose}>
          Закрыть
        </Button>
      }>
      <TextField multiline rows={15} disabled value={value} fullWidth />
    </Modal>
  );
};
