import { useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Box, Button, Paper, Grid, Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { Column } from '@components/Table/types';
import { useNavigate, useOutlet } from 'react-router';
import { Table } from '@components/Table/Table';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { DateService } from '@services/DateService';
import { ApplicationsFilter } from '@area/applicationsPage/ApplicationsFilter';
import { Tag } from '@components/Tag';
import {
  applicationStatusColors,
  applicationStatusTextColors,
  DEFAULT_APPLICATIONS_SIZE,
} from '@area/applicationsPage/defaultValues';
import { useApplications } from '@area/applications/hooks/useApplications';
import { ApplicationsFilterRequest, ApplicationResponse } from '@api/admin/adminGeneratedApi';
import { useQueryParams } from '@hooks/useQueryParams';
import { getStatusText } from '@area/applications/utils/getStatusText';
import { getApplicationType } from '@area/applications/utils/getApplicationType';
import { OrderStatus } from '@area/applications/types';
import { getStatusValue } from '@area/applications/utils/getStatusValue';
import styles from './styles.module.scss';

export const ApplicationsPage = () => {
  usePageTitle('Заявки');
  const { getApplications, applicationsList, isSuccess, paginate, originalArgs, getValuesFromParams, isLoading } =
    useApplications();

  const [data, setData] = useState<ApplicationResponse[]>([]);
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { params } = useQueryParams();

  const fetchData = (values: Partial<ApplicationsFilterRequest>, skip: number, size = DEFAULT_APPLICATIONS_SIZE) => {
    getApplications({
      skip,
      size,
      applicationsFilterRequest: {
        status: values.status,
        type: values.type,
        search: values.search,
        createdAt: values.createdAt,
      },
    });
  };

  const onChange = (values: any) => {
    setData([]);

    const val = {
      ...values,
      status: getStatusValue(values.status),
      type: values.type ? values.type.value : undefined,
      createdAt: values.createdAt ? dayjs(values.createdAt) : undefined,
    };

    fetchData(val, 0);
  };

  const loadMore = () => {
    fetchData(originalArgs?.applicationsFilterRequest!, data.length);
  };

  const columns: Column<ApplicationResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        width: '72px',
        align: 'center',
        render: (_, row) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tag
                label={getStatusText(row.status)}
                sx={{
                  background: applicationStatusColors[row.status as OrderStatus],
                  color: applicationStatusTextColors[row.status as OrderStatus],
                  width: '100%',
                }}
              />
            </Box>
          );
        },
      },
      {
        title: 'Дата подачи',
        dataIndex: 'createdAt',
        key: 'date',
        width: '72px',
        render: (value: Date) => (
          <Typography className={styles.date} variant="body2">
            {DateService.getLocalDate(value)}
          </Typography>
        ),
      },
      {
        title: 'Заявитель',
        dataIndex: 'name',
        key: 'name',
        width: '170px',
        render: (_, row) => {
          const { lastName, firstName, middleName } = row.human;
          return <Typography variant="body2">{`${lastName} ${firstName} ${middleName}`}</Typography>;
        },
      },
      {
        title: 'Тип заявки',
        dataIndex: 'type',
        key: 'type',
        width: '72px',
        render: (value) => <Typography variant="body2">{getApplicationType(value)}</Typography>,
      },
    ],
    [],
  );

  const navigateToApp = (row: ApplicationResponse) => {
    navigate(`${row.type}/${row.id}`);
  };

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(applicationsList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Grid container direction="column" gap="24px">
        <Grid item>
          <Header title="Заявки" />
        </Grid>
        <Grid item>
          <ApplicationsFilter onChange={onChange} />
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <>
            <span className={styles.searchResult}> Найдено участников: </span>
            <span className={styles.totalCount}>{paginate?.totalCount}</span>
          </>
          <Paper className={styles.paper}>
            <Table<ApplicationResponse>
              onRowClick={(row) => navigateToApp(row)}
              columns={columns}
              data={data}
              loading={isLoading}
            />
            {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
              <Button
                variant="outlined"
                className={styles.loadMoreBtn}
                startIcon={<Icon name="more" />}
                onClick={loadMore}
                disabled={isLoading}>
                Загрузить еще
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
