import { useSlate } from 'slate-react';
import { Typography } from '@mui/material';
import { getCharacterCount } from '../../utils/elements';
import styles from '../../styles.module.scss';

export const CharacterCount = () => {
  const editor = useSlate();

  return (
    <Typography className={styles.characterCount} component="div">
      {getCharacterCount(editor.children)}
    </Typography>
  );
};
