import { Grid, Paper } from '@mui/material';
import { Filter } from '@components/Filter';
import { Table } from '@components/Table/Table';
import { TranslationResponse } from '@api/admin/adminGeneratedApi';
import { EditModal } from '@area/translations/components/EditModal';
import { useTranslations } from '@area/translations/hooks/useTranslations';
import { Field } from '@components/Filter/types';

const searchField: Field = {
  type: 'text',
  name: 'search',
  props: { placeholder: 'Поиск', sx: { minWidth: '200px' } },
  stretchable: true,
};

export const Translations = () => {
  const { modalRef, languages, translations, columns, isLoading, isFetched, refetch, totalCount } = useTranslations();

  return (
    <>
      <Grid item>
        <Filter<{ search: string }> onChange={() => {}} searchField={searchField} />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Paper sx={{ padding: '20px' }}>
          <Table<TranslationResponse>
            columns={columns}
            data={translations ?? []}
            loading={isLoading}
            fetched={isFetched}
            showPagination
            paginationProps={{ count: totalCount, labelRowsPerPage: 'Количество перeводов на странице' }}
          />
        </Paper>
      </Grid>
      {languages.data && <EditModal modalRef={modalRef} languages={languages.data?.data ?? []} onSubmit={refetch} />}
    </>
  );
};
