import { useNavigate } from 'react-router';
import { removeLocalStorageObject, setLocalStorageObject } from '@area/localstorage/localStorage';
import { LocalStorageKeys } from '@area/localstorage/constants';
import { Routes } from '@navigation/routes';
import { useBackUrl } from '@hooks/useBackUrl';
import { LoginResponseDto } from '@api/admin/adminGeneratedApi';
import { buildRoutePath } from '@navigation/buildRoutePath';

export const useAuthHelpers = () => {
  const navigate = useNavigate();
  const { buildRouteToContinue, buildRouteWithBackUrl } = useBackUrl();

  const login = (dto: LoginResponseDto) => {
    setLocalStorageObject(LocalStorageKeys.AuthToken, dto);
    navigate(buildRouteToContinue(Routes.main));
  };

  const logout = (includeBack = false) => {
    removeLocalStorageObject(LocalStorageKeys.AuthToken);
    navigate(includeBack ? buildRouteWithBackUrl(Routes.login) : buildRoutePath(Routes.login, { absolute: true }));
  };

  return {
    login,
    logout,
  };
};
