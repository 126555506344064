import { Grid, Button, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { partnersStatusOptions } from '../defaultValues';
import { BaseFieldValues } from '../types';
import styles from './style.module.scss';

export const CreateForm = <TValues extends BaseFieldValues>() => {
  const form = useForm<TValues>({});

  const formData = form.watch();

  const handleCheckAndSubmit = form.handleSubmit((data) => {});
  const handleDeleteFile = () => {};

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <FormProvider {...form}>
          <Grid item>
            <FormField name="show" type="switch" label={formData.published ? 'Вкл' : 'Выкл'} title="Показывать" />
          </Grid>
          <Grid item>
            <FormField name="anchor" type="switch" label={formData.important ? 'Вкл' : 'Выкл'} title="Якорный" />
          </Grid>
          <Accordion className={styles.accordionStyles}>
            <AccordionSummary
              expandIcon={<Icon name={'chevronDown'} />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography variant="h2">Карточка партнера</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item flexDirection="column" gap={indents.l} className={styles.formWrapper}>
                <Grid item lg={6}>
                  <FormField titleVariant="body1" name="title" title="Название" fullWidth />
                </Grid>
                <Grid item lg={6} className={styles.typeSelect}>
                  <FormField
                    name="category"
                    titleVariant="body1"
                    type="select"
                    placeholder="Выберите категорию"
                    title="Категория"
                    options={partnersStatusOptions}
                  />
                </Grid>
                <Grid item lg={6}>
                  <FormField titleVariant="body1" name="description" title="Описание" fullWidth />
                </Grid>
                <Grid item lg={6}>
                  <FormField titleVariant="body1" name="discount" title="Скидка" fullWidth />
                </Grid>
                <Grid item>
                  <Grid container flexDirection="column" gap={indents.xs}>
                    <Grid item>
                      <Typography variant="body1">Логотип</Typography>
                    </Grid>
                    <Grid item>
                      <FormField
                        onDelete={handleDeleteFile}
                        imageUrl={form.getValues()?.mainImage?.url}
                        name="mainImage.file"
                        type="imagePartner"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid item>
                    <Typography variant="body1">Предпросмотр карточки</Typography>
                  </Grid>
                  <Grid container flexDirection="row" gap={indents.s} mt={1.5}>
                    <Grid container flexDirection="column" width="292px">
                      <Typography mb={1.5} variant="caption" color={colors.grey}>
                        ЯКОРНЫЙ
                      </Typography>
                      <Grid className={styles.emptyCard} container alignItems="center" justifyContent="center">
                        <Typography variant="caption">Нет данных</Typography>
                      </Grid>
                    </Grid>

                    <Grid container flexDirection="column" width="292px">
                      <Typography mb={1.5} variant="caption" color={colors.grey}>
                        НЕ ЯКОРНЫЙ
                      </Typography>
                      <Grid className={styles.emptyCard} container alignItems="center" justifyContent="center">
                        <Typography variant="caption">Нет данных</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordionStyles}>
            <AccordionSummary
              expandIcon={<Icon name={'chevronDown'} />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography variant="h2">Данные партнера из РБД</Typography>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordionStyles}>
            <AccordionSummary
              expandIcon={<Icon name={'chevronDown'} />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography variant="h2">Программы партнера из РБД</Typography>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
        </FormProvider>
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          type="button"
          variant="contained"
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleCheckAndSubmit}>
          Сохранить
        </Button>
      </Box>
    </>
  );
};
