import { Grid, Button, Box, Avatar, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { UserResponse } from '@api/admin/adminGeneratedApi';
import { UserUpdateForm } from '@area/users/type';
import { updateUserValidation } from '@area/users/validation/updateUserValidation';
import { UserMapper } from '@area/users/mappers';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';
import { Modal } from '@components/Modal';
import { useEffect, useState } from 'react';
import { useUpdateUser } from '@area/users/hooks/useUpdateUser';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useResetPasswordUser } from '@area/users/hooks/useResetPasswordUser';
import { ActiveStatusSwitch } from '@area/users/components/EditUserForm/ActiveStatusSwitch';
import styles from './style.module.scss';
import { RoleSelect } from './RoleSelect';

export interface EditUserFormProps {
  user: UserResponse;
}

export const EditUserForm = ({ user }: EditUserFormProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const resolver = useYupValidationResolver<UserUpdateForm>(updateUserValidation);
  const { currentUser } = useCurrentUser();
  const form = useForm<UserUpdateForm>({
    defaultValues: UserMapper.userResponseToForm(user),
    resolver,
  });

  const { handlePushItem } = useNotifications();
  const { handleUpdate, result } = useUpdateUser(user.id);
  const { handleResetPassword, result: resetPasswordResult } = useResetPasswordUser(user.id);

  useEffect(() => {
    if (!result.isSuccess) return;

    handlePushItem({ severity: 'success', text: 'Пользователь успешно изменен' });
    form.reset(UserMapper.userResponseToForm(result.data));
  }, [result.isSuccess, handlePushItem]);

  useEffect(() => {
    if (!resetPasswordResult.isSuccess) return;

    handlePushItem({ severity: 'success', text: 'Пароль пользователя успешно сброшен' });
  }, [resetPasswordResult.isSuccess, handlePushItem]);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <Grid item>
          <ActiveStatusSwitch
            userId={user.id}
            isActive={user.isActive}
            disabled={!currentUser || currentUser.id === user.id}
          />
        </Grid>
        <FormProvider {...form}>
          <Grid item>
            <Avatar src={user.avatar?.url} className={styles.avatar} />
          </Grid>
          <Grid item>
            <FormField name="lastName" title="Фамилия" className={styles.fieldWidth} />
          </Grid>
          <Grid item>
            <FormField name="firstName" title="Имя" className={styles.fieldWidth} />
          </Grid>
          <Grid item>
            <FormField name="middleName" title="Отчество" className={styles.fieldWidth} />
          </Grid>
          <Grid item>
            <FormField name="email" title="Email" disabled className={styles.fieldWidth} />
          </Grid>
        </FormProvider>
        <Grid item>
          <RoleSelect user={user} />
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            onClick={openModal}
            startIcon={<Icon name="key" />}
            disabled={!currentUser || currentUser.id === user.id}>
            Сбросить пароль
          </Button>
        </Grid>
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          type="button"
          variant="contained"
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={form.handleSubmit((data) => {
            handleUpdate(data);
          })}>
          Сохранить
        </Button>
      </Box>
      <Modal
        open={isOpenModal}
        title="Сбросить пароль"
        onClose={closeModal}
        fullWidth={false}
        sx={{ paddingLeft: 0 }}
        actions={
          <Stack direction="row" gap={indents.s}>
            <Button
              variant="contained"
              onClick={async () => {
                await handleResetPassword();
                closeModal();
              }}>
              Да, сбросить
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Нет, оставить
            </Button>
          </Stack>
        }>
        <Typography variant="body2">Вы действительно хотите сбросить пароль пользователя?</Typography>
      </Modal>
    </>
  );
};
