import { useEventsControllerDeleteEventMutation } from '@api/admin/adminGeneratedApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useEffect, useState } from 'react';

export const useKampusDeleteEvent = (cb: () => void) => {
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [deletingRowId, setDeletingRowId] = useState<string | null>(null);
  const [loadingRowId, setloadingRowId] = useState<string>();
  const { handlePushItem } = useNotifications();
  const [deleteKampusEvent, { isSuccess, status }] = useEventsControllerDeleteEventMutation();

  const openDeleteConfirmationModal = (id: string) => {
    setDeletingRowId(id);
    setDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeletingRowId(null);
    setDeleteConfirmationModalOpen(false);
  };

  const confirmDelete = () => {
    deleteKampusEvent({ id: deletingRowId! });
    setloadingRowId(deletingRowId!);
    closeDeleteConfirmationModal();
  };

  useEffect(() => {
    if (isSuccess && status === 'fulfilled') {
      handlePushItem({ severity: 'success', text: 'Мероприятие успешно удалено' });
      cb();
    }
  }, [status, isSuccess]);

  return {
    deleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    confirmDelete,
    loadingRowId,
  };
};
