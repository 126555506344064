import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { LoadingSpinner } from '@components/LoadingSpinner';
import {
  usePartnerControllerUpdatePartnerMutation,
  usePartnerControllerUploadFileMutation,
} from '@api/admin/adminGeneratedApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { Routes } from '@navigation/routes';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { UpdateFormDataValues } from '../types';
import { EditForm } from '../components/EditForm';
import { useGetPartnerById } from '../hooks/useGetPartnerById';
import { useGetCategories } from '../hooks/useGetCategories';

export const UpdatePartnerView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: partner } = useGetPartnerById(id!);
  const { data: categories } = useGetCategories('');
  const [updatePartner, { isLoading: isLoadingUpdatingPartner, isSuccess }] =
    usePartnerControllerUpdatePartnerMutation();
  const [uploadFile, { isLoading: isLoadingUpdatingLogo }] = usePartnerControllerUploadFileMutation();
  const { handlePushItem } = useNotifications();

  const onSubmit = async (data: UpdateFormDataValues) => {
    const { name, categories, description, discount, isDraft, isAnchor, logo } = data;

    if (logo && logo instanceof File) {
      const fd = new FormData();

      fd.set('file', logo);
      await uploadFile({ id: id!, partnerUpdateLogoRequest: fd as any });
    }

    await updatePartner({
      id: id!,
      partnerUpdateRequest: {
        name,
        categoryIds: categories.map(({ value }) => value),
        description,
        discount,
        isDraft: !isDraft,
        isAnchor,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Партнер успешно обновлен' });
      navigate(buildRoutePath(Routes.partners, { absolute: true }));
    }
  }, [isSuccess]);

  return (
    <>
      {partner && categories ? (
        <EditForm<UpdateFormDataValues>
          onSubmit={onSubmit}
          isLoading={isLoadingUpdatingLogo || isLoadingUpdatingPartner}
          categories={categories.map(({ id, name }) => ({ value: id, label: name }))}
          data={partner}
        />
      ) : (
        <LoadingSpinner sx={{ margin: '48px 0' }} />
      )}
    </>
  );
};
