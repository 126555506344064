import { combineReducers } from '@reduxjs/toolkit';
import { notificationsSlice } from '@area/notifications/store';
import { sidebarSlice } from '@area/sidebar/store';
import { mediaFilesUploadSlice } from '@area/mediaFilesUploadSnackbar/store';
import { adminApi } from '@api/admin/adminApi';
import { userSlice } from '@area/user/store';
import { CLEAR_STATE_ACTION_TYPE } from '../actions/actionTypes';

export const createRootReducer = () => {
  const appReducer = combineReducers({
    [adminApi.reducerPath]: adminApi.reducer,
    notificationsSlice: notificationsSlice.reducer,
    sidebarSlice: sidebarSlice.reducer,
    userSlice: userSlice.reducer,
    mediaFilesUploadSlice: mediaFilesUploadSlice.reducer,
  });

  const clearableReducer: typeof appReducer = (state, action) => {
    if (action.type === CLEAR_STATE_ACTION_TYPE) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }

    return appReducer(state, action);
  };

  return clearableReducer;
};
