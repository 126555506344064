import { FC } from 'react';
import { useParams } from 'react-router';
import { Modal } from '@components/Modal';
import { Button, Grid, Typography } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { useGetApplicationById } from '@area/applications/hooks/useGetApplicationById';
import { useApplicationControllerApproveApplicationMutation } from '@api/admin/adminGeneratedApi';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ConfirmParticipationModal: FC<Props> = ({ open, onClose }) => {
  const { id } = useParams();
  const { refetch } = useGetApplicationById(id!);
  const [approve, { isLoading }] = useApplicationControllerApproveApplicationMutation();
  const handleApprove = async () => {
    await approve({ id: id! });
    await refetch();
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Подтвердить участие в программе"
      fullWidth={false}
      loading={isLoading}
      actions={
        <Grid container flexDirection="row" gap={indents.s}>
          <Grid item>
            <Button variant="contained" onClick={handleApprove}>
              Да, подтвердить
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined">
              Отмена
            </Button>
          </Grid>
        </Grid>
      }>
      <Typography variant="body2">Вы действительно хотите подтвердить участие в программе?</Typography>
    </Modal>
  );
};
