import { Filter } from '@components/Filter';
import { Icon } from '@components/Icon';
import { PaymentCandidateProcessStatusOptions } from '@area/payments/defaultValues';
import { PaymentsCandidatesFiltersRequest } from '@api/admin/adminGeneratedApi';
import { useEducationalOrganizations } from '@area/payments/hooks/useEducationalOrganizations';
import colors from '@styles/colors.module.scss';

export const PaymentMembersFilter = () => {
  const { educationalOrganizationsOptions, isLoading } = useEducationalOrganizations();

  if (isLoading) {
    return null;
  }

  return (
    <Filter<PaymentsCandidatesFiltersRequest>
      onChange={() => null}
      searchField={{
        type: 'text',
        name: 'search',
        props: { placeholder: 'Поиск', sx: { minWidth: 200, background: colors.white } },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'educationalOrganizations',
          props: {
            placeholder: 'ВУЗ',
            icon: <Icon name={'education'} />,
            options: educationalOrganizationsOptions,
            multiple: true,
            sx: { width: 234, background: colors.white },
          },
        },
        {
          type: 'select',
          name: 'processResults',
          props: {
            placeholder: 'Результат обработки',
            icon: <Icon name={'rubric'} />,
            multiple: true,
            options: PaymentCandidateProcessStatusOptions,
            sx: { width: 234, background: colors.white },
          },
        },
      ]}
    />
  );
};
