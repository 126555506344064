import { Button } from '@mui/material';
import { FormProvider, useForm, DefaultValues, SubmitHandler } from 'react-hook-form';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import styles from './styles.module.scss';
import { validationSchema, TValues } from './validationSchema';

interface FormProps {
  onSubmit: SubmitHandler<TValues>;
  defaultValues: DefaultValues<TValues>;
}

export const Form = ({ onSubmit, defaultValues }: FormProps) => {
  const resolver = useYupValidationResolver<TValues>(validationSchema);
  const form = useForm({
    resolver,
    defaultValues,
  });

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <>
      <FormProvider {...form}>
        <FormField className={styles.response} name="answerText" title="Ответ" sx={{ width: '100%', height: '64px' }} />
      </FormProvider>
      <Button
        variant="contained"
        startIcon={<Icon name="send" />}
        sx={{ marginTop: '32px', padding: '8px 16px' }}
        onClick={handleSubmit}>
        Отправить
      </Button>
    </>
  );
};
