import dayjs from 'dayjs';

export class DateService {
  public static dateFormats = {
    date: 'YYYY.MM.DD',
    dateTime: 'YYYY.MM.DD - HH:mm',
    dateTimeSecond: 'YYYY.MM.DD - HH:mm:ss',
    dateField: 'DD-MM-YYYY',
    dateTimeField: 'DD-MM-YYYY - HH.mm',
  } as const;

  public static getLocalDate = (date: Date | string, format: string = DateService.dateFormats.dateTimeSecond) => {
    const utc = dayjs(date).utc();

    return utc.local().format(format);
  };

  public static getUTCDate = (date?: Date | string) => {
    if (!date) return;
    return dayjs(date).add(1, 'day').utc().format();
  };

  public static getDateFromParams = (date: string) => {
    if (!date) return;

    const [d, m, y] = date.split('-');

    return dayjs(`${m}-${d}-${y}`).add(1, 'day').utc().format();
  };

  public static getRangeDateFromParams = (date: string) => {
    const [startDateFromParams, endDateFromParams] = decodeURIComponent(date).split(',');
    let startDate;
    let endDate;

    if (Boolean(startDateFromParams)) {
      const [ds, ms, ys] = startDateFromParams.split('-');

      startDate = dayjs(`${ms}-${ds}-${ys}`).utc().format();
    }

    if (Boolean(endDateFromParams)) {
      const [de, me, ye] = endDateFromParams.split('-');

      endDate = dayjs(`${me}-${de}-${ye}`).utc().format();
    }

    return { startDate, endDate };
  };

  public static getNormalDate = (date: string, separator: string) => {
    const [d, m, y] = date.split(separator);

    return [m, d, y].join(separator);
  };
}
