import * as yup from 'yup';

export const validationSchema = yup.object({
  authorName: yup.string().required('Обязательное поле.'),
  imageUrl: yup.string().test('imageUrlOrImage', 'Добавьте ссылку на фото или загрузите свое', function (value) {
    const { image, imageFileUrl } = this.parent;

    return !!value || !!image || !!imageFileUrl;
  }),
  image: yup
    .mixed()
    .nullable()
    .test(
      'imageUrlOrImage',
      'Добавьте ссылку на фото или загрузите свое',
      function (value: any, { path, createError }) {
        const { imageUrl, imageFileUrl } = this.parent;

        if (value && value.size > 5 * 1024 * 1024) {
          return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
        }

        return !!value || !!imageUrl || !!imageFileUrl;
      },
    ),
  quote: yup.string().required('Обязательное поле.'),
});
