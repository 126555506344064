import { useEventsControllerCreateEventMutation } from '@api/admin/adminGeneratedApi';
import { FormKampusEventValues } from '../defaultValues';
import { KampusEventMapper } from '../mappers';

export const useKampusCreateEvent = () => {
  const [createKampusEvent, resultOfCreate] = useEventsControllerCreateEventMutation();

  const handleCreate = async (values: FormKampusEventValues) => {
    const event = await createKampusEvent({
      eventRequest: KampusEventMapper.formModelToApiModel(values),
    }).unwrap();
    return event;
  };

  return {
    createKampusEvent: handleCreate,
    resultOfCreate,
  };
};
