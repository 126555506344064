import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { useMatch, useParams } from 'react-router';
import { UpdatePartnerView } from '@area/partners/views/UpdatePartnerView';
import { CreatePartnerView } from '@area/partners/views/CreatePartnerView';
import styles from './style.module.scss';

export const EditPartners = () => {
  const isNew = Boolean(useMatch(buildRoutePath([Routes.partners, Routes.newItem])));
  const title = isNew ? 'Создание партнера' : 'Редактирование партнера';
  const params = useParams();
  usePageTitle(title);

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title={title}
          backBtnTitle="Назад к списку партнеров"
          backBtnRoute={buildRoutePath(Routes.partners, { absolute: true })}
        />
      </Grid>
      {params.id ? <UpdatePartnerView /> : <CreatePartnerView />}
    </Grid>
  );
};
