import { useState } from 'react';
import { ConfirmDialog, ConfirmDialogProps } from './index';

export const useConfirmDialog = (props: Pick<ConfirmDialogProps, 'text' | 'title' | 'onConfirm'>) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const dialog = ConfirmDialog({
    ...props,
    isOpen,
    onClose,
  });

  return {
    onClickConfirm: () => {
      setIsOpen(true);
    },
    dialog,
  };
};
