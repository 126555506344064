import { useNewsControllerCreateNewsMutation } from '@api/admin/adminGeneratedApi';
import { FormPublicationValues } from '../defaultValues';
import { PublicationMapper } from '../mappers';

export const useCreatePublication = () => {
  const [createKampusEvent, resultOfCreate] = useNewsControllerCreateNewsMutation();

  const handleCreate = async (values: FormPublicationValues) => {
    const news = await createKampusEvent({
      newsRequest: PublicationMapper.formModelToApiModel(values),
    }).unwrap();
    return news;
  };

  return {
    createPublication: handleCreate,
    resultOfCreate,
  };
};
