import { useUsersControllerUpdateUserMutation } from '@api/admin/adminApi';
import { UserUpdateForm } from '@area/users/type';
import { UserMapper } from '@area/users/mappers';

export const useUpdateUser = (id: string) => {
  const [update, result] = useUsersControllerUpdateUserMutation();

  const handleUpdate = async (data: UserUpdateForm) => {
    return await update({
      id,
      userUpdaterDto: UserMapper.userUpdateFormToUserUpdaterDto(data),
    }).unwrap();
  };

  return {
    handleUpdate,
    result,
  };
};
