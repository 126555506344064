import { Tabs } from '@components/Tabs';
import { Tab } from '@components/Tabs/types';
import styles from './styles.module.scss';

export interface RedactorProps {
  tabs: Tab[];
  defaultTab?: number;
}

export const Redactor = ({ tabs, defaultTab = 0 }: RedactorProps) => {
  if (!tabs.length) {
    return null;
  }

  return (
    <Tabs
      name="redactorTab"
      defaultValue={defaultTab}
      tabs={tabs}
      tabClassName={styles.tab}
      tabPanelClassName={styles.tabPanel}
      variant="scrollable"
      scrollButtons
    />
  );
};
