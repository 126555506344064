import { RefObject } from 'react';
import { TextFieldProps } from '@mui/material';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import { pickersDayClasses, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { pickersPopperClasses } from '@mui/x-date-pickers/internals';
import cn from 'classnames';

import colors from '@styles/colors.module.scss';
import typography from '@styles/typography.module.scss';
import fontWeights from '@styles/font-weights.module.scss';
import shadows from '@styles/shadows.module.scss';

import { TextField } from './components/TextField';
import { OpenPickerButton } from './components/OpenPickerButton';
import { LeftArrowIcon } from './components/LeftArrowIcon';
import { RightArrowIcon } from './components/RightArrowIcon';
import { SwitchViewIcon } from './components/SwitchViewIcon';
import { Day } from './Day';
import { DatePickerProps, DateTimePickerProps, DateRangePickerProps } from './types';

export const getSlots = (props: DatePickerProps | DateTimePickerProps | DateRangePickerProps) => {
  return {
    textField: (ownProps: TextFieldProps) => (
      <TextField
        {...ownProps}
        {...props.textFieldProps}
        className={cn({ fullWidth: props.fullWidth })}
        error={props.textFieldProps?.error || ownProps.error}
      />
    ),
    openPickerButton: OpenPickerButton,
    leftArrowIcon: LeftArrowIcon,
    rightArrowIcon: RightArrowIcon,
    switchViewIcon: SwitchViewIcon,
    day: Day,
  };
};

export const getSlotProps = (wrapperRef: RefObject<HTMLDivElement>, dayProps?: PickersDayProps<unknown>) => {
  return {
    popper: {
      anchorEl: () => wrapperRef.current!,
      sx: {
        [`&.${pickersPopperClasses.root}`]: {
          '& .MuiPaper-root, & .MuiDateCalendar-root': {
            borderRadius: '8px',
          },
          zIndex: 1600,
        },
      },
    },
    layout: {
      sx: {
        [`&.${pickersLayoutClasses.root}`]: {
          '& .MuiDateCalendar-root': {
            width: '280px',
            boxShadow: shadows.card,
          },
          '& .MuiDayCalendar-weekDayLabel, & .MuiPickersDay-root': {
            width: '22px',
            height: '22px',
            margin: '4px 7px',
            textTransform: 'capitalize',
          },
          '& .MuiPickersSlideTransition-root': {
            minHeight: '205px',
          },
          '& .MuiYearCalendar-root': {
            width: '280px',
            maxHeight: '235px',
          },
          '& .MuiPickersYear-yearButton': {
            width: '60px',
          },
          '& .MuiIconButton-root': {
            border: 0,
          },
        },
      },
    },
    day: {
      ...dayProps,
      sx: {
        [`&.${pickersDayClasses.root}`]: {
          margin: '4px 7px',
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
          borderRadius: '2px',
        },
        [`&.${pickersDayClasses.selected}`]: {
          position: 'relative',
          zIndex: 0,
          backgroundColor: `${colors.red} !important`,
        },
        [`&.${pickersDayClasses.today}`]: {
          border: 0,
          backgroundColor: `${colors.lightRed} !important`,
        },
      },
    },
  };
};
