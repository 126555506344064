import { useSlate } from 'slate-react';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { Editor } from 'slate';
import { Icon } from '@components/Icon';
import { toggleMark } from '@area/redactor/components/Wysiwyg/utils/elements';
import { FC } from 'react';
import styles from './styles.module.scss';

interface QuoteProps {
  isTable?: boolean;
}

export const Quote: FC<QuoteProps> = ({ isTable = false }) => {
  const editor = useSlate();
  const marks = Editor.marks(editor);

  const onClick = () => {
    toggleMark(editor, 'quote');
  };

  return (
    <IconButton
      className={cn({ [styles.selected]: !!marks?.quote })}
      centerRipple={false}
      onClick={onClick}
      title="Вставить цитату"
      disabled={isTable}>
      <Icon name="wysiwygKeyQuote" viewBox="0 0 16 16" />
    </IconButton>
  );
};
