import { useMemo } from 'react';
import { Tabs } from '@components/Tabs';
import { Tab } from '@components/Tabs/types';
import { useGetUserById } from '@area/users/hooks/useGetUserById';
import { useParams } from 'react-router';
import { EditUserForm } from '../EditUserForm/Form';
import styles from './styles.module.scss';

export const UserTabs = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useGetUserById(id);

  const tabs: Tab[] | null = useMemo(() => {
    if (!user) return null;

    return [
      {
        label: 'Общая информация',
        content: <EditUserForm user={user} />,
      },
    ];
  }, [user]);

  if (!tabs) return null;

  return (
    <Tabs
      name="userTabs"
      defaultValue={0}
      tabs={tabs}
      tabClassName={styles.tab}
      tabPanelClassName={styles.tabPanel}
      variant="scrollable"
      scrollButtons
    />
  );
};
