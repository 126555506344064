import { StudentResponse } from '@api/admin/adminGeneratedApi';
import { FC, ReactNode } from 'react';
import { Divider, Box, Stack, Typography } from '@mui/material';

import { DateService } from '@services/DateService';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import styles from './styles.module.scss';

interface IFieldOptions {
  field: Partial<keyof StudyInfoView | string>;
  title: string;
  formatter?: (value: any, record: StudyInfoView) => ReactNode;
}

interface StudyInfoView {
  institution?: string;
  speciality?: string;
  formOfStudy?: string;
  costRecovery?: string;
  currentRate?: string;
  currentStatus?: string;
  academicLeave?: boolean;
  studentCategory?: string;
  personalNumber?: string;
  admissionYear?: string;
  issueYear?: string;
  startDate?: string;
  endDate?: string;
  okso?: string;
  scholarshipAccount?: string;
}

const fieldOptions: IFieldOptions[] = [
  {
    field: 'institution',
    title: 'Учебное заведение',
  },
  {
    field: 'speciality',
    title: 'Специальность',
  },
  {
    field: 'okso',
    title: 'Код специальности',
  },
  {
    field: 'formOfStudy',
    title: 'Форма обучения',
  },
  {
    field: 'costRecovery',
    title: 'Вид возмещения затрат',
  },
  {
    field: 'currentRate',
    title: 'Текущий курс',
  },
  {
    field: 'currentStatus',
    title: 'Текущий статус',
  },
  {
    field: 'academicLeave',
    title: 'Взял академический отпуск',
    formatter: (value) => <Icon name={value ? 'check' : 'close'} color={value ? colors.success : colors.red} />,
  },
  {
    field: 'studentCategory',
    title: 'Категория студента',
  },
  {
    field: 'personalNumber',
    title: 'Личный номер',
  },
  {
    field: 'admissionYear',
    title: 'Год приема',
  },
  {
    field: 'issueYear',
    title: 'Год выпуска',
  },
  {
    field: 'startDate',
    title: 'Дата начала обучения',
    formatter: (value) => DateService.getLocalDate(new Date(value), 'DD.MM.YYYY'),
  },
  {
    field: 'endDate',
    title: 'Дата окончания обучение',
    formatter: (value) => DateService.getLocalDate(new Date(value), 'DD.MM.YYYY'),
  },
  {
    field: 'scholarshipAccount',
    title: 'Персональный счет зачисления стипендии',
  },
];

export interface StudyTabProps {
  data?: StudentResponse;
}

const mapStudentResponseToStudyInfoView = (data?: StudentResponse): StudyInfoView => {
  return {
    institution: data?.educationalOrganization?.fullName,
    speciality: data?.educationalProgram?.name,
    formOfStudy: data?.educationalProgram?.developForm?.name,
    costRecovery: data?.compensationType?.name,
    currentRate: data?.courseNumber,
    currentStatus: data?.studentStatus?.name,
    academicLeave: data?.studentStatus?.usedAsAcademicLeave,
    studentCategory: data?.studentCategory?.name,
    personalNumber: data?.personalNumber,
    admissionYear: data?.entranceYear,
    issueYear: data?.yearEnd,
    startDate: data?.startDate,
    endDate: data?.endDate,
    scholarshipAccount: data?.personalAccount,
    okso: data?.educationalProgram?.okso,
  };
};

export const StudyInfo: FC<StudyTabProps> = (props) => {
  const data = props.data;
  const mappedData = mapStudentResponseToStudyInfoView(data);
  return (
    <Stack divider={<Divider className={styles.divider} />}>
      {fieldOptions.map(({ field, title, formatter }) => {
        if (!(field in mappedData)) return null;
        const dataValue = mappedData[field as keyof StudyInfoView];

        return (
          <Box key={field} className={styles.row}>
            <Typography className={styles.rowTitle}>{title}:</Typography>
            <Typography variant="body2" color={dataValue ? colors.black : colors.grey}>
              {dataValue ? (formatter ? formatter(dataValue, mappedData) : (dataValue as any)) : 'Нет данных'}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
