import { InfoPageRequest } from '@api/admin/adminGeneratedApi';
import { IconName } from '@components/Icon/types';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { MediaFileParams } from '@area/publications/defaultValues';

export type FormInfoPageValues = Omit<InfoPageRequest, 'editorContent'> & {
  editorContent: CustomDescendant[] | string;
  snippetPhoto?: MediaFileParams;
  photoGallery?: MediaFileParams[];
};

export const defaultInfoPageValues: FormInfoPageValues = {
  published: true,
  isMenu: false,
  title: '',
  url: '',
  editorContent: '',
  metaTags: [],
  embedded: '',
};

export const sharedInfoPageFieldNames = ['title'];

export const DEFAULT_INFO_PAGE_SIZE = 50;

export const INFO_PAGE_ICONS: IconName[] = ['link', 'delete'];
