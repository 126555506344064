import { Box, Divider, Stack, Typography } from '@mui/material';
import { PaymentStatusTag } from '@area/payments/components/PaymentStatusTag';
import { PaymentResponse } from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';
import colors from '@styles/colors.module.scss';

export type PaymentMainInfoProps = Pick<PaymentResponse, 'createdAt' | 'number' | 'startedBy' | 'status' | 'period'>;

export const PaymentMainInfo = ({ createdAt, startedBy, number, status, period }: PaymentMainInfoProps) => (
  <Box border="1px solid" borderColor={colors.grey} borderRadius={1} p="20px">
    <Stack
      sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}
      divider={<Divider flexItem orientation={'vertical'} />}>
      <Box>
        <Typography mb="12px">Дата создания выплаты</Typography>
        <Typography fontWeight={400}>{DateService.getLocalDate(createdAt)}</Typography>
      </Box>
      <Box>
        <Typography mb="12px">Инициатор</Typography>
        <Typography fontWeight={400}>{startedBy.nickname}</Typography>
      </Box>
      <Box>
        <Typography mb="12px">Номер</Typography>
        <Typography fontWeight={400}>{number}</Typography>
      </Box>
      <Box>
        <Typography mb="12px">Статус</Typography>
        <PaymentStatusTag status={status} />
      </Box>
      <Box>
        <Typography mb="12px">Период</Typography>
        <Typography fontWeight={400}>{period}</Typography>
      </Box>
    </Stack>
  </Box>
);
