import { useSlate } from 'slate-react';
import { ButtonBase, ButtonGroup } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { useTable } from '@area/redactor/components/Wysiwyg/hooks/useTabel';
import { TableUtil } from '@area/redactor/components/Wysiwyg/utils/tabels';

export const TableControls = () => {
  const editor = useSlate();
  const isTable = useTable(editor);
  const tableUtils = useMemo(() => new TableUtil(editor), [editor]);

  if (!isTable) {
    return null;
  }

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const onClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    switch (currentTarget.value) {
      case 'addColumn':
        tableUtils.insertColumn();
        break;
      case 'addRow':
        tableUtils.insertRow();
        break;
      case 'removeColumn':
        tableUtils.removeColumn();
        break;
      case 'removeRow':
        tableUtils.removeRow();
        break;
      case 'removeTable':
        tableUtils.removeTable();
        break;
    }
  };

  return (
    <ButtonGroup>
      {/* todo: icons */}
      <ButtonBase value="addColumn" sx={{ height: 38, width: 80 }} onMouseDown={onMouseDown} onClick={onClick}>
        Добавить колонку
      </ButtonBase>
      <ButtonBase value="addRow" sx={{ height: 38, width: 80 }} onMouseDown={onMouseDown} onClick={onClick}>
        Добавить строку
      </ButtonBase>
      <ButtonBase value="removeColumn" sx={{ height: 38, width: 80 }} onMouseDown={onMouseDown} onClick={onClick}>
        Удалить колонку
      </ButtonBase>
      <ButtonBase value="removeRow" sx={{ height: 38, width: 80 }} onMouseDown={onMouseDown} onClick={onClick}>
        Удалить строку
      </ButtonBase>
      <ButtonBase value="removeTable" sx={{ height: 38, width: 80 }} onMouseDown={onMouseDown} onClick={onClick}>
        Удалить таблицу
      </ButtonBase>
    </ButtonGroup>
  );
};
