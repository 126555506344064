import { Grid, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@navigation/routes';
import { usePageTitle } from '@hooks/usePageTitle';
import { Icon } from '@components/Icon';

import styles from './styles.module.scss';

export const NotFoundPage = () => {
  usePageTitle('404');
  const navigate = useNavigate();
  const goToHomePage = () => navigate(Routes.main);

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="24px"
      className={styles.container}>
      <Grid item>
        <Typography component="h1" className={styles.title}>
          4
          <Box className={styles.logoWrapper}>
            <Icon name="logoIcon" />
          </Box>
          4
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Кажется что-то пошло не так! Страница, которую вы запрашиваете, не существует.
        </Typography>
        <Typography variant="body2">
          Возможно она устарела, удалена или был введен неверный адрес в адресной строке.
        </Typography>
      </Grid>

      <Grid item>
        <Button variant="contained" className={styles.btn} onClick={goToHomePage}>
          Перейти на главную
        </Button>
      </Grid>
    </Grid>
  );
};
