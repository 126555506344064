export enum ApiTags {
  GetUser = 'GetUser',
  GetUserProfile = 'GetUserProfile',
  GetAuthors = 'GetAuthors',
  GetPublicationTags = 'GetPublicationTags',
  GetNews = 'GetNews',
  GetEvent = 'GetEvent',
  GetInfoPage = 'GetInfoPage',
  GetPartner = 'GetPartner',
  GetPayments = 'GetPayments',
  GetNotifications = 'GetNotifications',
  GetParticipant = 'GetParticipant',
  GetHumanTicketsAndMailing = 'GetHumanTicketsAndMailing',
  GetFooterLinks = 'GetFooterLinks',
}
