import { forwardRef } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { Icon } from '../Icon';

export const Checkbox = forwardRef<unknown, CheckboxProps>((props, _) => {
  return (
    <MuiCheckbox
      checkedIcon={<Icon name="checkboxChecked" inheritViewBox />}
      icon={<Icon name="checkboxUnchecked" inheritViewBox />}
      indeterminateIcon={<Icon name="checkboxIndeterminate" inheritViewBox />}
      {...props}
    />
  );
});
