/* eslint-disable indent */
import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';

import { Icon } from '@components/Icon';

import colors from '@styles/colors.module.scss';

import { notificationsSelector } from '../store';
import { useNotifications } from '../hooks/useNotifications';

const defaultDuration = 6000;

export const withNotifications =
  <T extends object>(Component: ComponentType<T>) =>
  (props: T) => {
    const { notifications } = useSelector(notificationsSelector);
    const { handleRemoveItem } = useNotifications();

    return (
      <>
        {notifications.length > 0 && (
          <>
            {notifications.map(({ data, id }, idx) => (
              <Snackbar
                open={true}
                key={id}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                  setTimeout(() => {
                    handleRemoveItem(id);
                  }, data.autoHideDuration ?? defaultDuration);
                }}
                autoHideDuration={data.autoHideDuration ?? defaultDuration}
                style={{
                  top: `${idx * 58 + (idx + 1) * 8}px`,
                }}>
                <Alert
                  variant="filled"
                  onClose={() => handleRemoveItem(id)}
                  severity={data.severity}
                  iconMapping={{
                    success: <Icon name="check" />,
                    info: <>!</>,
                    error: <>!</>,
                  }}
                  slotProps={{
                    closeButton: {
                      title: 'Закрыть',
                      sx: {
                        padidng: 0,
                        border: 0,
                        background: 'transparent',

                        '&:hover': {
                          border: 0,
                          background: 'transparent',
                        },
                      },
                    },
                    closeIcon: {
                      sx: {
                        width: '16px',
                        heigth: '16px',
                        color: colors.white,
                      },
                    },
                  }}
                  onClick={() => data.closeOnClick && handleRemoveItem(id)}>
                  <>{data.text}</>
                </Alert>
              </Snackbar>
            ))}
          </>
        )}

        <Component {...props} />
      </>
    );
  };
