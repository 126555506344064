import { FormField } from '@components/FormField';
import { Modal } from '@components/Modal';
import { Button, Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { validationSchema } from './validation';

export interface QuoteWithPhotoFormData extends Record<string, any> {
  quote: string;
  imageUrl?: string;
  image?: File;
  imageFileUrl?: string;
  imageFileId?: string;
  authorName: string;
  authorInfo?: string;
}

export interface QuoteWithPhotoFormProps {
  initialFormData?: QuoteWithPhotoFormData;
  onSubmit: SubmitHandler<QuoteWithPhotoFormData>;
  onClose: () => void;
}

export const QuoteWithPhotoForm: FC<QuoteWithPhotoFormProps> = ({ onClose, onSubmit, initialFormData }) => {
  const [deleteFile] = useFilesControllerDeleteFileMutation();
  const resolver = useYupValidationResolver<QuoteWithPhotoFormData>(validationSchema);

  const form = useForm<QuoteWithPhotoFormData>({
    defaultValues: initialFormData,
    resolver,
  });

  const onDeleteFile = async () => {
    if (initialFormData?.imageFileId) {
      await deleteFile({ fileId: initialFormData.imageFileId });
    }
    form.setValue('imageFileUrl', undefined);
    form.setValue('imageFileId', undefined);
    form.setValue('image', undefined);
  };

  const { handleSubmit } = form;

  const watchImageFileUrl = form.watch('imageFileUrl');
  const submit = handleSubmit(onSubmit);

  return (
    <Modal
      open
      maxWidth="xs"
      onClose={onClose}
      title={initialFormData ? 'Редактирование авторской цитаты' : 'Добавление авторской цитаты'}
      actions={
        <>
          <Button variant="contained" onClick={submit}>
            Принять
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.m}>
          <Grid>
            <FormField name="authorName" title="Имя автора" fullWidth />
          </Grid>
          <Grid>
            <FormField name="imageUrl" title="Добавьте ссылку на фото" fullWidth />
          </Grid>
          <Grid minHeight={230}>
            <FormField
              name="image"
              type="image"
              imageUrl={watchImageFileUrl}
              onDelete={onDeleteFile}
              title="Или загрузите свое"
            />
          </Grid>
          <Grid>
            <FormField name="authorInfo" title="Информация об авторе" fullWidth />
          </Grid>
          <Grid>
            <FormField type="text" name="quote" title="Цитата" fullWidth multiline rows={5} />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
