import { EventRequest } from '@api/admin/adminGeneratedApi';
import { serialize } from '@area/redactor/components/Wysiwyg/utils/serialize';
import { TypeService } from '@services/TypeService';
import { FormKampusEventValues } from '../defaultValues';

export class KampusEventMapper {
  public static formModelToApiModel(values: FormKampusEventValues): EventRequest {
    return {
      published: values.published,
      important: values.important,
      dateStart: TypeService.isString(values.dateStart) ? values.dateStart : values.dateStart.toString(),
      dateEnd: TypeService.isString(values.dateEnd) ? values.dateEnd : values.dateEnd.toString(),
      title: values.title,
      url: values.url,
      locationName: values.locationName,
      locationCoordinates: values.locationCoordinates,
      type: typeof values.type === 'string' ? values.type : values.type.value,
      editorContent: TypeService.isString(values.editorContent)
        ? values.editorContent
        : serialize(values.editorContent),
      metaTags: values.metaTags,
      embedded: values.embedded,
    };
  }
}
