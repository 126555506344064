/* eslint-disable no-unused-vars */
import { DatePickerFormFieldProps, InputFormFieldProps, SwitchFormFieldProps } from '@components/FormField/types';
import { InputMask } from '@components/Input/types';

// eslint-disable-next-line typescript-enum/no-enum
export enum SettingType {
  string = 0,
  number = 1,
  datetime = 2,
  text = 3,
  boolean = 4,
  time = 5,
}

export const settingTypeToFieldPropsMap: Record<
  SettingType | number,
  Omit<InputFormFieldProps, 'name'> | Omit<SwitchFormFieldProps, 'name'> | Omit<DatePickerFormFieldProps, 'name'>
> = {
  [SettingType.string]: {
    type: 'text',
    fullWidth: true,
  },
  [SettingType.number]: {
    type: 'number',
    fullWidth: true,
  },
  [SettingType.datetime]: {
    type: 'datepicker',
  },
  [SettingType.text]: {
    type: 'text',
    multiline: true,
    minRows: 3,
    fullWidth: true,
  },
  [SettingType.boolean]: {
    type: 'switch',
  },
  [SettingType.time]: {
    type: 'text',
    mask: InputMask.Time,
    fullWidth: true,
  },
} as const;
