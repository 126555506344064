import { useMemo, useEffect, useState } from 'react';
import { Typography, Box, Stack, IconButton, Tooltip, Button, Paper, Grid } from '@mui/material';
import { DateService } from '@services/DateService';
import { Icon } from '@components/Icon';
import { Column } from '@components/Table/types';
import colors from '@styles/colors.module.scss';
import { useNavigate, useOutlet } from 'react-router';
import { Table } from '@components/Table/Table';
import { Routes } from '@navigation/routes';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { usePublications } from '@area/publications/hooks/usePublications';
import { useDeletePublication } from '@area/publications/hooks/useDeletePublication';
import { PublicationsFilter } from '@area/publications/components/PublicationsFilter';
import { useQueryParams } from '@hooks/useQueryParams';
import { Option } from '@components/Select/types';
import { DEFAULT_PUBLICATIONS_SIZE, PUBLICATIONS_ICONS } from '@area/publications/defaultValues';
import { PageDeleteConfirmModal } from '@area/redactor/components/PageDeleteConfirmModal';
import { PublicationType } from '@area/publications/types';
import { NewsShortResponse, NewsFiltersRequest } from '../../api/admin/adminGeneratedApi';
import { getPreviewUrl } from '../../utils/getPreviewUrl';
import styles from './styles.module.scss';

export const PublicationsPage = () => {
  usePageTitle('Публикации');
  const [data, setData] = useState<NewsShortResponse[]>([]);
  const navigate = useNavigate();
  const outlet = useOutlet();
  const { getPublications, publicationsList, paginate, isLoading, isSuccess, originalArgs, getValuesFromParams } =
    usePublications();
  const {
    deleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    confirmDelete,
    loadingRowId,
  } = useDeletePublication(() => {
    setData([]);
    fetchData(originalArgs?.newsFiltersRequest!, 0, originalArgs?.skip! + originalArgs?.size!);
  });
  const { params } = useQueryParams();

  const fetchData = (
    values: Partial<NewsFiltersRequest & { date?: { startDate?: string; endDate?: string } }>,
    skip: number,
    size = DEFAULT_PUBLICATIONS_SIZE,
  ) => {
    getPublications({
      skip,
      size,
      newsFiltersRequest: {
        search: values.search,
        type: (values.type as unknown as Option)?.value,
        tagIds: values.tagIds?.map((tagId: Option | string) => (typeof tagId === 'string' ? tagId : tagId.value)),
        authorIds: values.authorIds?.map((authorId: Option | string) =>
          typeof authorId === 'string' ? authorId : authorId.value,
        ),
        startDateFrom: DateService.getUTCDate(values.date?.startDate),
        startDateTo: DateService.getUTCDate(values.date?.endDate),
      },
    });
  };

  const onChange = (values: Partial<NewsFiltersRequest & { date?: { startDate?: string; endDate?: string } }>) => {
    setData([]);

    fetchData(values, 0);
  };

  const goToNewPublication = () => navigate(Routes.newItem);
  const goToPublication = (row: NewsShortResponse) => navigate(row.id);
  const onClickLink = (url: string) => {
    window.open(getPreviewUrl('news', url), '_blank');
  };

  const loadMore = () => {
    fetchData(originalArgs?.newsFiltersRequest!, data.length);
  };

  const columns: Column<NewsShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'published',
        key: 'published',
        width: '72px',
        render: (value: boolean) => (
          <Tooltip title={value ? 'Опубликовано' : 'Не опубликовано'} arrow>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
            </Box>
          </Tooltip>
        ),
      },
      {
        title: 'Дата и время',
        dataIndex: 'dateStart',
        key: 'date',
        width: '170px',
        render: (value: Date) => (
          <Typography className={styles.date} variant="body2">
            {DateService.getLocalDate(value)}
          </Typography>
        ),
      },
      {
        title: 'Тип',
        align: 'center',
        dataIndex: 'type',
        key: 'type',
        width: '72px',
        render: (value: PublicationType) => {
          return (
            <Tooltip title={value === 'news' ? 'Статья' : 'Лонгрид'} arrow>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Icon name={value === 'news' ? 'article' : 'book'} htmlColor={colors.grey} />
              </Box>
            </Tooltip>
          );
        },
      },
      {
        title: 'Заголовок',
        dataIndex: 'title',
        key: 'title',
        sx: { whiteSpace: 'normal' },
      },
      {
        title: '',
        dataIndex: 'url',
        key: 'url',
        width: '90px',
        render: (url: string, row: NewsShortResponse) => {
          return (
            <Stack direction="row" gap="6px">
              {PUBLICATIONS_ICONS.map((iconName) => (
                <IconButton
                  key={iconName}
                  className={styles.iconButton}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (iconName === 'link') {
                      onClickLink(url);
                      return;
                    }

                    openDeleteConfirmationModal(row.id);
                  }}>
                  <Icon name={iconName} />
                </IconButton>
              ))}
            </Stack>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(publicationsList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Grid container direction="column" gap="24px">
        <Grid item>
          <Header
            title="Публикации"
            actions={
              <Button onClick={goToNewPublication} variant="contained">
                Новая публикация
              </Button>
            }
          />
        </Grid>
        <Grid item>
          <PublicationsFilter onChange={onChange} />
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <Paper className={styles.paper}>
            <Table<NewsShortResponse>
              columns={columns}
              data={data}
              loading={isLoading}
              onRowClick={goToPublication}
              loadingRowId={loadingRowId}
            />
            {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
              <Button
                variant="outlined"
                className={styles.loadMoreBtn}
                startIcon={<Icon name="more" />}
                onClick={loadMore}
                disabled={isLoading}>
                Загрузить еще
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>

      <PageDeleteConfirmModal
        titleToDelete="публикацию"
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onConfirm={confirmDelete}
      />
    </>
  );
};
