import { QuoteWithPhotoElement } from '../../../types';

export const serializeQuoteWithPhoto = (node: QuoteWithPhotoElement): string => {
  const textRender = `<div class="quote-with-photo__quote">${node.quote}</div>`;
  const imageRender =
    node.imageUrl || node.imageFileUrl
      ? `<img class="quote-with-photo__avatar" alt="" loading="lazy" src="${node.imageUrl || node.imageFileUrl}" />`
      : '';
  const dataImgUrl = node.imageUrl ? `data-image-url="${node.imageUrl}"` : '';
  const dataImgFileUrl = node.imageFileUrl ? `data-image-file-url="${node.imageFileUrl}"` : '';
  const dataImgFileId = node.imageFileId ? `data-image-file-id="${node.imageFileId}"` : '';
  const authorInfoNameRender = node.authorName
    ? `<div class="quote-with-photo__author-name">${node.authorName}</div>`
    : '';
  const authorInfoInfoRender =
    node.authorName && node.authorInfo ? `<div class="quote-with-photo__author-info">${node.authorInfo}</div>` : '';
  const authorInfoRender = `<div class="quote-with-photo__author-info-wrapper">${authorInfoNameRender}${authorInfoInfoRender}</div>`;
  const authorWrapperRender = `<div class="quote-with-photo__author-wrapper">${imageRender}${authorInfoRender}</div>`;

  return `<div class="quote-with-photo" data-quote="${node.quote}" ${dataImgUrl} ${dataImgFileId} ${dataImgFileUrl} data-author-name="${node.authorName}" data-author-info="${node.authorInfo}">${textRender}${authorWrapperRender}</div>`;
};
