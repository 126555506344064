import { usePartnerControllerGetCitiesQuery } from '@api/admin/adminGeneratedApi';

export const useGetCities = (search: string = '') => {
  const { data, isLoading } = usePartnerControllerGetCitiesQuery({
    search,
  });

  return {
    data,
    isLoading,
  };
};
