import { KeyQuoteElement } from '../../../types';
import { convertCSSPropertiesToString } from '../utils';

export const serializeKeyQuote = (node: KeyQuoteElement): string => {
  const rootStyle = convertCSSPropertiesToString({
    position: 'relative',
    padding: 24,
    paddingLeft: 48,
    borderRadius: 16,
    backgroundColor: '#f7f6f1',
    backdropFilter: 'blur(2px)',
    fontSize: 18,
    lineHeight: 25,
    fontFamily: 'Inter Regular',
    marginTop: 40,
    marginBottom: 40,
  });

  const iconStyle = convertCSSPropertiesToString({
    position: 'absolute',
    left: 20,
    top: 28,
    color: '#352f2f',
  });

  const style = `
      <style>
            @media (min-width: 1280px) {
                .key-quote {
                    font-size: 20px !important;
                    line-height: 28px !important;
                }
            }
            @media (min-width: 1920px) {
                .key-quote {
                     padding: 32px !important;
                     padding-left: 48px !important;
                }
                .key-quote__icon {
                    top: 36px !important;
                }
            }
      </style>
  `;

  const icon =
    '<img width="15" height="22" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNzg3IDEyLjY5NWMtLjE5NS0uNDU1LS4wMi0uOTc5LjM2My0xLjI5MmE2LjQyMSA2LjQyMSAwIDAgMCAyLjM1OC00Ljk4M0MxMy41MDggMi44NzQgMTAuNjUgMCA3LjEyMyAwIDMuNTk2IDAgLjczOCAyLjg3NC43MzggNi40MmMwIDIuMDEuOTE5IDMuODA2IDIuMzU4IDQuOTgzLjM4My4zMTQuNTU4LjgzNy4zNjMgMS4yOTNMLjA4MSAyMC42MDhBMSAxIDAgMCAwIDEuMDAxIDIyaDEyLjI0NGExIDEgMCAwIDAgLjkyLTEuMzkybC0zLjM3OC03LjkxM1oiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=" />';

  const iconRender = `<div class="key-quote__icon" style="${iconStyle}">${icon}</div>`;

  return `<div style="${rootStyle}" class="key-quote" data-quote="${node.quote}">${style}${iconRender}${node.quote}</div>`;
};
