import { Box } from '@mui/material';
import { FormField } from '@components/FormField';

import styles from './styles.module.scss';

export const Video = () => {
  return (
    <Box className={styles.root}>
      <Box className={styles.videoWrapper}>
        <FormField name="video" type="video" />
      </Box>
    </Box>
  );
};
