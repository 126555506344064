import { Editor, Element, NodeEntry, Path, Text, Transforms } from 'slate';
import { CustomEditor, CustomElement, List, ListElement, ListItemElement } from '../types';
import { listTypes } from '../constants';

export const unwrapList = (editor: CustomEditor, list: ListElement, path: Path, full = true) => {
  if (list.isNested) {
    Transforms.liftNodes(editor, { at: path });
    Transforms.unwrapNodes(editor, {
      match: (n) => Element.isElement(n) && n.type === 'paragraph',
      at: path?.slice(0, -1),
      split: false,
    });
    Transforms.setNodes(
      editor,
      {
        type: 'list-item',
        hasNestedList: undefined,
      },
      { at: path?.slice(0, -1) },
    );
  }

  Transforms.unwrapNodes(editor, {
    match: (n) => Element.isElement(n) && listTypes.includes(n.type as List),
    split: !list.isNested,
    at: !full ? path?.slice(0, -2) : undefined,
  });
};

export const toggleList = (editor: CustomEditor, value: List) => {
  Editor.withoutNormalizing(editor, () => {
    if (!editor.selection || !listTypes.includes(value)) return;

    const [list, path] = getActiveList(editor) ?? [];
    const isActive = list?.type === value;

    if (!isActive) {
      Transforms.setNodes(editor, { type: list ? value : 'list-item', children: [] }, { at: path });
    } else {
      unwrapList(editor, list, path ?? []);
      if (!list.isNested) {
        Transforms.setNodes(editor, { type: 'paragraph' });
      }
    }

    if (!list) {
      Transforms.wrapNodes(editor, { type: value, children: [] });
    }
  });
};

export const getActiveList = (editor: CustomEditor, path?: Path) => {
  const { selection } = editor;
  if (!selection) return null;

  const matches = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && listTypes.includes(n.type as List),
      mode: 'lowest',
      at: path,
    }),
  );

  if (matches.length !== 1) {
    return null;
  }

  return matches[0] as NodeEntry<ListElement>;
};

export const getActiveListType = (editor: CustomEditor) => {
  return getActiveList(editor)?.[0]?.type ?? null;
};

export const getActiveListItem = (editor: CustomEditor) => {
  const [currentLi] = Editor.nodes(editor, {
    match: (elem) => (elem as CustomElement).type === 'list-item',
    mode: 'lowest',
  });

  return currentLi ? (currentLi as NodeEntry<ListItemElement>) : null;
};

export const getPreviousListItem = (editor: CustomEditor) => {
  const [currentLi] = Editor.nodes(editor, {
    match: (elem) => (elem as CustomElement).type === 'list-item',
    mode: 'lowest',
  });

  const hasPrevious = currentLi && currentLi[1][currentLi[1].length - 1] > 0;
  return hasPrevious ? (Editor.node(editor, Path.previous(currentLi[1])) as NodeEntry<ListItemElement>) : null;
};

export const increaseListIndent = (editor: CustomEditor, listType: List) => {
  Editor.withoutNormalizing(editor, () => {
    const item = getActiveListItem(editor);
    const previous = getPreviousListItem(editor);
    if (!previous || !item) return;

    Transforms.setNodes(editor, { type: 'list-item' });

    Transforms.setNodes(editor, { type: 'paragraph', style: { margin: 0 } }, { at: previous[1] });
    Transforms.wrapNodes(
      editor,
      {
        type: 'list-item',
        hasNestedList: true,
        children: [],
      },
      { at: previous[1] },
    );

    Transforms.moveNodes(editor, { to: [...previous[1], 1] });

    Transforms.wrapNodes(editor, { type: listType, isNested: true, children: [] });
  });
};

export const decreaseListIndention = (editor: CustomEditor) => {
  Editor.withoutNormalizing(editor, () => {
    const [currentLi] = Editor.nodes(editor, {
      match: (elem) => (elem as CustomElement).type === 'list-item',
      mode: 'lowest',
    });

    const currentLiPath = currentLi[1];
    const currentParent = Path.parent(currentLiPath);
    const parentListItemPath = Path.parent(currentParent);
    const parentListItem = Editor.node(editor, parentListItemPath);
    const parentIsList = (parentListItem?.[0] as CustomElement).type === 'list-item';
    const isFirstInItsList = currentLiPath[currentLiPath.length - 1] === 0;
    const targetPath = parentIsList ? Path.next(parentListItemPath) : Path.next(currentParent);

    let next = Editor.next(editor, { at: currentLiPath });
    while (next) {
      moveToParent(editor, next[1], targetPath, parentIsList);
    }

    moveToParent(editor, currentLiPath, targetPath, parentIsList);

    if (isFirstInItsList) {
      Transforms.removeNodes(editor, { at: currentParent });
      if (parentIsList) {
        const previousParagraphPath = [...Path.previous(targetPath), 0];
        const previousParagraph = Editor.node(editor, previousParagraphPath) as NodeEntry<CustomElement>;

        if (!previousParagraph?.[0].type) {
          Transforms.unwrapNodes(editor, {
            at: previousParagraphPath,
            split: true,
          });
        }
      }
    }
  });
};

export const moveToParent = (editor: CustomEditor, nodePath: Path, targetPath: Path, parentIsList: boolean) => {
  Transforms.moveNodes(editor, { at: nodePath, to: targetPath });

  if (!parentIsList) {
    const targetNode = Editor.node(editor, targetPath) as NodeEntry<CustomElement>;
    const onlyTextChildren = targetNode?.[0].children?.every((child) => {
      return Text.isText(child) || Editor.isInline(editor, child as CustomElement);
    });

    if (onlyTextChildren) {
      Transforms.setNodes(editor, { type: 'paragraph' }, { at: targetPath });
    } else {
      Transforms.unwrapNodes(editor, { at: targetPath });
    }
  }
};
