import { EditForm } from '@area/kampusEvents/components/EditForm';
import { useKampusCreateEvent } from '@area/kampusEvents/hooks/useKampusCreateEvent';
import { useEffect, useMemo } from 'react';
import { FormKampusEventValues, defaultKampusEventValues } from '@area/kampusEvents/defaultValues';
import { deserialize } from '@area/redactor/components/Wysiwyg';
import { ReplaceEditorContent } from 'Types';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useNavigate } from 'react-router';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { TypeService } from '@services/TypeService';
import { getMetaTagsFromValues } from '@area/redactor/components/MetaTags/getMetaTagsFromValues';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';

export const CreateKampusEventView = () => {
  const { createKampusEvent, resultOfCreate } = useKampusCreateEvent();
  const { error: createError } = resultOfCreate;
  const { handlePushItem } = useNotifications();
  const navigate = useNavigate();

  const { uploadMediaFiles, uploadError, deleteError } = useUploadMediaFiles();

  const onSubmit = async (formValues: FormKampusEventValues) => {
    const event = await createKampusEvent(formValues);
    uploadMediaFiles({
      subjectId: event.id,
      mainImage: formValues.mainImage,
      snippetPhoto: formValues.snippetPhoto,
      photoGallery: formValues.photoGallery,
    });
  };

  useEffect(() => {
    if (resultOfCreate.isSuccess) {
      handlePushItem({ severity: 'success', text: 'Мероприятие создано' });
      navigate(buildRoutePath(Routes.events, { absolute: true }));
    }
  }, [resultOfCreate.isSuccess]);

  useEffect(() => {
    if (createError && 'message' in createError) {
      handlePushItem({ severity: 'error', text: createError.message });
    }

    if (uploadError && 'message' in uploadError) {
      handlePushItem({ severity: 'error', text: uploadError.message });
    }

    if (deleteError && 'message' in deleteError) {
      handlePushItem({ severity: 'error', text: deleteError.message });
    }
  }, [uploadError, deleteError, createError, handlePushItem]);

  const deserializeValues = useMemo(() => {
    return {
      ...defaultKampusEventValues,
      editorContent: TypeService.isString(defaultKampusEventValues.editorContent)
        ? deserialize(defaultKampusEventValues.editorContent)
        : defaultKampusEventValues.editorContent,
      metaTags: getMetaTagsFromValues(),
    };
  }, []);

  return (
    <EditForm<ReplaceEditorContent<FormKampusEventValues, CustomDescendant[]>>
      defaultValues={deserializeValues}
      onSubmit={onSubmit}
      isLoading={resultOfCreate.isLoading}
    />
  );
};
