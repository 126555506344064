import escapeHtml from 'escape-html';
import { Editor, Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import { defaultElementStyles, INDENT_LEVEL_SIZE, listTypes } from '../../constants';
import {
  ArticleLinkElement,
  CustomDescendant,
  CustomElement,
  CustomText,
  CustomTextMarks,
  DefinitionInfoElement,
  ElementType,
  ImageElement,
  KeyQuoteElement,
  LinkElement,
  List,
  ListElement,
  ListItemElement,
  QuoteWithPhotoElement,
  TextAlign,
} from '../../types';
import { elementsToTagsMap, getElementTypeByHtmlElement, tagsToMarksMap } from './constants';
import { serializeDefinitionInfo } from './render/definitionInfo';
import { serializeKeyQuote } from './render/keyQuote';
import { serializeQuoteWithPhoto } from './render/quoteWithPhoto';
import { serializeArticleLink } from './render/articleLink';
import { serializeImage } from './render/image';
import { convertCSSPropertiesToString } from './utils';

const serializeText = (node: CustomText): string => {
  let string = escapeHtml(node.text);

  if (node.bold) {
    string = `<strong>${string}</strong>`;
  }
  if (node.italic) {
    string = `<em>${string}</em>`;
  }
  if (node.underline) {
    string = `<u>${string}</u>`;
  }
  if (node.strikethrough) {
    string = `<s>${string}</s>`;
  }
  if (node.code) {
    string = `<code>${string}</code>`;
  }
  if (node.quote) {
    const icon =
      '<img width="15" height="22" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNzg3IDEyLjY5NWMtLjE5NS0uNDU1LS4wMi0uOTc5LjM2My0xLjI5MmE2LjQyMSA2LjQyMSAwIDAgMCAyLjM1OC00Ljk4M0MxMy41MDggMi44NzQgMTAuNjUgMCA3LjEyMyAwIDMuNTk2IDAgLjczOCAyLjg3NC43MzggNi40MmMwIDIuMDEuOTE5IDMuODA2IDIuMzU4IDQuOTgzLjM4My4zMTQuNTU4LjgzNy4zNjMgMS4yOTNMLjA4MSAyMC42MDhBMSAxIDAgMCAwIDEuMDAxIDIyaDEyLjI0NGExIDEgMCAwIDAgLjkyLTEuMzkybC0zLjM3OC03LjkxM1oiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=" />';

    string = `<q class="quote">${icon}${string}</q>`;
  }

  if (node.sub) {
    string = `<sub>${string}</sub>`;
  } else if (node.sup) {
    string = `<sup>${string}</sup>`;
  }

  // TODO: remove inline styles
  //   const style = convertCSSPropertiesToString({
  //     fontFamily: node.fontName,
  //     fontSize: node.fontSize,
  //     whiteSpace: node.tab ? 'pre-wrap' : undefined,
  //     paddingLeft: node.text === '' ? '0.1px' : undefined,
  //   });

  return string;
};

const serializeElement = (node: CustomElement, children: string): string => {
  const { type, indentLevel, align } = node;

  if (type === 'table') {
    return `<table><tbody>${children}</tbody></table>`;
  }

  if (type === 'link') {
    return `<a href="${escapeHtml(node.url)}">${children}</a>`;
  }

  if (type === 'quote-with-photo') {
    return serializeQuoteWithPhoto(node);
  }

  if (type === 'article-link') {
    return serializeArticleLink(node);
  }

  if (type === 'key-quote') {
    return serializeKeyQuote(node);
  }

  if (type === 'definition-info') {
    return serializeDefinitionInfo(node);
  }

  if (type === 'image') {
    return serializeImage(node);
  }

  if (type === 'heading-1') {
    return `<h1>${children}</h1>`;
  }

  if (type === 'heading-2') {
    return `<h2>${children}</h2>`;
  }

  if (type === 'heading-3') {
    return `<h3>${children}</h3>`;
  }

  if (type === 'paragraph' || type === undefined) {
    return `<p>${children}</p>`;
  }

  if (
    listTypes.includes(type as List) ||
    type === 'list-item' ||
    type === 'caption' ||
    type === 'table-header' ||
    type === 'table-row' ||
    type === 'table-cell'
  ) {
    const tag = elementsToTagsMap[type];
    // const style = convertCSSPropertiesToString({
    //   ...(defaultElementStyles[type] ?? {}),
    //   textAlign: align,
    //   paddingLeft: indentLevel ? indentLevel * INDENT_LEVEL_SIZE : undefined,
    //   ...node.style,
    // });

    let dataset = `data-indent-level="${node.indentLevel ?? 0}"`;
    if (listTypes.includes(type as List) && (node as ListElement).isNested) {
      dataset = `${dataset} data-is-nested-list="true"`;
    } else if (type === 'list-item' && node.hasNestedList) {
      dataset = `${dataset} data-has-nested-list="true"`;
    } else if (type === 'caption') {
      dataset = `${dataset} data-is-caption="true"`;
    }

    return `<${tag} ${dataset}>${children}</${tag}>`;
  }

  return children;
};

export const serializeDescendant = (node: CustomDescendant): string => {
  if (Editor.isEditor(node)) return '';
  if (Text.isText(node)) return serializeText(node);
  const children = (node.children as CustomDescendant[]).map(serializeDescendant).join('');
  return serializeElement(node, children);
};

export const serialize = (nodes: CustomDescendant[]): string => nodes.map(serializeDescendant).join('');

export const deserializeTextMarks = (el: HTMLElement, marks: CustomTextMarks): CustomTextMarks => {
  if (el.dataset?.isTab === 'true') {
    marks.tab = true;
  }

  if (el.style) {
    const { fontFamily, fontSize } = el.style;
    marks.fontSize = fontSize || undefined;
    marks.fontName = fontFamily || undefined;
  }

  const mark = tagsToMarksMap[el.nodeName.toLowerCase()];
  if (mark) {
    // @ts-ignore
    marks[mark] = true;
  }

  return marks;
};

export const deserializeElementAttributes = (
  el: HTMLElement,
  type: ElementType,
): Partial<Omit<CustomElement, 'type' | 'children'>> => {
  const attributes: Omit<CustomElement, 'type' | 'children'> = {};

  switch (type) {
    case 'link':
      (attributes as LinkElement).url = el.getAttribute('href') ?? undefined;
      break;
    case 'list-item':
      (attributes as ListItemElement).hasNestedList = el.dataset?.hasNestedList === 'true';
      break;
    case 'quote-with-photo': {
      (attributes as QuoteWithPhotoElement).quote = el.dataset.quote ?? '';
      (attributes as QuoteWithPhotoElement).imageUrl = el.dataset.imageUrl ?? '';
      (attributes as QuoteWithPhotoElement).imageFileUrl = el.dataset.imageFileUrl ?? '';
      (attributes as QuoteWithPhotoElement).imageFileId = el.dataset.imageFileId ?? '';
      (attributes as QuoteWithPhotoElement).authorName = el.dataset.authorName ?? '';
      (attributes as QuoteWithPhotoElement).authorInfo = el.dataset.authorInfo ?? '';
      break;
    }
    case 'article-link': {
      const link = el.getElementsByTagName('a')[0];

      (attributes as ArticleLinkElement).linkHref = link.getAttribute('href') ?? '';
      (attributes as ArticleLinkElement).linkText = link.textContent ?? '';
      (attributes as ArticleLinkElement).imageUrl = el.dataset.imageUrl ?? '';
      (attributes as ArticleLinkElement).imageFileUrl = el.dataset.imageFileUrl ?? '';
      (attributes as ArticleLinkElement).imageFileId = el.dataset.imageFileId ?? '';
      break;
    }
    case 'key-quote': {
      (attributes as KeyQuoteElement).quote = el.dataset.quote ?? '';
      break;
    }
    case 'image': {
      const position = el.className.split('-')[1];
      const img = el.getElementsByTagName('img')[0];
      const author = el.getElementsByClassName('image-author')[0]?.textContent;
      const description = el.getElementsByClassName('image-description')[0]?.textContent;

      (attributes as ImageElement).imageId = el.getAttribute('data-image-id') ?? undefined;
      (attributes as ImageElement).imageUrl = img.getAttribute('src') ?? '';
      (attributes as ImageElement).position = position;
      (attributes as ImageElement).author = author ?? undefined;
      (attributes as ImageElement).description = description ?? undefined;
      break;
    }
    case 'definition-info': {
      (attributes as DefinitionInfoElement).title = el.dataset.title ?? '';
      (attributes as DefinitionInfoElement).definition = el.dataset.definition ?? '';
      break;
    }
    case 'ordered-list':
    case 'unordered-list':
      (attributes as ListElement).isNested = el.dataset?.isNestedList === 'true';
      break;
  }

  if (el.dataset?.indentLevel) {
    attributes.indentLevel = Number(el.dataset.indentLevel) || undefined;
  }

  if (el.style?.textAlign) {
    attributes.align = el.style.textAlign as TextAlign;
  }

  return attributes;
};

export const deserializeHTML = (
  el: HTMLElement,
  marks: CustomTextMarks = {},
): CustomDescendant[] | CustomDescendant | null => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', marks, el.textContent);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  let type = getElementTypeByHtmlElement(el);

  if (type === 'quote-with-photo') {
    const attrs = deserializeElementAttributes(el, type);
    return jsx('element', { type, ...attrs, readonly: true }, { text: el.dataset.quote });
  }

  if (type === 'article-link') {
    const attrs = deserializeElementAttributes(el, type);
    return jsx('element', { type, ...attrs, readonly: true }, { text: (attrs as ArticleLinkElement).linkText });
  }

  if (type === 'key-quote') {
    const attrs = deserializeElementAttributes(el, type);
    return jsx('element', { type, ...attrs, readonly: true }, { text: el.dataset.quote });
  }

  if (type === 'definition-info') {
    const attrs = deserializeElementAttributes(el, type);
    return jsx('element', { type, ...attrs, readonly: true }, { text: el.dataset.title });
  }

  const children: Array<CustomDescendant | CustomDescendant[]> = [];
  for (const node of el.childNodes.values()) {
    const nodeMarks = deserializeTextMarks(node as HTMLElement, { ...marks });
    const child = deserializeHTML(node as HTMLElement, nodeMarks);
    if (child) children.push(child);
  }

  if (el.nodeName === 'BODY') {
    return children.length
      ? jsx('fragment', {}, children)
      : [jsx('element', { type: 'paragraph' }, [jsx('text', {}, '')])];
  }

  if (!children.length) {
    children.push(jsx('text', {}, ''));
  }

  if (!type) {
    return children as CustomDescendant[];
  }

  if (type === 'paragraph' && el.dataset?.isCaption) {
    type = 'caption';
  }

  if (type === 'strong') {
    return jsx('text', { bold: true }, el.textContent);
  }

  if (type === 'em') {
    return jsx('text', { italic: true }, el.textContent);
  }

  if (type === 'q') {
    return jsx('text', { quote: true }, el.textContent);
  }

  return jsx('element', { type, ...deserializeElementAttributes(el, type) }, children);
};

export const deserialize = (htmlString: string): CustomDescendant[] => {
  try {
    const html = new DOMParser().parseFromString(htmlString, 'text/html');
    const elements = deserializeHTML(html.body);

    if (Array.isArray(elements)) {
      return elements;
    }

    throw new Error('Не удалось получить данные');
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [{ type: 'paragraph', children: [{ text: (e as Error)?.message ?? '' }] }];
  }
};
