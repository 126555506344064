import {
  usePartnerControllerFilterPartnersMutation,
  PartnerControllerFilterPartnersApiArg,
  PartnerFilterRequest,
} from '@api/admin/adminGeneratedApi';
import { DEFAULT_PARTNERS_SIZE } from '../defaultValues';

export const usePartners = () => {
  const query: PartnerControllerFilterPartnersApiArg = {
    skip: 0,
    size: DEFAULT_PARTNERS_SIZE,
    partnerFilterRequest: {},
  };

  const [getPartners, { data, isLoading, isSuccess, originalArgs }] = usePartnerControllerFilterPartnersMutation();

  const handleRequest = (
    args?: Omit<PartnerControllerFilterPartnersApiArg, 'partnerFilterRequest'> & {
      partnerFilterRequest: Partial<PartnerFilterRequest>;
    },
  ) => {
    const request: PartnerControllerFilterPartnersApiArg = {
      ...query,
      ...args,
      partnerFilterRequest: {
        ...args?.partnerFilterRequest,
      },
    };

    getPartners(request);
  };

  return {
    getPartners: handleRequest,
    partnersList: data?.data,
    paginate: data?.paginate || {
      totalCount: 0,
    },
    isLoading,
    isSuccess,
    originalArgs,
  };
};
