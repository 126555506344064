import { ReactNode } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Icon } from '@components/Icon';
import indents from '@styles/indents.module.scss';
import colors from '@styles/colors.module.scss';
import { TicketResponse } from '@api/admin/adminGeneratedApi';
import styles from './styles.module.scss';

export type TicketDetailsProps = {
  data: TicketResponse;
};

const fieldOptions: {
  field: string;
  title: string;
  formatter?: (value: any, record: TicketResponse) => ReactNode;
}[] = [
  {
    field: 'subject',
    title: 'Тема обращения',
  },
  {
    field: 'humanEmail',
    title: 'Email',
    formatter: (value) => (
      <Grid display="flex" flexDirection="row" gap={indents.xxs}>
        <Icon name="email" />
        <Typography variant="body2">{value}</Typography>
      </Grid>
    ),
  },
  {
    field: 'humanName',
    title: 'ФИО',
    formatter: (value) => {
      return (
        <Grid display="flex" flexDirection="row" gap={indents.xxs}>
          <Icon name="user" />
          <Typography variant="body2">{value}</Typography>
        </Grid>
      );
    },
  },
  //   {
  //     field: 'phone',
  //     title: 'Телефон',
  //     formatter: (value) => (
  //       <Grid display="flex" flexDirection="row" gap={indents.xxs}>
  //         <Icon name="mobile" />
  //         <Typography variant="body2">{value}</Typography>
  //       </Grid>
  //     ),
  //   },
  {
    field: 'text',
    title: 'Текст обращения',
    formatter: (value) => (
      <Grid display="flex" flexDirection="row" gap={indents.xxs}>
        <Icon name="request" />
        <Typography variant="body2">{value}</Typography>
      </Grid>
    ),
  },
  {
    field: 'answerText',
    title: 'Ответ',
    formatter: (value) => (
      <Grid display="flex" flexDirection="row" gap={indents.xxs}>
        <Icon name="comment" color={colors.red} />
        <Typography variant="body2">{value}</Typography>
      </Grid>
    ),
  },
];

export const TicketDetails = ({ data }: TicketDetailsProps) => {
  return (
    <Grid mt={5} display="flex" flexDirection="row" alignItems="flex-start" gap={indents.l}>
      <Stack flex={1} divider={<Divider className={styles.divider} />}>
        {fieldOptions.map(({ field, title, formatter }) => {
          if (!(field in data)) return null;
          const dataValue = data[field as keyof typeof data];

          return (
            <Box key={field} className={styles.row}>
              <Typography className={styles.rowTitle}>{title}:</Typography>
              <Typography variant="body2" color={dataValue ? colors.black : colors.grey}>
                {dataValue !== undefined ? (formatter ? formatter(dataValue, data) : (dataValue as any)) : 'Не указан'}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Grid>
  );
};
