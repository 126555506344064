import { FC, ChangeEvent } from 'react';
import { Stack, Avatar, Typography } from '@mui/material';

import { useUsersControllerUpdateCurrentUserPhotoMutation } from '@api/admin/adminGeneratedApi';
import { useUsersControllerGetCurrentUserQuery } from '@api/admin/adminApi';
import styles from './styles.module.scss';

interface PhotoProps {
  email: string;
  photo?: string;
}

export const Photo: FC<PhotoProps> = ({ email, photo }) => {
  const [updatePhoto] = useUsersControllerUpdateCurrentUserPhotoMutation();
  const { refetch: refetchCurrentUser } = useUsersControllerGetCurrentUserQuery();

  const onPhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append('file', file);

      await updatePhoto({ body: formData as any });
      await refetchCurrentUser();
    }
  };

  return (
    <Stack gap="20px" direction="row" alignItems="center">
      <Avatar src={photo} className={styles.avatar}>
        <Typography variant="h1" component="p">
          {email[0]}
        </Typography>
      </Avatar>
      <label htmlFor={email} className={styles.changePhotoBtn}>
        <Typography variant="body1">Изменить фото</Typography>

        <input
          name="file"
          id={email}
          type="file"
          onChange={onPhotoChange}
          accept="image/*"
          className={styles.changePhotoInput}
        />
      </label>
    </Stack>
  );
};
