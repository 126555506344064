import { Option } from '@components/Select/types';
import colors from '@styles/colors.module.scss';
import { PartnerType, PartnerTypeName } from './types';

export const DEFAULT_PARTNERS_SIZE = 50;

export const partnersStatusOptions: Option<PartnerType>[] = [
  { label: 'Новый', value: 'new' },
  { label: 'Скрытый', value: 'hidden' },
  { label: 'Опубликован', value: 'published' },
];

export const partnersAnchorOptions: Option[] = [
  { label: 'Якорный', value: 'true' },
  { label: 'Неякорный', value: 'false' },
];

export const statusName: Record<PartnerType, PartnerTypeName> = {
  new: 'Новый',
  hidden: 'Скрытый',
  published: 'Опубликован',
};

export const partnersStatusColors: Record<PartnerType, string> = {
  new: colors.blue,
  published: colors.success,
  hidden: colors.error,
};
