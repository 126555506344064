import { Icon } from '@components/Icon';
import { FC } from 'react';
import { Grid, Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormField } from '@components/FormField';
import { UserResponse, UserUpdaterDto, UsersControllerUpdateCurrentUserApiArg } from '@api/admin/adminGeneratedApi';
import indents from '@styles/indents.module.scss';
import { Photo } from './Photo';

import styles from './styles.module.scss';

interface FormProps {
  data: UserResponse;
  onSubmit: (user: UsersControllerUpdateCurrentUserApiArg) => void;
  isLoading: boolean;
  onClickChangePassword: () => void;
}

export const Form: FC<FormProps> = ({ data, onSubmit, isLoading, onClickChangePassword }) => {
  // TODO: add validation
  const form = useForm({ defaultValues: data });

  const onSubmitForm = form.handleSubmit((data) => {
    const dataUser: UserUpdaterDto = {
      metadata: data.metadata,
      nickname: data.nickname,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
    };

    onSubmit({ userUpdaterDto: dataUser });
  });

  return (
    <FormProvider {...form}>
      <Grid container direction="column" gap={indents.l} className={styles.form}>
        {/* changing email is not working, TODO: add this feature to api */}
        <Grid item>
          <Photo email={data.email} photo={data.avatar?.url} />
        </Grid>

        <Grid item>
          <FormField name="email" title="Email" titleVariant="body1" fullWidth disabled={isLoading} />
        </Grid>

        <Grid item>
          <FormField name="nickname" title="Nickname" titleVariant="body1" fullWidth disabled={isLoading} />
        </Grid>

        <Grid item>
          <Button type="button" variant="text" onClick={onClickChangePassword}>
            Изменить пароль
          </Button>
        </Grid>

        {/* TODO: add additional fields */}
        <Grid item>
          <Button
            startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
            variant="contained"
            onClick={onSubmitForm}
            disabled={isLoading}>
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
