import { ReactComponent as BoldIcon } from './bold.svg';
import { ReactComponent as ItalicIcon } from './italic.svg';
import { ReactComponent as UnderlineIcon } from './underline.svg';
import { ReactComponent as StrikethroughIcon } from './strikethrough.svg';

import { ReactComponent as SubScriptIcon } from './sub-script.svg';
import { ReactComponent as SupScriptIcon } from './sup-script.svg';

import { ReactComponent as AlignLeftIcon } from './align-left.svg';
import { ReactComponent as AlignCenterIcon } from './align-center.svg';
import { ReactComponent as AlignJustifyIcon } from './align-justify.svg';
import { ReactComponent as AlignRightIcon } from './align-right.svg';

import { ReactComponent as IndentIncreaseIcon } from './indent-increase.svg';
import { ReactComponent as IndentDecreaseIcon } from './indent-decrease.svg';

import { ReactComponent as ListOrderedIcon } from './list-ordered.svg';
import { ReactComponent as ListUnorderedIcon } from './list-unordered.svg';

import { ReactComponent as LinkIcon } from './link.svg';
import { ReactComponent as UnlinkIcon } from './unlink.svg';
import { ReactComponent as ImageIcon } from './image.svg';
import { ReactComponent as CodeIcon } from './code.svg';

import { ReactComponent as TableIcon } from './table.svg';

import { ReactComponent as UndoIcon } from './undo.svg';
import { ReactComponent as RedoIcon } from './redo.svg';

import { ReactComponent as QuotesIcon } from './quotes.svg';

import { ReactComponent as QuoteWithPhotoIcon } from './quote-with-photo.svg';
import { ReactComponent as ArticleLinkIcon } from './article-link.svg';
import { ReactComponent as KeyQuoteIcon } from './key-quote.svg';
import { ReactComponent as DefinitionIcon } from './definition.svg';

export const wysiwygIcons = {
  wysiwygBold: BoldIcon,
  wysiwygItalic: ItalicIcon,
  wysiwygUnderline: UnderlineIcon,
  wysiwygStrikethrough: StrikethroughIcon,

  wysiwygSubScript: SubScriptIcon,
  wysiwygSupScript: SupScriptIcon,

  wysiwygAlignLeft: AlignLeftIcon,
  wysiwygAlignCenter: AlignCenterIcon,
  wysiwygAlignJustify: AlignJustifyIcon,
  wysiwygAlignRight: AlignRightIcon,

  wysiwygIndentIncrease: IndentIncreaseIcon,
  wysiwygIndentDecrease: IndentDecreaseIcon,

  wysiwygListOrdered: ListOrderedIcon,
  wysiwygListUnordered: ListUnorderedIcon,

  wysiwygLink: LinkIcon,
  wysiwygUnlink: UnlinkIcon,
  wysiwygImage: ImageIcon,
  wysiwygCode: CodeIcon,

  wysiwygTable: TableIcon,

  wysiwygUndo: UndoIcon,
  wysiwygRedo: RedoIcon,

  wysiwygQuotes: QuotesIcon,

  wysiwygQuoteWithPhoto: QuoteWithPhotoIcon,
  wysiwygArticleLInk: ArticleLinkIcon,
  wysiwygKeyQuote: KeyQuoteIcon,
  wysiwygDefinition: DefinitionIcon,
};
