import { CustomEditor, ImageElement } from '@area/redactor/components/Wysiwyg/types';
import { Range, Transforms, Location } from 'slate';
import { ReactEditor } from 'slate-react';
import { ImageFormData } from '@area/redactor/components/Wysiwyg/components/Toolbar/components/Image/ImageForm/ImageForm';

export const addImage = (editor: CustomEditor, formData: ImageFormData, selection?: Location) => {
  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const image: ImageElement = {
    type: 'image',
    imageUrl: formData.imageUrl || '',
    imageId: formData.imageId,
    position: formData.position.value,
    author: formData.author,
    description: formData.description,
    children: [{ text: formData.imageUrl || '' }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [image, { type: 'paragraph', children: [{ text: '' }] }], {
      at: selection,
    });
  } else {
    Transforms.wrapNodes(editor, image, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const deleteImage = (editor: CustomEditor, element: ImageElement): void => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.delete(editor, { at: path });
};

export const insertImage = (editor: CustomEditor, formData: ImageFormData, selection?: Location) => {
  if (editor.selection) {
    addImage(editor, formData, selection);
  }
};
