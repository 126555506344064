import { SettingResponse } from '@api/admin/adminGeneratedApi';
import { SettingType } from '@area/settings/types';
import { DateService } from '@services/DateService';

export const ValueCell = (value: SettingResponse['value'], { type }: SettingResponse) => {
  if (!value) return '';

  switch (type) {
    case SettingType.datetime:
      return DateService.getLocalDate(new Date(value), DateService.dateFormats.date);
    case SettingType.boolean:
      return value === 'true' ? 'Вкл.' : 'Выкл.';
    default:
      return value;
  }
};
