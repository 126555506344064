import { ReactNode } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@components/Icon';

import styles from './styles.module.scss';

interface HeaderProps {
  title: string;
  backBtnRoute?: string;
  backBtnTitle?: string;
  onBack?: () => void;
  actions?: ReactNode;
}

export const Header = ({ title, backBtnTitle, backBtnRoute, onBack, actions }: HeaderProps) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (backBtnRoute) {
      navigate(backBtnRoute);
      return;
    }

    onBack?.();
  };

  return (
    <Box component="header" className={styles.root}>
      {backBtnTitle && (backBtnRoute || onBack) && (
        <Box className={styles.backButton}>
          <ButtonBase onClick={onClick}>
            <Icon name="chevronLeft" className={styles.backButtonIcon} />
            <Typography variant="body2">{backBtnTitle}</Typography>
          </ButtonBase>
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h1">{title}</Typography>
        {actions && (
          <Box display="flex" alignItems="center">
            {actions}
          </Box>
        )}
      </Box>
    </Box>
  );
};
