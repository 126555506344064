import { MediaFileResponse, NewsRequest } from '@api/admin/adminGeneratedApi';
import { Option } from '@components/Select/types';
import colors from '@styles/colors.module.scss';
import { IconName } from '@components/Icon/types';
import dayjs from 'dayjs';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { PublicationType } from './types';

export const mockPublicationTypes: Option<PublicationType>[] = [
  { label: 'Новость', value: 'news' },
  { label: 'Лонгрид', value: 'longRead' },
];

export interface MediaFileParams extends Partial<MediaFileResponse> {
  file: File;
}

export type FormPublicationValues = Omit<NewsRequest, 'type' | 'editorContent' | 'startDate' | 'dateEnd'> & {
  type: Option<PublicationType> | string;
  editorContent: CustomDescendant[] | string;
  startDate: dayjs.Dayjs | string;
  newsTagIds?: Option[];
  authorIds?: Option[];
  mainImage?: MediaFileParams;
  snippetPhoto?: MediaFileParams;
  photoGallery?: MediaFileParams[];
};

export const defaultPublicationValues: FormPublicationValues = {
  published: true,
  startDate: dayjs(),
  title: '',
  url: '',
  type: '',
  editorContent: '',
  newsTagIds: [],
  authorIds: [],
  metaTags: [],
  embedded: '',
};

export const sharedPublicationFieldNames = ['photo', 'fullTitle', 'title'];

export const publicationTypeColors: Record<PublicationType, string> = {
  news: colors.greyLight,
  longRead: colors.lightRed,
};

export const DEFAULT_PUBLICATIONS_SIZE = 50;

export const PUBLICATIONS_ICONS: IconName[] = ['link', 'delete'];
