import { createSlice } from '@reduxjs/toolkit';
import { StorageState } from '@store/index';
import { getLocalStorageObject } from '@area/localstorage/localStorage';
import { LocalStorageKeys } from '@area/localstorage/constants';

interface Sidebar {
  open: boolean;
}

const initialState: Sidebar = {
  open: getLocalStorageObject<boolean>(LocalStorageKeys.SidebarState) ?? true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.open = !state.open;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export const sidebarSelector = (state: StorageState) => state.sidebarSlice;
