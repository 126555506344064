import { FC } from 'react';
import { TablePagination } from '@mui/material';

import { PaginationProps } from './types';

export const Pagination: FC<PaginationProps> = ({ settings, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
  return (
    <TablePagination
      component="div"
      count={settings?.count || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      labelRowsPerPage={settings?.labelRowsPerPage}
    />
  );
};
