import { Box, Button, ButtonBase, Grid, Typography, LinearProgress } from '@mui/material';
import cn from 'classnames';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { Routes } from '@navigation/routes';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Icon } from '@components/Icon';
import { FormField } from '@components/FormField';
import { usePageTitle } from '@hooks/usePageTitle';
import { useAuthControllerLoginMutation } from '@api/admin/adminApi';
import { useDispatch } from 'react-redux';
import { setOtpData } from '@area/user/store';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';
import styles from './style.module.scss';

export type LoginFormData = {
  login: string;
  password: string;
};

const validationSchema = yup.object<LoginFormData>({
  login: yup.string().required('Обязательное поле.').email('Не корректный формат электронной почты.'),
  password: yup.string().required('Обязательное поле.'),
});

export const LoginPage = () => {
  usePageTitle('Вход');

  const [showPassword, setShowPassword] = useState(false);
  const [httpError, setHttpError] = useState('');
  const [onLogin, { isLoading }] = useAuthControllerLoginMutation();
  const dispatch = useDispatch();

  const { login } = useAuthHelpers();

  const resolver = useYupValidationResolver<LoginFormData>(validationSchema);
  const form = useForm<LoginFormData>({
    defaultValues: {
      login: process.env.REACT_APP_TEST_USER_LOGIN ?? '',
      password: process.env.REACT_APP_TEST_USER_PASSWORD ?? '',
    },
    resolver,
  });

  const navigate = useNavigate();

  const isPasswordHas = Boolean(form.watch('password').length);

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await onLogin({
        loginDto: {
          email: data.login,
          password: data.password,
        },
      }).unwrap();

      if (res.accessToken) {
        return login(res);
      }

      dispatch(
        setOtpData({
          email: data.login,
          secret: res.otpSecret,
        }),
      );

      navigate(buildRoutePath(res.isConfirmed ? Routes.otp : Routes.confirm));
    } catch (e: any) {
      setHttpError(e?.data?.message ?? 'неизвестная ошибка');
    }
  });
  const onShowPasswordClick = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormProvider {...form}>
      <Grid container flexDirection="column" gap={4} className={styles.root}>
        <Grid item>
          <Typography variant="h1" textAlign="center">
            Вход
          </Typography>
          <Box className={cn(styles.httpError, !httpError && styles.disabled)}>
            <Typography variant="caption" className={styles.httpErrorTitle}>
              Ошибка.
            </Typography>
            <Typography variant="caption">{httpError}</Typography>
          </Box>
        </Grid>
        <Grid item container flexDirection="column" gap={3}>
          <Grid item display="flex" alignItems="center">
            <Typography variant="body1" textAlign="center" className={styles.fieldLabel}>
              Имя
            </Typography>
            <FormField className={styles.field} name="login" placeholder="Email" disabled={isLoading} />
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Icon name="key" width={32} height={32} className={styles.fieldLabel} />
              <FormField
                className={styles.field}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Пароль"
                disabled={isLoading}
                showEyeIcon={false}
              />
              <Icon name="keyHole" width={32} height={32} className={styles.keyHole} />
            </Box>
            <ButtonBase
              disableRipple
              className={cn(styles.showPassButton, !isPasswordHas && styles.hide)}
              onClick={onShowPasswordClick}>
              <Typography variant="caption">Показать пароль</Typography>
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid item>
          <Button className={styles.submit} onClick={onSubmit} variant="contained" disabled={isLoading}>
            Войти
          </Button>
        </Grid>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
    </FormProvider>
  );
};
