import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Stack from '@mui/material/Stack';
// import IconButton from '@mui/material/IconButton';
// import { Icon } from '@components/Icon';
// import { DateService } from '@services/DateService';
// import indents from '@styles/indents.module.scss';
import colors from '@styles/colors.module.scss';

export interface StudentNameEcpProps {
  name?: string;
  lastECPUpdate?: string;
}

export const StudentNameEcp = ({ name, lastECPUpdate }: StudentNameEcpProps) => {
  return (
    <Grid display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h2" color={colors.red}>
        {name ?? '—'}
      </Typography>
      {/* <Stack direction="row" alignItems="center" gap={indents.xs}>
        <Typography variant="body1" color={colors.grey}>
          Данные из ЕЦП получены:
        </Typography>
        <Typography variant="body2" color={colors.grey}>
          {lastECPUpdate ? DateService.getLocalDate(new Date(lastECPUpdate), DateService.dateFormats.dateTime) : '—'}
        </Typography>
        <IconButton color="primary">
          <Icon name="refresh" width={24} />
        </IconButton>
      </Stack> */}
    </Grid>
  );
};
