import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { usePageTitle } from '@hooks/usePageTitle';
import { ConfirmOtp } from '@area/user/components/ConfirmOtp';
import colors from '@styles/colors.module.scss';
import indents from '@styles/indents.module.scss';
import { ConfirmSuccess } from '@area/user/components/ConfirmSuccess';
import { LoginResponseDto } from '@api/admin/adminGeneratedApi';
import { otpSelector } from '@area/user/store';
import { useSelector } from 'react-redux';

export const ConfirmPage = () => {
  usePageTitle('Активация аккаунта');

  const otp = useSelector(otpSelector);
  const [loginResponse, setLoginResponse] = useState<LoginResponseDto | null>(null);
  const title = loginResponse ? 'Ваш аккаунт активирован успешно' : 'Активация аккаунта';

  return (
    <Grid container flexDirection="column" gap={indents.l}>
      <Grid item>
        <Typography variant="h1" textAlign="center">
          {title}
        </Typography>
        {!loginResponse && (
          <Typography component="div" variant="body2" textAlign="center" color={colors.grey}>
            {otp?.email ?? ''}
          </Typography>
        )}
      </Grid>
      {!loginResponse ? <ConfirmOtp onSuccess={setLoginResponse} /> : <ConfirmSuccess loginResponse={loginResponse} />}
    </Grid>
  );
};
