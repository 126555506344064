import { FC, ReactNode } from 'react';
import { Divider, Box, Stack, Typography } from '@mui/material';
import { LoadingSpinner } from '@components/LoadingSpinner';

import { DateService } from '@services/DateService';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { useGetHumanEskCard } from '@area/student/hooks/useGetHumanEskCard';
import { useParams } from 'react-router';
import { EskCardResponse } from '@api/admin/adminGeneratedApi';
import styles from './styles.module.scss';

interface IFieldOptions {
  fieldName: keyof EskCardResponse;
  title: string;
  formatter?: (value: string | boolean) => ReactNode;
}

const fieldOptions: IFieldOptions[] = [
  {
    fieldName: 'active',
    title: 'Выпущено',
    formatter: (value) => <Icon name={value ? 'check' : 'close'} color={value ? colors.success : colors.red} />,
  },
  {
    fieldName: 'number',
    title: 'Номер карты',
  },
  {
    fieldName: 'dateStart',
    title: 'Дата выдачи',
    formatter: (value) => (value ? DateService.getLocalDate(value as string, 'DD.MM.YYYY') : '-'),
  },
  {
    fieldName: 'dateEnd',
    title: 'Действует до',
    formatter: (value) => (value ? DateService.getLocalDate(value as string, 'DD.MM.YYYY') : '-'),
  },
];

export const ESK: FC = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetHumanEskCard(id!);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Typography>Ошибка загрузки данных</Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box>
        <Typography>Нет данных</Typography>
      </Box>
    );
  }

  return (
    <Stack divider={<Divider className={styles.divider} />}>
      {fieldOptions.map(({ fieldName, title, formatter }) => {
        if (!(fieldName in data)) return null;

        return (
          <Box key={fieldName} className={styles.row}>
            <Typography className={styles.rowTitle}>{title}:</Typography>
            <Typography variant="body2" color={data[fieldName] ? colors.black : colors.grey}>
              {data[fieldName] !== undefined ? (formatter ? formatter(data[fieldName]) : data[fieldName]) : 'Не указан'}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
