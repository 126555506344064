import { useMemo, useRef } from 'react';
import { Typography } from '@mui/material';
import { FooterLinkResponse } from '@api/admin/adminGeneratedApi';
import { Column } from '@components/Table/types';
import { useFooterLinksControllerGetQuery } from '@api/admin/adminApi';
import { EditModalHandlers } from '@area/footerLinks/components/EditModal/types';
import { ActionsCell } from '@area/footerLinks/components/ActionsCell';
import styles from '../styles.module.scss';

export const useFooterLinks = () => {
  const modalRef = useRef<EditModalHandlers>(null);
  const links = useFooterLinksControllerGetQuery();

  const columns = useMemo<Column<FooterLinkResponse>[]>(
    () => [
      {
        title: 'Название',
        dataIndex: 'title',
        render: (value: string) => (
          <Typography variant="body2" className={styles.title}>
            {value}
          </Typography>
        ),
      },
      {
        title: 'Ссылка',
        dataIndex: 'url',
        ellipsis: true,
      },
      {
        title: 'Ссылка на иконку',
        dataIndex: 'imageUrl',
        ellipsis: true,
      },
      {
        title: 'Порядок',
        dataIndex: 'order',
        width: 90,
      },
      {
        title: '',
        dataIndex: 'action',
        align: 'right',
        width: 80,
        render: (_: unknown, record) => <ActionsCell link={record} modalRef={modalRef} />,
      },
    ],
    [],
  );

  return {
    modalRef,
    links,
    columns,
  };
};
