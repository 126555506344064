import { HumansFilterRequest } from '@api/admin/adminGeneratedApi';
import { StatusSelectValue } from '../defaultValues';

export const getFilterParamsFromStatusSelectValue = (
  value?: StatusSelectValue,
): Pick<HumansFilterRequest, 'isActive' | 'isStudent'> => {
  if (!value) return {};

  if (value === StatusSelectValue.ACTIVE || value === StatusSelectValue.NOT_ACTIVE) {
    return { isActive: value === StatusSelectValue.ACTIVE };
  }

  return {
    isActive: true,
    isStudent: value === StatusSelectValue.ACTIVE_STUDENT,
  };
};
