import { Box } from '@mui/material';
import { Icon } from '@components/Icon';
import { UserRole, userRoleDictionary } from '@area/users/roles';
import colors from '@styles/colors.module.scss';
import { Column } from '@components/Table/types';
import { UserResponse } from '@api/admin/adminGeneratedApi';
import { DeleteButton } from './DeleteButton';

export const getColumns = (refetchUsers: () => void): Column<UserResponse>[] => [
  {
    title: 'Статус',
    align: 'center',
    dataIndex: 'isActive',
    width: '72px',
    render: (value: boolean) => (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
      </Box>
    ),
  },
  {
    title: 'ФИО',
    dataIndex: 'firstName',
    sx: { whiteSpace: 'normal' },
    render: (_: string, { firstName, middleName, lastName }: UserResponse) => {
      let fio = lastName ?? '';
      if (firstName) fio += ` ${firstName}`;
      if (middleName) fio += ` ${middleName}`;

      return fio;
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sx: { whiteSpace: 'normal' },
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    sx: { whiteSpace: 'normal' },
    render: (roles: UserRole[]) => roles.map((role) => <div key={role}>{userRoleDictionary[role]}</div>),
  },
  {
    title: '',
    dataIndex: 'id',
    width: '90px',
    render: (id: string) => <DeleteButton id={id} refetchUsers={refetchUsers} />,
  },
];
