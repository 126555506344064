import * as yup from 'yup';

import { FormDataValues } from '../types';

export const validationSchema = yup.object<FormDataValues>({
  name: yup.string().required('Обязательное поле.'),
  description: yup.string().required('Обязательное поле.'),
  discount: yup.string().required('Обязательное поле.'),
  categories: yup.array().min(1, 'Укажите хотя бы одну категорию'),
});
