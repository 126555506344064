import { CreateForm } from '../components/CreateForm';
import { UpdateFormDataValues } from '../types';

export const CreatePartnerView = () => {
  return (
    <>
      <CreateForm<UpdateFormDataValues> />
    </>
  );
};
