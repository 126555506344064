import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { ApplicationResponse } from '@api/admin/adminGeneratedApi';
import { Routes } from '@navigation/routes';
import { useNavigate } from 'react-router';
import { ReactComponent as NoInvolvedIcon } from './assets/no-involved.svg';
import { ReactComponent as InvolvedIcon } from './assets/involved.svg';

interface Props {
  title: string;
  involved?: boolean;
  application?: ApplicationResponse;
}
export const ProgramStatusItem: FC<Props> = ({ application, title, involved }) => {
  const navigate = useNavigate();
  const goToApplication = () => {
    if (application) {
      navigate(`/${Routes.applications}/${application.type}/${application.id}`);
    }
  };
  return (
    <Box
      sx={{ cursor: `${involved ? 'pointer' : 'auto'}` }}
      border={`${involved ? 2 : 1}px solid`}
      borderColor={involved ? colors.red : colors.black}
      borderRadius={2}
      padding={'20px'}
      margin={2}
      display={'flex'}
      flex={'1'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-evenly'}
      onClick={() => goToApplication()}>
      <Typography m={'auto'} mb={4} textAlign={'center'}>
        {title}
      </Typography>
      {involved ? (
        <InvolvedIcon style={{ marginTop: 'auto', marginBottom: '5px' }} />
      ) : (
        <NoInvolvedIcon style={{ marginTop: 'auto', marginBottom: '5px' }} />
      )}
      <Typography textAlign={'center'}>{involved ? 'Участвует' : 'Не участвует'}</Typography>
    </Box>
  );
};
