import * as yup from 'yup';
import { UserUpdateForm } from '@area/users/type';

export const updateUserValidation: yup.Schema<UserUpdateForm> = yup.object({
  firstName: yup.string().optional(),
  middleName: yup.string().optional(),
  lastName: yup.string().optional(),
  email: yup.string().email('Неверный email').required('Обязательное поле.'),
  isActive: yup.boolean(),
});
