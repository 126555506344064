import { generatePath } from 'react-router-dom';
import { KampusRoute } from './routes';

export interface BuildRoutePathOptions<T = Record<string, any>> {
  params?: Parameters<typeof generatePath>[1];
  search?: T;
  absolute?: boolean;
}

export const buildRoutePath = <T extends Record<string, any>>(
  route: KampusRoute | KampusRoute[],
  { params = {}, search = {} as T, absolute = false }: BuildRoutePathOptions<T> = {},
) => {
  let rawPath = generatePath(Array.isArray(route) ? route.join('/') : route, params as any);
  let path = rawPath.startsWith('/') ? rawPath.slice(1) : rawPath;

  const searchParams = Object.entries(search);
  if (searchParams.length) {
    path = `${path}?`;

    for (let i = 0; i < searchParams.length; i++) {
      const [key, value] = searchParams[i];
      if (!value) continue;

      path = `${path}${key}=${value}${i + 1 < searchParams.length ? '&' : ''}`;
    }
  }

  return `${absolute ? '/' : ''}${path}`;
};
