import { Grid, Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { getUserRoleOptions } from '@area/users/roles';
import { newUserValidation } from '@area/users/validation/newUserValidation';
import { UserCreateForm } from '@area/users/type';
import { Routes } from '@navigation/routes';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useCreateUser } from '@area/users/hooks/useCreateUser';
import { useEffect } from 'react';
import { useCurrentUserRoles } from '@area/user/hooks/useCurrentUserRoles';
import styles from './style.module.scss';

export const Form = () => {
  const navigate = useNavigate();
  const resolver = useYupValidationResolver<UserCreateForm>(newUserValidation);
  const form = useForm({ defaultValues: {}, resolver });
  const { handlePushItem } = useNotifications();
  const { handleCreate, result } = useCreateUser();
  const { roles } = useCurrentUserRoles();

  useEffect(() => {
    if (!result.isSuccess) return;

    handlePushItem({ severity: 'success', text: 'Новый пользователь создан' });
    navigate(
      buildRoutePath([Routes.users, Routes.id], {
        params: { id: result.data.id },
        absolute: true,
      }),
    );
  }, [result.isSuccess, handlePushItem]);

  return (
    <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
      <FormProvider {...form}>
        <Grid item>
          <FormField name="lastName" title="Фамилия" className={styles.fieldWidth} />
        </Grid>
        <Grid item>
          <FormField name="firstName" title="Имя" className={styles.fieldWidth} />
        </Grid>
        <Grid item>
          <FormField name="middleName" title="Отчество" className={styles.fieldWidth} />
        </Grid>
        <Grid item>
          <FormField name="email" title="Email" className={styles.fieldWidth} />
        </Grid>
        <Grid item>
          <FormField
            name="role"
            type="select"
            placeholder="Выберите роль"
            title="Роль в системе"
            options={getUserRoleOptions(roles)}
            multiple
            className={styles.fieldWidth}
          />
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
            onClick={form.handleSubmit(handleCreate)}>
            Сохранить
          </Button>
        </Grid>
      </FormProvider>
    </Grid>
  );
};
