import { ThemeProvider } from '@mui/material/styles';
import { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { ruRU } from '@mui/x-date-pickers';
import ru from 'dayjs/locale/ru';
import { CssBaseline } from '@mui/material';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary';
import { Content } from '@navigation/content';
import { Root } from '@layouts/Root';
import { theme } from './theme';
import { store } from './store';

const ruLocale = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

extend(utc);
extend(isBetweenPlugin);

export const App = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ru} localeText={ruLocale}>
              <Root>
                <Content />
              </Root>
            </LocalizationProvider>
            <CssBaseline />
          </ThemeProvider>
        </StoreProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
