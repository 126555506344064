import { FC } from 'react';
import { Stack, Skeleton } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

interface UserShortCardSkeletonProps {
  openSidebar: boolean;
}

export const UserShortCardSkeleton: FC<UserShortCardSkeletonProps> = ({ openSidebar }) => (
  <Stack direction="row" gap="8px" className={styles.wrapper}>
    <Skeleton variant="circular" width={32} height={32} className={cn(styles.skeleton, { short: !openSidebar })} />
    <Skeleton variant="text" className={cn(styles.skeleton, { text: true })} />
  </Stack>
);
