import { FC } from 'react';
import cn from 'classnames';
import { TextField as MuiTextField } from '@mui/material';
import { FieldsTextFieldProps } from '@mui/x-date-pickers/internals';

import styles from './styles.module.scss';

export const TextField: FC<FieldsTextFieldProps & { error?: boolean }> = (props) => {
  return <MuiTextField {...props} className={cn(props.className, styles.textField)} />;
};
