import { FC } from 'react';
import { Stack, Skeleton, Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';

interface FormSkeletonProps {
  rowsNumber?: number;
}

export const FormSkeleton: FC<FormSkeletonProps> = ({ rowsNumber = 5 }) => (
  <Grid container direction="column" gap={indents.l}>
    <Grid item>
      <Stack gap="20px" direction="row" alignItems="center">
        <Skeleton variant="circular" width={100} height={100} />

        <Skeleton variant="text" sx={{ flexGrow: 1 }} />
      </Stack>
    </Grid>

    {Array(rowsNumber)
      .fill(0)
      .map((_, index) => (
        <Grid item key={index}>
          <Stack gap={indents.xxs}>
            <Skeleton variant="text" width={150} />

            <Skeleton variant="rounded" height={40} />
          </Stack>
        </Grid>
      ))}
    <Grid item>
      <Skeleton variant="rounded" width={100} height={40} />
    </Grid>
  </Grid>
);
