import { useEffect, useMemo, useRef } from 'react';
import { useNsiSyncControllerFilterSyncDataLogsMutation } from '@api/admin/adminApi';
import { useQueryParams } from '@hooks/useQueryParams';
import {
  NsiOperationType,
  NsiSyncControllerFilterSyncDataLogsApiArg,
  NsiSyncControllerFilterSyncDataLogsApiResponse,
} from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';

const getApiArgFromParams = (params: { [key: string]: string }): NsiSyncControllerFilterSyncDataLogsApiArg => {
  const operationType = params.operationType
    ? (decodeURIComponent(params.operationType).split(',') as NsiOperationType[])
    : undefined;

  return {
    skip: (Number(params.page) - 1) * Number(params.size),
    size: Number(params.size),
    syncDataFilterRequestDto: {
      operationType,
      dateFrom: DateService.getDateFromParams(params.dateFrom),
      dateTo: DateService.getDateFromParams(params.dateTo),
      search: params.search,
    },
  };
};

export const useFilterNsiLogs = () => {
  const prevData = useRef<Required<NsiSyncControllerFilterSyncDataLogsApiResponse>>({
    data: [],
    paginate: { totalCount: 0 },
  });
  const { params } = useQueryParams();

  const [fetch, { data, isLoading, isSuccess, isError }] = useNsiSyncControllerFilterSyncDataLogsMutation();
  const logs = useMemo(() => {
    prevData.current.data = data?.data ?? prevData.current.data;
    prevData.current.paginate.totalCount = data?.paginate?.totalCount ?? prevData.current.paginate.totalCount;

    return prevData.current;
  }, [data?.data, data?.paginate?.totalCount]);

  useEffect(() => {
    fetch(getApiArgFromParams(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, params.size, params.page, params.operationType, params.search, params.dateFrom, params.dateTo]);

  return {
    logs: logs.data,
    count: logs.paginate.totalCount,
    isLoading,
    isFetched: !logs.data.length && (isError || isSuccess),
  };
};
