import { FC, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Modal } from '@components/Modal';
import { FooterLinkResponse } from '@api/admin/adminGeneratedApi';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useFooterLinksControllerCreateMutation, useFooterLinksControllerUpdateMutation } from '@api/admin/adminApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { validationSchema } from '@area/footerLinks/components/EditModal/validation';
import { EditModalProps, EditModalFormData } from './types';

export const EditModal: FC<EditModalProps> = ({ modalRef }) => {
  const { handlePushItem } = useNotifications();
  const [open, setOpen] = useState(false);
  const [create] = useFooterLinksControllerCreateMutation();
  const [update] = useFooterLinksControllerUpdateMutation();
  const form = useForm<EditModalFormData>({ resolver: useYupValidationResolver(validationSchema) });
  const id = form.watch('id');

  useImperativeHandle(modalRef, () => ({
    open: (link?: FooterLinkResponse) => {
      form.reset({
        title: link?.title ?? '',
        url: link?.url ?? '',
        imageUrl: link?.imageUrl ?? '',
        order: link?.order ?? 1,
        id: link?.id ?? null,
      });

      setOpen(true);
    },
  }));

  const onClose = () => {
    setOpen(false);
    form.reset();
  };

  const onSubmit = form.handleSubmit(async ({ id, ...data }) => {
    try {
      if (!id) {
        await create({ footerLinkCreatorDto: data }).unwrap();
      } else {
        await update({ id, footerLinkUpdaterDto: data }).unwrap();
      }

      handlePushItem({ severity: 'success', text: `Ссылка успешно ${id ? 'обновлена' : 'создана'}` });
      onClose();
    } catch (e) {
      handlePushItem({ severity: 'error', text: `Не удалось ${id ? 'обновить' : 'создать'} ссылку` });
    }
  });

  return (
    <Modal
      open={open}
      title={id ? 'Изменение подвальной ссылки' : 'Создание подвальной ссылки'}
      maxWidth="sm"
      onClose={onClose}
      actions={
        <Stack direction="row" gap={indents.s}>
          <Button variant="outlined" onClick={onClose} disabled={form.formState.isSubmitting}>
            Отмена
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={form.formState.isSubmitting || !form.formState.isDirty}>
            Сохранить
          </Button>
        </Stack>
      }>
      <FormProvider {...form}>
        <Stack gap={indents.m}>
          <FormField<EditModalFormData> name="title" placeholder="Введите название" title="Название" fullWidth />
          <FormField<EditModalFormData> name="url" placeholder="Введите ссылку" title="URL" fullWidth />
          <FormField<EditModalFormData>
            name="imageUrl"
            placeholder="Введите ссылку на логотип"
            title="URL логотипа"
            fullWidth
          />
          <FormField<EditModalFormData>
            name="order"
            type="number"
            placeholder="Введите номер порядка"
            title="Порядок"
            fullWidth
          />
        </Stack>
      </FormProvider>
    </Modal>
  );
};
