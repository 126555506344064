import { FormField } from '@components/FormField';
import { Modal } from '@components/Modal';
import { Button, Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export interface KeyQuoteFormData {
  quote: string;
}

export interface KeyQuoteFormProps {
  initialFormData?: KeyQuoteFormData;
  onSubmit: SubmitHandler<KeyQuoteFormData>;
  onClose: () => void;
}

export const KeyQuoteForm: FC<KeyQuoteFormProps> = (props) => {
  const { onClose, onSubmit, initialFormData } = props;

  const form = useForm<KeyQuoteFormData>({
    defaultValues: initialFormData,
  });

  const { handleSubmit } = form;

  const submit = handleSubmit(onSubmit);

  return (
    <Modal
      open
      maxWidth="xs"
      onClose={onClose}
      title={initialFormData ? 'Редактирование цитаты' : 'Добавление цитаты'}
      actions={
        <>
          <Button onClick={onClose}>Отмена</Button>
          <Button variant="contained" onClick={submit}>
            Принять
          </Button>
        </>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.m}>
          <Grid>
            <FormField type="text" name="quote" title="Цитата" fullWidth />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
