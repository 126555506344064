import { Option, SelectProps } from '@components/Select/types';
import { useNewsTagsControllerCreateNewsTagMutation } from '@api/admin/adminApi';
import { useRubricsOptions } from './useRubrics';

export const useRubricsSelectOptions = (): Pick<
  SelectProps,
  'options' | 'onClickAddNewIfNotMatch' | 'addNewIfNotMatchLabel'
> => {
  const { options } = useRubricsOptions();

  const [createNewsTag] = useNewsTagsControllerCreateNewsTagMutation();

  const onClickAddNewRubric: SelectProps['onClickAddNewIfNotMatch'] = async (value: string): Promise<Option> => {
    const newsTag = await createNewsTag({
      newsTagRequest: {
        title: value,
      },
    }).unwrap();
    return {
      label: newsTag.title,
      value: newsTag.id,
    };
  };

  return {
    onClickAddNewIfNotMatch: onClickAddNewRubric,
    options,
    addNewIfNotMatchLabel: (value) => `Ничего не найдено, добавить новую рубрику "${value}"?`,
  };
};
