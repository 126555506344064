import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useParticipantsHumansControllerGetHumanTicketsAndMailingQuery } from '@api/admin/adminApi';
import { LoadingSpinner } from '@components/LoadingSpinner';
import Divider from '@mui/material/Divider';
import { DateService } from '@services/DateService';
import { Link } from 'react-router-dom';
import { Routes } from '@navigation/routes';
import { buildRoutePath } from '@navigation/buildRoutePath';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { Tag } from '@components/Tag';
import { Fragment, useCallback } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export const TicketsMailingHistory = ({ id }: { id: string }) => {
  const { data, isLoading } = useParticipantsHumansControllerGetHumanTicketsAndMailingQuery({ id });

  const onRowClick = (id: string) => {
    window.open(buildRoutePath([Routes.tickets, Routes.id], { absolute: true, params: { id } }), '_blank');
  };

  return (
    <Stack gap="20px">
      <Typography variant="h2">История обращений</Typography>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TableContainer className={styles.tableContainer}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ width: 72, whiteSpace: 'nowrap' }}>
                    <Typography variant="body2">Тип</Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ width: 200, whiteSpace: 'nowrap' }}>
                    <Typography variant="body2">Дата создания</Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                    <Typography variant="body2">Тема</Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ width: 200, whiteSpace: 'nowrap' }}>
                    <Typography variant="body2">Статус</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => (
                  <Fragment key={item.id}>
                    <TableRow
                      className={cn([
                        styles.row,
                        { [styles.withSubRow]: !!item.text, [styles.canClick]: item.isTicket },
                      ])}
                      onClick={item.isTicket ? () => onRowClick(item.id) : undefined}>
                      <TableCell align="left" sx={{ width: 72, whiteSpace: 'nowrap' }}>
                        <Box display="flex" alignItems="center">
                          <Icon
                            name={item.isTicket ? 'request' : 'bubble'}
                            color={item.isTicket ? colors.black : colors.red}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="left" sx={{ width: 200, whiteSpace: 'nowrap' }}>
                        <Typography variant="body2" component="p">
                          {DateService.getLocalDate(item.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                        <Typography variant="body1" component="p">
                          {item.subject}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ width: 200, whiteSpace: 'nowrap' }}>
                        {item.isTicket && (
                          <Tag
                            label={item.resolved ? 'Решено' : 'Новое'}
                            sx={{
                              width: 84,
                              background: item.resolved ? colors.success : colors.lightRed,
                              color: item.resolved ? colors.white : colors.black,
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    {item.text && (
                      <TableRow
                        onClick={item.isTicket ? () => onRowClick(item.id) : undefined}
                        className={cn([styles.textRow, { [styles.canClickTextRow]: item.isTicket }])}>
                        <TableCell />
                        <TableCell align="left" colSpan={3}>
                          <Typography variant="body2" component="p" dangerouslySetInnerHTML={{ __html: item.text }} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            {!data?.length && (
              <Box className={styles.noData}>
                <Typography variant="body1">Нет данных</Typography>
              </Box>
            )}
          </TableContainer>
        </>
      )}
    </Stack>
  );
};
