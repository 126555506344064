import { useNewsControllerUpdateNewsMutation } from '@api/admin/adminGeneratedApi';
import { FormPublicationValues } from '../defaultValues';
import { PublicationMapper } from '../mappers';

export const useUpdatePublication = (id: string) => {
  const [updatePublication, resultOfUpdate] = useNewsControllerUpdateNewsMutation();

  const handleUpdate = (values: FormPublicationValues) => {
    updatePublication({
      id,
      newsRequest: PublicationMapper.formModelToApiModel(values),
    });
  };

  return {
    updatePublication: handleUpdate,
    resultOfUpdate,
  };
};
