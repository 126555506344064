import colors from '@styles/colors.module.scss';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { useParams } from 'react-router';
import { useDownloadFile } from '@hooks/useDownloadFile';
import styles from './styles.module.scss';

export const PaymentsByAllCandidates = () => {
  const { id } = useParams();
  const url = `/api/payments/${id}/download-approved-candidates-csv`;
  const { isDownloading, download } = useDownloadFile(url, `payment_result.csv`);

  return (
    <Box sx={{ border: `1px solid ${colors.greyLight}` }} borderRadius={1} mt={4}>
      <Accordion>
        <AccordionSummary className={styles.accordion} expandIcon={<Icon name={'chevronRight'} color={colors.red} />}>
          <Typography variant={'h2'}>Выплаты</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button disabled={isDownloading} variant="outlined" startIcon={<Icon name="download" />} onClick={download}>
            Скачать CSV
          </Button>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
