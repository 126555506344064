import { Box, Grid, Typography } from '@mui/material';
import { ProgramStatusItem } from '@area/student/components/ProgramStatusItem';
import { useGetHumanProgramsById } from '@area/student/hooks/useGetHumanProgramsById';
import { useParams } from 'react-router';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { getProgramStatusTitle } from '@area/student/utils/getProgramStatusTitle';

export const ProgramStatus = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetHumanProgramsById(id!);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Typography>Ошибка загрузки данных</Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box>
        <Typography>Нет данных</Typography>
      </Box>
    );
  }

  return (
    <Grid container style={{ justifyContent: 'center' }}>
      {data.map((item) => (
        <Grid wrap={'wrap'} style={{ display: 'flex', maxWidth: '300px', width: '100%' }} key={item.type} item md={4}>
          <ProgramStatusItem
            application={item.application}
            title={getProgramStatusTitle(item)}
            involved={item.isApproved}
          />
        </Grid>
      ))}
    </Grid>
  );
};
