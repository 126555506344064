import { FormField } from '@components/FormField';
import { Modal } from '@components/Modal';
import { Button, Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { validationSchema } from './validation';

export interface ArticleLinkFormData extends Record<string, any> {
  linkHref: string;
  linkText: string;
  imageUrl?: string;
  image?: File;
  imageFileUrl?: string;
  imageFileId?: string;
}

export interface QuoteWithPhotoFormProps {
  initialFormData?: ArticleLinkFormData;
  onSubmit: SubmitHandler<ArticleLinkFormData>;
  onClose: () => void;
}

export const ArticleLinkForm: FC<QuoteWithPhotoFormProps> = ({ onClose, onSubmit, initialFormData }) => {
  const [deleteFile] = useFilesControllerDeleteFileMutation();
  const resolver = useYupValidationResolver<ArticleLinkFormData>(validationSchema);

  const form = useForm<ArticleLinkFormData>({
    defaultValues: initialFormData,
    resolver,
  });

  const onDeleteFile = async () => {
    if (initialFormData?.imageFileId) {
      await deleteFile({ fileId: initialFormData.imageFileId });
    }
    form.setValue('imageFileUrl', undefined);
    form.setValue('imageFileId', undefined);
    form.setValue('image', undefined);
  };

  const { handleSubmit } = form;

  const watchImageFileUrl = form.watch('imageFileUrl');
  const submit = handleSubmit(onSubmit);

  return (
    <Modal
      open
      maxWidth="xs"
      onClose={onClose}
      title={`${initialFormData ? 'Редактирование' : 'Добавление'} ссылки на другую публикацию`}
      actions={
        <>
          <Button variant="contained" onClick={submit}>
            Принять
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.m}>
          <Grid>
            <FormField name="linkHref" title="Ссылка" fullWidth />
          </Grid>
          <Grid>
            <FormField name="linkText" title="Текст" fullWidth />
          </Grid>
          <Grid>
            <FormField name="imageUrl" title="Добавьте ссылку на фото" fullWidth />
          </Grid>
          <Grid minHeight={250}>
            <FormField
              name="image"
              type="image"
              imageUrl={watchImageFileUrl}
              onDelete={onDeleteFile}
              title="Или загрузите свое"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
