import { forwardRef, useRef } from 'react';
import { Tooltip, ThemeProvider } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateService } from '@services/DateService';
import { DatePickerProps } from './types';
import { getSlots, getSlotProps } from './Slots';
import { innerTheme } from './theme';

export const DatePicker = forwardRef<unknown, DatePickerProps>((props, _) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const slots = getSlots(props);
  const slotProps = getSlotProps(wrapperRef);

  return (
    <ThemeProvider theme={innerTheme}>
      <Tooltip open={Boolean(props.error)} title={props.error} arrow placement="bottom-start">
        <div ref={wrapperRef}>
          <MuiDatePicker
            format={props.dateFormat ?? DateService.dateFormats.dateField}
            dayOfWeekFormatter={(day: string) => day}
            {...props}
            value={props.value || null}
            slots={slots}
            slotProps={slotProps}
          />
        </div>
      </Tooltip>
    </ThemeProvider>
  );
});
