import { useEffect } from 'react';
import { UserResponse } from '@api/admin/adminGeneratedApi';
import { LocalStorageKeys } from '../../localstorage/constants';
import { removeLocalStorageObject, setLocalStorageObject } from '../../localstorage/localStorage';
import { defaultLang } from '../constants/defaultLang';

export const useSyncCurrentLangLS = (currentUser?: null | UserResponse) => {
  const currentUserLang = currentUser?.language?.key ?? defaultLang;

  useEffect(() => {
    if (currentUser) {
      setLocalStorageObject(LocalStorageKeys.UserLang, currentUserLang);
    } else {
      removeLocalStorageObject(LocalStorageKeys.UserLang);
    }
  }, [currentUser, currentUserLang]);
};
