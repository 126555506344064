import { BaseFormFieldProps } from '@components/FormField/types';
import { SettingType, settingTypeToFieldPropsMap } from '@area/settings/types';
import { TypeService } from '@services/TypeService';
import { EditModalFormData } from '@area/settings/components/EditModal/types';

export const useFieldFormProps = ({
  type,
  minValue,
  maxValue,
  regex,
  regexFlags,
}: EditModalFormData): Omit<BaseFormFieldProps, 'name'> => {
  const rules: BaseFormFieldProps['rules'] = {};

  if (TypeService.isNumber(minValue)) {
    rules.min = { value: minValue, message: `Минимально допустимое значение ${minValue}` };
  }
  if (TypeService.isNumber(maxValue)) {
    rules.max = { value: maxValue, message: `Максимально допустимое значение ${maxValue}` };
  }
  if (!TypeService.isNil(regex)) {
    rules.pattern = {
      value: new RegExp(regex, regexFlags ?? ''),
      message: `Значение должно соответствовать шаблону ${regex}`,
    };
  }

  return {
    ...settingTypeToFieldPropsMap[type || 0],
    rules: type !== SettingType.boolean ? rules : undefined,
  };
};
