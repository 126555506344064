import { Modal } from '@components/Modal';
import { Button, Grid, FormControlLabel } from '@mui/material';
import { FC, useState } from 'react';
import indents from '@styles/indents.module.scss';
import { Checkbox } from '@components/Checkbox';

interface Props {
  title: string;
  applyText: string;
  onClose: () => void;

  onApply(): void;
}

export const ConfirmModal: FC<Props> = ({ onClose, title, onApply, applyText }) => {
  const [checked, setChecked] = useState(false);
  const handleSave = async () => {
    if (checked) {
      onApply();
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={title}
      maxWidth="xs"
      actions={
        <Grid container flexDirection="row" gap={indents.m}>
          <Grid item>
            <Button disabled={!checked} onClick={handleSave} variant="contained">
              {applyText}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined">
              Отмена
            </Button>
          </Grid>
        </Grid>
      }>
      <Grid container flexDirection="column" gap={indents.l}>
        <FormControlLabel
          control={<Checkbox onChange={(_, val) => setChecked(val)} checked={checked} placeholder="hello" />}
          label="Я все проверил(а)"
        />
      </Grid>
    </Modal>
  );
};
