import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { FC } from 'react';
import { Icon } from '@components/Icon';
import styles from './styles.module.scss';

export interface ConfirmDialogProps {
  text: string;
  title: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({ onConfirm, onClose, isOpen, text, title }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">
      {title}
      {onClose && (
        <IconButton disableRipple onClick={onClose} className={styles.closeButton}>
          <Icon name="close" className={styles.closeIcon} />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onConfirm}>
        Удалить
      </Button>
      <Button onClick={onClose}>Отмена</Button>
    </DialogActions>
  </Dialog>
);
