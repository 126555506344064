import { useMemo, useState, useEffect, Dispatch } from 'react';
import * as yup from 'yup';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Nullable } from 'Types';

import { FieldToCheck } from '../components/ModalCheckMistakes/types';

type CheckMistakesVariants = 'publication' | 'kampusEvent' | 'infoPage';

type UseCheckMistakes = <S>(
  fieldsNames: string[],
  variant: CheckMistakesVariants,
  mainFormValidationSchema: S,
  formData: { [key: string]: any },
  imageUrl?: string,
) => {
  fieldsToCheck: FieldToCheck[];
  modalShow: boolean;
  form: UseFormReturn<Record<string, unknown>, any>;
  setInitialValues: Dispatch<React.SetStateAction<Nullable<Record<string, any>>>>;
};

export const useCheckMistakes: UseCheckMistakes = (
  fieldsNames,
  variant,
  mainFormValidationSchema,
  formData,
  imageUrl,
) => {
  const [initValues, setInitialValues] = useState<Nullable<Record<string, any>>>(null);

  const validationSchema = useMemo(() => {
    const extraValidation = fieldsNames
      .filter((fieldName) => !!formData![fieldName] || (fieldName === 'photo' && imageUrl))
      .reduce((acc, fieldName) => {
        return {
          ...acc,
          [generateFieldName(fieldName)]: yup.boolean().isTrue('Обязательное поле'),
        };
      }, {});

    return yup.object({
      ...mainFormValidationSchema,
      ...extraValidation,
    });
  }, [fieldsNames, mainFormValidationSchema, formData]);

  const defaultValues = useMemo(() => {
    return fieldsNames.reduce((acc, fieldName) => {
      return {
        ...acc,
        [generateFieldName(fieldName)]: false,
      };
    }, initValues || {});
  }, [initValues, fieldsNames]);

  const fieldsToCheck: FieldToCheck[] = useMemo(() => {
    // TODO: хардкордно fieldType и title, можно подумать о других вариантах,
    // если родительскую форму тоже формировать не через JSX, а какой нибудь класс
    // или передавать в хук, как параметр
    let titles: Array<string | undefined>;

    // undefined нужен для обозначения фото
    switch (variant) {
      case 'publication':
        titles = [undefined, 'Большой заголовок', 'Основной заголовок'];
        break;
      case 'kampusEvent':
        titles = [undefined, 'Название'];
        break;
      case 'infoPage':
        titles = ['Заголовок'];
    }

    return fieldsNames
      .map((fieldName, idx) => ({
        fieldType: titles[idx] ? 'text' : 'img', // если undefined значит это фото
        title: titles[idx],
        fieldName: fieldName,
        fieldNameCheckbox: generateFieldName(fieldName),
        imgUrl: titles[idx] ? '' : imageUrl,
      }))
      .filter(({ fieldName }) => !!formData![fieldName] || (fieldName === 'photo' && imageUrl)) as FieldToCheck[];
  }, [variant, fieldsNames, formData, imageUrl]);

  const resolver = useYupValidationResolver(validationSchema);

  const form = useForm({
    defaultValues,
    resolver,
    mode: 'all',
  });

  useEffect(() => {
    if (!initValues) return;

    form.reset(defaultValues);
  }, [form, defaultValues, initValues]);

  return {
    fieldsToCheck,
    modalShow: !!initValues,
    form,
    setInitialValues,
  };
};

function generateFieldName(fieldName: string) {
  return fieldName + 'Confirm';
}
