/* eslint-disable indent */
import { ArticleLinkElement } from '../../../types';

export const serializeArticleLink = (node: ArticleLinkElement): string => {
  const imageRender =
    node.imageUrl || node.imageFileUrl
      ? `<div class="article-link__photo-wrapper"><img class="article-link__photo" alt="" loading="lazy" src="${
          node.imageUrl || node.imageFileUrl
        }" /></div>`
      : '';
  const dataImgUrl = node.imageUrl ? `data-image-url="${node.imageUrl}"` : '';
  const dataImgFileUrl = node.imageFileUrl ? `data-image-file-url="${node.imageFileUrl}"` : '';
  const dataImgFileId = node.imageFileId ? `data-image-file-id="${node.imageFileId}"` : '';
  const dataLinkHref = `data-link-href="${node.linkHref}"`;
  const dataLinkText = `data-link-text="${node.linkText}"`;
  const linkRender = `<a href="${node.linkHref}" class="article-link__link">${node.linkText}</a>`;

  return `<div class="article-link" ${dataImgUrl} ${dataImgFileId} ${dataImgFileUrl} ${dataLinkHref} ${dataLinkText}"><div class="article-link__wrapper">${imageRender}${linkRender}</div></div>`;
};
