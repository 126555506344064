import * as yup from 'yup';
import { Dayjs } from 'dayjs';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';

export type PushFormData = {
  title: string;
  text: CustomDescendant[];
  date: Dayjs;
  link: string;
  targets: string;
};

export const validationSchema = yup.object<PushFormData>({
  title: yup.string().required('Обязательное поле.').min(5, 'Минимальное количество символов 5'),
  // text: yup.string().required('Обязательное поле.').min(5, 'Минимальное количество символов 5'), // TODO: add validator to wysiwyg
  date: yup.date(),
  link: yup.string(),
  targets: yup.string(),
});
