import { Navigate, useOutlet, useParams } from 'react-router';
import { usePageTitle } from '@hooks/usePageTitle';
import { PaymentType } from '@area/payments/types';
import { Routes } from '@navigation/routes';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { PaymentsContent } from '@area/payments/components/PaymentsContent';

export const PaymentsPage = () => {
  usePageTitle('Выплаты');

  const outlet = useOutlet();
  const { paymentType } = useParams();

  if (!paymentType || !(paymentType in PaymentType)) {
    return (
      <Navigate
        to={buildRoutePath([Routes.payments, Routes.paymentsType], {
          params: { paymentType: PaymentType.extraGrant },
          absolute: true,
        })}
      />
    );
  }
  if (outlet) {
    return outlet;
  }

  return <PaymentsContent />;
};
