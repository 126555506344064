import { useRef } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { Routes } from '@navigation/routes';

import { NotificationTypeName } from '@area/notificationsPage/types';

export const CreateButton = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const gotToNewNotificationPage = (notoficationType: NotificationTypeName) =>
    navigate(`${notoficationType}/${Routes.newItem}`);

  return (
    <div ref={wrapperRef}>
      <Button onClick={() => gotToNewNotificationPage('email')} variant="contained">
        Новая рассылка
      </Button>
    </div>
  );
};
