import { Button, Grid } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { Routes } from '@navigation/routes';
import { UsersTable } from '@area/users/components/UsersTable';

export const UsersPage = () => {
  usePageTitle('Пользователи системы');

  const outlet = useOutlet();
  const navigate = useNavigate();

  if (outlet) {
    return outlet;
  }

  return (
    <Grid container direction="column" gap="24px">
      <Grid item>
        <Header
          title="Пользователи системы"
          actions={
            <Button onClick={() => navigate(Routes.newItem)} variant="contained">
              Новый пользователь
            </Button>
          }
        />
      </Grid>
      <UsersTable />
    </Grid>
  );
};
