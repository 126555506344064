import { memo, Ref, useImperativeHandle, useState } from 'react';
import { ShowHTMLModalContent } from './ShowHTMLModalContent';

export interface ShowHTMLRef {
  open: () => void;
  close: () => void;
}

export interface AddKeyQuoteProps {
  modalRef: Ref<ShowHTMLRef>;
}

export const ShowHTMLModal = memo(({ modalRef }: AddKeyQuoteProps) => {
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  useImperativeHandle(modalRef, () => ({
    open: () => setShowModal(true),
    close: onClose,
  }));

  if (!showModal) {
    return null;
  }

  return <ShowHTMLModalContent onClose={onClose} />;
});
