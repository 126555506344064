import { useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';
import { MouseEvent } from 'react';
import { Select } from '@components/Select';
import { Option } from '@components/Select/types';
import { fontNames, fontSizes, typography } from '@area/redactor/components/Wysiwyg/constants';
import { ElementType } from '@area/redactor/components/Wysiwyg/types';
import { getActiveType, toggleBlock } from '@area/redactor/components/Wysiwyg/utils/elements';
import styles from './styles.module.scss';

// const fontSizeOptions: Option[] = fontSizes.map((value) => ({ value, label: value }));
// const fontNameOptions: Option[] = fontNames.map((value) => ({ value, label: value }));

export const FontSettings = () => {
  const editor = useSlate();
  const marks = Editor.marks(editor);
  const activeTypography = getActiveType(editor);

  const onMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  //   const changeFont = (name: 'fontName' | 'fontSize', opt: Option | Option[]) => {
  //     setTimeout(() => {
  //       if (!marks || !editor.selection || Array.isArray(opt)) return;

  //       Transforms.select(editor, editor.selection);
  //       Editor.addMark(editor, name, opt.value);
  //     }, 30);
  //   };
  //   const onFontSizeChange = (opt: Option | Option[]) => changeFont('fontSize', opt);
  //   const onFontNameChange = (opt: Option | Option[]) => changeFont('fontName', opt);

  const onTypographyChange = (opt: Option | Option[]) => {
    const value = (opt as Option).value as ElementType;
    toggleBlock(editor, value);
  };

  return (
    <>
      {/* <Select
        value={marks && marks.fontSize ? { value: marks.fontSize, label: marks.fontSize } : undefined}
        placeholder="FontSize"
        className={styles.root}
        arrowClassName={styles.arrow}
        options={fontSizeOptions}
        disabled={!marks}
        onChange={onFontSizeChange}
        onMouseDown={onMouseDown}
        title="Размер шрифта"
      />
      <Select
        value={marks && marks.fontName ? { value: marks.fontName, label: marks.fontName } : undefined}
        placeholder="FontName"
        className={styles.root}
        arrowClassName={styles.arrow}
        options={fontNameOptions}
        disabled={!marks}
        onChange={onFontNameChange}
        onMouseDown={onMouseDown}
        title="Имя шрифта"
      /> */}
      <Select
        value={typography.find((i) => i.value === activeTypography)}
        placeholder="Typography"
        className={styles.root}
        arrowClassName={styles.arrow}
        options={typography}
        disabled={!marks}
        onChange={onTypographyChange}
        onMouseDown={onMouseDown}
        title="Стили"
      />
    </>
  );
};
