import cn from 'classnames';
import { ButtonBase, Typography } from '@mui/material';
import { forwardRef, MouseEvent, useImperativeHandle, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
// eslint-disable-next-line import/no-unresolved
import { OnProgressProps } from 'react-player/base';
import { Icon } from '../Icon';
import styles from './style.module.scss';

export interface ControlsMethods {
  setProgress: (data: OnProgressProps) => void;
}

export interface ControlsProps {
  player: ReactPlayer | null;
  playing: boolean;
  disabled: boolean;
  onPlay: () => void;
  onPause: () => void;
  onStop: () => void;
}

const initialProgressState: OnProgressProps = {
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0,
};

const formatTime = (seconds: number): string => {
  const m = Math.floor(seconds / 60)
    .toString(10)
    .padStart(2, '0');

  const s = Math.trunc(seconds % 60)
    .toString(10)
    .padStart(2, '0');

  return `${m}:${s}`;
};

export const Controls = forwardRef<ControlsMethods, ControlsProps>(
  ({ player, playing, disabled, onPlay, onPause, onStop }: ControlsProps, ref) => {
    const isChangingTimeRef = useRef(false);
    const [progress, setProgress] = useState<OnProgressProps>(initialProgressState);

    useImperativeHandle(ref, () => ({
      setProgress: (data) => setProgress(data),
    }));

    const changePlayedTime = (event: MouseEvent<HTMLDivElement>) => {
      const timeline = (event.target as Element)?.closest('.timeline');
      if (!timeline) return;

      const { left, width } = timeline.getBoundingClientRect();
      const mouseX = event.clientX - left;

      isChangingTimeRef.current = true;
      player?.seekTo(mouseX / width, 'fraction');
    };

    const onMouseMove = (event: MouseEvent<HTMLDivElement>) => {
      if (!isChangingTimeRef.current) return;
      changePlayedTime(event);
    };
    const onMouseDown = (event: MouseEvent<HTMLDivElement>) => {
      changePlayedTime(event);
    };
    const onMouseUp = () => {
      isChangingTimeRef.current = false;
    };

    return (
      <div className={cn(styles.controls, disabled && styles.controlsDisabled, playing && styles.playing)}>
        <div>
          <ButtonBase className={styles.controlsButton} disabled={disabled} onClick={playing ? onPause : onPlay}>
            <Icon className={styles.buttonIcon} name="play" />
          </ButtonBase>
          <ButtonBase className={styles.controlsButton} disabled={disabled} onClick={onStop}>
            <Icon className={styles.buttonIcon} name="stop" />
          </ButtonBase>
        </div>
        <div
          className={cn('timeline', styles.timeline)}
          role="presentation"
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseDown={onMouseDown}>
          <div className={styles.progress} style={{ width: `${progress.played * 100}%` }} />
        </div>
        <Typography className={styles.time} variant="body2">
          {formatTime(progress.playedSeconds)}
        </Typography>
      </div>
    );
  },
);
