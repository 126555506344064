import { RenderElementProps } from 'slate-react';
import { CSSProperties } from 'react';
import { ImageCard } from '@area/redactor/components/Wysiwyg/components/ImageCard/ImageCard';
import { defaultElementStyles, INDENT_LEVEL_SIZE } from '../../constants';
import { DefinitionInfoCard } from '../DefinitionInfoCard';
import { KeyQuoteCard } from '../KeyQuoteCard';
import { QuoteWithPhotoCard } from '../QuoteWithPhotoCard';
import { ArticleLink } from '../ArticleLink';

export const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  //   const style: CSSProperties = {
  //     ...(defaultElementStyles[element.type] ?? {}),
  //     textAlign: element.align,
  //     paddingLeft: element.indentLevel ? element.indentLevel * INDENT_LEVEL_SIZE : undefined,
  //     ...element.style,
  //   };

  switch (element.type) {
    case 'heading-1':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-2':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-3':
      return <h3 {...attributes}>{children}</h3>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'unordered-list':
      return <ul {...attributes}>{children}</ul>;
    case 'caption':
      return (
        <p data-is-caption="true" {...attributes}>
          {children}
        </p>
      );
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    case 'table':
      return <table>{children}</table>;
    case 'table-body':
      return <tbody {...attributes}>{children}</tbody>;
    case 'table-row':
      return <tr {...attributes}>{children}</tr>;
    case 'table-header':
      return <th {...attributes}>{children}</th>;
    case 'table-cell':
      return <td {...attributes}>{children}</td>;
    case 'quote-with-photo': {
      return <QuoteWithPhotoCard {...props} />;
    }
    case 'article-link': {
      return <ArticleLink {...props} />;
    }
    case 'key-quote': {
      return <KeyQuoteCard {...props} />;
    }
    case 'definition-info': {
      return <DefinitionInfoCard {...props} />;
    }
    case 'image': {
      return <ImageCard {...props} />;
    }
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const renderElement = ({ children, ...props }: RenderElementProps) => <Element {...props}>{children}</Element>;
