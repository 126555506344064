import { IconButton } from '@mui/material';
import { useRef } from 'react';
import { Icon } from '@components/Icon';
import { ShowHTMLModal, ShowHTMLRef } from './ShowHTMLModal';

export const ShowHTML = () => {
  const modalRef = useRef<ShowHTMLRef>(null);

  const onClick = () => modalRef.current?.open();

  return (
    <>
      <IconButton centerRipple={false} onClick={onClick} title="Вставить код" sx={{ borderRadius: '4px' }}>
        <Icon name="wysiwygCode" viewBox="0 0 16 16" />
      </IconButton>
      <ShowHTMLModal modalRef={modalRef} />
    </>
  );
};
