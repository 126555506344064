import { Icon } from '@components/Icon';
import { IconButton } from '@mui/material';
import { MouseEvent, useRef } from 'react';
import { useSlate } from 'slate-react';
import { insertDefinitionInfo } from '../../../../utils/definition-info';
import { AddDefinitionInfoModal, AddDefinitionInfoRef } from './AddDefinitionInfoModal';

export const DefinitionInfo = () => {
  const modalRef = useRef<AddDefinitionInfoRef>(null);
  const editor = useSlate();

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onClickAdd = () => {
    modalRef.current?.open();
  };

  return (
    <>
      <IconButton centerRipple={false} onMouseDown={onMouseDown} onClick={onClickAdd} title="Вставить определение">
        <Icon name="wysiwygDefinition" viewBox="0 0 16 16" />
      </IconButton>
      <AddDefinitionInfoModal
        modalRef={modalRef}
        onSubmit={(formData) => {
          insertDefinitionInfo(editor, formData);
        }}
      />
    </>
  );
};
