import { FormField } from '@components/FormField';
import { FC } from 'react';
import indents from '@styles/indents.module.scss';
import { Grid } from '@mui/material';
import styles from './styles.module.scss';

interface Props {
  fieldName?: string;
}

export const Embedded: FC<Props> = ({ fieldName = 'embedded' }) => {
  return (
    <Grid container className={styles.root} gap={indents.m}>
      <Grid item width="100%">
        <FormField name={fieldName} title="Произвольный код в тег<head> страницы" fullWidth multiline />
      </Grid>
    </Grid>
  );
};
