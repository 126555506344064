import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { StorageState } from '@store/index';

import { NotificationItem } from '../types';

interface ErrorNotifications {
  notifications: NotificationItem[];
}

const initialState: ErrorNotifications = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (state, action: PayloadAction<NotificationItem>) => {
      state.notifications = [...state.notifications, action.payload];
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { pushNotification, removeNotification } = notificationsSlice.actions;

export const notificationsSelector = (state: StorageState) => state.notificationsSlice;
