import { useEventsControllerGetEventQuery } from '@api/admin/adminGeneratedApi';

export const useKampusEventById = (id: string) => {
  const { data, isLoading, isSuccess } = useEventsControllerGetEventQuery({
    id,
  });

  return {
    kampusEvent: data,
    isLoading,
    isSuccess,
  };
};
