import { FC } from 'react';
import { Modal } from '@components/Modal';
import { Button, Grid, Typography } from '@mui/material';
import indents from '@styles/indents.module.scss';

interface Props {
  titleToDelete: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PageDeleteConfirmModal: FC<Props> = ({ open, titleToDelete, onClose, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Удаление"
      fullWidth={false}
      actions={
        <Grid container flexDirection="row" gap={indents.s}>
          <Grid item>
            <Button onClick={onConfirm} variant="contained">
              Да, удалить
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined">
              Нет, оставить
            </Button>
          </Grid>
        </Grid>
      }>
      <Typography>Вы действительно хотите удалить {titleToDelete}?</Typography>
    </Modal>
  );
};
