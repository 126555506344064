import { NewsRequest } from '@api/admin/adminGeneratedApi';
import { serialize } from '@area/redactor/components/Wysiwyg/utils/serialize';
import { TypeService } from '@services/TypeService';
import dayjs from 'dayjs';
import { FormPublicationValues } from '../defaultValues';

export class PublicationMapper {
  public static formModelToApiModel(values: FormPublicationValues): NewsRequest {
    return {
      published: values.published,
      startDate: dayjs(values.startDate).format(),
      title: values.title,
      fullTitle: values.fullTitle,
      url: values.url,
      type: typeof values.type === 'string' ? values.type : values.type.value,
      editorContent: TypeService.isString(values.editorContent)
        ? values.editorContent
        : serialize(values.editorContent),
      metaTags: values.metaTags,
      newsTagIds: values.newsTagIds?.map(({ value }: any) => value) || [],
      authorIds: values.authorIds?.map(({ value }: any) => value) || [],
      embedded: values.embedded,
    };
  }
}
