import { useLocation } from 'react-router';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { KampusRoute } from '@navigation/routes';
import { useQueryParams } from './useQueryParams';

const QUERY_PARAM_CONTINUE = 'continue';

export const useBackUrl = () => {
  const { pathname } = useLocation();
  const { params } = useQueryParams();

  const buildRouteWithBackUrl = (route: KampusRoute) => {
    return buildRoutePath(route, { search: { [QUERY_PARAM_CONTINUE]: pathname, ...params } });
  };

  const buildRouteToContinue = (route: KampusRoute) => {
    const backUrl = params[QUERY_PARAM_CONTINUE];
    const cleanedParams = { ...params };
    delete cleanedParams[QUERY_PARAM_CONTINUE];

    if (backUrl) {
      return buildRoutePath(backUrl as KampusRoute, { search: cleanedParams, absolute: true });
    }

    return route;
  };

  return {
    buildRouteWithBackUrl,
    buildRouteToContinue,
  };
};
