import { Column } from '@components/Table/types';
import { PaymentStatusChangesResponse } from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';
import { PaymentStatusTag } from '@area/payments/components/PaymentStatusTag';

export const columns: Column<Partial<PaymentStatusChangesResponse>>[] = [
  {
    width: 180,
    title: 'Дата создания',
    dataIndex: 'createdAt',
    render: (value) => DateService.getLocalDate(value),
  },
  {
    width: 140,
    title: 'Статус',
    dataIndex: 'toStatus',
    render: (value) => <PaymentStatusTag status={value} />,
  },

  {
    title: 'Инициатор',
    dataIndex: 'triggeredBy',
    render: (value) => value.nickname,
  },
];
