import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { QRCodeSVG } from 'qrcode.react';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { InputMask } from '@components/Input/types';
import { FormField } from '@components/FormField';
import indents from '@styles/indents.module.scss';
import styles from '@pages/Login/style.module.scss';
import { LoginResponseDto } from '@api/admin/adminGeneratedApi';
import { useOtpForm } from '@area/user/hooks/useOtpForm';
import { useDispatch, useSelector } from 'react-redux';
import { otpSelector, setOtpData } from '@area/user/store';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';

export interface ConfirmOtpProps {
  onSuccess: (accessToken: LoginResponseDto) => void;
}

export const ConfirmOtp = ({ onSuccess }: ConfirmOtpProps) => {
  const dispatch = useDispatch();
  const [qrValue, setQrValue] = useState('');
  const { logout } = useAuthHelpers();
  const { email, secret } = useSelector(otpSelector) ?? {};
  const { onSubmit, isLoading, otpFieldRules, form } = useOtpForm(email, onSuccess);

  useEffect(() => {
    if (!secret || !email) {
      return logout();
    }

    const encodedIssuer = encodeURIComponent('Campus');
    const encodedUser = encodeURIComponent(email);
    const encodedOtpSecret = encodeURIComponent(secret);

    setQrValue(
      `otpauth://totp/${encodedIssuer}:${encodedUser}?secret=${encodedOtpSecret}&issuer=${encodedIssuer}&algorithm=SHA1&digits=6&period=30`,
    );

    return () => {
      dispatch(setOtpData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secret, email]);

  return (
    <>
      <Grid item>
        <Typography variant="body1" align="center">
          Отсканируйте QR в приложении аутентификации
        </Typography>
      </Grid>
      <Grid item alignSelf="center">
        <QRCodeSVG
          value={qrValue}
          size={227}
          level="L"
          imageSettings={{
            src: '/assets/qr-logo.png',
            width: 46,
            height: 46,
            excavate: true,
          }}
        />
        <Typography variant="body1" align="center" sx={{ marginTop: indents.xxs }}>
          Секретный ключ
        </Typography>
        <Typography variant="body2" align="center">
          {secret}
        </Typography>
      </Grid>
      <FormProvider {...form}>
        <Grid item alignSelf="center" width={260}>
          <FormField
            autoFocus
            fullWidth
            name="otp"
            type="text"
            title="Введите код из приложения"
            titleVariant="body1"
            titleAlign="center"
            rules={otpFieldRules}
            mask={InputMask.Otp}
          />
        </Grid>
      </FormProvider>
      <Grid item alignSelf="center">
        <Button onClick={onSubmit} variant="contained" disabled={isLoading}>
          Отправить
        </Button>
      </Grid>
      {isLoading && <LinearProgress className={styles.loadingIndicator} />}
    </>
  );
};
