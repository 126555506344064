import { Ref, useImperativeHandle, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSlateStatic } from 'slate-react';
import {
  ImageForm,
  ImageFormData,
} from '@area/redactor/components/Wysiwyg/components/Toolbar/components/Image/ImageForm/ImageForm';

export interface AddImageToEditorRef {
  open: () => void;
  close: () => void;
}

export interface AddImageToEditorModalProps {
  modalRef: Ref<AddImageToEditorRef>;
  onSubmit: SubmitHandler<ImageFormData>;
}

export const AddImageToEditorModal = ({ modalRef, onSubmit }: AddImageToEditorModalProps) => {
  const editor = useSlateStatic();

  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  useImperativeHandle(modalRef, () => ({
    open: () => {
      if (!editor.selection) return;
      setShowModal(true);
    },
    close: onClose,
  }));

  if (!showModal) {
    return null;
  }

  return (
    <ImageForm
      onClose={onClose}
      onSubmit={(formData) => {
        onSubmit(formData);
        onClose();
      }}
    />
  );
};
