import { useUsersControllerCreateUserMutation } from '@api/admin/adminApi';
import { UserCreateForm } from '@area/users/type';
import { UserMapper } from '@area/users/mappers';

export const useCreateUser = () => {
  const [create, result] = useUsersControllerCreateUserMutation();

  const handleCreate = async (data: UserCreateForm) => {
    return await create({
      userCreatorDto: UserMapper.userCreateFormToUserCreatorDto(data),
    }).unwrap();
  };

  return {
    handleCreate,
    result,
  };
};
