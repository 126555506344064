import { useMemo, useState, useEffect } from 'react';
import { Box, Button, Tooltip, IconButton, Paper, Stack, Grid } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router-dom';
import { Icon } from '@components/Icon';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { Routes } from '@navigation/routes';
import colors from '@styles/colors.module.scss';
import { Filter } from '@components/Filter';
import { InfoPageShortResponse, InfoPageFiltersRequest } from '@api/admin/adminGeneratedApi';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParams } from '@hooks/useQueryParams';
import { useInfoPages } from '@area/infoPages/hooks/useInfoPages';
import { useDeleteInfoPage } from '@area/infoPages/hooks/useDeleteInfoPage';
import { PageDeleteConfirmModal } from '@area/redactor/components/PageDeleteConfirmModal';
import { DEFAULT_INFO_PAGE_SIZE, INFO_PAGE_ICONS } from '@area/infoPages/defaultValues';
import { Header } from '@layouts/Base/components/Header';

import { getPreviewUrl } from '../../utils/getPreviewUrl';
import styles from './styles.module.scss';

export const InfoPages = () => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const [data, setData] = useState<InfoPageShortResponse[]>([]);
  const { getInfoPages, infoPagesList, isSuccess, isLoading, paginate, originalArgs } = useInfoPages();
  const {
    deleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    confirmDelete,
    loadingRowId,
  } = useDeleteInfoPage(() => {
    setData([]);
    fetchData(originalArgs?.infoPageFiltersRequest!, 0, originalArgs?.skip! + originalArgs?.size!);
  });

  const { params } = useQueryParams();

  usePageTitle('Информационные страницы');

  const fetchData = (values: Partial<InfoPageFiltersRequest>, skip: number, size = DEFAULT_INFO_PAGE_SIZE) => {
    getInfoPages({
      skip,
      size,
      asc: true,
      infoPageFiltersRequest: {
        search: values.search,
      },
    });
  };

  const onChange = (values: Partial<InfoPageFiltersRequest>) => {
    setData([]);

    fetchData(values, 0);
  };
  const gotoNewInfoPage = () => navigate(Routes.newItem);
  const onRowClick = (row: InfoPageShortResponse) => {
    navigate(row.id);
  };
  const onClickLink = (url: string) => {
    window.open(getPreviewUrl('pages', url), '_blank');
  };
  const onClickDelete = (id: string) => {
    openDeleteConfirmationModal(id);
  };
  const loadMore = () => {
    fetchData(originalArgs?.infoPageFiltersRequest!, data.length);
  };

  const columns: Column<InfoPageShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'published',
        key: 'published',
        width: '72px',
        render: (value: boolean) => (
          <Tooltip title={value ? 'Опубликовано' : 'Не опубликовано'} arrow>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
            </Box>
          </Tooltip>
        ),
      },
      {
        title: 'Название',
        dataIndex: 'title',
        key: 'title',
        sx: { whiteSpace: 'normal' },
      },
      {
        title: '',
        dataIndex: 'url',
        key: 'url',
        width: '90px',
        render: (url: string, row: InfoPageShortResponse) => {
          return (
            <Stack direction="row" gap="6px" className={styles.actions}>
              {INFO_PAGE_ICONS.map((iconName) => (
                <IconButton
                  key={iconName}
                  className={styles.iconButton}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (iconName === 'link') {
                      onClickLink(url);
                      return;
                    }

                    onClickDelete(row.id);
                  }}>
                  <Icon name={iconName} />
                </IconButton>
              ))}
            </Stack>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!outlet) {
      fetchData(params, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(infoPagesList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Grid container direction="column" gap="24px">
        <Grid item>
          <Header
            title="Информационные страницы"
            actions={
              <Button onClick={gotoNewInfoPage} variant="contained">
                Новая инфо страница
              </Button>
            }
          />
        </Grid>

        <Grid item>
          <Filter<InfoPageFiltersRequest>
            defaultValues={{ search: '' }}
            onChange={onChange}
            searchField={{
              type: 'text',
              name: 'search',
              props: {
                placeholder: 'Поиск',
                sx: { minWidth: '200px', background: colors.white },
              },
              stretchable: true,
            }}
            clearable={false}
          />
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <Paper className={styles.paper}>
            <Table<InfoPageShortResponse>
              columns={columns}
              data={data}
              loading={isLoading}
              onRowClick={onRowClick}
              loadingRowId={loadingRowId}
            />

            {!!paginate?.totalCount && data.length < paginate?.totalCount && (
              <Button
                variant="outlined"
                startIcon={<Icon name="more" />}
                className={styles.loadMoreBtn}
                onClick={loadMore}
                disabled={isLoading}>
                Загрузить еще
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>

      <PageDeleteConfirmModal
        titleToDelete="информационную страницу"
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onConfirm={confirmDelete}
      />
    </>
  );
};
