import { useUsersControllerUpdateUserRoleMutation } from '@api/admin/adminApi';
import { UserRole } from '@area/users/roles';

export const useUpdateUserRole = (id: string) => {
  const [update, result] = useUsersControllerUpdateUserRoleMutation();

  const handleUpdate = async (role: UserRole[]) => {
    return await update({
      id,
      userRoleUpdaterDto: {
        role: role as any,
      },
    }).unwrap();
  };

  return {
    handleUpdate,
    result,
  };
};
