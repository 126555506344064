export const studentGrades = [
  'зимний 2021/2022',
  'летний 2021/2022',
  'зимний 2022/2023',
  'летний 2022/2023',
  'зимний 2023/2024',
  'летний 2023/2024',
  'зимний 2024/2025',
  'летний 2024/2025',
  'зимний 2025/2026',
  'летний 2025/2026',
  'зимний 2026/2027',
  'летний 2026/2027',
  'зимний 2027/2028',
  'летний 2027/2028',
];
