import isUrl from 'is-url';
import { EditorPlugin } from '../types';
import { wrapLink } from '../utils/links';

export const withLinks: EditorPlugin = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => element.type === 'link' || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
