import { useUsersControllerGetUserQuery } from '@api/admin/adminApi';

export const useGetUserById = (id = '') => {
  const { data, isLoading, isError } = useUsersControllerGetUserQuery({ id }, { skip: !id });

  return {
    user: data,
    isLoading,
    isError,
  };
};
