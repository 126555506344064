import { useUsersControllerGetCurrentUserQuery } from '@api/admin/adminApi';

export const useCurrentUser = (skip = false) => {
  const { data, isLoading, isFetching, isSuccess, isError, error } = useUsersControllerGetCurrentUserQuery(undefined, {
    skip,
  });

  return {
    currentUser: data ?? null,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  };
};
