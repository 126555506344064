import { Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  filesNumberSelector,
  setFilesNumber,
  setSnackbarOpen,
  setUploadedFilesNumber,
  snackbarIsOpenSelector,
  uploadedFilesNumberSelector,
} from '@area/mediaFilesUploadSnackbar/store';
import { useEffect } from 'react';

export const MediaFilesUploadSnackbar = () => {
  const dispatch = useDispatch();

  const filesNumber = useSelector(filesNumberSelector);
  const uploadedFilesNumber = useSelector(uploadedFilesNumberSelector);
  const snackbarIsOpen = useSelector(snackbarIsOpenSelector);

  useEffect(() => {
    if (filesNumber === 0) return;
    if (uploadedFilesNumber === filesNumber) {
      dispatch(setSnackbarOpen(false));
      dispatch(setFilesNumber(0));
      dispatch(setUploadedFilesNumber(0));
    }
  }, [dispatch, filesNumber, uploadedFilesNumber]);

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      message={`Загружено ${uploadedFilesNumber} файлов из ${filesNumber}`}
      open={snackbarIsOpen}
    />
  );
};
