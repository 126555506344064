import { MouseEvent, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, IconButton, Paper, Popper, Typography } from '@mui/material';
import { Range, Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { Icon } from '@components/Icon';
import { TableUtil } from '@area/redactor/components/Wysiwyg/utils/tabels';
import { TableControls } from './TableControls';
import styles from './styles.module.scss';

export const Table = () => {
  const editor = useSlateStatic();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const [selection, setSelection] = useState<Range | null>();

  const [tableData, setTableData] = useState({ row: 0, column: 0 });
  const [tableInput, setTableInput] = useState(
    Array.from({ length: 6 }, () => Array.from({ length: 6 }, (v, i) => ({ bg: 'lightGray', column: i }))),
  );

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const onOpen = () => {
    if (!editor.selection) return;

    setSelection(editor.selection);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (!selection) return;
      Transforms.select(editor, selection);
    }, 50);
  };
  const onClick = () => {
    open ? onClose() : onOpen();
  };
  const onInsert = () => {
    if (!selection) return;
    new TableUtil(editor).insertTable(selection, tableData.row, tableData.column);
    onClose();
  };

  useEffect(() => {
    const newTable = Array.from({ length: 6 }, (obj, row) =>
      Array.from({ length: 6 }, (v, col) => ({
        bg: row + 1 <= tableData.row && col + 1 <= tableData.column ? 'orange' : 'lightgray',
        column: col,
      })),
    );

    setTableInput(newTable);
  }, [tableData]);
  useEffect(() => {
    if (!open) setTableData({ row: 0, column: 0 });
  }, [open]);

  return (
    <>
      <IconButton
        sx={{ borderRadius: '4px' }}
        ref={buttonRef}
        centerRipple={false}
        onMouseDown={onMouseDown}
        onClick={onClick}
        title="Вставить таблицу">
        <Icon name="wysiwygTable" viewBox="0 0 16 16" />
      </IconButton>
      <Popper open={open} anchorEl={buttonRef.current} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}>
            <Paper variant="outlined" className={styles.tableInputBox}>
              <ClickAwayListener onClickAway={onClose}>
                <div>
                  <Typography variant="caption" component="div" marginBottom="4px" textAlign="center">
                    {tableData.row}x{tableData.column}
                  </Typography>
                  <div className={styles.tableInput}>
                    {tableInput.map((grp, row) => {
                      return grp.map(({ column, bg }, col) => (
                        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <div
                          key={row + col}
                          onMouseOver={() => setTableData({ row: row + 1, column: column + 1 })}
                          onClick={onInsert}
                          className={styles.tableUnit}
                          style={{ border: `1px solid ${bg}` }}
                        />
                      ));
                    })}
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <TableControls />
    </>
  );
};
