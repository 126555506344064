import * as yup from 'yup';
import { ChangePasswordFormData } from './types';

export const validationSchema = yup.object<ChangePasswordFormData>({
  password: yup
    .string()
    .min(8, 'Минимальная длина пароля 8 символов')
    .max(24, 'Максимальная длина пароля 24 символов')
    .required('Обязательное поле.')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).*$/, {
      message: 'В пароле должны присутствовать: числа, большие и маленькие буквы',
    }),
  repeatPassword: yup
    .string()
    .required('Обязательное поле.')
    .oneOf([yup.ref('password'), ''], 'Пароли должны совпадать'),
});
