import { NotificationsShortResponse } from '@api/admin/adminGeneratedApi';
import { Dayjs } from 'dayjs';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';

export type NotificationTypeName = 'email' | 'push';

export const NotificationType: Record<NotificationTypeName, NotificationsShortResponse['type']> = {
  email: 1,
  push: 3,
} as const;

// TODO: make one type with Notification and Values
export type PushValues = {
  date: Dayjs;
  title: string;
  text: CustomDescendant[];
  link: string;
  img: string;
  to: string;
};

// TODO: make one type with Notification and Values
// TODO add actual email values
export type EmailValues = {
  date: Dayjs;
  title: string;
  text: CustomDescendant[];
  link: string;
  img: string;
  to: string;
};
