import { useState, FC } from 'react';
import { Modal } from '@components/Modal';
import { PreviewButtonType } from '../types';
import { getIframeSize } from '../utils/getIframeSize';
import { PreviewButtons } from './PreviewButtons';

import styles from './styles.module.scss';

// TODO: remove
const PAGE_URL = 'https://kampus-dev.softmedialab.com/';

interface PreviewProps {
  url?: string;
  disabled?: boolean;
}

export const Preview: FC<PreviewProps> = ({ url, disabled }) => {
  const [type, setType] = useState<PreviewButtonType | null>(null);
  const size = getIframeSize(type);

  const onButtonClick = (type: PreviewButtonType) => setType(type);
  const onClose = () => setType(null);

  return (
    <>
      <PreviewButtons disabled={disabled} onClick={onButtonClick} />

      <Modal
        open={!!type}
        title={type === 'mobile' ? 'Mobile' : 'Desktop'}
        onClose={onClose}
        maxWidth="xs"
        className={styles.modal}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              // 80 is standard modal side padding x2
              maxWidth: (size?.width ?? 0) + 80,
            },
          },
        }}>
        <iframe
          title="frame"
          src={url ?? PAGE_URL}
          width={size?.width}
          height={size?.height}
          className={styles.iframe}
        />
      </Modal>
    </>
  );
};
