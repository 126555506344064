import { useSyncCurrentLangLS } from '@area/user/hooks/useSyncCurrentLangLS';
import cn from 'classnames';
import { Navigate, Outlet } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarSelector, toggleSidebar } from '@area/sidebar/store';
import { navigation } from '@navigation/navigation';
import { getLocalStorageObject, setLocalStorageObject } from '@area/localstorage/localStorage';
import { LocalStorageKeys } from '@area/localstorage/constants';
import { Routes } from '@navigation/routes';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';
import { LinearProgress } from '@mui/material';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { useEffect } from 'react';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';
import { Sidebar } from './components/Sidebar';
import styles from './styles.module.scss';

export const BaseLayout = () => {
  const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
  const { currentUser, isLoading, isFetching, isError } = useCurrentUser(!token);
  const { open } = useSelector(sidebarSelector);
  const { logout } = useAuthHelpers();
  const dispatch = useDispatch();

  useSyncCurrentLangLS(currentUser);

  const toggleDrawer = () => {
    setLocalStorageObject(LocalStorageKeys.SidebarState, !open);
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    if (isError || !token) {
      logout(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, token]);

  if (isError || !token) {
    return null;
  }

  if (isLoading || isFetching) {
    return <LinearProgress />;
  }

  if (!currentUser?.changePasswordDate) {
    return <Navigate to={buildRoutePath(Routes.onboardingChangePassword, { absolute: true })} />;
  }

  return (
    <>
      <Sidebar chapters={navigation} open={open} toggleDrawer={toggleDrawer} />
      <main className={styles.main}>
        <div className={cn(styles.bg, { open })}>
          <Outlet />
        </div>
      </main>
    </>
  );
};
