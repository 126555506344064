import { EditForm } from '@area/kampusEvents/components/EditForm';
import { FC, useEffect, useMemo } from 'react';
import { FormKampusEventValues, defaultKampusEventValues, kampusEventTypes } from '@area/kampusEvents/defaultValues';
import { deserialize } from '@area/redactor/components/Wysiwyg';
import { ReplaceEditorContent } from 'Types';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useKampusEventById } from '@area/kampusEvents/hooks/useKampusEventById';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Routes } from '@navigation/routes';
import { useNavigate } from 'react-router';
import { buildRoutePath } from '@navigation/buildRoutePath';
import dayjs from 'dayjs';
import { TypeService } from '@services/TypeService';
import { getMetaTagsFromValues } from '@area/redactor/components/MetaTags/getMetaTagsFromValues';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';
import { useKampusUpdateEvent } from '../hooks/useKampusUpdateEvent';

interface Props {
  eventId: string;
}

export const UpdateKampusEventView: FC<Props> = ({ eventId }) => {
  const { updateKampusEvent, resultOfUpdate } = useKampusUpdateEvent(eventId);
  const { error: updateError } = resultOfUpdate;
  const { handlePushItem } = useNotifications();
  const navigate = useNavigate();
  const { kampusEvent, isLoading, isSuccess } = useKampusEventById(eventId);

  const { uploadMediaFiles, uploadError, deleteError } = useUploadMediaFiles();

  const onSubmit = async (formValues: FormKampusEventValues) => {
    const event = await updateKampusEvent(formValues);
    uploadMediaFiles({
      subjectId: event.id,
      mainImage: formValues.mainImage,
      snippetPhoto: formValues.snippetPhoto,
      photoGallery: formValues.photoGallery,
    });
  };

  useEffect(() => {
    if (resultOfUpdate.isSuccess) {
      handlePushItem({ severity: 'success', text: 'Мероприятие обновлено' });
      navigate(buildRoutePath(Routes.events, { absolute: true }));
    }
  }, [resultOfUpdate.isSuccess]);

  useEffect(() => {
    if (!kampusEvent && !isLoading && isSuccess) {
      navigate(buildRoutePath([Routes.events, Routes.newItem], { absolute: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kampusEvent, isSuccess, isLoading]);

  useEffect(() => {
    if (updateError && 'message' in updateError) {
      handlePushItem({ severity: 'error', text: updateError.message });
    }

    if (uploadError && 'message' in uploadError) {
      handlePushItem({ severity: 'error', text: uploadError.message });
    }

    if (deleteError && 'message' in deleteError) {
      handlePushItem({ severity: 'error', text: deleteError.message });
    }
  }, [updateError, handlePushItem]);

  const deserializeValues = useMemo(() => {
    if (kampusEvent) {
      return {
        ...kampusEvent,
        dateEnd: dayjs(kampusEvent.dateEnd),
        dateStart: dayjs(kampusEvent.dateStart),
        type: kampusEventTypes.find((o) => o.value === kampusEvent.type) ?? '',
        editorContent: deserialize(kampusEvent.editorContent),
        metaTags: getMetaTagsFromValues(kampusEvent.metaTags),
        embedded: kampusEvent.embedded || '',
      };
    }

    return {
      ...defaultKampusEventValues,
      editorContent: TypeService.isString(defaultKampusEventValues.editorContent)
        ? deserialize(defaultKampusEventValues.editorContent)
        : defaultKampusEventValues.editorContent,
      metaTags: getMetaTagsFromValues(),
    };
  }, [kampusEvent]);

  return (
    <>
      {isLoading && <LoadingSpinner sx={{ margin: '48px 0' }} />}
      {!isLoading && (
        <EditForm<ReplaceEditorContent<FormKampusEventValues, CustomDescendant[]>>
          defaultValues={deserializeValues}
          onSubmit={onSubmit}
          isLoading={resultOfUpdate.isLoading}
        />
      )}
    </>
  );
};
