import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useParticipantsHumansControllerUndeleteHumanMutation } from '@api/admin/adminApi';

export interface ActiveStatusSwitchProps {
  userId: string;
  isActive: boolean;
  disabled: boolean;
}

export const DeleteStatusHumanSwitch = ({ userId, isActive, disabled }: ActiveStatusSwitchProps) => {
  const [update, { isLoading }] = useParticipantsHumansControllerUndeleteHumanMutation();

  const onChange = async () => {
    const canDelete = window.confirm('Вы уверены что хотите разблокировать пользователя?');

    if (canDelete) {
      await update({
        id: userId,
      }).unwrap();

      window.location.reload();
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={isLoading || disabled}
          checked={isActive}
          title={isActive ? 'Удален' : 'Действующий'}
          onChange={onChange}
        />
      }
      label={isActive ? 'Удален' : 'Действующий'}
    />
  );
};
