import { FC } from 'react';
import cn from 'classnames';
import { PartnerResponse } from '@api/admin/adminGeneratedApi';
import { CardDecoration } from '../CardDecoration';
import { LogoDecoration } from '../LogoDecoration';
import styles from './style.module.scss';

interface Prop {
  name: PartnerResponse['name'];
  description: PartnerResponse['description'];
  categories: PartnerResponse['categories'];
  logoUrl: PartnerResponse['logoUrl'];
  color?: 'red' | 'purple';
}

export const BonusCard: FC<Prop> = ({ name, description, categories, logoUrl, color = 'purple' }) => {
  const backgroundColor = {
    [styles.purple]: color === 'purple',
    [styles.red]: color === 'red',
  };

  return (
    <div className={styles.wrap}>
      <div className={cn(styles.header, backgroundColor)}>
        <div className={cn(styles.before, backgroundColor)}></div>
        <div className={cn(styles.logoBlock, backgroundColor)}>
          <LogoDecoration />
          {logoUrl && <img src={logoUrl} className={styles.logo} alt={name} />}
        </div>
        <div className={cn(styles.titleWrap, backgroundColor)}>
          <h5 className={styles.title}>«{name}»</h5>
          <div className={styles.type}>{categories[0]?.name}</div>
        </div>
        <div className={cn(styles.after, backgroundColor)}></div>
      </div>
      <div className={cn(styles.description, backgroundColor)}>
        <div className={styles.decorationWrap}>
          <CardDecoration />
        </div>
        <span className={styles.descriptionText}>{description}</span>
      </div>
    </div>
  );
};
