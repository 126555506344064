import { Option, SelectProps } from '@components/Select/types';
import { useAuthorsControllerGetAuthorsQuery } from '@api/admin/adminApi';

export const useAuthorsOptions = (): Pick<SelectProps, 'options'> & { isLoading: boolean } => {
  const { data: newsTagsResponse, isLoading } = useAuthorsControllerGetAuthorsQuery();

  const authorsOptions: Option[] = (newsTagsResponse ?? []).map((author) => ({
    value: author.id,
    label: author.name,
  }));

  return {
    options: authorsOptions,
    isLoading,
  };
};
