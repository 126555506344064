import { IconButton } from '@mui/material';
import { Icon } from '@components/Icon';
import { MouseEvent, useEffect } from 'react';
import { useDeleteUser } from '@area/users/hooks/useDeleteUser';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import styles from './styles.module.scss';

export interface DeleteButtonProps {
  id: string;
  refetchUsers: () => void;
}

export const DeleteButton = ({ id, refetchUsers }: DeleteButtonProps) => {
  const { currentUser } = useCurrentUser();
  const { handlePushItem } = useNotifications();
  const { handleDelete, result } = useDeleteUser(id);

  const onClick = async (event: MouseEvent) => {
    event.stopPropagation();
    await handleDelete();
  };

  useEffect(() => {
    if (result.isSuccess) {
      handlePushItem({ severity: 'success', text: 'Пользователь успешно удален' });
      refetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isSuccess]);

  return (
    <IconButton
      key="delete"
      disabled={!currentUser || currentUser.id === id}
      className={styles.iconButton}
      onClick={onClick}>
      <Icon name="delete" />
    </IconButton>
  );
};
