import * as yup from 'yup';
import { InfoPageRequest } from '@api/admin/adminGeneratedApi';

export const infoPageValidation: yup.Schema<Pick<InfoPageRequest, 'title' | 'url'>> = yup.object({
  title: yup.string().min(5, 'Минимальное количество символов = 5').required('Обязательное поле.'),
  url: yup.string().min(5, 'Минимальное количество символов = 5').required('Обязательное поле.'),
  photoGallery: yup.array().of(
    yup.object({
      file: yup
        .mixed()
        .nullable()
        .test('fileSize', 'The file is too large', (value: any, { path, createError }) => {
          if (!value) return true;

          if (value.size > 5 * 1024 * 1024) {
            return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
          }

          return true;
        }),
      alt: yup.string().nullable(),
      metadata: yup.string().nullable(),
    }),
  ),
  snippetPhoto: yup
    .object({
      file: yup
        .mixed()
        .nullable()
        .test('fileSize', 'The file is too large', (value: any, { path, createError }) => {
          if (!value) return true;

          if (value.size > 5 * 1024 * 1024) {
            return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
          }

          return true;
        }),
    })
    .nullable(),
});
