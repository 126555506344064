import { useUsersControllerDeleteUserMutation } from '@api/admin/adminApi';

export const useDeleteUser = (id: string) => {
  const [remove, result] = useUsersControllerDeleteUserMutation();

  const handleDelete = async () => {
    return await remove({ id }).unwrap();
  };

  return {
    handleDelete,
    result,
  };
};
