import { FC, useState, ChangeEvent, KeyboardEvent, Dispatch, SetStateAction } from 'react';
import { TextField } from '@mui/material';
import { EducationalOrganizationControllerSetEduOrgGrantApiArg } from '@api/admin/adminGeneratedApi';

interface InputProps {
  eduId: string;
  setGrant: (arg: EducationalOrganizationControllerSetEduOrgGrantApiArg) => void;
  defaultValue: string;
  setLoadingRowId: Dispatch<SetStateAction<string | undefined>>;
}

export const Input: FC<InputProps> = ({ eduId, setGrant, defaultValue, setLoadingRowId }) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);

  const handleSave = async () => {
    if (!error) {
      setLoadingRowId(eduId);
      await setGrant({ eduId, educationalOrganizationGrantRequest: { price: Number(value) } });
      setLoadingRowId(undefined);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setValue(value);
    setError(Number.isNaN(Number(value)));
  };

  return <TextField value={value} onChange={onChange} onKeyDown={onKeyDown} error={error} onBlur={handleSave} />;
};
