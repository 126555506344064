// eslint-disable-next-line import/no-unresolved
import { SelectionMode } from 'slate/dist/interfaces/types';
import { Editor, Element, Text, Transforms } from 'slate';
import { CustomDescendant, CustomEditor, CustomElement, CustomTextMark, ElementType, List, TextAlign } from '../types';
import { listTypes, textAlignTypes } from '../constants';
import { getActiveList, unwrapList } from './lists';

export const isBlockActive = (
  editor: CustomEditor,
  value: TextAlign | ElementType,
  blockType: 'align' | 'type',
  mode?: SelectionMode,
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === value,
      mode,
    }),
  );

  return !!match;
};

export const isMarkActive = (editor: CustomEditor, mark: CustomTextMark) => {
  return Editor.marks(editor)?.[mark] === true;
};

export const toggleBlock = (editor: CustomEditor, value: TextAlign | ElementType) => {
  if (!editor.selection) return;

  const isActive = isBlockActive(editor, value, textAlignTypes.includes(value as TextAlign) ? 'align' : 'type');

  let newProperties: Partial<Element>;
  if (textAlignTypes.includes(value as TextAlign)) {
    newProperties = { align: isActive ? undefined : (value as TextAlign) };
  } else {
    const listEntry = getActiveList(editor);
    if (listEntry && listEntry[0].isNested) {
      unwrapList(editor, listEntry[0], listEntry[1]);
    }

    Transforms.unwrapNodes(editor, {
      match: (n) => Element.isElement(n) && listTypes.includes(n.type as List),
      split: true,
    });

    newProperties = { type: isActive ? 'paragraph' : (value as ElementType) };
  }

  Transforms.setNodes(editor, newProperties);
};

export const toggleMark = (editor: CustomEditor, mark: CustomTextMark, value?: boolean | string) => {
  const isActive = isMarkActive(editor, mark);

  if (isActive || (value !== undefined && !value)) {
    Editor.removeMark(editor, mark);
  } else {
    Editor.addMark(editor, mark, value ?? true);
  }
};

export const getActiveAlignment = (editor: CustomEditor) => {
  const { selection } = editor;
  if (!selection) return 'left';

  const matches = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n),
  });

  let currentAligns: TextAlign[] = [];
  for (const [node] of matches) {
    const { align = 'left' } = node as CustomElement;
    if (!~currentAligns.indexOf(align)) {
      currentAligns.push(align);
    }
  }

  return currentAligns.length === 1 ? currentAligns[0] : null;
};

export const getActiveType = (editor: CustomEditor) => {
  const { selection } = editor;
  if (!selection) return null;

  const matches = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n),
  });

  let currentTypes: ElementType[] = [];
  for (const [node] of matches) {
    const { type } = node as CustomElement;
    if (!~currentTypes.indexOf(type as ElementType)) {
      currentTypes.push(type as ElementType);
    }
  }

  return currentTypes.length === 1 ? currentTypes[0] : null;
};

export const getCharacterCount = (value: CustomDescendant[]): number => {
  return value.reduce((acc, item) => {
    if (Text.isText(item)) {
      return acc + item.text.length;
    } else if (Element.isElement(item)) {
      return acc + getCharacterCount(item.children as CustomDescendant[]);
    }

    return acc;
  }, 0);
};
