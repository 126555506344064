import { useInfoPagesControllerGetInfoPageQuery } from '@api/admin/adminGeneratedApi';

export const useGetInfoPageById = (id: string) => {
  const { data, isLoading, isError } = useInfoPagesControllerGetInfoPageQuery({
    id,
  });

  return {
    data,
    isLoading,
    isError,
  };
};
