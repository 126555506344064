import { FC } from 'react';
import { YMaps, Map as YMap, Placemark } from '@pbe/react-yandex-maps';
import { getHintContent } from '@area/partners/utils/getHintContent';
import { getCenter } from '@area/partners/utils/getCenter';
import { PartnerDepartmentResponse } from '@api/admin/adminGeneratedApi';
import styles from './styles.module.scss';
import './styles.scss';

interface IMapProps {
  departments: PartnerDepartmentResponse[];
}

export const defaultMapState = {
  zoom: 10,
};

export const Container: FC<IMapProps> = ({ departments }) => {
  const center = getCenter(departments);

  if (!departments.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.mapWrapper}>
        <YMaps>
          <YMap defaultState={{ ...defaultMapState, center }} className={styles.map} width="100%" height="100%">
            {departments.map((department) => {
              const { id, latitude, longitude } = department;
              const hintContent = getHintContent(department);

              return (
                <Placemark
                  key={id}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  defaultGeometry={[Number(latitude), Number(longitude)]}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: '/icons/map-pin.svg',
                    iconImageSize: [32, 33],
                  }}
                  properties={{
                    hintContent,
                  }}
                />
              );
            })}
          </YMap>
        </YMaps>
      </div>
    </div>
  );
};
