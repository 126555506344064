import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Table } from '@components/Table/Table';
import { PaymentResponse, PaymentStatusChangesResponse } from '@api/admin/adminGeneratedApi';
import { PaymentStatus } from '@area/payments/types';
import { columns } from './columns';

export type StatusHistoryTableProps = Pick<PaymentResponse, 'createdAt' | 'startedBy' | 'statusChanges'>;

export const StatusHistoryTable = ({ createdAt, startedBy, statusChanges }: StatusHistoryTableProps) => {
  const history = useMemo<Partial<PaymentStatusChangesResponse>[]>(() => {
    const firstElement: Partial<PaymentStatusChangesResponse> = {
      createdAt,
      triggeredBy: startedBy,
      toStatus: PaymentStatus.processing,
    };
    return [firstElement].concat(statusChanges);
  }, [createdAt, startedBy, statusChanges]);

  return (
    <Box mt={4}>
      <Typography mb={'12px'} variant={'h2'}>
        История статусов
      </Typography>
      <Table<Partial<PaymentStatusChangesResponse>> columns={columns} data={history} fetched={true} />
    </Box>
  );
};
