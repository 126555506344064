import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { AppCard } from '@area/applications/AppCard';
import { useGetApplicationById } from '@area/applications/hooks/useGetApplicationById';
import { getApplicationPageHeaderTitle } from '@area/applications/utils/getApplicationPageHeaderTitle';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { OrderType } from '@area/applications/types';

export const ApplicationPage = () => {
  const { application_type, id } = useParams();
  const { data } = useGetApplicationById(id!);

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item container flexDirection="column" gap={indents.xxs}>
        <Header
          title={getApplicationPageHeaderTitle(application_type as OrderType)}
          backBtnTitle="Назад к списку заявок"
          backBtnRoute={buildRoutePath(Routes.applications, { absolute: true })}
        />
      </Grid>
      <Grid item>{data ? <AppCard data={data} /> : <LoadingSpinner />}</Grid>
    </Grid>
  );
};
