import { Table } from '@components/Table/Table';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { columns } from './columns';

interface Props {
  data: any[];
}
export const AppProgressTable: FC<Props> = ({ data }) => {
  return (
    <Box mt={4} width={'100%'}>
      <Typography mb={2} variant={'h2'}>
        Ход выполнения
      </Typography>
      <Table columns={columns} data={data} />
    </Box>
  );
};
