import { FC, useEffect, BaseSyntheticEvent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, Typography, Link } from '@mui/material';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { DateService } from '@services/DateService';
import { CreditApplicationResponse, MediaFileResponse } from '@api/admin/adminGeneratedApi';
import { OrderStatus } from '../types';

interface Form {
  contractNumber: string;
  contractDate: Dayjs;
  personalAccountNumber: string;
  inn: string;
  checkContractNumber: boolean;
  checkContractDate: boolean;
  checkPersonalAccountNumber: boolean;
  checkInn: boolean;
  checkForm: boolean;
}

interface LoanDetailsProps {
  credit?: CreditApplicationResponse;
  creditFiles?: MediaFileResponse[];
  status: OrderStatus;
  setCreditDataValid: (value: boolean) => void;
}

export const LoanDetails: FC<LoanDetailsProps> = ({ credit, creditFiles, status, setCreditDataValid }) => {
  const defaultValues = {
    contractNumber: credit?.contractNumber,
    contractDate: credit?.contractDate ? dayjs(DateService.getNormalDate(credit?.contractDate, '.')) : undefined,
    personalAccountNumber: credit?.personalAccountNumber,
    inn: credit?.inn,
  };
  const shouldVerifyFormData = status === OrderStatus.new;

  const form = useForm<Form>({
    defaultValues,
  });

  const watchCheckContractNumber = form.watch('checkContractNumber');
  const watchCheckContractDate = form.watch('checkContractDate');
  const watchCheckPersonalAccountNumber = form.watch('checkPersonalAccountNumber');
  const watchCheckInn = form.watch('checkInn');
  const watchCheckForm = form.watch('checkForm');

  const onCheckForm = (e: BaseSyntheticEvent) => {
    const value = e.target.checked;

    form.setValue('checkContractNumber', value);
    form.setValue('checkContractDate', value);
    form.setValue('checkPersonalAccountNumber', value);
    form.setValue('checkInn', value);
  };

  useEffect(() => {
    const isFormValid =
      watchCheckContractNumber && watchCheckContractDate && watchCheckPersonalAccountNumber && watchCheckInn;

    form.setValue('checkForm', isFormValid);
  }, [watchCheckContractNumber, watchCheckContractDate, watchCheckPersonalAccountNumber, watchCheckInn]);

  useEffect(() => {
    setCreditDataValid(watchCheckForm);
  }, [watchCheckForm]);

  return (
    <Box sx={{ backgroundColor: colors.white }} borderRadius={1} p={'20px'} mt={3}>
      <Typography mb={2} variant={'h2'}>
        Данные о кредите
      </Typography>
      {credit && (
        <FormProvider {...form}>
          <Grid mt={3} item container flexDirection="row" alignItems="end">
            <FormField
              name="contractNumber"
              type="text"
              title="Номер договора"
              titleVariant="subtitle2"
              sx={{ width: 338 }}
              disabled
            />
            {shouldVerifyFormData && (
              <>
                <Box ml={4} mb={1}>
                  <FormField name="checkContractNumber" type="checkbox" />
                </Box>
                <Typography mb={0.9} variant="body2">
                  Я точно проверил
                </Typography>
              </>
            )}
          </Grid>
          <Grid mt={3} item container flexDirection="row" alignItems="end">
            <FormField
              name="contractDate"
              type="datepicker"
              title="Дата договора"
              titleVariant="subtitle2"
              sx={{ width: 338 }}
              disabled
            />
            {shouldVerifyFormData && (
              <>
                <Box ml={4} mb={1}>
                  <FormField name="checkContractDate" type="checkbox" />
                </Box>
                <Typography mb={0.9} variant="body2">
                  Я точно проверил
                </Typography>
              </>
            )}
          </Grid>
          <Grid mt={3} item container flexDirection="row" alignItems="end">
            <FormField
              name="personalAccountNumber"
              type="text"
              title="Номер лицевого счета"
              titleVariant="subtitle2"
              sx={{ width: 338 }}
              disabled
            />
            {shouldVerifyFormData && (
              <>
                <Box ml={4} mb={1}>
                  <FormField name="checkPersonalAccountNumber" type="checkbox" />
                </Box>
                <Typography mb={0.9} variant="body2">
                  Я точно проверил
                </Typography>
              </>
            )}
          </Grid>
          <Grid mt={3} item container flexDirection="row" alignItems="end">
            <FormField name="inn" type="text" title="Инн" titleVariant="subtitle2" sx={{ width: 338 }} disabled />
            {shouldVerifyFormData && (
              <>
                <Box ml={4} mb={1}>
                  <FormField name="checkInn" type="checkbox" />
                </Box>
                <Typography mb={0.9} variant="body2">
                  Я точно проверил
                </Typography>
              </>
            )}
          </Grid>
        </FormProvider>
      )}

      {creditFiles && creditFiles?.length > 0 && (
        <>
          <Typography mt={3} variant="subtitle2">
            Прикрепленные документы
          </Typography>
          {creditFiles?.map(({ id, originalFileName, url }: any) => (
            <Link
              key={id}
              href={url}
              color={colors.red}
              sx={{ textDecoration: 'none' }}
              display="flex"
              alignItems="center">
              <Typography variant="body2">{originalFileName}</Typography>
              <Icon name={'chevronRight'} />
            </Link>
          ))}
        </>
      )}
      {shouldVerifyFormData && (
        <>
          <FormProvider {...form}>
            <Grid mt={3} item container flexDirection="row" alignItems="center">
              <FormField name="checkForm" type="checkbox" onClick={onCheckForm} />
              <Typography variant="body2">Данные физлица соответствуют договору</Typography>
            </Grid>
          </FormProvider>
        </>
      )}
    </Box>
  );
};
