import { memo } from 'react';
import { Modal } from '@components/Modal';
import { Button, Grid, Typography, Stack, CircularProgress } from '@mui/material';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';

export interface LoadingModalProps {
  open: boolean;
  setShowModal: (value: boolean) => void;
  isDownloading: boolean;
  isSuccess?: boolean;
}

export const LoadingModal = memo(({ open, setShowModal, isDownloading, isSuccess }: LoadingModalProps) => {
  const onClose = () => {
    setShowModal(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open
      maxWidth="xs"
      onClose={onClose}
      title={
        <Typography variant="h1" textAlign="center">
          {isSuccess ? 'Отчет сформирован' : 'Отчет формируется'}
        </Typography>
      }
      actions={
        isSuccess && (
          <Stack alignItems="center" width="100%">
            <Button variant="contained" onClick={onClose}>
              Закрыть
            </Button>
          </Stack>
        )
      }>
      <Grid container direction="column" alignItems="center" gap="30px">
        <Grid item>
          <Typography fontWeight="normal">
            {isSuccess ? 'Отчет находится в ваших загрузках' : 'Пожалуйста, дождитесь загрузки'}
          </Typography>
        </Grid>
        <Grid item minHeight="100px">
          {isDownloading && (
            <CircularProgress
              size={80}
              sx={{
                color: colors.red,
              }}
            />
          )}
          {isSuccess && <Icon name="success" viewBox="0 0 80 80" color={colors.success} />}
        </Grid>
      </Grid>
    </Modal>
  );
});
