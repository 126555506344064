import { useParams } from 'react-router';
import { usePaymentControllerGetEducationalOrganizationsQuery } from '@api/admin/adminApi';
import { useMemo } from 'react';
import { Option } from '@components/Select/types';

export const useEducationalOrganizations = () => {
  const { id } = useParams();
  const { data, isLoading } = usePaymentControllerGetEducationalOrganizationsQuery({
    id: id!,
  });
  const educationalOrganizationsOptions = useMemo<Option[]>(() => {
    if (!data) return [];

    return data.map(({ id, nameShort }) => ({ value: id, label: nameShort }));
  }, [data]);

  return {
    educationalOrganizations: data ?? [],
    educationalOrganizationsOptions,
    isLoading,
  };
};
