import { Grid } from '@mui/material';
import { usePageTitle } from '@hooks/usePageTitle';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { Scholarships } from '@area/scholarships';

export const ScholarshipsPage = () => {
  usePageTitle('Стипендии');

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header title="Стипендии" />
      </Grid>
      <Scholarships />
    </Grid>
  );
};
