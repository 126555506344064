import { forwardRef, useRef, useMemo } from 'react';
import { Tooltip, ThemeProvider } from '@mui/material';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { DateService } from '@services/DateService';
import { DateTimePickerProps } from './types';
import { getSlots, getSlotProps } from './Slots';
import { innerTheme } from './theme';

export const DateTimePicker = forwardRef<unknown, DateTimePickerProps>((props, _) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const slots = getSlots({ ...props, textFieldProps: { ...props.textFieldProps, error: !!props.error } });
  const slotProps = getSlotProps(wrapperRef);

  const dateTimePicker = useMemo(
    () => (
      <div ref={wrapperRef}>
        <MuiDateTimePicker
          format={DateService.dateFormats.dateTimeField}
          dayOfWeekFormatter={(day: string) => day}
          {...props}
          value={props.value}
          slots={slots}
          slotProps={slotProps}
        />
      </div>
    ),
    [props.value],
  );

  return (
    <ThemeProvider theme={innerTheme}>
      <Tooltip open={Boolean(props.error)} title={props.error} arrow placement="bottom-start">
        {dateTimePicker}
      </Tooltip>
    </ThemeProvider>
  );
});
