import {
  useNotificationsControllerGetByIdQuery,
  useNotificationsControllerUpdateMutation,
} from '@api/admin/adminGeneratedApi';
import { PushFormData } from '@area/notificationsPage/validation/validationSchemaPush';
import { deserialize, serialize } from '@area/redactor/components/Wysiwyg';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Header } from '@layouts/Base/components/Header';
import { EditPushForm } from '@area/notificationsPage/components/EditPushForm';
import { usePageTitle } from '@hooks/usePageTitle';
import { DefaultValues, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import styles from './style.module.scss';

export const EditPushPage = () => {
  usePageTitle('Push-рассылка');
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: notificationMailing } = useNotificationsControllerGetByIdQuery({
    id: id ?? '',
  });

  const defaultValues: DefaultValues<PushFormData> = {
    title: notificationMailing?.title ?? '',
    text: notificationMailing?.text
      ? deserialize(notificationMailing?.text)
      : [{ type: 'paragraph', children: [{ text: '' }] }],
    date: dayjs(notificationMailing?.sendAt),
    link: notificationMailing?.link ?? '',
    targets: notificationMailing?.targets?.join(',') ?? '',
  };

  const [updateNotificationMailing, { isLoading }] = useNotificationsControllerUpdateMutation();

  const onEditPush: SubmitHandler<PushFormData> = async (formData) => {
    await updateNotificationMailing({
      id: id ?? '',
      notificationsRequest: {
        type: 3,
        sendAt: formData.date.toString(),
        title: formData.title,
        text: serialize(formData.text),
        targets: formData.targets.split(','),
        link: formData.link,
      },
    });
    navigate(buildRoutePath(Routes.notifications, { absolute: true }));
  };

  if (!notificationMailing) {
    return null;
  }

  const readonly =
    notificationMailing &&
    (notificationMailing?.status >= 1 || new Date(notificationMailing?.sendAt).getTime() <= new Date().getTime());

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Push-рассылка"
          backBtnTitle="Назад к списку рассылок"
          backBtnRoute={buildRoutePath(Routes.notifications, { absolute: true })}
        />
      </Grid>
      <EditPushForm readonly={readonly} onSubmit={onEditPush} defaultValues={defaultValues} isLoading={isLoading} />
    </Grid>
  );
};
