import { ImageElement } from '../../../types';

export const serializeImage = (node: ImageElement): string => {
  const dataImageId = node.imageId ? `data-image-id="${node.imageId}"` : '';
  const dataImageAuthor = node.author ? `data-image-author="${node.author}"` : '';
  const dataImageDescription = node.description ? `data-image-description="${node.description}"` : '';
  const author = node.author ? `<div class="image-author">${node.author}</div>` : '';
  const description = node.description ? `<div class="image-description">${node.description}</div>` : '';

  return `<div ${dataImageId} ${dataImageAuthor} ${dataImageDescription} class="image-${node.position}"><img src="${node.imageUrl}" />${author}${description}</div>`;
};
