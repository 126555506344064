import { PartnerDepartmentResponse } from '@api/admin/adminGeneratedApi';

export const getHintContent = (department: PartnerDepartmentResponse): string => {
  const { name, address, phone, operatingMode } = department;

  const operationModeRows = operatingMode
    .split(';')
    .map((row) => row.trim())
    .filter((row) => row.length)
    .map((row) => `<p class="balloon-hint__text">${row}</p>`)
    .join('');

  return `<div class="balloon-hint">
            <h3 class="balloon-hint__name">${name}</h3>
            <p class="balloon-hint__text">${address}</p>
            <p class="balloon-hint__text">${phone}</p>
            <div class="balloon-hint__operating-mode">${operationModeRows}</div>
          </div>
        `;
};
