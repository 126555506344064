import { useMemo, useRef } from 'react';
import { IconButton, Typography } from '@mui/material';
import { EditModalHandlers } from '@area/settings/components/EditModal/types';
import { SettingResponse, useSettingsControllerGetQuery } from '@api/admin/adminGeneratedApi';
import { Column } from '@components/Table/types';
import { Icon } from '@components/Icon';
import { ValueCell } from '@area/settings/components/ValueCell';
import styles from '../styles.module.scss';

export const useSettings = () => {
  const modalRef = useRef<EditModalHandlers>(null);

  const settings = useSettingsControllerGetQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      return {
        ...rest,
        data:
          data &&
          [...data]?.sort((a, b) => {
            if (a.groupName > b.groupName) {
              return 1;
            }
            if (a.groupName < b.groupName) {
              return -1;
            }
            return 0;
          }),
      };
    },
  });

  const columns = useMemo<Column<SettingResponse>[]>(() => {
    return [
      {
        title: 'Название',
        dataIndex: 'title',
        width: 350,
        render: (value: string) => (
          <Typography variant="body2" className={styles.title}>
            {value}
          </Typography>
        ),
      },
      {
        title: 'Группа',
        dataIndex: 'groupName',
        width: 120,
      },
      {
        title: 'Описание',
        dataIndex: 'help',
        ellipsis: true,
      },
      {
        title: 'Тип данных',
        dataIndex: 'label',
        width: 150,
        ellipsis: true,
      },
      {
        title: 'Значение',
        align: 'center',
        dataIndex: 'value',
        render: ValueCell,
        width: 120,
        ellipsis: true,
      },
      {
        title: 'Значение по умолчанию',
        align: 'center',
        dataIndex: 'defaultValue',
        render: ValueCell,
        width: 120,
        ellipsis: true,
      },
      {
        title: '',
        dataIndex: 'action',
        align: 'center',
        width: 80,
        render: (_: unknown, record) => (
          <IconButton className={styles.iconButton} onClick={() => modalRef.current?.open(record)}>
            <Icon name="edit" />
          </IconButton>
        ),
      },
    ];
  }, []);

  return {
    modalRef,
    settings,
    columns,
  };
};
