import { FC, useState, useEffect, SyntheticEvent } from 'react';
import { Tabs as MuiTabs, Tab, TabsProps as MuiTabsProps } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Tab as TabType } from './types';
import styles from './styles.module.scss';

import { TabPanel } from './TabPanel';

interface TabsProps extends MuiTabsProps {
  name: string;
  tabs: TabType[];
  defaultValue?: number;
  tabClassName?: string;
  tabPanelClassName?: string;
}

export const Tabs: FC<TabsProps> = ({ name, tabs, defaultValue, tabClassName, tabPanelClassName, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(defaultValue || Number(searchParams.get(name)));

  const setParams = (value: number) => {
    const params = [...searchParams.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    setSearchParams({
      ...searchParams,
      ...params,
      [name]: value,
    });
  };

  const onChange = (e: SyntheticEvent<Element, Event>, value: number) => {
    setParams(value);
    setValue(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setParams(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <>
      <MuiTabs className={styles.scrollTabs} value={value} onChange={onChange} {...props}>
        {tabs.map(({ label }) => (
          <Tab key={label} label={label} className={tabClassName} />
        ))}
      </MuiTabs>

      {tabs.map(({ label, content }, index) => (
        <TabPanel key={label} value={value} index={index}>
          <div className={tabPanelClassName}>{content}</div>
        </TabPanel>
      ))}
    </>
  );
};
