import * as yup from 'yup';
import dayjs from 'dayjs';
import { EventRequest } from '@api/admin/adminGeneratedApi';

export const kampusEventValidation: yup.Schema<
  Pick<EventRequest, 'title' | 'locationName' | 'url'> & { dateStart: Date; dateEnd: Date }
> = yup.object({
  dateStart: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;

      return dayjs(originalValue).format();
    })
    .typeError('Неправильный формат даты')
    .required('Обязательное поле.'),
  dateEnd: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;

      return dayjs(originalValue).format();
    })
    .typeError('Неправильный формат даты')
    .required('Обязательное поле.'),
  title: yup.string().min(5, 'Минимальное количество символов = 5').required('Обязательное поле.'),
  url: yup.string().min(5, 'Минимальное количество символов = 5').required('Обязательное поле.'),
  locationName: yup.string().min(5, 'Минимальное количество символов = 5').required('Обязательное поле.'),
  type: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .typeError('Обязательное поле.')
    .required('Обязательное поле.'),
  mainImage: yup.object({
    file: yup
      .mixed()
      .nullable()
      .test('fileSize', 'The file is too large', (value: any, { path, createError }) => {
        if (!value) return true;

        if (value.size > 5 * 1024 * 1024) {
          return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
        }

        return true;
      }),
  }),
  photoGallery: yup.array().of(
    yup.object({
      file: yup
        .mixed()
        .nullable()
        .test('fileSize', 'The file is too large', (value: any, { path, createError }) => {
          if (!value) return true;

          if (value.size > 5 * 1024 * 1024) {
            return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
          }

          return true;
        }),
      alt: yup.string().nullable(),
      metadata: yup.string().nullable(),
    }),
  ),
  snippetPhoto: yup
    .object({
      file: yup
        .mixed()
        .nullable()
        .test('fileSize', 'The file is too large', (value: any, { path, createError }) => {
          if (!value) return true;

          if (value.size > 5 * 1024 * 1024) {
            return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
          }

          return true;
        }),
    })
    .nullable(),
});
