import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { useMatch, useParams } from 'react-router';
import { UpdateKampusEventView } from '@area/kampusEvents/views/UpdateKampusEventView';
import { CreateKampusEventView } from '@area/kampusEvents/views/CreateKampusEventView';
import styles from './style.module.scss';

export const EditKampusEvent = () => {
  const isNew = Boolean(useMatch(buildRoutePath([Routes.events, Routes.newItem])));
  const title = isNew ? 'Создание мероприятия' : 'Мероприятие';
  const params = useParams();
  usePageTitle(title);

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title={title}
          backBtnTitle="Назад к списку мероприятий"
          backBtnRoute={buildRoutePath(Routes.events, { absolute: true })}
        />
      </Grid>
      {params.id ? <UpdateKampusEventView eventId={params.id} /> : <CreateKampusEventView />}
    </Grid>
  );
};
