import { Option } from '@components/Select/types';

// eslint-disable-next-line typescript-enum/no-enum
export enum NsiOperationType {
  retrieve = 'retrieve',
  insert = 'insert',
  update = 'update',
  delete = 'delete',
}

export const nsiOperationTypeOptions: Option[] = Object.entries(NsiOperationType).map(([value, label]) => ({
  value,
  label,
}));
