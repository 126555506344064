import { EmailFormData } from '@area/notificationsPage/validation/validationSchemaEmail';
import { serialize } from '@area/redactor/components/Wysiwyg';
import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Header } from '@layouts/Base/components/Header';
import { EditEmailForm } from '@area/notificationsPage/components/EditEmailForm';
import { usePageTitle } from '@hooks/usePageTitle';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useNotificationsControllerCreateMutation } from '@api/admin/adminApi';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';

export const NewEmailPage = () => {
  usePageTitle('Новая email-рассылка');
  const navigate = useNavigate();

  const defaultValues = {
    targets: '',
    text: [{ type: 'paragraph', children: [{ text: '' }] }] as CustomDescendant[],
  };

  const [createNotification, { isLoading }] = useNotificationsControllerCreateMutation();

  const onCreateEmail: SubmitHandler<EmailFormData> = async (formData) => {
    const createdNotification = await createNotification({
      notificationsRequest: {
        type: 1,
        sendAt: undefined,
        title: formData.title,
        sender: formData.from?.value,
        text: serialize(formData.text),
        targets: formData.targets.split(','),
      },
    }).unwrap();
    navigate(
      buildRoutePath([Routes.notifications, 'email', createdNotification?.id.toString() as any], { absolute: true }),
    );
  };

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header
          title="Новая email-рассылка"
          backBtnTitle="Назад к списку рассылок"
          backBtnRoute={buildRoutePath(Routes.notifications, { absolute: true })}
        />
      </Grid>
      <Grid item>
        <EditEmailForm onSubmit={onCreateEmail} defaultValues={defaultValues} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};
