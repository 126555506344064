import { useApplicationControllerGetApplicationQuery } from '@api/admin/adminGeneratedApi';

export const useGetApplicationById = (id: string) => {
  const { data, isLoading, isError, refetch } = useApplicationControllerGetApplicationQuery({
    id,
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
