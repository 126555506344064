import { PaymentCandidatesResponse } from '@api/admin/adminGeneratedApi';
import { Typography } from '@mui/material';
import { PaymentCandidateProcessStatusColors, PaymentCandidateProcessStatusName } from '@area/payments/defaultValues';
import { PaymentCandidateProcessStatus } from '@area/payments/types';

const getStatus = ({
  errorDate,
  rejectedDate,
  approvedDate,
  deletedAt,
}: PaymentCandidatesResponse): PaymentCandidateProcessStatus | undefined => {
  if (errorDate) {
    return PaymentCandidateProcessStatus.error;
  }

  if (rejectedDate) {
    return PaymentCandidateProcessStatus.rejected;
  }

  if (approvedDate) {
    return PaymentCandidateProcessStatus.approved;
  }

  if (deletedAt) {
    return PaymentCandidateProcessStatus.deleted;
  }
};

export const Status = (candidate: PaymentCandidatesResponse) => {
  const status = getStatus(candidate);

  if (!status) return <></>;

  return (
    <Typography color={PaymentCandidateProcessStatusColors[status]}>
      {PaymentCandidateProcessStatusName[status]}
    </Typography>
  );
};
