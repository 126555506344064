import { useMemo, useState } from 'react';
import colors from '@styles/colors.module.scss';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { Select } from '@components/Select';
import { useEducationalOrganizations } from '@area/payments/hooks/useEducationalOrganizations';
import { EducationalOrganizationResponse } from '@api/admin/adminGeneratedApi';
import { Option } from '@components/Select/types';
import { DownloadDocButtons } from './DownloadDocButtons';
import styles from './styles.module.scss';

const columns: Column<EducationalOrganizationResponse>[] = [
  {
    title: 'ВУЗ',
    dataIndex: 'fullName',
    sx: { whiteSpace: 'wrap' },
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    width: 200,
    render: (id: EducationalOrganizationResponse['id']) => <DownloadDocButtons educationalOrganizationId={id} />,
  },
];

export const PaymentsByUniversity = () => {
  const [selectedEducationalOrganizations, setSelectedEducationalOrganizations] = useState<Option[]>([]);
  const { educationalOrganizations, educationalOrganizationsOptions, isLoading } = useEducationalOrganizations();

  const filteredEducationalOrganizations = useMemo(() => {
    if (!selectedEducationalOrganizations.length) return educationalOrganizations;

    const set = new Set(selectedEducationalOrganizations.map(({ value }) => value));

    return educationalOrganizations.filter(({ id }) => set.has(id));
  }, [educationalOrganizations, selectedEducationalOrganizations]);

  if (isLoading) {
    return null;
  }

  const onFilterChange = (values: Option | Option[]) => {
    setSelectedEducationalOrganizations(Array.isArray(values) ? values : [values]);
  };

  return (
    <Box sx={{ border: `1px solid ${colors.greyLight}` }} borderRadius={1} mt={4}>
      <Accordion>
        <AccordionSummary className={styles.accordion} expandIcon={<Icon name={'chevronRight'} color={colors.red} />}>
          <Typography variant={'h2'}>Выплаты по ВУЗам</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            multiple
            placeholder="Выберите ВУЗы"
            sx={{ width: 480 }}
            options={educationalOrganizationsOptions}
            onChange={onFilterChange}
          />
          <Box mt={3}>
            <Table<EducationalOrganizationResponse>
              columns={columns}
              data={filteredEducationalOrganizations}
              fetched={!isLoading}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
