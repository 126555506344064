import { FC } from 'react';

export const CardDecoration: FC = () => {
  return (
    <svg viewBox="0 0 127 286" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M0.242188 285.468L98.3341 285.467C114.043 285.467 126.777 273.036 126.777 257.701V28.2669C126.777 12.9322 114.043 0.500977 98.3341 0.500977H65.9755C36.6671 12.1335 16.0299 39.9783 16.0299 72.4784C16.0299 104.018 35.4653 131.173 63.3999 143.382L0.242188 285.468Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};
