import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StorageState } from '@store/index';

export interface UserState {
  login: {
    otpData: null | {
      email: string;
      secret?: string;
    };
  };
}

const initialState: UserState = {
  login: {
    otpData: null,
  },
};

export const userSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setOtpData: (state, { payload }: PayloadAction<UserState['login']['otpData'] | null>) => {
      state.login.otpData = payload;
    },
  },
});

export const { setOtpData } = userSlice.actions;

export const otpSelector = (state: StorageState) => state.userSlice.login.otpData;
