import { useMemo } from 'react';
import { Column } from '@components/Table/types';
import { useOutlet, useParams } from 'react-router';
import { Table } from '@components/Table/Table';
import { usePageTitle } from '@hooks/usePageTitle';
import { DateService } from '@services/DateService';
import {
  HumanPayResponseItem,
  useParticipantsHumansControllerGetHumanPayHistoryQuery,
} from '@api/admin/adminGeneratedApi';

export const Payouts = () => {
  usePageTitle('Участники программ');
  const { id } = useParams();

  const { data } = useParticipantsHumansControllerGetHumanPayHistoryQuery({
    id: id as string,
  });

  const outlet = useOutlet();

  const checkType = (value: string) => {
    switch (value) {
      case 'creditGrant':
        return 'Компенсация образовательного кредита';
      case 'extraGrant':
        return 'Выплата дополнительной стипендии';
      default:
        break;
    }
  };

  const columns: Column<HumanPayResponseItem>[] = useMemo(
    () => [
      {
        title: '№ выгрузки',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Дата формирования выгрузки',
        dataIndex: 'date',
        key: 'date',
        render: (value: string) => DateService.getLocalDate(new Date(value), 'DD.MM.YYYY'),
      },
      {
        title: 'Тип программы',
        dataIndex: 'type',
        key: 'type',
        render: (value: string) => checkType(value),
      },
      {
        title: 'Сумма, руб.',
        dataIndex: 'price',
        key: 'price',
      },
    ],
    [],
  );

  if (outlet) {
    return outlet;
  }

  if (!data) {
    return null;
  }

  if (!data.data.length) {
    return <>Нет данных</>;
  }

  return <Table<HumanPayResponseItem> columns={columns} data={data.data} />;
};
