import { useNavigate } from 'react-router';
import { SyncDataResponseDto } from '@api/admin/adminGeneratedApi';
import { Table } from '@components/Table/Table';
import { columns } from '@area/nsi/components/NsiTable/columns';
import { useFilterNsiLogs } from '@area/nsi/hooks/useFilterNsiLogs';

export const NsiTable = () => {
  const navigate = useNavigate();
  const onRowClick = ({ id }: SyncDataResponseDto) => navigate(id);
  const { logs, count, isLoading, isFetched } = useFilterNsiLogs();

  return (
    <Table<SyncDataResponseDto>
      tableLayout="fixed"
      showPagination
      paginationProps={{ count, labelRowsPerPage: 'Количество записей на странице' }}
      columns={columns}
      data={logs}
      loading={isLoading}
      fetched={isFetched}
      onRowClick={onRowClick}
    />
  );
};
