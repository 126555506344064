import { Avatar } from '@mui/material';
import { FC } from 'react';

interface Props {
  name: string;
  photo?: string;
}

export const UserPhoto: FC<Props> = ({ name, photo }) => {
  if (photo) {
    return <Avatar alt={name} src={photo} />;
  }

  return <Avatar>{name[0]}</Avatar>;
};
