import { Grid, Paper } from '@mui/material';
import { Table } from '@components/Table/Table';
import { SettingResponse } from '@api/admin/adminGeneratedApi';
import { EditModal } from '@area/settings/components/EditModal';
import { useSettings } from '@area/settings/hooks/useSettings';

export const Settings = () => {
  const { modalRef, settings, columns } = useSettings();

  return (
    <>
      <Grid item sx={{ width: '100%' }}>
        <Paper sx={{ padding: '20px' }}>
          <Table<SettingResponse>
            columns={columns}
            data={settings.data ?? []}
            loading={settings.isLoading}
            tableLayout="fixed"
          />
        </Paper>
      </Grid>
      <EditModal modalRef={modalRef} onSubmit={settings.refetch} />
    </>
  );
};
