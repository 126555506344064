import { FC, useImperativeHandle, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from '@components/Modal';
import { TranslationResponse } from '@api/admin/adminGeneratedApi';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { useResourcesControllerCreateOrUpdateTranslationMutation } from '@api/admin/adminApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { EditModalProps, EditModalFormData } from './types';
import { validationSchema } from './validation';

export const EditModal: FC<EditModalProps> = ({ modalRef, languages, onSubmit }) => {
  const [updater] = useResourcesControllerCreateOrUpdateTranslationMutation();
  const [open, setOpen] = useState(false);

  const resolver = useYupValidationResolver<EditModalFormData>(validationSchema);
  const form = useForm<EditModalFormData>({ resolver });

  useImperativeHandle(modalRef, () => ({
    open: (t: TranslationResponse & { language?: string }) => {
      setOpen(true);
      form.reset({ ...t, language_option: { value: t.languageId, label: t.language } });
    },
  }));

  const onClose = () => {
    setOpen(false);
    form.reset();
  };
  const onLocalSubmit = form.handleSubmit(async (data) => {
    const { language_option, language, ...rest } = data;
    await updater({ translationRequest: { ...rest, languageId: language_option.value } });
    setOpen(false);
    onSubmit();
  });

  const { isSubmitting, isDirty, isValid } = form.formState;

  return (
    <Modal
      title="Изменение перевода"
      open={open}
      onClose={onClose}
      maxWidth="xs"
      actions={
        <Grid container gap={indents.s}>
          <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
            Отмена
          </Button>
          <Button variant="contained" onClick={onLocalSubmit} disabled={isSubmitting || !isDirty || !isValid}>
            Сохранить
          </Button>
        </Grid>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.m}>
          <FormField<EditModalFormData>
            name="mnemonic"
            fullWidth
            placeholder="Введите мнемоник"
            title="Мнемоник"
            disabled
          />
          <FormField<EditModalFormData>
            required
            name="translation"
            fullWidth
            placeholder="Введите перевод"
            title="Перевод"
          />
          <FormField<EditModalFormData>
            name="language_option"
            type="select"
            options={languages.map(({ id, title }) => ({ value: id, label: title }))}
            placeholder="Выберите язык"
            title="Язык"
          />
        </Grid>
      </FormProvider>
    </Modal>
  );
};
