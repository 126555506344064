import { useNewsControllerGetNewsQuery } from '@api/admin/adminGeneratedApi';

export const useGetPublicationById = (id: string) => {
  const { data, isLoading, isError } = useNewsControllerGetNewsQuery({
    id,
  });

  return {
    data,
    isLoading,
    isError,
  };
};
