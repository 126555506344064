import { useEffect } from 'react';
import { Box, Button, Grid, Typography, LinearProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import colors from '@styles/colors.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import indents from '@styles/indents.module.scss';
import {
  useTicketControllerCreateTicketMutation,
  HumanShortResponse,
  HumanResponse,
} from '@api/admin/adminGeneratedApi';
import { useHumans } from '@area/studentsPage/hooks/useHumans';
import { validationSchema, TValues } from '@area/tickets/Form/validationSchema';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { subjectOptions } from '@area/tickets/defaultValues';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useNavigate } from 'react-router';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import styles from './styles.module.scss';

const HUMANS_COUNT = 100;

export const NewTicketData = () => {
  const resolver = useYupValidationResolver<TValues>(validationSchema);
  const form = useForm({
    resolver,
    defaultValues: {
      subject: '',
      text: '',
    },
  });
  const { getHumans, humansList, isLoading } = useHumans();
  const [createTicket, { isLoading: loadginCreatingTicket, isSuccess }] = useTicketControllerCreateTicketMutation();
  const { handlePushItem } = useNotifications();
  const navigate = useNavigate();

  const fetcher = (search: string) => {
    getHumans({
      skip: 0,
      size: HUMANS_COUNT,
      asc: true,
      humansFilterRequest: {
        search,
      },
    });
  };

  const handleSubmit = form.handleSubmit((formData) => {
    const userEmail = (formData.email as HumanResponse)?.email;
    const userId = typeof formData.email === 'string' ? undefined : formData.email?.id;

    createTicket({
      participantTicketRequest: {
        subject: (formData.subject as any).value,
        text: formData.text,
        userName: formData.userName,
        userEmail,
        userId,
      },
    });
  });

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Обращение созано успешно' });
      navigate(buildRoutePath(Routes.tickets, { absolute: true }));
    }
  }, [isSuccess]);

  return (
    <Box className={styles.root} sx={{ backgroundColor: colors.white }} width="100%" borderRadius={1} p="20px">
      <Typography variant="h2">От кого</Typography>
      <FormProvider {...form}>
        <Grid width="50%" columns={{ xs: 2 }} container flexDirection="column" gap={indents.xs} mt={1.5}>
          <FormField
            titleVariant="subtitle2"
            name="subject"
            type="select"
            title="Тема"
            sx={{ width: '100%' }}
            options={subjectOptions}
          />
          <FormField<HumanShortResponse>
            titleVariant="subtitle2"
            name="email"
            type="autocomplete"
            title="Email"
            sx={{ width: '100%' }}
            fetcher={fetcher}
            // TODO: fix
            // @ts-ignore
            options={humansList || []}
            loading={isLoading}
            getOptionLabel={(option) => (option as HumanShortResponse).email}
            freeSolo
            disableClearable
          />
          <FormField titleVariant="subtitle2" name="userName" type="text" title="ФИО" sx={{ width: '100%' }} />
          <FormField
            className={styles.ticketText}
            titleVariant="subtitle2"
            name="text"
            title="Текст обращения"
            sx={{ width: '100%' }}
          />
        </Grid>
      </FormProvider>
      <Button
        variant="contained"
        startIcon={<Icon name="logoIcon" style={{ color: colors.red }} />}
        sx={{ marginTop: '32px', padding: '8px 16px' }}
        onClick={handleSubmit}>
        Сохранить
      </Button>
      {loadginCreatingTicket && <LinearProgress className={styles.loadingIndicator} />}
    </Box>
  );
};
