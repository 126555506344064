import { useParams } from 'react-router';
import { Icon } from '@components/Icon';
import { Button } from '@mui/material';
import { PaymentStatus } from '@area/payments/types';
import { ChangeStatusButtonIcons, ChangeStatusButtonTitles } from '@area/payments/defaultValues';
import { usePaymentControllerChangeStatusMutation } from '@api/admin/adminApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';

export interface PaymentChangeStatusButtonProps {
  status: PaymentStatus;
  loading: boolean;
  onSuccess: () => void;
}

export const PaymentChangeStatusButton = ({ status, loading, onSuccess }: PaymentChangeStatusButtonProps) => {
  const { handlePushItem } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const [patch, { isLoading }] = usePaymentControllerChangeStatusMutation();

  const onClick = async () => {
    await patch({ id: id! }).unwrap();
    handlePushItem({ severity: 'success', text: 'Статус успешно изменен' });
    onSuccess();
  };

  return (
    <Button
      variant="contained"
      startIcon={<Icon name={ChangeStatusButtonIcons[status]} />}
      disabled={loading || isLoading || status === PaymentStatus.approved}
      onClick={onClick}>
      {ChangeStatusButtonTitles[status]}
    </Button>
  );
};
