import { MetaRequest } from '@api/admin/adminGeneratedApi';
import { InputFormFieldProps } from '@components/FormField/types';
import { MetaTagTitles } from './types';

export const metaTagFields: Record<MetaTagTitles, Partial<InputFormFieldProps>> = {
  [MetaTagTitles.title]: {
    title: 'Title',
    type: 'text',
    fullWidth: true,
  },
  [MetaTagTitles.description]: {
    title: 'Description',
    type: 'text',
    fullWidth: true,
    multiline: true,
    minRows: 2,
  },
  [MetaTagTitles.keywords]: {
    title: 'Keywords',
    type: 'text',
    fullWidth: true,
  },
  [MetaTagTitles.viewport]: {
    title: 'Viewport',
    type: 'text',
    fullWidth: true,
    multiline: true,
    minRows: 2,
  },
};

export const defaultMetaTags: MetaRequest[] = [
  {
    name: MetaTagTitles.title,
    content: '',
  },
  {
    name: MetaTagTitles.description,
    content: '',
  },
  {
    name: MetaTagTitles.keywords,
    content: '',
  },
  {
    name: MetaTagTitles.viewport,
    content: '',
  },
];
