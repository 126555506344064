import { useEffect } from 'react';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { Routes } from '@navigation/routes';
import { InputMask } from '@components/Input/types';
import { usePageTitle } from '@hooks/usePageTitle';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { useDispatch, useSelector } from 'react-redux';
import { otpSelector, setOtpData } from '@area/user/store';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';
import { useOtpForm } from '@area/user/hooks/useOtpForm';
import styles from './style.module.scss';

export type OtpFormData = {
  otp: string;
};

export const OtpPage = () => {
  usePageTitle('Двухфакторная защита');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, secret } = useSelector(otpSelector) ?? {};
  const { login, logout } = useAuthHelpers();
  const { onSubmit, isLoading, otpFieldRules, form } = useOtpForm(email, login);

  useEffect(() => {
    if (!email) {
      logout();
    } else if (secret) {
      navigate(buildRoutePath([Routes.login, Routes.confirm], { absolute: true }));
      return;
    }

    return () => {
      dispatch(setOtpData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, secret]);

  return (
    <FormProvider {...form}>
      <Grid container flexDirection="column" gap={4} className={styles.root}>
        <Grid item>
          <Typography variant="h1" textAlign="center">
            Двухфакторная защита
          </Typography>
          <Typography component="div" className={styles.description} variant="caption" textAlign="center">
            Введите код доступа из мобильного приложения
          </Typography>
        </Grid>
        <Grid item container>
          <Icon name="key" width={32} height={32} className={styles.fieldLabel} />
          <FormField
            autoFocus
            className={styles.field}
            name="otp"
            type="text"
            placeholder="Одноразовый код"
            rules={otpFieldRules}
            mask={InputMask.Otp}
          />
          <Icon name="keyHole" width={32} height={32} className={styles.keyHole} />
        </Grid>
        <Grid item display="flex" alignItems="center" gap={1} className={styles.buttons}>
          <Button className={styles.button} disabled={isLoading} onClick={onSubmit} variant="contained">
            Отправить
          </Button>
          <Button className={styles.button} disabled={isLoading} onClick={() => logout()} variant="outlined">
            Назад
          </Button>
        </Grid>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
    </FormProvider>
  );
};
