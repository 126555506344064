import { MouseEvent } from 'react';
import { PaymentStatus } from '@area/payments/types';
import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '@components/Icon';
import { PaymentShortResponse } from '@api/admin/adminGeneratedApi';
import {
  usePaymentControllerChangeStatusMutation,
  usePaymentControllerDeletePaymentMutation,
} from '@api/admin/adminApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import styles from './styles.module.scss';

export type ActionButtonProps = PaymentShortResponse & { onSuccess?: () => void };

export const ActionButton = ({ id, status, onSuccess }: ActionButtonProps) => {
  const { handlePushItem } = useNotifications();

  const [patch, { isLoading: isPatching }] = usePaymentControllerChangeStatusMutation();
  const [remove, { isLoading: isRemoving }] = usePaymentControllerDeletePaymentMutation();

  const onClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const params = { id };
    if (status === PaymentStatus.processing) {
      await patch(params).unwrap();
      handlePushItem({ severity: 'success', text: 'Выплата успешно остановлена' });
    } else {
      await remove(params).unwrap();
      handlePushItem({ severity: 'success', text: 'Выплата успешно удалена' });
    }

    onSuccess?.();
  };

  return (
    <Tooltip title={status === PaymentStatus.processing ? 'Остановить' : 'Удалить'}>
      <IconButton className={styles.iconButton} onClick={onClick} disabled={isPatching || isRemoving}>
        <Icon className={styles.icon} name={status === PaymentStatus.processing ? 'close' : 'delete'} />
      </IconButton>
    </Tooltip>
  );
};
