import { FC, PropsWithChildren } from 'react';

interface TabPanelProps extends PropsWithChildren {
  value: number;
  index?: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  );
};
