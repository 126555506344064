import { FC } from 'react';

export const LogoDecoration: FC = () => {
  return (
    <svg viewBox="0 0 115 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.230469 19.7461C0.230469 9.25267 8.73706 0.746094 19.2305 0.746094L95.5589 0.746094C108.466 0.746094 117.617 13.3396 113.63 25.6155L95.206 82.3385C92.663 90.168 85.3673 95.4692 77.1353 95.4692H19.2305C8.73705 95.4692 0.230469 86.9626 0.230469 76.4692V19.7461Z"
        fill="currentColor"
      />
    </svg>
  );
};
