import { forwardRef, ChangeEvent, useId, useState, useCallback } from 'react';
import cn from 'classnames';
import { Box, Typography, LinearProgress, Stack, Button, IconButton, Grid } from '@mui/material';
import { Icon } from '@components/Icon';
import { Input } from '@components/Input';

import indents from '@styles/indents.module.scss';

import styles from './styles.module.scss';

import { ImagePickerProps } from './types';

export const ImagePickerPartner = forwardRef(
  ({ value, onChange, accept, multiple, canUseLink, imageUrl, onDelete }: ImagePickerProps, _) => {
    // TODO: remove loading when BE logic will be implemented
    const [loading, setLoading] = useState(false);
    const id = useId();
    const [url, setUrl] = useState('');

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value);

    const onBtnClick = useCallback(() => {
      // TODO: implement changing imgage logic
      // onChange?.(url);
    }, []);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        try {
          setLoading(true);
          onChange?.(multiple ? [...(value as File[]), e.target.files[0]] : e.target.files[0]);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    };

    const fileInput = useCallback(
      () => (
        <input
          id={id}
          type="file"
          onChange={onFileChange}
          accept={accept}
          className={styles.input}
          multiple={multiple}
        />
      ),
      [],
    );

    const inputWrapper = useCallback(
      () => (
        <Stack direction="row" gap={indents.xs}>
          <Input
            className={styles.textField}
            placeholder="Ссылка на изображение"
            value={url}
            onChange={onInputChange}
            fullWidth
          />

          <IconButton onClick={onBtnClick}>
            <Icon name="check" />
          </IconButton>
        </Stack>
      ),
      [url, onBtnClick],
    );

    const handleDelete = () => {
      if (value) {
        // TODO: remove if won't need
        //   onChange?.(multiple ? (value as string[]).filter((val: string) => val !== value[0]) : undefined)
        onChange?.(undefined);
        return;
      }
      if (imageUrl) onDelete?.();
    };

    if (loading) {
      return (
        <Stack gap="20px">
          <Box className={cn(styles.imgWrapper, { loading })}>
            <Typography variant="body2">Загружаем фото</Typography>
            {/* TODO: add loading in percents if needed */}
            <LinearProgress className={styles.indicator} />
          </Box>

          {canUseLink && inputWrapper()}
        </Stack>
      );
    }

    if (!value && !imageUrl) {
      return (
        <Stack gap="20px">
          <label htmlFor={id} className={styles.imgWrapper}>
            <Icon name="photo" viewBox="0 0 52 42" />

            <Typography variant="body2">Загрузить</Typography>

            {fileInput()}
          </label>

          {canUseLink && inputWrapper()}
        </Stack>
      );
    }

    const imagePreview = (() => {
      if (!value || !((value as any) instanceof File)) return;
      return multiple && Array.isArray(value)
        ? URL.createObjectURL(value?.[0] as File)
        : URL.createObjectURL(value as File);
    })();

    return (
      <Stack gap="20px" flexDirection="row">
        <Box className={cn(styles.imgWrapper, { image: !!value || !!imageUrl })}>
          <img src={imagePreview ?? imageUrl} alt="" className={styles.img} />
        </Box>
        <Stack className={styles.buttons} direction="column" gap="16px">
          <Grid container alignItems="center" justifyContent="center">
            <Button variant="text" className={styles.button}>
              <Icon name="refresh" />
              Заменить
              <label htmlFor={id} className={styles.imageInput}>
                {fileInput()}
              </label>
            </Button>
          </Grid>

          {!multiple && (
            <Grid container alignItems="center" justifyContent="center">
              <Button className={styles.button} onClick={handleDelete}>
                <Icon name="delete" />
                Удалить
              </Button>
            </Grid>
          )}
        </Stack>
        {canUseLink && inputWrapper()}
      </Stack>
    );
  },
);
