import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Tag } from '../Tag';
import { SelectedOption, SelectSelectedProps } from './types';
import styles from './style.module.scss';

const calculationBoxSx = { position: 'fixed', top: 0, left: -99999 };

export const SelectSelected = ({ multiple, option, onSelect, disabled }: SelectSelectedProps) => {
  const calcBoxRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const [visibleOptions, setVisibleOption] = useState<SelectedOption[]>([]);
  const [isCalculation, setIsCalculation] = useState(true);

  useEffect(() => {
    if (multiple && option.length) {
      setIsCalculation(true);
    }
  }, [multiple, option]);
  useEffect(() => {
    if (!boxRef.current || !calcBoxRef.current) {
      setIsCalculation(false);
      return;
    }

    const maxWidth = boxRef.current.clientWidth;
    const child = calcBoxRef.current.childNodes;

    if (!child.length || maxWidth > calcBoxRef.current.clientWidth) {
      setIsCalculation(false);
      setVisibleOption(option);
      return;
    }

    const visibleElements = [];

    let currentWidth = (child[0] as HTMLElement).offsetWidth;
    for (let i = 1; i < child.length; i++) {
      currentWidth += (child[i] as HTMLElement).offsetWidth + 8;
      if (currentWidth < maxWidth) {
        visibleElements.push(option[i - 1]);
      } else {
        break;
      }
    }

    setIsCalculation(false);
    setVisibleOption(visibleElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalculation]);

  if (!option.length) {
    return null;
  }
  if (!multiple) {
    return (
      <Typography noWrap variant="body2">
        {option[0].label}
      </Typography>
    );
  }

  return (
    <Box flexWrap="nowrap" display="flex" alignItems="center" ref={boxRef}>
      {isCalculation && (
        <Box ref={calcBoxRef} sx={calculationBoxSx}>
          <Typography component="span" variant="body2">
            и еще {option.length}
          </Typography>
          {option.map(({ value, label, inputLabel }) => (
            <Tag
              sx={{ marginRight: '8px' }}
              className={cn(disabled && styles.disabledTag)}
              key={value}
              label={inputLabel ?? label}
              onDelete={disabled ? undefined : () => onSelect({ value, label, inputLabel }, true)}
            />
          ))}
        </Box>
      )}
      {visibleOptions.length ? (
        <>
          {visibleOptions.map(({ value, label, inputLabel }) => (
            <Tag
              sx={{ marginRight: '8px' }}
              className={cn(disabled && styles.disabledTag)}
              key={value}
              label={inputLabel ?? label}
              onDelete={disabled ? undefined : () => onSelect({ value, label, inputLabel }, true)}
            />
          ))}
          {option.length - visibleOptions.length ? (
            <Typography component="span" variant="body2">
              и еще {option.length - visibleOptions.length}
            </Typography>
          ) : null}
        </>
      ) : (
        <Typography component="span" variant="body2">
          Выбрано {option.length}
        </Typography>
      )}
    </Box>
  );
};
