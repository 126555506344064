import {
  ApplicationControllerFilterApplicationsApiArg,
  useApplicationControllerFilterApplicationsMutation,
  ApplicationsFilterRequest,
} from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';
import { DEFAULT_APPLICATIONS_SIZE } from '@area/applicationsPage/defaultValues';

export const useApplications = () => {
  const query: ApplicationControllerFilterApplicationsApiArg = {
    size: DEFAULT_APPLICATIONS_SIZE,
    applicationsFilterRequest: {},
  };
  const [getApplications, res] = useApplicationControllerFilterApplicationsMutation();

  const handleRequest = (
    args?: Omit<ApplicationControllerFilterApplicationsApiArg, 'ApplicationsFilterRequest'> & {
      applicationsFilterRequest: Partial<ApplicationsFilterRequest>;
    },
  ) => {
    const request: ApplicationControllerFilterApplicationsApiArg = {
      ...query,
      ...args,
      applicationsFilterRequest: {
        ...query.applicationsFilterRequest,
        ...args?.applicationsFilterRequest,
      },
    };

    getApplications(request);
  };

  const getValuesFromParams = (params: { [key: string]: string }) => {
    const createdAt = params.createdAt ? DateService.getDateFromParams(params.createdAt) : undefined;
    const status = params.status ? decodeURIComponent(params.status).split(',') : undefined;

    return { ...params, createdAt, status };
  };

  return {
    getApplications: handleRequest,
    applicationsList: res.data?.data,
    paginate: res.data?.paginate,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
    getValuesFromParams,
  };
};
