import { FormField } from '@components/FormField';
import { Modal } from '@components/Modal';
import { Button, Grid, Typography, Stack } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { useGetEduOrgs } from '@area/payments/hooks/useGetEduOrgs';
import { Option } from '@components/Select/types';
import { Dayjs } from 'dayjs';

export interface FormData {
  fromDate: Dayjs;
  toDate?: Dayjs;
  eduOrgsIds?: Option[];
}

export interface QuoteWithPhotoFormProps {
  open: boolean;
  onSubmit: SubmitHandler<FormData>;
  onClose: () => void;
}

export const ReportModalForm: FC<QuoteWithPhotoFormProps> = ({ open, onClose, onSubmit }) => {
  const { data: eduOrgs } = useGetEduOrgs();
  const options = eduOrgs?.map(({ id, nameShort }) => ({ label: nameShort, value: id }));
  const form = useForm<FormData>();
  const { handleSubmit } = form;
  const submit = handleSubmit(onSubmit);

  return (
    <Modal
      open
      maxWidth="md"
      onClose={onClose}
      title="Сформировать отчет"
      actions={
        <>
          <Button variant="contained" onClick={submit} startIcon={<Icon name="logoIcon" color={colors.red} />}>
            Сформировать
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Отмена
          </Button>
        </>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap="30px">
          <Grid item>
            <Typography marginBottom="8px">Дата подтверждения</Typography>
            <Grid container flexDirection="row" spacing={indents.s}>
              <Grid item xs={6}>
                <FormField type="datepicker" name="fromDate" fullWidth />
              </Grid>

              <Grid item xs={6}>
                <FormField type="datepicker" name="toDate" fullWidth />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography marginBottom="8px">Список ВУЗов</Typography>

            <FormField type="select" name="eduOrgsIds" multiple options={options} />
          </Grid>
          <Grid item>
            <Stack direction="row" alignItems="center" gap={indents.xxs}>
              <Icon name="info" />
              <Typography fontWeight="normal">В отчет будут включены только подтвержденные выплаты</Typography>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
