import { Icon } from '@components/Icon';
import { FC, useRef } from 'react';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import {
  useFilesControllerUploadFileMutation,
  useFilesControllerDeleteFileMutation,
} from '@api/admin/adminGeneratedApi';
import { FileType } from '@hooks/useUploadMediaFiles';
import { ArticleLinkElement } from '../../types';
import { deleteArticleLink, updateArticleLink } from '../../utils/article-link';
import { useConfirmDialog } from '../ConfirmDialog/useConfirmDialog';
import { AddArticleLinkModal, AddArticleLinkRef } from '../Toolbar/components/ArticleLink/AddArticleLinkModal';
import { ArticleLinkFormData } from '../Toolbar/components/ArticleLink/ArticleLinkForm';
import styles from './styles.module.scss';

export const ArticleLink: FC<RenderElementProps> = (props) => {
  const { attributes, children } = props;
  const editor = useSlate();
  const element = props.element as ArticleLinkElement;
  const modalRef = useRef<AddArticleLinkRef>(null);
  const [uploadFile] = useFilesControllerUploadFileMutation();
  const [deleteFile] = useFilesControllerDeleteFileMutation();

  const { onClickConfirm, dialog } = useConfirmDialog({
    title: 'Подтвердите действие',
    text: 'Удалить ссылку на другую публикацию?',
    onConfirm: async () => {
      ReactEditor.focus(editor);
      if (element.imageFileId) {
        await deleteFile({ fileId: element.imageFileId });
      }
      deleteArticleLink(editor, element);
    },
  });

  const onLinkClickEdit = () => {
    ReactEditor.focus(editor);
    modalRef.current?.open();
  };

  const handleSubmit = async (formData: ArticleLinkFormData) => {
    if (formData.image) {
      if (element.imageFileId) {
        await deleteFile({ fileId: element.imageFileId });
      }

      const fd = new FormData();
      fd.set('file', formData.image);
      fd.set('type', FileType.editorPhoto.toString());
      const res = await uploadFile({ subjectId: '-1', mediaFileUpdaterDto: fd as any }).unwrap();

      updateArticleLink(editor, { ...formData, imageFileUrl: res.url, imageFileId: res.id }, element);
      return;
    }
    updateArticleLink(editor, formData, element);
  };

  return (
    <div className="article-link" {...attributes} contentEditable={false}>
      <div className="article-link__wrapper">
        <div style={{ display: 'none' }}>{children}</div>
        {(element.imageUrl || element.imageFileUrl) && (
          <div className="article-link__photo-wrapper">
            <img src={element.imageUrl || element.imageFileUrl} alt="" className="article-link__photo" />
          </div>
        )}
        <a href={element.linkHref} className="article-link__link" target="_blank" rel="noopener noreferrer">
          {element.linkText}
        </a>
      </div>
      <div className={styles.actions}>
        <button onClick={onLinkClickEdit}>
          <Icon name="edit" width={16} height={16} />
        </button>
        <button onClick={onClickConfirm}>
          <Icon name="delete" width={16} height={16} />
        </button>
      </div>
      <AddArticleLinkModal modalRef={modalRef} initialFormData={element} onSubmit={handleSubmit} />
      {dialog}
    </div>
  );
};
