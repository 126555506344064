export const DEFAULT_TICKETS_PAGE_SIZE = 50;

export const statusOptions = [
  { value: '0', label: 'Новое' },
  { value: '1', label: 'Решено' },
];

export const subjectOptions = [
  {
    label: 'Проблемы с регистрацией',
    value: 'Проблемы с регистрацией',
  },
  {
    label: 'Проблемы с подачей заявки на получение дополнительной стипендии',
    value: 'Проблемы с подачей заявки на получение дополнительной стипендии',
  },
  {
    label: 'Проблемы с подачей заявки на компенсацию части образовательного кредита',
    value: 'Проблемы с подачей заявки на компенсацию части образовательного кредита',
  },
  {
    label: 'Проблемы с подачей заявки на выпуск карты лояльности',
    value: 'Проблемы с подачей заявки на выпуск карты лояльности',
  },
  {
    label: 'Как стать партнером программы лояльности',
    value: 'Как стать партнером программы лояльности',
  },
  {
    label: 'Вопрос по выплате дополнительной стипендии',
    value: 'Вопрос по выплате дополнительной стипендии',
  },
  {
    label: 'Вопрос по выплате компенсации образовательного кредита',
    value: 'Вопрос по выплате компенсации образовательного кредита',
  },
  {
    label: 'Другой вопрос',
    value: 'Другой вопрос',
  },
];
