import { OrderType } from '../types';

export const getApplicationPageHeaderTitle = (type: OrderType) => {
  switch (type) {
    case OrderType.esk_card:
      return 'Заявка на выпуск карты ЕСК';
    case OrderType.extra_grant:
      return 'Заявка на выплату дополнительной стипендии';
    case OrderType.credit_grant:
      return 'Заявка на компенсацию образовательного кредита';
    default:
      return 'Неизвестный тип';
  }
};
