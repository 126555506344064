import { Grid } from '@mui/material';
import { FormField } from '@components/FormField';
import indents from '@styles/indents.module.scss';

import { useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import { MediaFileParams } from '@area/publications/defaultValues';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import styles from './styles.module.scss';

interface SnippetProps<TValues extends FieldValues> {
  snippetPhoto?: MediaFileParams;
  form: UseFormReturn<TValues, any>;
}

export const Snippet = <TValues extends FieldValues>({ snippetPhoto, form }: SnippetProps<TValues>) => {
  const [deleteFile, { isLoading }] = useFilesControllerDeleteFileMutation();

  const handleDeleteFile = async () => {
    const id = snippetPhoto?.id;

    if (id) {
      await deleteFile({ fileId: id });
    }
    form.setValue('snippetPhoto' as Path<TValues>, undefined as any);
  };

  return (
    <Grid container className={styles.root} gap={indents.m}>
      <Grid item sx={{ width: 304 }}>
        <FormField
          onDelete={handleDeleteFile}
          imageUrl={snippetPhoto?.url}
          name="snippetPhoto.file"
          type="image"
          loadingText="Удаление фото"
          loading={isLoading}
        />
      </Grid>
      <Grid item width="100%">
        <FormField name="snippetPhoto.alt" title="Заголовок" fullWidth />
      </Grid>
      <Grid item width="100%">
        <FormField name="snippetPhoto.metadata" title="Описание" fullWidth multiline minRows={2} />
      </Grid>
    </Grid>
  );
};
