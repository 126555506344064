import { useTicketControllerGetByIdQuery } from '@api/admin/adminGeneratedApi';

export const useGetTicketById = (id: string) => {
  const { data, refetch } = useTicketControllerGetByIdQuery({
    id,
  });

  return {
    data,
    refetch,
  };
};
