import { FC, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { Modal } from '@components/Modal';
import { SettingResponse, useSettingsControllerUpdateMutation } from '@api/admin/adminGeneratedApi';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { SettingType } from '@area/settings/types';
import { useFieldFormProps } from '@area/settings/hooks/useFieldFormProps';
import { EditModalProps, EditModalFormData } from './types';

export const EditModal: FC<EditModalProps> = ({ modalRef, onSubmit }) => {
  const [updater] = useSettingsControllerUpdateMutation();
  const [open, setOpen] = useState(false);
  const form = useForm<EditModalFormData>();

  useImperativeHandle(modalRef, () => ({
    open: (s: SettingResponse) => {
      setOpen(true);

      let value: EditModalFormData['value'] = s.value;
      switch (s.type) {
        case SettingType.datetime:
          value = dayjs(s.value);
          break;
        case SettingType.boolean:
          value = s.value === 'true';
          break;
      }

      form.reset({ ...s, value });
    },
  }));

  const onClose = () => {
    setOpen(false);
    form.reset();
  };
  const onLocalSubmit = form.handleSubmit(async ({ id, type, value }) => {
    let valueToSend = null;
    if (typeof value === 'string') {
      valueToSend = value;
    }

    if (typeof value === 'boolean') {
      valueToSend = value === true ? 'true' : 'false';
    }

    if (typeof value === 'object' && dayjs.isDayjs(value)) {
      valueToSend = value.toISOString();
    }

    if (valueToSend) {
      await updater({
        id,
        settingUpdaterDto: { value: valueToSend },
      });
    }

    setOpen(false);
    onSubmit();
  });

  const { isSubmitting, isDirty } = form.formState;
  const fieldProps = useFieldFormProps(form.getValues());

  return (
    <Modal
      open={open}
      title="Изменение настройки"
      maxWidth="xs"
      onClose={onClose}
      actions={
        <Stack direction="row" gap={indents.s}>
          <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
            Отмена
          </Button>
          <Button variant="contained" onClick={onLocalSubmit} disabled={isSubmitting || !isDirty}>
            Сохранить
          </Button>
        </Stack>
      }>
      <FormProvider {...form}>
        <Stack gap={indents.m}>
          <FormField name="value" placeholder="Введите значение" title="Значение" {...fieldProps} />
        </Stack>
      </FormProvider>
    </Modal>
  );
};
