import * as yup from 'yup';
import { UserCreateForm } from '@area/users/type';

export const newUserValidation: yup.Schema<UserCreateForm> = yup.object({
  firstName: yup.string().min(1, 'Минимальное количество символов = 1').required('Обязательное поле.'),
  middleName: yup.string().min(1, 'Минимальное количество символов = 1'),
  lastName: yup.string().min(1, 'Минимальное количество символов = 1'),
  email: yup.string().email('Неверный email').required('Обязательное поле.'),
  role: yup.array().required('Обязательное поле.'),
});
