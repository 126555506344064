import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { Modal } from '@components/Modal';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Button, Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './style.module.scss';
import { ChangePasswordFormData, Props } from './types';
import { validationSchema } from './validation';

export const ChangePasswordModal: FC<Props> = ({ open, onClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const resolver = useYupValidationResolver<ChangePasswordFormData>(validationSchema);

  const form = useForm<ChangePasswordFormData>({
    resolver,
  });

  const onSubmitForm = async () => {
    setIsLoading(true);
    await form.handleSubmit(onSubmit)();
    setIsLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Изменить пароль"
      fullWidth={true}
      maxWidth="xs"
      actions={
        <Grid container flexDirection="row" gap={indents.s}>
          <Grid item>
            <Button
              onClick={onSubmitForm}
              variant="contained"
              startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
              disabled={isLoading}>
              Сохранить
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined" disabled={isLoading}>
              Отмена
            </Button>
          </Grid>
        </Grid>
      }>
      <FormProvider {...form}>
        <Grid container gap={indents.l} direction="column">
          <Grid item>
            <FormField
              disabled={isLoading}
              name="oldPassword"
              title="Старый пароль"
              titleVariant="body1"
              fullWidth
              type="password"
            />
          </Grid>
          <Grid item>
            <FormField
              disabled={isLoading}
              name="password"
              title="Новый пароль"
              titleVariant="body1"
              fullWidth
              type="password"
            />
          </Grid>
          <Grid item>
            <FormField
              disabled={isLoading}
              name="newPassword"
              title="Повторите новый пароль"
              titleVariant="body1"
              fullWidth
              type="password"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
