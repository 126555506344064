import { PreviewButtonType } from '../types';

const width: Record<PreviewButtonType, number> = {
  mobile: 375,
  desktop: 1200,
};

export const getIframeSize = (type: PreviewButtonType | null) => {
  if (!type) return null;

  return { width: width[type], height: 600 };
};
