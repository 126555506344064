import * as yup from 'yup';
import { ChangePasswordFormData } from './types';

export const validationSchema = yup.object<ChangePasswordFormData>({
  oldPassword: yup.string().required('Обязательное поле.'),
  password: yup
    .string()
    .min(8)
    .max(24)
    .required('Обязательное поле.')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).*$/, {
      message: 'В пароле должны присутствовать: число, большая буква, маленькая буква',
    }),
  newPassword: yup
    .string()
    .required('Обязательное поле.')
    .oneOf([yup.ref('password'), ''], 'Пароли должны совпадать'),
});
