import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Tooltip, IconButton, Paper, Stack, Grid, Typography } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router';
import { Icon } from '@components/Icon';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { Routes } from '@navigation/routes';
import { Filter } from '@components/Filter';
import { Tag } from '@components/Tag';
import colors from '@styles/colors.module.scss';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { DateService } from '@services/DateService';
import { useQueryParams } from '@hooks/useQueryParams';
import { useKampusEvents } from '@area/kampusEvents/hooks/useKampusEvents';
import {
  DEFAULT_KAMPUS_EVENTS_SIZE,
  KAMPUS_EVENTS_ICONS,
  kampusEventTypeColors,
  kampusEventTypes,
} from '@area/kampusEvents/defaultValues';
import { KampusEventType, KampusEventsFilterType } from '@area/kampusEvents/types';
import { EventShortResponse } from '@api/admin/adminGeneratedApi';
import { useKampusDeleteEvent } from '@area/kampusEvents/hooks/useKampusDeleteEvent';
import { PageDeleteConfirmModal } from '@area/redactor/components/PageDeleteConfirmModal';
import { getPreviewUrl } from '../../utils/getPreviewUrl';
import styles from './styles.module.scss';

export const KampusEvents = () => {
  usePageTitle('Мероприятия');
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { getKampusEvents, eventsList, isSuccess, paginate, isLoading, originalArgs, getValuesFromParams } =
    useKampusEvents();
  const {
    deleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    confirmDelete,
    loadingRowId,
  } = useKampusDeleteEvent(() => {
    setData([]);
    fetchData(
      originalArgs?.eventFiltersRequest as KampusEventsFilterType,
      0,
      originalArgs?.skip! + originalArgs?.size!,
    );
  });
  const [data, setData] = useState<EventShortResponse[]>([]);
  const { params } = useQueryParams();

  const fetchData = (
    values: Partial<KampusEventsFilterType & { date?: { startDate?: string; endDate?: string } }>,
    skip: number,
    size = DEFAULT_KAMPUS_EVENTS_SIZE,
  ) => {
    getKampusEvents({
      skip,
      size,
      asc: true,
      eventFiltersRequest: {
        search: values.search,
        dateStartFrom: DateService.getUTCDate(values.date?.startDate),
        dateStartTo: DateService.getUTCDate(values.date?.endDate),
        type: values.type && typeof values.type === 'string' ? values.type : values.type?.value,
      },
    });
  };

  const onChange = (values: Partial<KampusEventsFilterType>) => {
    setData([]);

    fetchData(values, 0);
  };

  const handleRowClick = (row: EventShortResponse) => {
    navigate(row.id);
  };

  const goToNewEvent = () => navigate(Routes.newItem);

  const onClickLink = (url: string) => {
    window.open(getPreviewUrl('events', url), '_blank');
  };

  const onClickDelete = (id: string) => openDeleteConfirmationModal(id);

  const loadMore = () => {
    fetchData(originalArgs?.eventFiltersRequest as KampusEventsFilterType, data.length);
  };

  const columns: Column<EventShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'published',
        key: 'published',
        width: '72px',
        render: (value: boolean) => (
          <Tooltip title={value ? 'Опубликовано' : 'Не опубликовано'} arrow>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
            </Box>
          </Tooltip>
        ),
      },
      {
        title: 'Дата начала',
        dataIndex: 'dateStart',
        key: 'dateStart',
        width: '170px',
        render: (value: Date) => (
          <Typography className={styles.date} variant="body2">
            {DateService.getLocalDate(value, DateService.dateFormats.dateTime)}
          </Typography>
        ),
      },
      {
        title: 'Тип',
        align: 'center',
        dataIndex: 'type',
        key: 'type',
        width: '90px',
        render: (value: KampusEventType) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tag label={value} className={styles.type} sx={{ background: kampusEventTypeColors[value] }} />
            </Box>
          );
        },
      },
      {
        title: 'Название',
        dataIndex: 'title',
        key: 'title',
        width: 'auto',
        sx: { whiteSpace: 'normal' },
      },
      {
        title: '',
        dataIndex: 'url',
        key: 'url',
        width: '90px',
        render: (url: string, row: EventShortResponse) => {
          return (
            <Stack direction="row" gap="6px" className={styles.actions}>
              {KAMPUS_EVENTS_ICONS.map((iconName) => (
                <IconButton
                  key={iconName}
                  className={styles.iconButton}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (iconName === 'link') {
                      onClickLink(url);
                      return;
                    }

                    onClickDelete(row.id);
                  }}>
                  <Icon name={iconName} />
                </IconButton>
              ))}
            </Stack>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(eventsList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  return (
    <Grid container direction="column" gap="24px">
      <Grid item>
        <Header
          title="Мероприятия"
          actions={
            <Button onClick={goToNewEvent} variant="contained">
              Новое мероприятие
            </Button>
          }
        />
      </Grid>

      <Grid item>
        <Filter<KampusEventsFilterType>
          defaultValues={{ search: '' }}
          onChange={onChange}
          searchField={{
            type: 'text',
            name: 'search',
            props: {
              placeholder: 'Поиск',
              sx: { minWidth: '200px', background: colors.white },
            },
            stretchable: true,
          }}
          trackedFields={[
            {
              type: 'select',
              name: 'type',
              props: {
                placeholder: 'Тип',
                icon: <Icon name="type" />,
                options: kampusEventTypes,
                sx: { width: '200px', background: colors.white },
              },
            },
            {
              type: 'dateRangePicker',
              name: 'date',
              props: {
                placeholder: 'Дата',
                textFieldProps: { placeholder: 'Интервал дат', sx: { width: '240px', background: colors.white } },
              },
            },
          ]}
        />
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Paper className={styles.paper}>
          <Table
            columns={columns}
            data={data}
            loading={isLoading}
            onRowClick={handleRowClick}
            loadingRowId={loadingRowId}
          />
          {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
            <Button
              variant="outlined"
              startIcon={<Icon name="more" />}
              className={styles.loadMoreBtn}
              onClick={loadMore}
              disabled={isLoading}>
              Загрузить еще
            </Button>
          )}
        </Paper>
      </Grid>

      <PageDeleteConfirmModal
        titleToDelete="событие"
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onConfirm={confirmDelete}
      />
    </Grid>
  );
};
