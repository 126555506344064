import { sharedInfoPageFieldNames } from '@area/infoPages/defaultValues';
import { infoPageValidation } from '@area/infoPages/validation/infoPageValidation';
import { sharedInfoPageValidation } from '@area/infoPages/validation/sharedValidation';
import { Embedded } from '@area/redactor/components/Embedded';
import { MetaTags } from '@area/redactor/components/MetaTags';
import { ModalCheckMistakes } from '@area/redactor/components/ModalCheckMistakes';
import { Photo } from '@area/redactor/components/Photo';
import { Preview } from '@area/redactor/components/Preview/components';
import { Redactor } from '@area/redactor/components/Redactor';
import { SlugField } from '@area/redactor/components/SlugField';
import { Snippet } from '@area/redactor/components/Snippet';
import { TypographButton } from '@area/redactor/components/TypographButton';
import { Video } from '@area/redactor/components/Video';
import { useCheckMistakes } from '@area/redactor/hooks/useCheckMistakes';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { usePreviewUrl } from '@hooks/usePreviewUrl';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Box, Button, Grid, LinearProgress } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { MediaFileParams } from '@area/publications/defaultValues';
import { BaseFieldValues } from '../types';
import styles from './style.module.scss';

interface FormProps<TValues> {
  defaultValues: DefaultValues<TValues>;
  onSubmit: (values: TValues) => void;
  isLoading?: boolean;
}

export const EditForm = <TValues extends BaseFieldValues>({
  defaultValues,
  onSubmit,
  isLoading,
}: FormProps<TValues>) => {
  const resolver = useYupValidationResolver<TValues>(infoPageValidation);

  const form = useForm({
    defaultValues,
    resolver,
  });

  const handleSubmitInfo = (values: any) => {
    const result = sharedInfoPageFieldNames.reduce((acc, name) => {
      acc[name] = values[name];
      return acc;
    }, {} as typeof values);

    onSubmit({
      ...form.getValues(),
      ...result,
    });
    setInitialValues(null);
  };

  const formData = form.watch();

  const {
    fieldsToCheck,
    modalShow,
    form: modalForm,
    setInitialValues,
  } = useCheckMistakes(sharedInfoPageFieldNames, 'infoPage', sharedInfoPageValidation, formData);

  const [key, setKey] = useState(0);

  const handleCheckAndSubmit = form.handleSubmit((data) => {
    if (!formData.published) {
      handleSubmitInfo(data);
      return;
    }

    setInitialValues({
      title: data.title,
    });
  });

  const bottomComponent = useMemo(() => {
    const valuesToCheck = {
      title: formData.title,
      editorContent: formData.editorContent,
    };
    const callBackAfterCheck = (values: Record<string, any>) => {
      form.reset({
        ...formData,
        ...values,
      });

      setKey(key + 1);
    };

    return <TypographButton valuesToCheck={valuesToCheck} callBackAfterCheck={callBackAfterCheck} />;
  }, [form, formData]);

  const tabs = useMemo(
    () => [
      {
        label: 'Текст публикации',
        content: <FormField name="editorContent" type="wysiwyg" bottomComponent={bottomComponent} key={key} />,
      },
      {
        label: 'Embedded',
        content: <Embedded fieldName="embedded" />,
      },
      {
        label: 'Фото репортаж',
        content: <Photo name="photoGallery" photoGallery={formData.photoGallery} />,
      },
      {
        label: 'Видео',
        content: <Video />,
      },
      {
        label: 'Сниппет',
        content: <Snippet<TValues> form={form} snippetPhoto={formData.snippetPhoto as MediaFileParams} />,
      },
      {
        label: 'Метатеги',
        content: <MetaTags tags={defaultValues.metaTags} />,
      },
    ],
    [bottomComponent, defaultValues.metaTags, formData.photoGallery, formData.snippetPhoto],
  );

  const url = usePreviewUrl('pages', formData.url);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <Preview url={url} disabled={!formData.id} />
        <FormProvider {...form}>
          <Grid item>
            <FormField name="published" type="switch" label={formData.published ? 'Вкл' : 'Выкл'} title="Показать" />
          </Grid>
          <Grid item>
            <FormField name="isMenu" type="switch" label={formData.isMenu ? 'Вкл' : 'Выкл'} title="Показать в меню" />
          </Grid>
          <Grid item>
            <FormField name="title" title="Название" fullWidth />
          </Grid>
          <Grid item>
            <SlugField fieldNameToSlugify="title" disabled={!!defaultValues.id} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Redactor tabs={tabs} />
          </Grid>
        </FormProvider>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          type="button"
          variant="contained"
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleCheckAndSubmit}>
          Сохранить
        </Button>
      </Box>
      <ModalCheckMistakes
        fieldsToCheck={fieldsToCheck}
        form={modalForm}
        open={modalShow}
        onClose={() => setInitialValues(null)}
        onSubmit={handleSubmitInfo}
      />
    </>
  );
};
