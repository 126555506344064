import { UseControllerProps, useForm } from 'react-hook-form';
import { useAuthControllerLogin2FaMutation } from '@api/admin/adminApi';
import { LoginResponseDto } from '@api/admin/adminGeneratedApi';
import { useEffect } from 'react';

export type OtpFormData = {
  otp: string;
};

const otpFieldRules: UseControllerProps<OtpFormData>['rules'] = {
  required: { value: true, message: 'Обязательное поле' },
  pattern: {
    value: /^\d{3} \d{3}$/,
    message: 'Код должен соответствовать шаблону 999 999',
  },
};

const processOtp = (otp: string) => otp.replace(/\D/gm, '');

export const useOtpForm = (email?: string, onSuccess?: (accessToken: LoginResponseDto) => void) => {
  const [login2fa, { isLoading }] = useAuthControllerLogin2FaMutation();

  const form = useForm<OtpFormData>({ defaultValues: { otp: '' } });

  const onSubmit = form.handleSubmit(async (data) => {
    if (!email) {
      return;
    }

    const otp = processOtp(data.otp);
    const res = await login2fa({ otpCreatorDto: { email, otp } }).unwrap();

    onSuccess?.(res);
  });

  const code = form.watch('otp');
  useEffect(() => {
    const processedOtp = processOtp(code);
    if (processedOtp.length === 6) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return {
    form,
    onSubmit,
    otpFieldRules,
    isLoading,
  };
};
