import { KeyboardEvent } from 'react';
import { isHotkey } from 'is-hotkey';
import { Editor, Text } from 'slate';
import { hotkeys, TAB_UNICODE } from '../constants';
import { CustomEditor } from '../types';
import { toggleMark } from './elements';

export const applyHotkey = (editor: CustomEditor, event: KeyboardEvent<HTMLElement>) => {
  for (const hotkey in hotkeys) {
    if (isHotkey(hotkey, event as any)) {
      event.preventDefault();
      toggleMark(editor, hotkeys[hotkey]);
    }
  }
};

export const toggleTab = (editor: CustomEditor, event: KeyboardEvent<HTMLElement>) => {
  if (event.key === 'Tab') {
    event.preventDefault();

    if (event.shiftKey) {
      const previous = Editor.previous(editor);
      if (previous && previous.length && Text.isText(previous[0]) && previous[0]?.tab) {
        Editor.deleteBackward(editor, { unit: 'character' });
      }
    } else {
      Editor.insertNode(editor, { text: TAB_UNICODE.toString(), tab: true });
    }
  }
};
