import { IconButton } from '@mui/material';
import { MouseEvent, useRef } from 'react';
import { useSlate } from 'slate-react';
import { Icon } from '@components/Icon';
import { isLinkActive, unwrapLink } from '@area/redactor/components/Wysiwyg/utils/links';
import { AddLinkModal, AddLinkModalRef } from './AddLinkModal';

export const Links = () => {
  const modalRef = useRef<AddLinkModalRef>(null);
  const editor = useSlate();

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const onLinkClick = () => {
    modalRef.current?.open();
  };
  const onUnlinkClick = () => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
  };

  return (
    <>
      <IconButton centerRipple={false} onMouseDown={onMouseDown} onClick={onLinkClick} title="Вставить ссылку">
        <Icon name="wysiwygLink" viewBox="0 0 16 16" />
      </IconButton>
      <IconButton
        disabled={!isLinkActive(editor)}
        centerRipple={false}
        onMouseDown={onMouseDown}
        onClick={onUnlinkClick}
        title="Удалить ссылку">
        <Icon name="wysiwygUnlink" viewBox="0 0 16 16" />
      </IconButton>
      <AddLinkModal modalRef={modalRef} />
    </>
  );
};
