import { memo, Ref, useImperativeHandle, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSlateStatic } from 'slate-react';
import { DefinitionInfoForm, DefinitionInfoFormData } from './definitionInfoForm';

export interface AddDefinitionInfoRef {
  open: () => void;
  close: () => void;
}

export interface AddDefinitionInfoProps {
  modalRef: Ref<AddDefinitionInfoRef>;
  initialFormData?: DefinitionInfoFormData;
  onSubmit: SubmitHandler<DefinitionInfoFormData>;
}

export const AddDefinitionInfoModal = memo(({ modalRef, initialFormData, onSubmit }: AddDefinitionInfoProps) => {
  const editor = useSlateStatic();

  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  useImperativeHandle(modalRef, () => ({
    open: () => {
      if (!editor.selection) return;
      setShowModal(true);
    },
    close: onClose,
  }));

  if (!showModal) {
    return null;
  }

  return (
    <DefinitionInfoForm
      onClose={onClose}
      initialFormData={initialFormData}
      onSubmit={(formData) => {
        onSubmit(formData);
        onClose();
      }}
    />
  );
});
