import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { PaymentMembersFilter } from '@area/payments/components/PaymentMembersFilter';
import { Table } from '@components/Table/Table';
import { useCandidates } from '@area/payments/hooks/useCandidates';
import { PaymentCandidatesResponse } from '@api/admin/adminGeneratedApi';
import { getColumns } from '@area/payments/components/PaymentsMembers/columns';
import colors from '@styles/colors.module.scss';
import styles from './styles.module.scss';

export const PaymentsMembers = () => {
  const { id } = useParams();
  const { candidates, isLoading, isFetched, hasMore, fetchCandidates } = useCandidates(id!);
  const columns = useMemo(() => getColumns(), []);

  return (
    <Box className={styles.root} borderRadius={1} mt={4}>
      <Accordion>
        <AccordionSummary className={styles.accordion} expandIcon={<Icon name={'chevronRight'} color={colors.red} />}>
          <Typography variant={'h2'}>Участники</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <PaymentMembersFilter />
          <Box mt={3}>
            <Table<PaymentCandidatesResponse>
              columns={columns}
              data={candidates}
              loading={isLoading}
              fetched={isFetched}
            />
          </Box>
          {hasMore && (
            <Button
              variant="outlined"
              className={styles.loadMoreBtn}
              startIcon={<Icon name="more" />}
              onClick={() => fetchCandidates()}
              disabled={isLoading}>
              Загрузить еще
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
