import { Button } from '@mui/material';
import { Icon } from '@components/Icon';
import { FC } from 'react';
import styles from './styles.module.scss';

export interface TypografSettings {
  valuesToCheck: Record<string, any>;
  callBackAfterCheck: <T extends Record<string, any>>(values: T) => void;
}

export const TypographButton: FC<TypografSettings> = ({ valuesToCheck, callBackAfterCheck }) => {
  const typographReplacer = (raw: string): string => {
    if (!window.Typograf) {
      console.error('Typograf lib not found');
      return raw;
    }

    window.Typograf.rule({
      name: 'common/other/doubleHyphen',
      handler: function (text: string) {
        let dashes = this.data('common/dash');
        let re1 = new RegExp('(' + dashes + ')(' + dashes + ')', 'g');
        return text.replace(re1, '\u2014');
      },
    });

    window.Typograf.rule({
      name: 'common/other/firstHyphen',
      handler: function (text: string) {
        let dashes = this.data('common/dash');
        let re1 = new RegExp('(^|' + window.Typograf._privateLabel + ')(' + dashes + ')( |\u00A0)?', 'gm');
        return text.replace(re1, '$1\u2014\u00A0');
      },
    });

    let typograf = new window.Typograf({ lang: 'ru' });

    // RULES DISABLE
    typograf.disable('common/space/replaceTab');
    typograf.disable('ru/dash/izpod');
    typograf.disable('ru/dash/izza');
    typograf.disable('ru/dash/ka');
    typograf.disable('ru/dash/koe');
    typograf.disable('ru/dash/taki');
    typograf.disable('ru/dash/to');
    typograf.disable('ru/other/accent');
    typograf.disable('ru/money/currency');

    return typograf.execute(raw);
  };

  const replacer = (obj: Record<string, any>) => {
    const valuesToCheckKeys = Object.keys(obj);
    return valuesToCheckKeys.reduce((updatedValues, key) => {
      // Типы пропускаем, это служебное поле
      if (key === 'type') {
        // Проверим чтобы в самой форме не было поля с именем type
        if (Object.keys(valuesToCheck).includes(key)) {
          throw Error('Rename field key = "type" is used in WYSIWYG');
        }
        return updatedValues;
      }

      const value = obj[key];

      // Если поле falsy, оставим ключ
      if (!value) {
        updatedValues[key] = value;
      }

      if (typeof value === 'string') {
        updatedValues[key] = typographReplacer(value);
      }

      if (Array.isArray(value)) {
        updatedValues[key] = value.map((v) => {
          if ((v.type !== undefined && v.type !== 'paragraph') || v.quote || v.bold || v.italic) {
            return v;
          }
          return replacer(v);
        });
        return updatedValues;
      }

      if (typeof value === 'object' && value !== null) {
        updatedValues[key] = replacer(value);
      }
      return updatedValues;
    }, {} as Record<string, any>);
  };

  const handleCheck = () => {
    callBackAfterCheck(replacer(valuesToCheck));
  };

  return (
    <div className={styles.btn}>
      <Button variant="outlined" startIcon={<Icon name="typograph" />} onClick={handleCheck}>
        Типограф
      </Button>
    </div>
  );
};
