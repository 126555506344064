import { Element, Transforms, Node, Path, Editor } from 'slate';
import { CustomEditor, EditorPlugin } from '../types';

const normalizeParagraph = (editor: CustomEditor, path: Path) => {
  for (const [child, childPath] of Node.children(editor, path)) {
    if (Element.isElement(child) && !editor.isInline(child)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return;
    }
  }
};

const normalizeListItem = (editor: CustomEditor, path: Path) => {
  if (Editor.isEditor(Editor.parent(editor, path)[0])) {
    Transforms.setNodes(editor, { type: 'paragraph', children: [] }, { at: path });
  }
};

export const withNormalization: EditorPlugin = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node)) {
      switch (node.type) {
        case 'paragraph':
          normalizeParagraph(editor, path);
          break;
        case 'list-item':
          normalizeListItem(editor, path);
          break;
      }
    }

    normalizeNode(entry);
  };

  return editor;
};
