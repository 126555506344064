import { Filter } from '@components/Filter';
import { FC } from 'react';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { useGetCities } from '@area/partners/hooks/useGetCities';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { partnersStatusOptions, partnersAnchorOptions } from '@area/partners/defaultValues';
import { PartnerFilterType } from '@area/partners/types';

interface Props {
  onChange: (values: Partial<PartnerFilterType>) => void;
}

export const PartnersFilter: FC<Props> = ({ onChange }) => {
  const { data: cities, isLoading: loadingCities } = useGetCities('');
  const citiesOptions = (cities || []).map(({ name, id }) => ({ label: name, value: id }));

  if (loadingCities) {
    return <LoadingSpinner />;
  }

  return (
    <Filter<Partial<PartnerFilterType>>
      onChange={onChange}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '224px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'status',
          props: {
            placeholder: 'Статус',
            icon: <Icon name="filter" />,
            options: partnersStatusOptions,
            sx: { width: '300px', background: colors.white },
          },
        },
        {
          type: 'select',
          name: 'isAnchor',
          props: {
            placeholder: 'Якорный',
            sx: { width: '300px', background: colors.white },
            icon: <Icon name="type" />,
            options: partnersAnchorOptions,
          },
        },
        {
          type: 'select',
          name: 'cityId',
          props: {
            placeholder: 'Город',
            sx: { width: '300px', background: colors.white },
            icon: <Icon name="rubric" />,
            options: citiesOptions,
          },
        },
      ]}
    />
  );
};
