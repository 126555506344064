import { Editor, Element, Range, Transforms } from 'slate';
import { CustomEditor, LinkElement } from '../types';

export const isLinkActive = (editor: CustomEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
  return !!link;
};

export const wrapLink = (editor: CustomEditor, url: string, name?: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: name ?? url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};

export const insertLink = (editor: CustomEditor, url: string, name?: string) => {
  if (editor.selection) {
    wrapLink(editor, url, name);
  }
};
