/* eslint-disable indent */
import { isRejectedWithValue, Middleware, AnyAction } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import { nanoid } from 'nanoid';
import { pushNotification } from '@area/notifications/store';
import { normalizeMessage } from '@api/normalizeMessage';

const skippedEndpointNames = ['authControllerLogin'];
const defaultErrorMessage = 'An error has occurred';

const getErrorMessage = (payload: any) => {
  let toastErrorMessage = defaultErrorMessage;

  if (payload?.status?.toString()?.[0] === '4') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    toastErrorMessage = normalizeMessage(payload?.data?.message);
  }

  if (payload?.status?.toString()?.[0] === '5') {
    toastErrorMessage = 'The service is unavailable, please try again later';
  }

  return toastErrorMessage;
};

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const endpointName = action?.meta?.arg?.endpointName || '';
      const isSkipToast = skippedEndpointNames.includes(endpointName as string);

      if (!isSkipToast && action?.payload?.originalStatus !== HttpStatusCode.Unauthorized) {
        if (action.payload?.data?.messages?.length) {
          action.payload.data.messages.forEach((item: string) => {
            const notificationId = nanoid();
            dispatch(
              pushNotification({
                id: notificationId,
                data: { id: notificationId, text: item, severity: 'error' },
              }),
            );
          });
        } else {
          const notificationId = nanoid();
          const toastErrorMessage = getErrorMessage(action?.payload);
          dispatch(
            pushNotification({
              id: notificationId,
              data: { id: notificationId, text: toastErrorMessage, severity: 'error' },
            }),
          );
        }
      }
    }

    return next(action as AnyAction);
  };
