import { FC, useMemo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { useNavigate } from 'react-router';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { EditForm } from '@area/infoPages/components/EditForm';
import { FormInfoPageValues, defaultInfoPageValues } from '@area/infoPages/defaultValues';
import { useCreateInfoPage } from '@area/infoPages/hooks/useCreateInfoPage';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { TypeService } from '@services/TypeService';
import { deserialize } from '@area/redactor/components/Wysiwyg';
import { getMetaTagsFromValues } from '@area/redactor/components/MetaTags/getMetaTagsFromValues';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';
import styles from './style.module.scss';

export const NewInfoPage: FC = () => {
  usePageTitle('Создание информационной страницы');

  const {
    createInfoPage,
    resultOfCreate: { isSuccess, isLoading, error: createError },
  } = useCreateInfoPage();
  const { handlePushItem } = useNotifications();
  const navigate = useNavigate();

  const { uploadMediaFiles, uploadError, deleteError } = useUploadMediaFiles();

  const onSubmit = async (values: FormInfoPageValues) => {
    const infoPage = await createInfoPage(values);
    uploadMediaFiles({
      subjectId: infoPage.id,
      snippetPhoto: values.snippetPhoto,
      photoGallery: values.photoGallery,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Новая информационная страница создана' });
      navigate(buildRoutePath(Routes.informationPages, { absolute: true }));
    }
  }, [isSuccess, handlePushItem]);

  useEffect(() => {
    if (createError && 'message' in createError) {
      handlePushItem({ severity: 'error', text: createError.message });
    }

    if (uploadError && 'message' in uploadError) {
      handlePushItem({ severity: 'error', text: uploadError.message });
    }

    if (deleteError && 'message' in deleteError) {
      handlePushItem({ severity: 'error', text: deleteError.message });
    }
  }, [uploadError, deleteError, createError, handlePushItem]);

  const defaultValues = useMemo(() => {
    return {
      ...defaultInfoPageValues,
      editorContent: TypeService.isString(defaultInfoPageValues.editorContent)
        ? deserialize(defaultInfoPageValues.editorContent)
        : defaultInfoPageValues.editorContent,
      metaTags: getMetaTagsFromValues(),
    };
  }, []);

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Создание информационной страницы"
          backBtnTitle="Назад к списку информационных страниц"
          backBtnRoute={buildRoutePath(Routes.informationPages, { absolute: true })}
        />
      </Grid>
      <EditForm<FormInfoPageValues> defaultValues={defaultValues} onSubmit={onSubmit} isLoading={isLoading} />
    </Grid>
  );
};
