import { Filter } from '@components/Filter';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { getUserRoleOptions } from '@area/users/roles';
import { UserFilterDto } from '@api/admin/adminGeneratedApi';
import { useCurrentUserRoles } from '@area/user/hooks/useCurrentUserRoles';

export const UsersFilter = () => {
  const { roles } = useCurrentUserRoles();

  return (
    <Filter<UserFilterDto>
      onChange={() => null}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '200px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'role',
          props: {
            placeholder: 'Роль',
            icon: <Icon name="user" />,
            options: getUserRoleOptions(roles),
            sx: { width: '274px', background: colors.white },
            multiple: true,
          },
        },
        {
          type: 'select',
          name: 'isActive',
          props: {
            placeholder: 'Статус',
            sx: { width: '274px', background: colors.white },
            icon: <Icon name="type" />,
            options: [
              { value: 'true', label: 'Активный' },
              { value: 'false', label: 'Заблокирован' },
            ],
          },
        },
      ]}
    />
  );
};
