import { Modal } from '@components/Modal';
import { Button, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Option } from '@components/Select/types';
import { validationSchema } from './validation';

export interface ImageFormData {
  author?: string;
  position: Option;
  description?: string;
  imageUrl?: string;
  image?: File;
  imageId?: string;
}

export interface ImageFormProps {
  onSubmit: SubmitHandler<ImageFormData>;
  onClose: () => void;
}

export const ImageForm = ({ onSubmit, onClose }: ImageFormProps) => {
  const resolver = useYupValidationResolver<Omit<ImageFormData, 'imageUrl'>>(validationSchema);
  const form = useForm<ImageFormData>({
    defaultValues: { position: '' as unknown as Option },
    resolver,
  });

  const { handleSubmit } = form;

  return (
    <Modal
      open
      maxWidth="xs"
      onClose={onClose}
      title={'Добавление изображения'}
      actions={
        <>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Добавить
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </>
      }>
      <FormProvider {...form}>
        <Grid container minHeight={650} flexDirection="column" gap={indents.m}>
          <Grid>
            <FormField name="author" title="Автор" fullWidth />
          </Grid>
          <Grid>
            <FormField
              name="position"
              type="select"
              title="Стили"
              options={[
                { label: 'Слева', value: 'left' },
                { label: 'Справа', value: 'right' },
                { label: 'По центру', value: 'center' },
                { label: 'Широкая', value: 'fullWidth' },
              ]}
            />
          </Grid>
          <Grid>
            <FormField name="description" title="Подпись" fullWidth />
          </Grid>
          <Grid>
            <FormField name="imageUrl" title="Добавьте ссылку на фото" fullWidth />
          </Grid>
          <Grid>
            <FormField name="image" type={'image'} title="Или загрузите свое" />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
