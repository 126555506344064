import { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Typography } from '@mui/material';
import { Icon } from '@components/Icon';
import { PaymentCandidatesResponse } from '@api/admin/adminGeneratedApi';
import { CandidatesPaymentResults, PaymentResultsRow } from '@area/payments/types';
import colors from '@styles/colors.module.scss';
import styles from './styles.module.scss';

const rows: PaymentResultsRow[] = [
  { label: 'Всего участников:', key: 'membersCount' },
  { label: 'Всего обработано:', key: 'total' },
  { label: 'Соответствуют:', key: 'approved', icon: <Icon name={'check'} color={colors.success} /> },
  { label: 'Не соответствуют:', key: 'rejected', icon: <Icon name={'close'} color={colors.red} /> },
  { label: 'Обработано с ошибками:', key: 'error', icon: <Icon name={'info'} color={colors.red} /> },
  { label: 'РЕЗУЛЬТАТ ОБРАБОТКИ:', key: 'result', color: colors.success },
];

export interface PaymentsResultsTableProps {
  candidates: PaymentCandidatesResponse[];
}

export const PaymentsResultsTable = ({ candidates }: PaymentsResultsTableProps) => {
  const results = useMemo(() => {
    const candidatesResults: CandidatesPaymentResults = candidates.reduce<CandidatesPaymentResults>(
      (acc, { processDate, approvedDate, rejectedDate, errorDate }) => {
        acc.total += !!processDate ? 1 : 0;
        acc.approved += !!approvedDate ? 1 : 0;
        acc.rejected += !!rejectedDate ? 1 : 0;
        acc.error += !!errorDate ? 1 : 0;

        return acc;
      },
      {
        total: 0,
        approved: 0,
        rejected: 0,
        error: 0,
      },
    );

    return {
      membersCount: candidates.length,
      ...candidatesResults,
      result: 'Успешно', // todo: заменить на реальный текст
    };
  }, [candidates]);

  return (
    <Box sx={{ border: `1px solid ${colors.greyLight}` }} borderRadius={1} mt={4}>
      <Accordion defaultExpanded>
        <AccordionSummary className={styles.accordion} expandIcon={<Icon name={'chevronRight'} color={colors.red} />}>
          <Typography variant={'h2'}>Итоги</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack divider={<Divider />}>
            {rows.map(({ label, key, icon, color }) => (
              <Box key={key} display={'flex'} alignItems={'center'} height={72}>
                <Typography width={200}>{label}</Typography>
                <Typography color={color} display={'flex'} alignItems={'center'} gap={'12px'} fontWeight={400}>
                  {icon} {results[key]}
                </Typography>
              </Box>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
