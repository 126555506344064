import { Icon } from '@components/Icon';
import { IconButton } from '@mui/material';
import { MouseEvent, useRef } from 'react';
import { useSlate } from 'slate-react';
import { useFilesControllerUploadFileMutation } from '@api/admin/adminGeneratedApi';
import { FileType } from '@hooks/useUploadMediaFiles';
import { Location } from 'slate';
import { insertArticleLink } from '../../../../utils/article-link';
import { AddArticleLinkModal, AddArticleLinkRef } from './AddArticleLinkModal';
import { ArticleLinkFormData } from './ArticleLinkForm';

export const ArticleLink = () => {
  const modalRef = useRef<AddArticleLinkRef>(null);
  const editor = useSlate();
  const [uploadFile] = useFilesControllerUploadFileMutation();

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onClickAdd = () => {
    modalRef.current?.open();
  };

  const handleSubmit = async (formData: ArticleLinkFormData) => {
    if (formData.image) {
      const fd = new FormData();
      const { selection } = editor;
      fd.set('file', formData.image);
      fd.set('type', FileType.editorPhoto.toString());
      const res = await uploadFile({ subjectId: '-1', mediaFileUpdaterDto: fd as any }).unwrap();

      insertArticleLink(editor, { ...formData, imageFileUrl: res.url, imageFileId: res.id }, selection as Location);
      return;
    }

    insertArticleLink(editor, formData);
  };

  return (
    <>
      <IconButton
        centerRipple={false}
        onMouseDown={onMouseDown}
        onClick={onClickAdd}
        title="Вставить ссылку на другую публикацию">
        <Icon name="wysiwygArticleLInk" viewBox="0 0 32 32" />
      </IconButton>
      <AddArticleLinkModal modalRef={modalRef} onSubmit={handleSubmit} />
    </>
  );
};
