import { Stack } from '@mui/material';
import { HumanResponse } from '@api/admin/adminGeneratedApi';
import { TicketsMailingForm } from '@area/student/components/TicketsMailingForm';
import { TicketsMailingHistory } from '@area/student/components/TicketsMailingHistory';

export const TicketsMailing = ({ human }: { human: HumanResponse }) => {
  return (
    <Stack gap="32px">
      <TicketsMailingForm email={human.email} />
      <TicketsMailingHistory id={human.id} />
    </Stack>
  );
};
