import { PartnerDepartmentResponse } from '@api/admin/adminGeneratedApi';

export const getCenter = (departments: PartnerDepartmentResponse[]): [number, number] => {
  const [lat, long] = departments.reduce(
    ([lat, long], d) => {
      lat += Number(d.latitude);
      long += Number(d.longitude);

      return [lat, long];
    },
    [0, 0] as number[],
  );

  return [lat / departments.length, long / departments.length];
};
