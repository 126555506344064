import { memo, Ref, useImperativeHandle, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSlateStatic } from 'slate-react';
import { QuoteWithPhotoForm, QuoteWithPhotoFormData } from './QuoteWithPhotoForm';

export interface AddQuoteWithPhotoRef {
  open: () => void;
  close: () => void;
}

export interface AddQuoteWithPhotoProps {
  modalRef: Ref<AddQuoteWithPhotoRef>;
  initialFormData?: QuoteWithPhotoFormData;
  onSubmit: SubmitHandler<QuoteWithPhotoFormData>;
}

export const AddQuoteWithPhotoModal = memo(({ modalRef, initialFormData, onSubmit }: AddQuoteWithPhotoProps) => {
  const editor = useSlateStatic();

  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  useImperativeHandle(modalRef, () => ({
    open: () => {
      if (!editor.selection) return;
      setShowModal(true);
    },
    close: onClose,
  }));

  if (!showModal) {
    return null;
  }

  return (
    <QuoteWithPhotoForm
      onClose={onClose}
      initialFormData={initialFormData}
      onSubmit={(formData) => {
        onSubmit(formData);
        onClose();
      }}
    />
  );
});
