import { useEffect, useRef } from 'react';
import { Box, InputBase } from '@mui/material';
import cn from 'classnames';
import { SelectPlaceholderProps } from './types';
import styles from './style.module.scss';

export const SelectPlaceholder = ({
  multiple,
  placeholder,
  open,
  inputValue,
  isMenuSelect,
  setInputValue,
  onEnterPress,
}: SelectPlaceholderProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    if (open) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [open]);

  if (!multiple || isMenuSelect) {
    return <Box className={cn(styles.placeholder, isMenuSelect && styles.menuSelect)}>{placeholder}</Box>;
  }

  return (
    <InputBase
      value={inputValue}
      inputRef={inputRef}
      sx={{ padding: 0, minWidth: 200 }}
      placeholder={placeholder}
      onChange={({ target }) => setInputValue(target.value)}
      onKeyDown={onEnterPress}
    />
  );
};
