import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import colors from '@styles/colors.module.scss';
import { ChangePasswordFormData } from '@area/user/components/OnboardingChangePassword/types';
import { useNavigate } from 'react-router';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { useUsersControllerChangeCurrentUserPasswordMutation } from '@api/admin/adminApi';
import styles from '@pages/Login/style.module.scss';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';
import { validationSchema } from './validation';

export const OnboardingChangePassword = () => {
  const navigate = useNavigate();
  const resolver = useYupValidationResolver<ChangePasswordFormData>(validationSchema);
  const form = useForm<ChangePasswordFormData>({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    resolver,
  });
  const { handlePushItem } = useNotifications();
  const { logout } = useAuthHelpers();
  const [change, { isLoading, isSuccess }] = useUsersControllerChangeCurrentUserPasswordMutation();

  const onSubmit = form.handleSubmit(async (data) => {
    await change({ userPasswordUpdaterDto: { password: data.password } }).unwrap();

    handlePushItem({ severity: 'success', text: 'Пароль успешно изменен', autoHideDuration: 3000 });
    navigate(buildRoutePath(Routes.main, { absolute: true }));
  });
  const onExit = () => {
    logout();
  };

  return (
    <FormProvider {...form}>
      <Grid item>
        <Typography component="div" variant="body2">
          Для продолжения работы задайте новый пароль
        </Typography>
      </Grid>
      <Grid item>
        <FormField name="password" title="Новый пароль" titleVariant="body1" fullWidth type="password" />
      </Grid>
      <Grid item>
        <FormField name="repeatPassword" title="Повторите пароль" titleVariant="body1" fullWidth type="password" />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Button
          onClick={onSubmit}
          variant="contained"
          startIcon={<Icon name="logoIcon" color={colors.red} />}
          disabled={isLoading}>
          Сохранить
        </Button>
        <Button
          onClick={onExit}
          variant="outlined"
          disabled={isLoading || isSuccess}
          startIcon={<Icon name="signout" color={colors.red} />}>
          Выйти из системы
        </Button>
      </Grid>
      {isLoading && <LinearProgress className={styles.loadingIndicator} />}
    </FormProvider>
  );
};
