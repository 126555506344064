import { Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { KeyQuoteFormData } from '../components/Toolbar/components/KeyQuote/keyQuoteForm';
import { CustomEditor, KeyQuoteElement } from '../types';

export const isKeyQuoteActive = (editor: CustomEditor) => {
  const [quote] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'key-quote',
  });
  return !!quote;
};

export const addKeyQuote = (editor: CustomEditor, formData: KeyQuoteFormData) => {
  if (isKeyQuoteActive(editor)) {
    removeKeyQuote(editor);
  }

  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const div: KeyQuoteElement = {
    type: 'key-quote',
    quote: formData.quote,
    children: [{ text: formData.quote }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [div, { type: 'paragraph', children: [{ text: '' }] }]);
  } else {
    Transforms.wrapNodes(editor, div, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const updateKeyQuote = (editor: CustomEditor, formData: KeyQuoteFormData, element: KeyQuoteElement) => {
  const path = ReactEditor.findPath(editor, element);

  const div: KeyQuoteElement = {
    type: 'key-quote',
    quote: formData.quote,
    children: [{ text: formData.quote }],
  };

  Transforms.delete(editor, { at: path });
  Transforms.insertNodes(editor, div, { at: path });
};

export const deleteKeyQuote = (editor: CustomEditor, element: KeyQuoteElement): void => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.delete(editor, { at: path });
};

export const removeKeyQuote = (editor: CustomEditor) => {
  Transforms.removeNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'key-quote',
  });
};

export const insertKeyQuote = (editor: CustomEditor, formData: KeyQuoteFormData) => {
  if (editor.selection) {
    addKeyQuote(editor, formData);
  }
};
