import { useMemo, useState, useEffect } from 'react';
import cn from 'classnames';
import { Box, Button, Tooltip, Paper, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router';
import { Icon } from '@components/Icon';
// import { Routes } from '@navigation/routes';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { PartnerType, PartnerFilterType } from '@area/partners/types';
import { PartnerShortResponse, PartnerDepartmentResponse } from '@api/admin/adminGeneratedApi';
import { PartnersFilter } from '@area/partnersPage/PartnersFilter';
import { rbdMockDate } from '@area/partnersPage/mock';
import { Tag } from '@components/Tag';
import { usePartners } from '@area/partners/hooks/usePartners';
import { useSyncAll } from '@area/partners/hooks/useSyncAll';
import { useQueryParams } from '@hooks/useQueryParams';
import { DEFAULT_PARTNERS_SIZE, statusName, partnersStatusColors } from '@area/partners/defaultValues';

import colors from '@styles/colors.module.scss';
import styles from './styles.module.scss';

export const PartnersPage = () => {
  usePageTitle('Партнеры программы лояльности');
  const outlet = useOutlet();
  const navigate = useNavigate();

  const [data, setData] = useState<PartnerShortResponse[]>([]);
  const { syncAll, isLoading: loadingSync } = useSyncAll();
  const { getPartners, partnersList, paginate, isSuccess, isLoading, originalArgs } = usePartners();
  const { params } = useQueryParams();

  const fetchData = (values: Partial<PartnerFilterType>, skip: number, size = DEFAULT_PARTNERS_SIZE) => {
    getPartners({
      skip,
      size,
      asc: true,
      partnerFilterRequest: {
        search: values.search,
        status: typeof values.status === 'string' ? values.status : values.status?.value,
        cityId: typeof values.cityId === 'string' ? values.cityId : values.cityId?.value,
        isAnchor: values.isAnchor
          ? typeof values.isAnchor === 'string'
            ? values.isAnchor === 'true'
            : values.isAnchor?.value === 'true'
          : undefined,
      },
    });
  };

  const onChange = (values: Partial<PartnerFilterType>) => {
    setData([]);

    fetchData(values, 0);
  };

  const handleRowClick = (row: PartnerShortResponse) => {
    navigate(row.id);
  };

  const loadMore = () => {
    fetchData(originalArgs?.partnerFilterRequest as PartnerFilterType, data.length);
  };

  const columns: Column<PartnerShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'status',
        key: 'status',
        width: '72px',
        render: (value: PartnerType) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Tag label={statusName[value]} sx={{ background: partnersStatusColors[value], width: '100%' }} />
          </Box>
        ),
      },
      {
        title: 'Якорный',
        align: 'center',
        dataIndex: 'isAnchor',
        key: 'isAnchor',
        width: '72px',
        render: (value: boolean) => (
          <Tooltip title={value ? 'Опубликовано' : 'Не опубликовано'} arrow>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
            </Box>
          </Tooltip>
        ),
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: '350px',
        sx: { whiteSpace: 'normal' },
      },
      {
        title: 'Город',
        dataIndex: 'departments',
        key: 'departments',
        width: 'auto',
        sx: { whiteSpace: 'normal' },
        render: (value: PartnerDepartmentResponse[]) => {
          const cities = value.map(({ city }) => city?.name);

          return [...new Set(cities)].join(' ,');
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!outlet) {
      fetchData(params, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(partnersList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  //   const goToNewPartner = () => navigate(Routes.newItem);

  return (
    <Grid container direction="column" gap="24px">
      <Grid item>
        <Header
          title="Партнеры программы лояльности"
          actions={
            <>
              {/* TODO: add correct date  */}
              {/* <Typography mr={1} className={styles.textRBD} variant="body2">
                Данные из РБД получены:
              </Typography>
              <Typography mr={1} className={styles.textGrey} variant="body2">
                {rbdMockDate}
              </Typography> */}
              <IconButton sx={{ background: colors.white }} onClick={() => syncAll()} disabled={loadingSync}>
                <Icon name="refresh" className={cn(styles.refreshBtnIcon, { spin: loadingSync })} />
              </IconButton>
              {/* <Button className={styles.createPartnerBtn} onClick={goToNewPartner} variant="contained">
                Новый партнер
              </Button> */}
            </>
          }
        />
      </Grid>

      <Grid item>
        <PartnersFilter onChange={onChange} />
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Paper className={styles.paper}>
          <Table columns={columns} data={data} onRowClick={handleRowClick} loading={isLoading} />
          {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
            <Button
              variant="outlined"
              startIcon={<Icon name="more" />}
              className={styles.loadMoreBtn}
              onClick={loadMore}
              disabled={isLoading}>
              Загрузить еще
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
