import { useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import Box from '@mui/material/Box';
import cn from 'classnames';
import { ButtonBase } from '@mui/material';
import { Icon } from '../Icon';
import { ControlsMethods, Controls } from './Controls';
import styles from './style.module.scss';

export interface VideoPlayerProps {
  url?: string;
  width?: number | string;
  height?: number | string;
}

export const VideoPlayer = ({ url, width, height }: VideoPlayerProps) => {
  const playerRef = useRef<ReactPlayer>(null);
  const controlRef = useRef<ControlsMethods>(null);

  const [disabled, setDisabled] = useState(true);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!url || !ReactPlayer.canPlay(url)) {
      setDisabled(true);
      return;
    }
  }, [url]);

  const onReady: ReactPlayerProps['onReady'] = () => {
    setDisabled(false);
  };
  const onProgress: ReactPlayerProps['onProgress'] = (data) => {
    controlRef.current?.setProgress(data);
  };
  const onEnded: ReactPlayerProps['onEnded'] = () => {
    setPlaying(false);
  };
  const onError: ReactPlayerProps['onError'] = () => {
    setDisabled(true);
  };

  const onPlay = () => {
    setPlaying(true);
  };
  const onPause = () => {
    setPlaying(false);
  };
  const onStop = () => {
    setPlaying(false);
    playerRef.current?.seekTo(0);
  };
  const onBackdropClick = () => {
    if (disabled) return;
    playing ? onPause() : onPlay();
  };

  return (
    <Box className={cn(styles.root, playing && styles.playing)} width={width} height={height}>
      <ReactPlayer
        ref={playerRef}
        className={styles.player}
        url={url}
        playing={playing}
        controls={false}
        width="100%"
        height="100%"
        onReady={onReady}
        onProgress={onProgress}
        onEnded={onEnded}
        onError={onError}
      />
      <Controls
        ref={controlRef}
        player={playerRef.current}
        playing={playing}
        disabled={disabled}
        onPlay={onPlay}
        onPause={onPause}
        onStop={onStop}
      />
      <ButtonBase
        onClick={onPlay}
        disabled={disabled}
        className={cn(styles.playButton, disabled && styles.playButtonDisabled, playing && styles.playButtonPlaying)}>
        <Icon className={styles.buttonIcon} name="play" width={30} height={30} />
      </ButtonBase>
      <div
        className={cn(styles.backdrop, disabled && styles.backdropDisabled)}
        onClick={onBackdropClick}
        role="presentation"
      />
    </Box>
  );
};
