import { useState } from 'react';
import { Grid, Button, Box, Typography, LinearProgress } from '@mui/material';
import { FormProvider, useForm, DefaultValues, SubmitHandler } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Modal } from '@components/Modal';
import { PushFormData, validationSchema } from '../validation/validationSchemaPush';
import styles from './style.module.scss';

interface FormProps {
  defaultValues: DefaultValues<PushFormData>;
  onSubmit: SubmitHandler<PushFormData>;
  isNew?: boolean;
  readonly?: boolean;
  isLoading: boolean;
}

export const EditPushForm = ({ defaultValues, onSubmit, readonly = false, isNew = false, isLoading }: FormProps) => {
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const resolver = useYupValidationResolver<PushFormData>(validationSchema);

  const form = useForm<PushFormData>({
    defaultValues: defaultValues as unknown as PushFormData,
    resolver,
  });

  const handleSubmit = form.handleSubmit(onSubmit);

  const toggleSendModal = () => setSendModalOpen(!sendModalOpen);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        <Grid item className={styles.logBtnWrapper}>
          {!isNew && (
            <Button variant="outlined" className={styles.logBtn} onClick={toggleSendModal}>
              Лог рассылки
            </Button>
          )}
        </Grid>
        <FormProvider {...form}>
          <Grid item>
            <FormField
              name="date"
              type="dateTimePicker"
              title="Дата отправки"
              sx={{ width: 200 }}
              disabled={readonly}
            />
          </Grid>
          <Grid item>
            <FormField name="title" title="Заголовок письма" fullWidth disabled={readonly} />
          </Grid>
          <Grid item>
            <FormField name="text" title="Текст" type="wysiwyg" />
          </Grid>
          <Grid item>
            <FormField name="link" title="Ссылка" fullWidth disabled={readonly} />
          </Grid>
          <Grid item sx={{ width: 304 }}>
            <FormField name="img" title="Иллюстрация" type="image" canUseLink />
          </Grid>
          <Grid item flex={1}>
            <FormField name="targets" type="text" title="Кому, через запятую" fullWidth disabled={readonly} />
          </Grid>
        </FormProvider>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
      <Box className={styles.submitBtnWrapper}>
        <Button
          variant="contained"
          className={styles.submitBtn}
          disabled={readonly}
          startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
          onClick={handleSubmit}>
          Сохранить
        </Button>
        {/*TODO: Странная кнопка, зачем нужна если есть дата отправки*/}
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  className={styles.sendBtn}*/}
        {/*  startIcon={<Icon name="send" className={styles.submitBtnIcon} />}*/}
        {/*  onClick={handleSubmitNow}>*/}
        {/*  Отправить*/}
        {/*</Button>*/}
      </Box>

      <Modal
        open={sendModalOpen}
        title="Отправка сообщения"
        maxWidth="sm"
        onClose={toggleSendModal}
        actions={
          <Button variant="contained" onClick={toggleSendModal} className={styles.closeModalBtn}>
            Закрыть
          </Button>
        }>
        <Grid container gap={indents.s}>
          <Grid item xs={12}>
            <Typography variant="body2">Собираем HTML письма.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Письмо собралось.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Создаем сообщение.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запускается....</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запущена.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Отправлено: 1 пользователю..</Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
