import indents from '@styles/indents.module.scss';
import { Box, Button, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { Header } from '@layouts/Base/components/Header';
import { CreatePaymentButton } from '@area/payments/components/CreatePaymentButton';
import { PaymentsFilters } from '@area/payments/components/PaymentsFilters';
import { useNavigate, useParams } from 'react-router';
import { PaymentType, PaymentStatus } from '@area/payments/types';
import { usePayments } from '@area/payments/hooks/usePayments';
import { Table } from '@components/Table/Table';
import { PaymentShortResponse } from '@api/admin/adminGeneratedApi';
import { Icon } from '@components/Icon';
import { useMemo } from 'react';
import { ReportModal as ReportModalButton } from '../ReportModal';
import { getColumns } from './columns';
import styles from './styles.module.scss';

export const PaymentsContent = () => {
  const { paymentType } = useParams<{ paymentType: PaymentType }>();
  const title = paymentType === 'extraGrant' ? 'Выплаты стипендий' : 'Выплаты компенсаций';

  const { payments, totalStudent, isLoading, isFetched, hasMore, fetchPayments, refetchPayments } = usePayments(
    paymentType!,
  );
  const columns = useMemo(() => getColumns(refetchPayments), [refetchPayments]);
  const navigate = useNavigate();
  const onRowClick = ({ id }: PaymentShortResponse) => navigate(id);
  const canMakeReport = payments?.some(({ status }) => status === PaymentStatus.approved);

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header
          title={title}
          actions={
            <>
              <CreatePaymentButton onCreate={refetchPayments} />
              {canMakeReport && <ReportModalButton />}
            </>
          }
        />
      </Grid>
      <Grid item>
        <PaymentsFilters />
      </Grid>
      <Grid item>
        <Paper className={styles.paper}>
          <Table<PaymentShortResponse>
            onRowClick={onRowClick}
            columns={columns}
            data={payments}
            fetched={isFetched}
            loading={isLoading}
            tableLayout="fixed"
          />
          {hasMore && (
            <Button
              variant="outlined"
              className={styles.loadMoreBtn}
              startIcon={<Icon name="more" />}
              onClick={() => fetchPayments()}
              disabled={isLoading}>
              Загрузить еще
            </Button>
          )}
          <Box display="flex" className={styles.totalRow}>
            <Typography className={styles.totalRow__name}>Итого</Typography>
            <Typography className={styles.totalRow__studentCount}>{isLoading ? <Skeleton /> : totalStudent}</Typography>
            <Typography className={styles.totalRow__sum} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
