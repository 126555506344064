import { memo, Ref, useImperativeHandle, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSlateStatic } from 'slate-react';
import { ArticleLinkForm, ArticleLinkFormData } from './ArticleLinkForm';

export interface AddArticleLinkRef {
  open: () => void;
  close: () => void;
}

export interface AddQuoteWithPhotoProps {
  modalRef: Ref<AddArticleLinkRef>;
  initialFormData?: ArticleLinkFormData;
  onSubmit: SubmitHandler<ArticleLinkFormData>;
}

export const AddArticleLinkModal = memo(({ modalRef, initialFormData, onSubmit }: AddQuoteWithPhotoProps) => {
  const editor = useSlateStatic();

  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  useImperativeHandle(modalRef, () => ({
    open: () => {
      if (!editor.selection) return;
      setShowModal(true);
    },
    close: onClose,
  }));

  if (!showModal) {
    return null;
  }

  return (
    <ArticleLinkForm
      onClose={onClose}
      initialFormData={initialFormData}
      onSubmit={(formData) => {
        onSubmit(formData);
        onClose();
      }}
    />
  );
});
