import { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import { Icon } from '../Icon';
import { IconName } from '../Icon/types';
import { LogoName } from './types';

const iconsNames: Record<LogoName, IconName> = {
  long: 'logoLong',
  short: 'logoShort',
  icon: 'logoIcon',
};

interface LogoProps extends SvgIconProps {
  name?: LogoName;
  size?: number;
}

export const Logo: FC<LogoProps> = ({ name = 'long', size, ...rest }) => {
  const iconName = iconsNames[name];

  return <Icon name={iconName} size={size} {...rest} inheritViewBox />;
};
