import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StorageState } from '@store/index';

interface MediaFilesUploads {
  filesNumber: number;
  uploadedFilesNumber: number;
  snackbarIsOpen: boolean;
}

const initialState: MediaFilesUploads = {
  filesNumber: 0,
  uploadedFilesNumber: 0,
  snackbarIsOpen: false,
};

export const mediaFilesUploadSlice = createSlice({
  name: 'mediaFilesUpload',
  initialState,
  reducers: {
    setFilesNumber: (state, action: PayloadAction<number>) => {
      state.filesNumber = action.payload;
    },
    setUploadedFilesNumber: (state, action: PayloadAction<number>) => {
      state.uploadedFilesNumber = action.payload;
    },
    incrementUploadedFilesNumber: (state) => {
      state.uploadedFilesNumber++;
    },
    setSnackbarOpen: (state, action: PayloadAction<boolean>) => {
      state.snackbarIsOpen = action.payload;
    },
  },
});

export const { setFilesNumber, setUploadedFilesNumber, setSnackbarOpen, incrementUploadedFilesNumber } =
  mediaFilesUploadSlice.actions;

export const filesNumberSelector = (state: StorageState) => state.mediaFilesUploadSlice.filesNumber;
export const uploadedFilesNumberSelector = (state: StorageState) => state.mediaFilesUploadSlice.uploadedFilesNumber;
export const snackbarIsOpenSelector = (state: StorageState) => state.mediaFilesUploadSlice.snackbarIsOpen;
