/* eslint-disable typescript-enum/no-enum,no-unused-vars */
import { ReactNode } from 'react';

export enum PaymentType {
  creditGrant = 'creditGrant',
  extraGrant = 'extraGrant',
}

export enum PaymentStatus {
  processing,
  paused,
  processingComplete,
  approved,
}

export const PaymentStatusName: Record<PaymentStatus, string> = {
  [PaymentStatus.processing]: 'Формирование',
  [PaymentStatus.paused]: 'Остановлена',
  [PaymentStatus.processingComplete]: 'Сформирована',
  [PaymentStatus.approved]: 'Подтверждена',
} as const;

export interface PaymentResults {
  membersCount: number;
  total: number;
  approved: number;
  rejected: number;
  error: number;
  result: string;
}

export type CandidatesPaymentResults = Omit<PaymentResults, 'membersCount' | 'result'>;

export interface PaymentResultsRow {
  label: string;
  key: keyof PaymentResults;
  icon?: ReactNode;
  color?: string;
}

export enum PaymentCandidateProcessStatus {
  deleted,
  error,
  rejected,
  approved,
}

export enum PaymentEoOrgDocFormat {
  csv = 'csv',
  xlsx = 'xlsx',
  pdf = 'pdf',
}
