import { forwardRef, ChangeEvent, useState } from 'react';
import { Grid, IconButton, Stack } from '@mui/material';
import { Icon } from '@components/Icon';
import { VideoPlayer } from '@components/VideoPlayer';
import indents from '@styles/indents.module.scss';
import { Input } from '@components/Input';
import styles from './styles.module.scss';

import { VideoPickerProps } from './types';

export const VideoPicker = forwardRef(({ value, onChange }: VideoPickerProps, _) => {
  const [url, setUrl] = useState('');
  const [val, setVal] = useState<string | undefined>();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value);

  const onBtnClick = () => {
    setVal(url);
    // TODO: implement change logic
    // onChange?.(url);
  };

  return (
    <Grid container gap="20px">
      <Grid item className={styles.textFieldWrapper}>
        <Stack direction="row" gap={indents.xs}>
          <Input
            className={styles.textField}
            placeholder="Вставьте ссылку на видео"
            value={url}
            onChange={onInputChange}
          />

          <IconButton onClick={onBtnClick}>
            <Icon name="check" />
          </IconButton>
        </Stack>
      </Grid>

      <Grid item width="100%">
        <VideoPlayer url={val} />
      </Grid>
    </Grid>
  );
});
