import * as yup from 'yup';

export const validationSchema = yup.object({
  author: yup.string(),
  position: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .typeError('Обязательное поле.')
    .required('Обязательное поле.'),
  imageUrl: yup.string().test('imageUrlOrImage', 'Добавьте ссылку на фото или загрузите свое', function (value) {
    const { image } = this.parent;

    return !!value || !!image;
  }),
  image: yup
    .mixed()
    .nullable()
    .test(
      'imageUrlOrImage',
      'Добавьте ссылку на фото или загрузите свое',
      function (value: any, { path, createError }) {
        const { imageUrl } = this.parent;

        if (value && value.size > 5 * 1024 * 1024) {
          return createError({ path, message: 'Размер изображения не должен превышать 5Мб' });
        }

        return !!value || !!imageUrl;
      },
    ),
});
