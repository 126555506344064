import { FC, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from '@components/Modal';
import indents from '@styles/indents.module.scss';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { FormField } from '@components/FormField';
import { FormFieldProps } from '@components/FormField/types';
import { Mask } from 'react-text-mask';
import { useParticipantsHumansControllerUpdateHumanMutation } from '@api/admin/adminApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { PersonalDataEditModalFormData, PersonalDataEditModalProps } from './types';
import { validationSchema } from './validation';

const fieldConfigs: Array<
  FormFieldProps<PersonalDataEditModalFormData> & {
    title: string;
  }
> = [
  {
    name: 'phoneNumber',
    title: 'Телефон',
    placeholder: 'Введите телефон',
    type: 'text',
    fullWidth: true,
    mask: (rawValue) => {
      const result: Mask = ['+'];

      rawValue
        .replace(/\D/g, '')
        .split('')
        .forEach((char) => result.push(RegExp(char)));

      return result.length > 1 ? result : [];
    },
  },
  {
    name: 'note',
    title: 'Комментарий',
    placeholder: 'Введите комментарий',
    type: 'text',
    multiline: true,
    fullWidth: true,
    rows: 4,
  },
];

export const PersonalDataEditModal: FC<PersonalDataEditModalProps> = ({ open, human, onClose }) => {
  const [updater] = useParticipantsHumansControllerUpdateHumanMutation();

  const { handlePushItem } = useNotifications();
  const resolver = useYupValidationResolver<PersonalDataEditModalFormData>(validationSchema);
  const form = useForm<PersonalDataEditModalFormData>({ resolver });

  const onLocalClose = () => {
    onClose();
    form.reset();
  };
  const onSubmit = form.handleSubmit(async (data) => {
    if (!human?.id) return;

    try {
      await updater({ id: human.id, humanUpdateRequest: data }).unwrap();
      handlePushItem({ severity: 'success', text: 'Данные участника успешно обновлены' });
      onClose();
    } catch (error) {
      handlePushItem({ severity: 'error', text: 'Не удалось обновить данные участника' });
    }
  });

  useEffect(() => {
    if (!human) {
      return form.reset();
    }

    const formData = fieldConfigs.reduce<PersonalDataEditModalFormData>((acc, config) => {
      acc[config.name] = human[config.name] ?? '';
      return acc;
    }, {});

    form.reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [human]);

  return (
    <Modal
      title="Изменение данных участника"
      open={open}
      onClose={onClose}
      maxWidth="sm"
      actions={
        <Grid container gap={indents.s}>
          <Button variant="outlined" onClick={onLocalClose} disabled={form.formState.isSubmitting}>
            Отмена
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={form.formState.isSubmitting || !form.formState.isDirty}>
            Сохранить
          </Button>
        </Grid>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.m}>
          {fieldConfigs.map(({ name, ...rest }) => (
            <FormField<PersonalDataEditModalFormData> key={name} name={name} {...rest} />
          ))}
        </Grid>
      </FormProvider>
    </Modal>
  );
};
