import { Icon } from '@components/Icon';
import { Box, Grid, Paper, Typography } from '@mui/material';
import indents from '@styles/indents.module.scss';

import colors from '@styles/colors.module.scss';
import styles from '@layouts/Login/style.module.scss';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';
import { useEffect } from 'react';
import { useAuthHelpers } from '@area/user/hooks/useAuthHelpers';
import { OnboardingChangePassword } from 'area/user/components/OnboardingChangePassword';

export const OnboardingChangePasswordPage = () => {
  const { currentUser, isError } = useCurrentUser();
  const { logout } = useAuthHelpers();

  useEffect(() => {
    if (isError) {
      logout();
    }
  }, [isError]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" className={styles.root}>
      <div className={styles.logo}>
        <Icon name="logoLong" width={132} height={60} viewBox="0 0 131 60" />
      </div>
      <Paper variant="outlined" className={styles.content}>
        <Grid container flexDirection="column" gap={indents.l} width={340}>
          <Grid item>
            <Typography variant="h1" textAlign="center">
              Изменение пароля
            </Typography>
            <Typography component="div" variant="body2" textAlign="center" color={colors.grey}>
              {currentUser?.email ?? ''}
            </Typography>
          </Grid>
          <OnboardingChangePassword />
        </Grid>
      </Paper>
    </Box>
  );
};
