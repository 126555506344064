import { Editor, Element, Range, Transforms, Location } from 'slate';
import { ReactEditor } from 'slate-react';
import { ArticleLinkFormData } from '../components/Toolbar/components/ArticleLink/ArticleLinkForm';
import { ArticleLinkElement, CustomEditor } from '../types';

export const isArticleLinkActive = (editor: CustomEditor) => {
  const [quote] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'article-link',
  });
  return !!quote;
};

export const addArticleLink = (editor: CustomEditor, formData: ArticleLinkFormData, selection?: Location) => {
  if (isArticleLinkActive(editor)) {
    removeArticleLink(editor);
  }

  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const div: ArticleLinkElement = {
    type: 'article-link',
    linkHref: formData.linkHref,
    linkText: formData.linkText,
    imageUrl: formData.imageUrl,
    imageFileUrl: formData.imageFileUrl,
    imageFileId: formData.imageFileId,
    children: [{ text: formData.linkText }],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, [div, { type: 'paragraph', children: [{ text: '' }] }], { at: selection });
  } else {
    Transforms.wrapNodes(editor, div, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const updateArticleLink = (editor: CustomEditor, formData: ArticleLinkFormData, element: ArticleLinkElement) => {
  const path = ReactEditor.findPath(editor, element);

  const div: ArticleLinkElement = {
    type: 'article-link',
    linkHref: formData.linkHref,
    linkText: formData.linkText,
    imageUrl: formData.imageUrl,
    imageFileUrl: formData.imageFileUrl,
    imageFileId: formData.imageFileId,
    children: [{ text: formData.linkText }],
  };

  Transforms.delete(editor, { at: path });
  Transforms.insertNodes(editor, div, { at: path });
};

export const deleteArticleLink = (editor: CustomEditor, element: ArticleLinkElement): void => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.delete(editor, { at: path });
};

export const removeArticleLink = (editor: CustomEditor) => {
  Transforms.removeNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'quote-with-photo',
  });
};

export const insertArticleLink = (editor: CustomEditor, formData: ArticleLinkFormData, seletion?: Location) => {
  if (editor.selection) {
    addArticleLink(editor, formData, seletion);
  }
};
