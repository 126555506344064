import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { Tag } from '@components/Tag';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@navigation/routes';
import { Icon } from '@components/Icon';
import { AppProgressTable } from '@area/applications/AppProgressTable';
import { ApplicationResponse } from '@api/admin/adminGeneratedApi';
import { Statuses } from '../Statuses';
import { OrderStatus, OrderType } from '../types';

interface AppCardProps {
  data: ApplicationResponse;
  isCreditDataValid?: boolean;
}

const renderStatusTag = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.new:
      return <Tag label={'Новый'} sx={{ background: colors.greyLight, color: colors.black }} />;
    case OrderStatus.processing:
      return <Tag label={'В обработке'} sx={{ background: colors.greyLight, color: colors.black }} />;
    case OrderStatus.success:
      return <Tag label={'Успешно'} sx={{ background: colors.success, color: colors.white }} />;
    case OrderStatus.paused:
      return <Tag label={'Остановлена'} sx={{ background: colors.lightRed, color: colors.black }} />;
    case OrderStatus.error:
      return <Tag label={'Неуспешно'} sx={{ background: colors.red, color: colors.white }} />;
  }
};

export const AppCard: FC<AppCardProps> = ({ data, isCreditDataValid }) => {
  const navigate = useNavigate();
  const goToStudent = () => navigate(`/${Routes.students}/${data.human.id}`);
  return (
    <Box sx={{ backgroundColor: colors.white }} borderRadius={1} p={'20px'} mt={3}>
      <Grid display="flex" flexDirection="row" justifyContent="space-between">
        <Typography mb={2} variant={'h2'}>
          Текущий статус
        </Typography>
        <Statuses status={data.status} type={data.type as OrderType} isCreditDataValid={isCreditDataValid} />
      </Grid>
      {renderStatusTag(data.status)}
      <Typography mt={4} variant={'h2'}>
        Страница участника
      </Typography>
      <Box mt={1} sx={{ cursor: 'pointer' }} display={'flex'} alignItems={'center'} color={colors.red}>
        <Typography
          onClick={goToStudent}>{`${data.human.lastName} ${data.human.firstName} ${data.human.middleName}`}</Typography>
        <Icon name={'chevronRight'} />
      </Box>
      <AppProgressTable
        data={[{ date: data.updatedAt || data.createdAt, status: data.status, statusText: data.statusText }]}
      />
    </Box>
  );
};
