import { useMemo, useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  LinearProgress,
} from '@mui/material';
import cn from 'classnames';
import { useQueryParams } from '@hooks/useQueryParams';
import { Column, PaginationSettings } from './types';
import styles from './styles.module.scss';
import { Pagination } from './Pagination';

interface TableProps<T extends Record<string, any>> {
  columns: Column<T>[];
  data: T[];
  loading?: boolean;
  fetched?: boolean;
  tableLayout?: 'auto' | 'fixed';
  showPagination?: boolean;
  paginationProps?: PaginationSettings;
  onRowClick?: (row: T) => void;
  loadingRowId?: string;
}

const NO_DATA_MESSAGE = 'Нет данных';
const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 50;

export const Table = <T extends Record<string, any>>({
  columns: rawColumns,
  data,
  loading,
  fetched = false,
  tableLayout = 'auto',
  showPagination = false,
  paginationProps,
  onRowClick,
  loadingRowId,
}: TableProps<T>) => {
  const columns = useMemo(() => {
    return rawColumns.filter((c) => Boolean(c.key) || Boolean(c.dataIndex));
  }, [rawColumns]);

  const { setParams } = useQueryParams();
  const [page, setPage] = useState(paginationProps?.page || DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(paginationProps?.rowsPerPage || DEFAULT_ROWS_PER_PAGE);

  const onPageChange = (_: unknown, newPage: number) => setPage(newPage);
  const onRowsPerPageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(e.target.value as unknown as number);
    setPage(0);
  };

  useEffect(() => {
    if (showPagination) {
      setParams({ page: String(page + 1), size: String(rowsPerPage) });
    }
  }, [page, rowsPerPage, setParams, showPagination]);

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <TableContainer className={styles.root}>
      <MuiTable className={cn(styles.table, tableLayout === 'fixed' && styles.tableLayoutFixed)}>
        <TableHead>
          <TableRow>
            {columns.map(({ key, dataIndex, title, align = 'left', width }) => (
              <TableCell key={key ?? dataIndex} align={align} sx={{ width, whiteSpace: 'nowrap' }}>
                <Typography variant="body2">{title}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={cn(styles.body, { loading })}>
          {data.map((row, index: number) => {
            const loadingRow = loadingRowId && row.id === loadingRowId;

            return (
              <TableRow
                key={row?.id ?? index}
                onClick={() => handleRowClick(row)}
                sx={{ cursor: onRowClick && 'pointer' }}
                className={cn(styles.row, { loadingRow })}>
                <>
                  {columns.map(({ render, key, dataIndex, align, width, sx, ellipsis }) => {
                    const value = row[dataIndex];

                    return (
                      <TableCell
                        key={key ?? dataIndex}
                        align={align}
                        sx={{ whiteSpace: 'nowrap', ...sx, width }}
                        className={cn(styles.cell, { ellipsisCell: ellipsis })}
                        title={ellipsis ? (render ? render(value, row) : value) : undefined}>
                        {render ? render(value, row) : value ?? ''}
                      </TableCell>
                    );
                  })}
                  {loadingRow && <LinearProgress className={styles.loadingIndicator} />}
                </>
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
      {showPagination && data.length > 0 && (
        <Pagination
          settings={paginationProps}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      {data.length === 0 && (
        <Box className={cn(styles.noData, { loading })}>
          {fetched && <Typography variant="body1">{NO_DATA_MESSAGE}</Typography>}
        </Box>
      )}
      <Box className={cn(styles.spinnerWrapper, { loading })}>
        <CircularProgress className={styles.spinner} />
      </Box>
    </TableContainer>
  );
};
