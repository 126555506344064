import { ProgramResponse } from '@api/admin/adminGeneratedApi';

export const getProgramStatusTitle = (response: ProgramResponse): string => {
  let title = '';
  switch (response.type) {
    case 'credit_grant':
      title = 'Компенсация образовательного кредита';
      break;
    case 'extra_grant':
      title = 'Стипендиальная программа';
      break;
    case 'esk_card':
      title = 'Социальная карта';
      break;
  }
  return title;
};
