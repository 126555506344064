import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { ChangePasswordModal } from '@area/user/components/ChangePasswordModal';
import { ChangePasswordFormData } from '@area/user/components/ChangePasswordModal/types';
import { Grid, Paper, Box, LinearProgress } from '@mui/material';
import { Header } from '@layouts/Base/components/Header';
import {
  useUsersControllerChangeCurrentUserPasswordMutation,
  useUsersControllerUpdateCurrentUserMutation,
} from '@api/admin/adminApi';
import { UsersControllerUpdateCurrentUserApiArg } from '@api/admin/adminGeneratedApi';

import { Form as UserForm } from '@area/user/components/Form/Form';
import { FormSkeleton } from '@area/user/components/Form/FormSkeleton';
import { useCurrentUser } from '@area/user/hooks/useCurrentUser';

import indents from '@styles/indents.module.scss';

import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import styles from './styles.module.scss';

export const ProfilePage = () => {
  const { currentUser } = useCurrentUser();
  const { handlePushItem } = useNotifications();

  const [updateUser, { isLoading }] = useUsersControllerUpdateCurrentUserMutation();
  const [changePassword] = useUsersControllerChangeCurrentUserPasswordMutation();

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const onSubmit = async (user: UsersControllerUpdateCurrentUserApiArg) => {
    await updateUser(user);
  };

  const onClickChangePassword = () => {
    setChangePasswordModalOpen(true);
  };

  const onCloseChangePassword = () => {
    setChangePasswordModalOpen(false);
  };

  const onSubmitChangePassword: SubmitHandler<ChangePasswordFormData> = async (data) => {
    try {
      await changePassword({
        userPasswordUpdaterDto: {
          // oldPassword: data.oldPassword,
          password: data.password,
        },
      }).unwrap();
      setChangePasswordModalOpen(false);
      handlePushItem({ severity: 'success', text: 'Пароль успешно изменен' });
    } catch {
      // do nothing
    }
  };

  return (
    <Grid container direction="column" gap={indents.m}>
      <Grid item>
        <Header title="Мой профиль" />
      </Grid>

      <Grid item>
        <Paper className={styles.paper}>
          <Box className={styles.form}>
            {currentUser ? (
              <UserForm
                data={currentUser}
                onSubmit={onSubmit}
                onClickChangePassword={onClickChangePassword}
                isLoading={isLoading}
              />
            ) : (
              <FormSkeleton />
            )}
          </Box>

          {isLoading && <LinearProgress className={styles.loadingIndicator} />}
          {isChangePasswordModalOpen && (
            <ChangePasswordModal open onClose={onCloseChangePassword} onSubmit={onSubmitChangePassword} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
