import { Icon } from '@components/Icon';
import { Box } from '@mui/material';
import { FC, useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import { deleteImage } from '@area/redactor/components/Wysiwyg/utils/image';
import { useWindowSize } from '@hooks/useWindowSize';
import { useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import { ImageElement } from '../../types';
import { useConfirmDialog } from '../ConfirmDialog/useConfirmDialog';

export const ImageCard: FC<RenderElementProps> = (props) => {
  const [width] = useWindowSize();
  const [imgStyles, setImgStyles] = useState<{
    width: number;
    marginLeft: number;
  }>();
  const [deleteFile] = useFilesControllerDeleteFileMutation();
  const { attributes, children } = props;
  const editor = useSlate();
  const element = props.element as ImageElement;
  const ref = useRef<HTMLDivElement>(null);

  const { onClickConfirm, dialog } = useConfirmDialog({
    title: 'Подтвердите действие',
    text: 'Удалить фотографию?',
    onConfirm: async () => {
      ReactEditor.focus(editor);

      if (element.imageId) {
        await deleteFile({ fileId: element.imageId });
      }

      deleteImage(editor, element);
    },
  });

  useEffect(() => {
    if (element.position === 'fullWidth') {
      const elementWidth = ref.current?.clientWidth;
      const editorWrapperElement = document.getElementById('editorViewport');
      const editorWrapperElementWidth =
        // 42 - paddings and margins
        (editorWrapperElement?.clientWidth || 0) - 42;

      if (elementWidth) {
        if (elementWidth < editorWrapperElementWidth) {
          setImgStyles({
            width: editorWrapperElementWidth,
            marginLeft: ((editorWrapperElementWidth - elementWidth) / 2) * -1,
          });
        } else {
          setImgStyles({
            width: elementWidth,
            marginLeft: 0,
          });
        }
      }
    }
  }, [width]);

  return (
    <Box className={cn('image-card', { [`image-${element.position}`]: true })} {...attributes} contentEditable={false}>
      <div className="image-card__image-wrapper" ref={ref}>
        <div className="image-card__image" style={imgStyles}>
          <img src={element.imageUrl} alt="" />
        </div>
      </div>
      {element.author && <div className="image-author">{element.author}</div>}
      {element.description && <div className="image-description">{element.description}</div>}
      <p style={{ display: 'none' }}>{children}</p>
      <div className="image-card__actions">
        <button onClick={onClickConfirm}>
          <Icon name="delete" width={16} height={16} />
        </button>
      </div>
      {dialog}
    </Box>
  );
};
