import { Button, Stack, Typography } from '@mui/material';
import { FormField } from '@components/FormField';
import { FormProvider, useForm } from 'react-hook-form';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import * as yup from 'yup';
import { TicketsMailingFormFormData } from '@area/student/components/TicketsMailingForm/types';
import { adminApi, useNotificationsControllerCreateMutation } from '@api/admin/adminApi';
import { Icon } from '@components/Icon';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import dayjs from 'dayjs';
import { ApiTags } from '@api/admin/tags';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

const validationSchema = yup.object<TicketsMailingFormFormData>({
  title: yup.string(),
  text: yup.string().required('Обязательное поле.'),
});

export const TicketsMailingForm = ({ email }: { email: string }) => {
  const dispatch = useDispatch();
  const { handlePushItem } = useNotifications();
  const [createNotification] = useNotificationsControllerCreateMutation();

  const form = useForm<TicketsMailingFormFormData>({
    defaultValues: {},
    resolver: useYupValidationResolver<TicketsMailingFormFormData>(validationSchema),
  });

  const handleSubmit = form.handleSubmit(async (formData) => {
    try {
      await createNotification({
        notificationsRequest: {
          type: 1,
          sendAt: dayjs().toString(),
          title: formData.title || 'Сообщение от фонда «Кампус»',
          sender: 'campus@studgorod.ru',
          text: formData.text,
          targets: [email],
        },
      }).unwrap();

      handlePushItem({ severity: 'success', text: 'Сообщение отправлено' });
      dispatch(adminApi.util.invalidateTags([ApiTags.GetHumanTicketsAndMailing]));
      form.reset();
    } catch (error) {
      handlePushItem({ severity: 'error', text: 'Не удалось отправить сообщение' });
    }
  });

  return (
    <Stack gap="20px">
      <Typography variant="h2">Новое сообщение</Typography>
      <FormProvider {...form}>
        <FormField name="title" title="Тема сообщения" titleVariant="body1" sx={{ width: 500 }} />
        <FormField name="text" title="Текст сообщения" titleVariant="body1" multiline rows={2} sx={{ width: 500 }} />
        <div>
          <Button
            variant="contained"
            startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
            onClick={handleSubmit}
            disabled={form.formState.isSubmitting}>
            Отправить
          </Button>
        </div>
      </FormProvider>
    </Stack>
  );
};
