import { adminApi } from '@api/admin/adminApi';

export const useSyncAll = () => {
  const [syncAll, { isLoading }] = adminApi.endpoints.partnerControllerSyncAll.useLazyQuery();

  return {
    syncAll,
    isLoading,
  };
};
