import { Grid } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { Translations } from '@area/translations';

export const TranslationsPage = () => {
  usePageTitle('Переводы');

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header title="Переводы" />
      </Grid>
      <Translations />
    </Grid>
  );
};
