import { Icon } from '@components/Icon';
import { FC, useRef } from 'react';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';
import { DefinitionInfoElement } from '../../types';
import { deleteDefinitionInfo, updateDefinitionInfo } from '../../utils/definition-info';
import { useConfirmDialog } from '../ConfirmDialog/useConfirmDialog';
import {
  AddDefinitionInfoModal,
  AddDefinitionInfoRef,
} from '../Toolbar/components/DefinitionInfo/AddDefinitionInfoModal';
import styles from './styles.module.scss';

export const DefinitionInfoCard: FC<RenderElementProps> = (props) => {
  const { attributes, children } = props;
  const editor = useSlate();
  const element = props.element as DefinitionInfoElement;
  const modalRef = useRef<AddDefinitionInfoRef>(null);

  const { onClickConfirm, dialog } = useConfirmDialog({
    title: 'Подтвердите действие',
    text: 'Удалить определение?',
    onConfirm: () => {
      ReactEditor.focus(editor);
      deleteDefinitionInfo(editor, element);
    },
  });

  const onLinkClickEdit = () => {
    ReactEditor.focus(editor);
    modalRef.current?.open();
  };

  return (
    <div className="definition-info" {...attributes} contentEditable={false}>
      <div className="definition-info__container">
        <div className="definition-info__title">{children}</div>
        {element.definition && <div className="definition-info__text">{element.definition}</div>}
        <div className={styles.definitionInfoActions}>
          <button onClick={onLinkClickEdit}>
            <Icon name="edit" width={16} height={16} />
          </button>
          <button onClick={onClickConfirm}>
            <Icon name="delete" width={16} height={16} />
          </button>
        </div>
        <AddDefinitionInfoModal
          modalRef={modalRef}
          initialFormData={element}
          onSubmit={(formData) => {
            updateDefinitionInfo(editor, formData, element);
          }}
        />
        {dialog}
      </div>
    </div>
  );
};
