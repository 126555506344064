import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLazyGetAllPaymentsByTypeQuery } from '@api/admin/adminApi';
import { PaymentType } from '@area/payments/types';
import { useQueryParams } from '@hooks/useQueryParams';
import { DateService } from '@services/DateService';
import { PaymentShortResponse } from '@api/admin/adminGeneratedApi';
import { DEFAULT_PAYMENTS_SIZE } from '../defaultValues';

export const usePayments = (paymentType: PaymentType) => {
  const isRefetch = useRef<boolean>(true);
  const localPayments = useRef<PaymentShortResponse[]>([]);
  const { params } = useQueryParams();
  const [fetch, { data, isLoading, isFetching, isSuccess, isError }] = useLazyGetAllPaymentsByTypeQuery();
  const payments = useMemo(() => {
    if (!data?.data) {
      return localPayments.current;
    }

    if (isRefetch.current) {
      isRefetch.current = false;
      localPayments.current = [];
    }

    localPayments.current = [...localPayments.current, ...data?.data];

    return localPayments.current;
  }, [data?.data]);

  const fetchPayments = useCallback(
    async (size = DEFAULT_PAYMENTS_SIZE) => {
      if (isLoading || isFetching) return;

      await fetch({
        paymentType,
        skip: isRefetch.current ? 0 : localPayments.current.length,
        size,
        paymentsFiltersRequest: {
          confirmationDateFrom: DateService.getDateFromParams(params.confirmationDateFrom),
          confirmationDateTo: DateService.getDateFromParams(params.confirmationDateTo),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, params.confirmationDateFrom, params.confirmationDateTo, paymentType],
  );
  const refetchPayments = useCallback(async () => {
    isRefetch.current = true;
    await fetchPayments(localPayments.current.length || DEFAULT_PAYMENTS_SIZE);
    isRefetch.current = false;
  }, [fetchPayments]);
  useEffect(() => {
    isRefetch.current = true;
    fetchPayments();
  }, [fetchPayments]);
  useEffect(() => {
    const id = setInterval(() => refetchPayments(), 10000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType, params.confirmationDateFrom, params.confirmationDateTo]);

  return {
    payments: payments,
    totalStudent: localPayments.current.reduce((acc, item) => acc + item.studentsCount, 0) ?? 0,
    totalSum: localPayments.current.reduce((acc, item) => acc + item.sum, 0) ?? 0,
    isLoading: !payments.length && (isLoading || isFetching),
    isFetched: !isRefetch.current && (isError || isSuccess),
    hasMore: (data?.paginate?.totalCount ?? 0) > localPayments.current.length,
    fetchPayments,
    refetchPayments,
  };
};
