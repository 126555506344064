import { memo, Ref, useImperativeHandle, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSlateStatic } from 'slate-react';
import { KeyQuoteForm, KeyQuoteFormData } from './keyQuoteForm';

export interface AddKeyQuoteRef {
  open: () => void;
  close: () => void;
}

export interface AddKeyQuoteProps {
  modalRef: Ref<AddKeyQuoteRef>;
  initialFormData?: KeyQuoteFormData;
  onSubmit: SubmitHandler<KeyQuoteFormData>;
}

export const AddKeyQuoteModal = memo(({ modalRef, initialFormData, onSubmit }: AddKeyQuoteProps) => {
  const editor = useSlateStatic();

  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  useImperativeHandle(modalRef, () => ({
    open: () => {
      if (!editor.selection) return;
      setShowModal(true);
    },
    close: onClose,
  }));

  if (!showModal) {
    return null;
  }

  return (
    <KeyQuoteForm
      onClose={onClose}
      initialFormData={initialFormData}
      onSubmit={(formData) => {
        onSubmit(formData);
        onClose();
      }}
    />
  );
});
