import * as yup from 'yup';
import { Option } from '@components/Select/types';
import { HumanResponse } from '@api/admin/adminGeneratedApi';

export type TValues = {
  subject: Option | string;
  text: string;
  userName?: string;
  email?: string | HumanResponse;
};

export const validationSchema = yup.object({
  subject: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .typeError('Обязательное поле')
    .required('Обязательное поле'),
  text: yup.string().required('Обязательное поле'),
  userEmail: yup.mixed().oneOf([
    yup.string(),
    yup.object({
      id: yup.string(),
      email: yup.string(),
    }),
  ]),
  userName: yup.string(),
});
