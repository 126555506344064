import { useCallback } from 'react';
import { UserRole } from '@area/users/roles';
import { useCurrentUser } from './useCurrentUser';

export const useCurrentUserRoles = () => {
  const { currentUser } = useCurrentUser();

  const hasRole = useCallback(
    (role: UserRole | UserRole[]): boolean => {
      if (!currentUser) return false;

      if (Array.isArray(role)) {
        return role.some((r) => currentUser.role.includes(r as any));
      }

      return currentUser.role.includes(role as any);
    },
    [currentUser],
  );

  return {
    roles: currentUser?.role ?? [],
    hasRole,
  };
};
