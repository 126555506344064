import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { UserTabs } from '@area/users/components/UserTabs';
import styles from './style.module.scss';

export const EditUserPage = () => {
  usePageTitle('Данные пользователя');

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Данные пользователя"
          backBtnTitle="Назад к списку пользователей"
          backBtnRoute={buildRoutePath(Routes.users, { absolute: true })}
        />
      </Grid>
      <Grid item>
        <UserTabs />
      </Grid>
    </Grid>
  );
};
