import { ReactNode, useMemo, KeyboardEvent, memo, useCallback } from 'react';
import cn from 'classnames';
import { createEditor } from 'slate';
import { Slate, Editable, RenderElementProps } from 'slate-react';
import { ThemeProvider } from '@mui/material';
import { isHotkey } from 'is-hotkey';
import { Toolbar } from './components/Toolbar';
import { renderElement } from './components/Element';
import { renderLeaf } from './components/Leaf';
import { CharacterCount } from './components/CharacterCount';
import { applyHotkey, toggleTab } from './utils/keyboard';
import { hotkeysList } from './constants';
import { innerTheme } from './theme';
import { CustomDescendant, CustomEditor } from './types';
import { plugins } from './plugins';
import styles from './styles.module.scss';
import '@styles/editor.css';

export { serialize, deserialize } from './utils/serialize';

export interface WysiwygProps {
  initialValue?: CustomDescendant[];
  onChange?: (value: CustomDescendant[]) => void;
  bottomComponent?: ReactNode;
}

const baseValue: CustomDescendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const Wysiwyg = memo(({ initialValue, bottomComponent, onChange }: WysiwygProps) => {
  const editor = useMemo(() => {
    return plugins.reduce<CustomEditor>((acc, withPlugin) => withPlugin(acc), createEditor());
  }, []);

  const onLocalChange = (value: CustomDescendant[]) => {
    onChange?.(value);
  };
  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.code === 'KeyZ' && event.ctrlKey) {
      editor.undo();
    }

    if (isHotkey(hotkeysList, event)) {
      return applyHotkey(editor, event);
    }

    toggleTab(editor, event);
  };

  // взято отсюда https://github.com/ianstormtaylor/slate/pull/4540#issuecomment-951380551
  const value = initialValue && initialValue.length > 0 ? initialValue : baseValue;

  const renderElementCb = useCallback((props: RenderElementProps) => {
    return renderElement(props);
  }, []);

  return (
    <div className={styles.root}>
      <Slate editor={editor} value={value} onChange={onLocalChange}>
        <ThemeProvider theme={innerTheme}>
          <div className={styles.border}>
            <Toolbar />
            <div className={styles.viewportBox} id="editorViewport">
              <Editable
                spellCheck
                className={cn('editor-content', styles.viewport)}
                placeholder="Введите текст"
                renderElement={renderElementCb}
                renderLeaf={renderLeaf}
                onKeyDown={onKeyDown}
                scrollSelectionIntoView={() => {}}
              />
            </div>
            <CharacterCount />
          </div>
        </ThemeProvider>
      </Slate>
      {bottomComponent}
    </div>
  );
});
