import { useNewsTagsControllerGetNewsTagsQuery } from '@api/admin/adminGeneratedApi';
import { Option, SelectProps } from '@components/Select/types';
import { useNewsTagsControllerDeleteNewsTagMutation } from '@api/admin/adminApi';

export const useRubricsOptions = (): Pick<SelectProps, 'options'> & {
  isLoading: boolean;
} => {
  const { data: newsTagsResponse, isLoading } = useNewsTagsControllerGetNewsTagsQuery();
  const [deleteTag] = useNewsTagsControllerDeleteNewsTagMutation();

  const rubricsOptions: Option[] = (newsTagsResponse ?? []).map((newsTag) => ({
    value: newsTag.id,
    label: newsTag.title,
    remove: (id: string) => deleteTag({ id }),
  }));

  return {
    options: rubricsOptions,
    isLoading,
  };
};
