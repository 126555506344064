import { IdentityCardResponse } from '@api/admin/adminGeneratedApi';
import { ReactNode } from 'react';
import { DateService } from '@services/DateService';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@styles/colors.module.scss';
import styles from './styles.module.scss';

export interface PassportProps {
  data: IdentityCardResponse; // todo: replace
}

const fieldOptions: {
  field: Partial<keyof IdentityCardResponse | string>;
  title: string;
  formatter?: (value: any, record: IdentityCardResponse) => ReactNode;
}[] = [
  {
    field: 'series',
    title: 'Серия паспорта',
  },
  {
    field: 'number',
    title: 'Номер паспорта',
  },
  {
    field: 'birthPlace',
    title: 'Место рождения',
  },
  {
    field: 'giveDate',
    title: 'Дата выдачи',
    formatter: (value) => DateService.getLocalDate(new Date(value)),
  },
  {
    field: 'whoGive',
    title: 'Кем выдано',
  },
  {
    field: 'departmentCode',
    title: 'Код подразделения',
  },
  {
    field: 'validity',
    title: 'Срок действия',
  },
];

export const Passport = ({ data }: PassportProps) => {
  return (
    <Stack divider={<Divider className={styles.divider} />}>
      {fieldOptions.map(({ field, title, formatter }) => {
        if (!(field in data)) return null;
        const dataValue = data[field as keyof IdentityCardResponse];

        return (
          <Box key={field} className={styles.row}>
            <Typography className={styles.rowTitle}>{title}:</Typography>
            <Typography variant="body2" color={dataValue ? colors.black : colors.grey}>
              {dataValue !== undefined ? (formatter ? formatter(dataValue, data) : (dataValue as any)) : 'Не указан'}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
