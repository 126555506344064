import { Modal, ModalProps } from '@components/Modal';
import { Button, Grid, ThemeProvider, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import indents from '@styles/indents.module.scss';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FormField } from '@components/FormField';
import { innerTheme } from '@components/TooltipInnerTheme/theme';
import styles from './style.module.scss';
import { FieldToCheck } from './types';

interface Props extends Omit<ModalProps, 'title'> {
  form: UseFormReturn<Record<string, unknown>, any>;
  fieldsToCheck: FieldToCheck[];
  onClose: () => void;
  onSubmit: (formData: any) => void;
}

export const ModalCheckMistakes: FC<Props> = ({ form, fieldsToCheck, open, onClose, onSubmit }) => {
  const { handleSubmit } = form;

  const handleSave = async () => {
    await form.trigger(); // to show errors
    await handleSubmit(onSubmit)(); // form will submit if no errors
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Проверка на ошибки"
      actions={
        <Grid container flexDirection="row" gap={indents.m}>
          <Grid item>
            <Button onClick={handleSave} variant="contained" startIcon={<Icon name="logoIcon" color={colors.red} />}>
              Опубликовать
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined">
              Отмена
            </Button>
          </Grid>
        </Grid>
      }>
      <FormProvider {...form}>
        <Grid container flexDirection="column" gap={indents.l}>
          {fieldsToCheck.map((field) => {
            const fieldErrorState = form.formState.errors[field.fieldNameCheckbox];
            const fieldError =
              fieldErrorState && 'message' in fieldErrorState ? (fieldErrorState.message as string) : '';

            return (
              <Grid container flexDirection="column" key={field.fieldNameCheckbox} gap={indents.xs}>
                {field.title && <Typography variant="h2">{field.title}</Typography>}
                <Grid container alignItems="center" gap={indents.l} wrap="nowrap">
                  {field.fieldType === 'text' && (
                    <Grid item xs={12}>
                      <FormField name={field.fieldName} type="text" fullWidth />
                    </Grid>
                  )}
                  {field.fieldType === 'img' && field.imgUrl && (
                    <Grid item sx={{ width: 304 }}>
                      <img src={field.imgUrl} alt="" className={styles.img} />
                    </Grid>
                  )}
                  {field.fieldType === 'img' && !field.imgUrl && (
                    <Grid item sx={{ width: 304 }}>
                      <Typography variant="h2">Нет фото</Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <ThemeProvider theme={innerTheme}>
                      <Tooltip open={!!fieldError} title={fieldError} arrow placement="bottom-start">
                        <div>
                          <FormField
                            label={<>Я&nbsp;точно&nbsp;проверил</>}
                            name={field.fieldNameCheckbox}
                            type="checkbox"
                          />
                        </div>
                      </Tooltip>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </FormProvider>
    </Modal>
  );
};
