import { Option, SelectProps } from '@components/Select/types';
import { useAuthorsControllerCreateAuthorMutation } from '@api/admin/adminApi';
import { useAuthorsOptions } from './useAuthors';

export const useAuthorsSelectOptions = (): Pick<
  SelectProps,
  'options' | 'onClickAddNewIfNotMatch' | 'addNewIfNotMatchLabel'
> => {
  const { options } = useAuthorsOptions();

  const [createAuthor] = useAuthorsControllerCreateAuthorMutation();

  const onClickAddNewAuthor: SelectProps['onClickAddNewIfNotMatch'] = async (value: string): Promise<Option> => {
    const newsTag = await createAuthor({
      authorRequest: {
        name: value,
      },
    }).unwrap();
    return {
      label: newsTag.name,
      value: newsTag.id,
    };
  };

  return {
    onClickAddNewIfNotMatch: onClickAddNewAuthor,
    options,
    addNewIfNotMatchLabel: (value) => `Ничего не найдено, добавить нового автора "${value}"?`,
  };
};
