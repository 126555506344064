import { useState } from 'react';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Grid, Button, Box, Typography, LinearProgress } from '@mui/material';
import { Modal } from '@components/Modal';
import { FormProvider, useForm, DefaultValues, SubmitHandler } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { EmailFormData, validationSchema } from '../validation/validationSchemaEmail';
import styles from './style.module.scss';

interface FormProps {
  defaultValues: DefaultValues<EmailFormData>;
  onSubmit: SubmitHandler<EmailFormData>;
  readonly?: boolean;
  isLoading: boolean;

  onSendEmail?(): void;

  onDelete?(): void;
}

export const EditEmailForm = ({
  defaultValues,
  onSubmit,
  readonly = false,
  isLoading,
  onSendEmail,
  onDelete,
}: FormProps) => {
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const resolver = useYupValidationResolver<EmailFormData>(validationSchema);

  const form = useForm<EmailFormData>({
    defaultValues: defaultValues as unknown as EmailFormData,
    resolver,
  });

  const handleSubmit = form.handleSubmit(onSubmit);

  const toggleSendModal = () => setSendModalOpen(!sendModalOpen);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        {/*<Grid item className={styles.logBtnWrapper}>*/}
        {/*  {!isNew && (*/}
        {/*    <Button variant="outlined" className={styles.logBtn} onClick={toggleSendModal}>*/}
        {/*      Лог рассылки*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</Grid>*/}

        <FormProvider {...form}>
          {/*<Grid item>*/}
          {/*  <FormField*/}
          {/*    name="date"*/}
          {/*    type="dateTimePicker"*/}
          {/*    title="Дата отправки"*/}
          {/*    sx={{ width: 200 }}*/}
          {/*    disabled={readonly}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item>
            <FormField name="title" title="Заголовок письма" fullWidth disabled={readonly} />
          </Grid>
          <Grid>
            <FormField name="text" title="Текст" type="wysiwyg" />
          </Grid>
          <Grid item flex={1}>
            <FormField name="targets" type="text" title="Кому, через запятую" fullWidth disabled={readonly} />
            <Typography fontWeight={400} fontSize="13px" marginTop="7px" marginLeft="5px">
              Доступные группы: <br />
              all_email - Все пользователи <br />
              esk_card_email - Владельцы карт ЕСК <br />
              extra_grant_email - Подавшие заявку на доп стипендию <br />
              credit_grant_email - Подавшие заявку на компенсацию кредита <br />
            </Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <FormField
                name="from"
                type="select"
                placeholder="Выберите отправителя"
                title="Отправитель"
                disabled={readonly}
                sx={{ maxWidth: 360 }}
                options={[
                  {
                    label: 'campus@studgorod.ru',
                    value: 'campus@studgorod.ru',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </FormProvider>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
      {!readonly && (
        <Box className={styles.submitBtnWrapper}>
          <Button
            disabled={readonly}
            variant="contained"
            className={styles.submitBtn}
            startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
            onClick={handleSubmit}>
            Сохранить
          </Button>
          {onSendEmail && (
            <Button
              variant="contained"
              className={styles.sendBtn}
              startIcon={<Icon name="send" className={styles.submitBtnIcon} />}
              onClick={onSendEmail}>
              Отправить
            </Button>
          )}
          {onDelete && (
            <Button
              variant="outlined"
              className={styles.delBtn}
              startIcon={<Icon name="delete" className={styles.submitDelIcon} />}
              onClick={onDelete}>
              Удалить
            </Button>
          )}
        </Box>
      )}
      <Modal
        open={sendModalOpen}
        title="Отправка сообщения"
        maxWidth="sm"
        onClose={toggleSendModal}
        actions={
          <Button variant="contained" onClick={toggleSendModal} className={styles.closeModalBtn}>
            Закрыть
          </Button>
        }>
        <Grid container gap={indents.s}>
          <Grid item xs={12}>
            <Typography variant="body2">Собираем HTML письма.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Письмо собралось.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Создаем сообщение.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запускается....</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запущена.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Отправлено: 1 пользователю..</Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
