import { CSSProperties } from 'react';
import { RenderLeafProps } from 'slate-react';

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.quote) {
    children = (
      <q className="quote">
        <img
          width="15"
          height="22"
          alt=""
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNzg3IDEyLjY5NWMtLjE5NS0uNDU1LS4wMi0uOTc5LjM2My0xLjI5MmE2LjQyMSA2LjQyMSAwIDAgMCAyLjM1OC00Ljk4M0MxMy41MDggMi44NzQgMTAuNjUgMCA3LjEyMyAwIDMuNTk2IDAgLjczOCAyLjg3NC43MzggNi40MmMwIDIuMDEuOTE5IDMuODA2IDIuMzU4IDQuOTgzLjM4My4zMTQuNTU4LjgzNy4zNjMgMS4yOTNMLjA4MSAyMC42MDhBMSAxIDAgMCAwIDEuMDAxIDIyaDEyLjI0NGExIDEgMCAwIDAgLjkyLTEuMzkybC0zLjM3OC03LjkxM1oiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo="
        />
        {children}
      </q>
    );
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.sub) {
    children = <sub>{children}</sub>;
  } else if (leaf.sup) {
    children = <sup>{children}</sup>;
  }

  const style: CSSProperties = {
    fontFamily: leaf.fontName,
    fontSize: leaf.fontSize,
    // хак, для того чтобы после ссылки можно было вводить простой текст
    paddingLeft: leaf.text === '' ? '0.1px' : undefined,
  };

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

export const renderLeaf = ({ children, ...props }: RenderLeafProps) => <Leaf {...props}>{children}</Leaf>;
