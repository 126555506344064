import { PropsWithChildren } from 'react';
import { withNotifications } from '@area/notifications/components/withNotifications';
import { MediaFilesUploadSnackbar } from '@area/mediaFilesUploadSnackbar';
import styles from './styles.module.scss';

export const Root = withNotifications(({ children }: PropsWithChildren) => (
  <div className={styles.root}>
    {children}
    <MediaFilesUploadSnackbar />
  </div>
));
