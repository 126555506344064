import {
  useEventsControllerFilterEventsMutation,
  EventsControllerFilterEventsApiArg,
  EventFiltersRequest,
} from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';
import { DEFAULT_KAMPUS_EVENTS_SIZE } from '../defaultValues';

export const useKampusEvents = () => {
  const query: EventsControllerFilterEventsApiArg = {
    skip: 0,
    size: DEFAULT_KAMPUS_EVENTS_SIZE,
    eventFiltersRequest: {
      excludeDrafts: false,
      excludeFuture: false,
    },
  };
  const [getKampusEvents, res] = useEventsControllerFilterEventsMutation();

  const handleRequest = (
    args?: Omit<EventsControllerFilterEventsApiArg, 'eventFiltersRequest'> & {
      eventFiltersRequest: Partial<EventFiltersRequest>;
    },
  ) => {
    const request: EventsControllerFilterEventsApiArg = {
      ...query,
      ...args,
      eventFiltersRequest: {
        ...query.eventFiltersRequest,
        ...args?.eventFiltersRequest,
      },
    };

    getKampusEvents(request);
  };

  const getValuesFromParams = (params: { [key: string]: string }) => {
    const date = params.date ? DateService.getRangeDateFromParams(params.date) : undefined;

    return { ...params, date };
  };

  return {
    getKampusEvents: handleRequest,
    eventsList: res.data?.data,
    paginate: res.data?.paginate || {
      totalCount: 0,
    },
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
    getValuesFromParams,
  };
};
