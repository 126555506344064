/* eslint-disable no-unused-vars */

// TODO: заменить как подъедет решение от Бэкенда
// eslint-disable-next-line typescript-enum/no-enum
export enum MetaTagTitles {
  title = 'title',
  description = 'description',
  keywords = 'keywords',
  viewport = 'viewport',
}
