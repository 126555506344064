import { createTheme, Theme } from '@mui/material';
import colors from '@styles/colors.module.scss';

export const innerTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            backgroundColor: colors.background,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            width: 38,
            height: 38,
            border: 'none !important',
            marginLeft: '0 !important',
            color: colors.black,
            transition: 'background-color 0.124s ease-in',
            '&:hover': {
              backgroundColor: colors.greyLight,
            },
            '&.Mui-selected': {
              backgroundColor: colors.greyLight,
            },
            '&.Mui-disabled': {
              opacity: 0.6,
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            backgroundColor: colors.background,
            overflow: 'hidden',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            width: 38,
            height: 38,
            color: colors.black,
            borderRadius: 0,
            backgroundColor: colors.background,
            transition: 'background-color 0.124s ease-in',
            '&:hover': {
              backgroundColor: colors.greyLight,
            },
            '&.Mui-disabled': {
              opacity: 0.6,
            },
          },
        },
      },
    },
  });
