import { createTheme, Theme } from '@mui/material';

export const innerTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: '0',
            borderRadius: '8px',
          },
          popper: {
            '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
              marginLeft: `2px !important`,
            },
          },
        },
      },
    },
  });
