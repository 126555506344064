import { IconButton } from '@mui/material';
import { Icon } from '@components/Icon';
import { FC, MouseEvent, useRef } from 'react';
import { AddDefinitionInfoRef } from '@area/redactor/components/Wysiwyg/components/Toolbar/components/DefinitionInfo/AddDefinitionInfoModal';
import { useSlate } from 'slate-react';
import { AddImageToEditorModal } from '@area/redactor/components/Wysiwyg/components/Toolbar/components/Image/AddImageToEditorModal';
import { useFilesControllerUploadFileMutation } from '@api/admin/adminGeneratedApi';
import { ImageFormData } from '@area/redactor/components/Wysiwyg/components/Toolbar/components/Image/ImageForm/ImageForm';
import { insertImage } from '@area/redactor/components/Wysiwyg/utils/image';
import { FileType } from '@hooks/useUploadMediaFiles';
import { Location } from 'slate';

interface ImageButtonProps {
  isTable?: boolean;
}

export const ImageButton: FC<ImageButtonProps> = ({ isTable = false }) => {
  const [uploadFile] = useFilesControllerUploadFileMutation();

  const modalRef = useRef<AddDefinitionInfoRef>(null);
  const editor = useSlate();

  const onMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onClickAdd = () => {
    modalRef.current?.open();
  };

  const handleSubmit = async (formData: ImageFormData) => {
    if (formData.imageUrl) {
      insertImage(editor, formData);
    } else if (formData.image) {
      const fd = new FormData();
      const { selection } = editor;
      fd.set('file', formData.image);
      fd.set('type', FileType.editorPhoto.toString());
      const res = await uploadFile({ subjectId: '-1', mediaFileUpdaterDto: fd as any }).unwrap();
      insertImage(
        editor,
        {
          imageUrl: res.url,
          imageId: res.id,
          position: formData.position,
          author: formData.author,
          description: formData.description,
        },
        selection as Location,
      );
    }
  };

  return (
    <>
      <IconButton
        centerRipple={false}
        onMouseDown={onMouseDown}
        onClick={onClickAdd}
        title="Вставить изображение"
        disabled={isTable}>
        <Icon name="wysiwygImage" viewBox="0 0 16 16" />
      </IconButton>
      <AddImageToEditorModal modalRef={modalRef} onSubmit={handleSubmit} />
    </>
  );
};
