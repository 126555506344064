import { CustomTextMarks, ElementType } from '../../types';
import { HtmlTag, MarkHtmlTag } from './types';

export const elementsToTagsMap: Record<Exclude<ElementType, 'table' | 'link'>, HtmlTag> = {
  caption: 'p',
  paragraph: 'p',
  'table-body': 'tbody',
  'table-row': 'tr',
  'table-header': 'th',
  'table-cell': 'td',
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'ordered-list': 'ol',
  'unordered-list': 'ul',
  'list-item': 'li',
  'quote-with-photo': 'div',
  'article-link': 'div',
  'key-quote': 'div',
  'definition-info': 'div',
  image: 'img',
  strong: 'strong',
  em: 'em',
  q: 'q',
};

export const getElementTypeByHtmlElement = (el: HTMLElement): ElementType => {
  const tag: HtmlTag = el.nodeName.toLowerCase() as HtmlTag;
  switch (tag) {
    case 'a': {
      return 'link';
    }
    case 'table': {
      return 'table';
    }
    case 'tbody': {
      return 'table-body';
    }
    case 'tr': {
      return 'table-row';
    }
    case 'th': {
      return 'table-header';
    }
    case 'td': {
      return 'table-cell';
    }
    case 'h1': {
      return 'heading-1';
    }
    case 'h2': {
      return 'heading-2';
    }
    case 'h3': {
      return 'heading-3';
    }
    case 'ol': {
      return 'ordered-list';
    }
    case 'ul': {
      return 'unordered-list';
    }
    case 'li': {
      return 'list-item';
    }
    case 'strong': {
      return 'strong';
    }
    case 'em': {
      return 'em';
    }
    case 'q': {
      return 'q';
    }
    case 'div': {
      switch (el.className) {
        case 'quote-with-photo': {
          return 'quote-with-photo';
        }
        case 'article-link': {
          return 'article-link';
        }
        case 'key-quote': {
          return 'key-quote';
        }
        case 'definition-info': {
          return 'definition-info';
        }
        case 'image-right':
        case 'image-left':
        case 'image-center':
        case 'image-fullWidth': {
          return 'image';
        }
        default: {
          return 'paragraph';
        }
      }
    }
    default: {
      return 'paragraph';
    }
  }
};

export const tagsToMarksMap: Record<MarkHtmlTag | string, keyof CustomTextMarks> = {
  strong: 'bold',
  em: 'italic',
  u: 'underline',
  s: 'strikethrough',
  code: 'code',
  sub: 'sub',
  sup: 'sup',
};
