import { HumanResponse } from '@api/admin/adminGeneratedApi';

export class HumanService {
  public static getShortHumanName({ lastName, firstName, middleName }: HumanResponse) {
    const firstNameLetter = firstName?.[0] ?? '';
    const middleNameLetter = middleName?.[0] ?? '';

    return `${lastName} ${firstNameLetter}.${middleNameLetter}.`;
  }
}
