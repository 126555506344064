import {
  NewsControllerFilterNewsApiArg,
  NewsFiltersRequest,
  useNewsControllerFilterNewsMutation,
} from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';
import { DEFAULT_PUBLICATIONS_SIZE } from '../defaultValues';

export const usePublications = () => {
  const query: NewsControllerFilterNewsApiArg = {
    size: DEFAULT_PUBLICATIONS_SIZE,
    newsFiltersRequest: {
      excludeDrafts: false,
      excludeFuture: false,
    },
  };
  const [getPublications, res] = useNewsControllerFilterNewsMutation();

  const handleRequest = (
    args?: Omit<NewsControllerFilterNewsApiArg, 'newsFiltersRequest'> & {
      newsFiltersRequest: Partial<NewsFiltersRequest>;
    },
  ) => {
    const request: NewsControllerFilterNewsApiArg = {
      ...query,
      ...args,
      newsFiltersRequest: {
        ...query.newsFiltersRequest,
        ...args?.newsFiltersRequest,
      },
    };

    getPublications(request);
  };

  const getValuesFromParams = (params: { [key: string]: string }) => {
    const authorIds = params.authorIds ? decodeURIComponent(params.authorIds).split(',') : [];
    const tagIds = params.tagIds ? decodeURIComponent(params.tagIds).split(',') : [];
    const date = params.date ? DateService.getRangeDateFromParams(params.date) : undefined;

    return { ...params, authorIds, tagIds, date };
  };

  return {
    getPublications: handleRequest,
    publicationsList: res.data?.data,
    paginate: res.data?.paginate,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
    getValuesFromParams,
  };
};
