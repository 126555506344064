import { useSlate } from 'slate-react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent } from 'react';
import { Editor } from 'slate';
import { Icon } from '@components/Icon';
import { toggleMark } from '@area/redactor/components/Wysiwyg/utils/elements';
import { CustomTextMark } from '@area/redactor/components/Wysiwyg/types';
import { textFormattingButtons } from '@area/redactor/components/Wysiwyg/constants';

export const TextFormatting = () => {
  const editor = useSlate();
  const marks = Editor.marks(editor);

  const onMouseDown = (event: MouseEvent) => {
    event.preventDefault();
  };
  const onClick = (_: MouseEvent, value: CustomTextMark) => {
    toggleMark(editor, value);
  };

  return (
    <ToggleButtonGroup value={marks ? Object.keys(marks) : null}>
      {textFormattingButtons.map(({ value, icon, title }) => (
        <ToggleButton key={value} value={value} onMouseDown={onMouseDown} onClick={onClick} title={title}>
          <Icon name={icon} viewBox="0 0 16 16" />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
